import type { DropdownInputGroupTierTwo } from "../../../types/modulesInterfaces";
import { Field, useFormikContext, getIn } from "formik";
import { useEffect, useState } from "react";
import { TierTwoIcon } from "../../../utils/customIcons";

import FormRow from "../formRow/FormRow";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import CustomSelectInput from "../CustomSelectInput";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import TranslatableText from "../../translations/TranslatableText";

const TierTwoDropdown = ({
	inputName,
	label,
	links,
	unit,
	index,
	dropdownOptions,
	disabled,
	autosubmit,
}: DropdownInputGroupTierTwo) => {
	const { values, errors, touched } = useFormikContext();

	const [rowValue, setRowValue] = useState<string | number | undefined>(undefined);
	const [defaultValue, setDefaultValue] = useState<string | number | undefined>(undefined);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const [name, setName] = useState<string>("");
	const translatedLabel = useCustomTranslation(label);
	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	useEffect(() => {
		setRowValue(getIn(values, name));
	}, [values, name]);

	useEffect(() => {
		const value: string | number = getIn(values, `${name}_default`);
		setDefaultValue(typeof value === "string" ? value : value?.toFixed(2));
	}, [values, name]);

	return (
		<TierTwoRowTooltip links={links} label={label}>
			<div
				className={`tiertwo-row ${links?.length ? "tiertwo-row-hover" : ""
					}`}
			>
				<TierTwoIcon fill={links?.length ? "active" : undefined} />
				<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
					{rowValue ? <div className="has-changed-dot" /> : null}
					<TranslatableText className="fs-12" translationKey={label}/>
					<span className="fs-10 op-5">{unit}</span>
				</div>
				<FormRow
					topLabel={`Default = ${defaultValue?.toString() ?? ""}`}
					isFetching={isFetchingData}
					error={
						getIn(errors, name) && getIn(touched, name)
							? getIn(errors, name)
							: undefined
					}
				>
					<Field
						label={translatedLabel}
						name={name}
						id={name}
						component={CustomSelectInput}
						options={dropdownOptions}
						disabled={disabled}
						classes={rowValue ? "tiertwo-input-changed" : ""}
						autosubmit={autosubmit}
					/>
				</FormRow>
			</div>
		</TierTwoRowTooltip>
	);
};

export default TierTwoDropdown;
