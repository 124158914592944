import authReducer from "./features/auth/authSlice";
import activityBuilderReducer from "./features/builder/builderSlice";
import projectReducer from "./features/project/projectSlice";
import headerReducer from "./features/header/headerSlice";
import registerReducer from "./features/register/registerSlice";
import setupAssistantReducer from "./features/setupAssistant/setupAssistantSlice";
import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "./features/api/apiSlice";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	blacklist: [apiSlice.reducerPath],
};

const rootReducer = combineReducers({
	// Add the generated reducer as a specific top-level slice
	/* person: personReducer, */
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth: authReducer,
	project: projectReducer,
	builder: activityBuilderReducer,
	register: registerReducer,
	header: headerReducer,
	setupAssistant: setupAssistantReducer,
});

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
	persistConfig,
	rootReducer,
);

const store = configureStore({
	reducer: persistedReducer,
	// Adding the api middleware enables caching, invalidation, polling, and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(apiSlice.middleware), //thunk when adding persistence
	devTools: process.env.NODE_ENV !== "production",
});
//for vitest
export const setupStore = (preloadedState?: Partial<RootState>) => {
	return configureStore({
	  reducer: rootReducer,
	  middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(apiSlice.middleware), //thunk when adding persistence
	  devTools: process.env.NODE_ENV !== "production",
	  preloadedState
	})
  }
  // export type RootState = ReturnType<typeof rootReducer>
  // export type AppDispatch = AppStore['dispatch']
  export type AppStore = ReturnType<typeof setupStore>

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>; //used by useAppSelector in hooks
export type AppDispatch = typeof store.dispatch; //used by useAppDispatch
export { persistor };
export default store;
