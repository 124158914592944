import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import type { Activity, Project, ProjectArea } from "../../../types/interfaces";

//missing carbon balance and notes
const initialState: ProjectState = {
	project: undefined,
	activity: undefined,
	activeActivityId: null,
};

const projectSlice = createSlice({
	name: "project",
	initialState: initialState,
	reducers: {
		setProject: (state, action: PayloadAction<Project | null>) => {
			state.project = action.payload;
		},
		setProjectArea: (state, action: PayloadAction<ProjectArea | null>) => {
			if (state.project) {
				state.project = { ...state.project,area: action.payload }
			}
		},
		setActivity: (state, action: PayloadAction<Activity>) => {
			state.activity = action.payload;
			state.activeActivityId = action.payload.id;
		},
		clearProject: (state) => {
			state.project = null;
			state.activity = null;
			state.activeActivityId = null;
		},
	},
});

export const { setProject, setActivity, clearProject, setProjectArea } =
	projectSlice.actions;
export const selectCurrentProject = (state: RootState) => state.project;
export const selectCurrentActiveActivity = (state: RootState) =>
	state.project.activeActivityId;

export default projectSlice.reducer;

interface ProjectState {
	project?: Project | null;
	activeActivityId?: number | null;
	activity?: Activity | null;
	activities?: Activity[],
}
