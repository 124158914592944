import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { motion } from "framer-motion";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentToken } from "../../app/features/auth/authSlice";
import LoginImage from "./LoginImage";

const AuthLayout = () => {
	const accessToken: string | null = useAppSelector(selectCurrentToken);
	const location = useLocation();
	const navigate = useNavigate();

	const isInTermsAndCond = useMemo(() => {
		return location.pathname.includes("terms-and-conditions");
	}, [location]);

	useEffect(() => {
		if (accessToken)
			navigate("/");
	}, [accessToken])

	return isInTermsAndCond ? (
		<div className="login-container">
			<ReturnToRegister />
			<article className="bg-gray my-2">
				<Outlet />
			</article>
			<ReturnToRegister />
		</div>
	) : (
		<div className="login-container">
			<LoginImage />
			<article className="login-content bg-gray mb-2">
				<Outlet />
			</article>
		</div>
	);
};

const ReturnToRegister = () => {
	return (
		<div className="w-100 d-flex justify-content-end">
			<Link
				to="/auth/register"
				className="fs-13 text-darker-gray no-decoration"
			>
				<motion.div
					className="d-flex align-items-center gap-8"
					whileHover={{ textDecoration: "underline" }}
				>
					Return to registration
					<MdKeyboardBackspace size={24} color="var(--darker-gray)" />
				</motion.div>
			</Link>
		</div>
	);
};

export default AuthLayout;
