import { apiSlice } from "../api/apiSlice";
import type { Group, ProjectInvitation } from "./permissionTypes";

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getGroups: builder.query<Group[], void>({
			query: () => "/groups/",
		}),
		getProjectInvitation: builder.query<ProjectInvitation[], number | null>({
			query: (id) =>
				id ? `/project-invitations/?project=${id}` : "/project-invitations/",
			providesTags: (_res, _error, id) =>
				id
					? [{ type: "Invitations", id }]
					: [{ type: "Invitations", id: "LIST" }],
		}),
		createProjectInvitation: builder.mutation<
			ProjectInvitation,
			ProjectInvitation
		>({
			query: (body) => ({
				url: "/project-invitations/",
				method: "POST",
				body,
			}),
			invalidatesTags: (_results, _error, data) => [
				{ type: "Invitations", id: data.project },
				"ProjectUsers",
			],
		}),
	}),
});

export const {
	useGetGroupsQuery,
	useCreateProjectInvitationMutation,
	useGetProjectInvitationQuery,
} = authApiSlice;
