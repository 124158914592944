import React, { useState } from 'react'
import { useDeleteProjectMutation } from '../../app/features/project/projectApiSlice';
import type { FastProject } from '../../types/interfaces';

const useDeleteProject = (refetch?: () => void) => {
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [toDeleteId, setToDeleteId] = useState<number | null>(null);

    const [deleteProject, { isLoading: isLoadingDelete }] =
        useDeleteProjectMutation();


    const handleDelete = async () => {
        if (isLoadingDelete || !toDeleteId) return;
        await deleteProject(toDeleteId).unwrap();
        setDeleteDialogOpen(false)
        if (refetch) refetch()
    }

    const showDeleteModal = (project: FastProject) => {
        setDeleteDialogOpen(true)
        setToDeleteId(project.id)
    }

    const handleCancel = () => {
        setDeleteDialogOpen(false)
    }

    return {
        isDeleteDialogOpen,
        handleCancel,
        handleDelete,
        showDeleteModal,
        isLoadingDelete
    }

}

export default useDeleteProject