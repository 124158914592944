import { Field, getIn, useFormikContext } from "formik";
import { useState, useEffect, FC } from "react";
import { HectaresInputGroup } from "../../../types/modulesInterfaces";
import FormRow from "../formRow/FormRow";
import CustomInput from "../CustomInput";
import { useCustomTranslation } from "../../../app/hooks";
import TranslatableText from "../../translations/TranslatableText";

const Hectares: FC<HectaresInputGroup> = ({
	label,
	unit,
	inputName,
	disabled,
	inputType,
	comment,
	help,
	index,
	autosubmit,
}) => {
	const [name, setName] = useState<string>("");
	const { errors, touched } = useFormikContext();
	const translatedLabel = useCustomTranslation(label);


	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	return (
		<div className="d-flex align-items-center mb-3">
			<span className="ff-medium fs-13 h-100 hectares-text pb-1 me-4">
				{translatedLabel ?? label}
			</span>
			<FormRow
				style={{ width: "fit-content" }}
				rowHelp={help}
				rowComment={comment}
				error={
					getIn(errors, name) && getIn(touched, name)
						? getIn(errors, name)
						: undefined
				}
			>
				<div className="d-flex align-items-center">
					<Field
						label={unit}
						name={inputName}
						id={inputName}
						component={CustomInput}
						disabled={disabled}
						type={inputType ? inputType : "text"}
						textend
						// autosubmit={autosubmit}
					/>
					<TranslatableText className="ms-2 w-100 h-100 break-word d-flex aling-items-center fs-12 ff-light" translationKey="module.hectares_description"/>
				</div>
			</FormRow>
		</div>
	);
};

export default Hectares;
