import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from "../../app/features/auth/authSlice";
import { User } from '../../types/interfaces';
import { useUpdatePasswordMutation } from '../../app/features/auth/authApiSlice';
import { Form, Formik, FormikProps } from 'formik';
import { ClassicInputGroup, FieldType } from '../../types/modulesInterfaces';
import { UpdatePasswordPayload } from '../../app/features/auth/authTypes';
import { motion } from 'framer-motion';
import * as Yup from "yup"
import GenericInputGroup from '../input/inputGroup/GenericInputGroup';
import ConfirmationModal from '../modal/ConfirmationModal';
import useErrorMessage from '../../utils/useErrorMessage';
import { useEffect } from 'react';
interface UpdatePassword {
  old_password: string,
  new_password: string,
  confirm_password: string,
}

const initValues: UpdatePassword = {
  old_password: "",
  new_password: "",
  confirm_password: "",
}

const inputGroups: ClassicInputGroup[] = [
  {
    type: FieldType.CLASSIC,
    inputName: "old_password",
    inputType: "password",
    label: "user.old_password",
  },
  {
    type: FieldType.CLASSIC,
    inputName: "new_password",
    inputType: "password",
    label: "user.new_password",
  },
  {
    type: FieldType.CLASSIC,
    inputName: "confirm_password",
    inputType: "password",
    label: "user.confirm_password",
  },
]

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("validations.field_required"),
  new_password: Yup.string().required("validations.field_required"),
  confirm_password: Yup.string()
    .required("validations.field_required")
    .oneOf([Yup.ref('new_password'), null], "validations.password_must_match")
});

interface PasswordModalProps {
  isUpdatePasswordDialogOpen: boolean,
  closeDialog: () => void;
}

const UpdatePasswordModal = ({ isUpdatePasswordDialogOpen, closeDialog }: PasswordModalProps) => {
  const user: User | null = useAppSelector(selectCurrentUser);
  const [updatePasswordUser, { isLoading, isError, error, isSuccess }] =
    useUpdatePasswordMutation();

  const { errorMsg } = useErrorMessage({ error, isError })

  const handleSubmit = async (values: UpdatePassword) => {
    if (isLoading || !user) return;
    const payload: UpdatePasswordPayload = {
      new_password: values.new_password,
      old_password: values.old_password,
      userId: user.id
    }
    await updatePasswordUser(payload).unwrap();
  }

  useEffect(() => {
    if (isSuccess)
      closeDialog()
  }, [isSuccess])


  return (
    <Formik initialValues={initValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, handleReset }: FormikProps<UpdatePassword>) => (
        <Form>
          <ConfirmationModal
            open={isUpdatePasswordDialogOpen}
            onCancel={() => {
              closeDialog();
              handleReset()
            }}
            onConfirm={handleSubmit}
            message={<ModalContent footerError={errorMsg} />}
            headerText="user.change_password"
            size="md"
            isLoading={isLoading}
          />
        </Form>
      )}
    </Formik>
  )
}

const ModalContent = ({ footerError }: { footerError: string }) => {
  return (
    <div className='d-flex f-column w-100'>
      {inputGroups.map((input) => (
        <div className="py-1 pe-3" key={input.label}>
          <GenericInputGroup inputGroup={input} />
        </div>
      ))}

      <motion.p
        className={`text-error fs-13 ${(footerError && footerError.length > 0)}`}
      >
        {footerError
          ? footerError : ""}
      </motion.p>
    </div>
  )
}

export default UpdatePasswordModal


// TODO:
// the validation error is not visible
// do not close the modal until the password is reset
// display error messages
// add loading animation