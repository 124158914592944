import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { FEModules } from "../../../utils/moduleList";

const initialState: { progressiveModules: FEModules[] } = {
	progressiveModules: [],
};

const setupAssistant = createSlice({
	name: "setupAssistant",
	initialState: initialState,
	reducers: {
		setProgressiveModules: (state, action: PayloadAction<FEModules[]>) => {
			state.progressiveModules = action.payload;
		},
		clearProgressiveModules: (state) => {
			state.progressiveModules = [];
		},
	},
});

export const { setProgressiveModules, clearProgressiveModules } =
	setupAssistant.actions;
export const selectCurrentModules = (state: RootState) =>
	state.setupAssistant.progressiveModules;

export default setupAssistant.reducer;
