import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import Button from '../button/Button';
import usePages from './usePages';

const baseButtonStyle: React.CSSProperties = { 
  cursor: 'pointer',
  color: 'white',
  alignContent:'center', 
  alignSelf:'center', 
  borderRadius: '50%'            
}

const MdPageButtonStyle: React.CSSProperties = { 
  fontSize: '15px',
  margin: '0 5px',
  padding: '11px 15px',
  ...baseButtonStyle
}

const SmPageButtonStyle: React.CSSProperties = { 
  fontSize: '12px',
  margin: '0 5px',
  padding: '7px 10px',
  ...baseButtonStyle
}
interface PaginationProps {
    totalItems: number;
    itemsPerPage?: number;
    onPageChange?: (page: number) => void;
    onNext?: () => void;
    onPrevious?: () => void;
    onPageClick?: (page: number) => void;
    size: 'sm' | 'md' | 'lg';
    style?: React.CSSProperties;
}
const Pagination: React.FC<PaginationProps> = ({ 
  totalItems, 
  itemsPerPage = 10, 
  onPageChange, 
  onNext, 
  onPrevious,
  onPageClick,
  size,
  style,
}) => {

  const {handlePrevious, handleNext, handlePageClick, currentPage, totalPages} = usePages({
    totalItems,
    itemsPerPage,
    onPageChange,
    onNext,
    onPrevious,
    onPageClick
  })

  return (
    <div 
    style={{
      ...style,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.5rem',
    }}
    >
      <Button onClick={handlePrevious} disabled={currentPage === 1}>
        <GrPrevious size={20} />
      </Button>
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <Button
          key={page}
          onClick={() => handlePageClick(page)}
          testId={`pagination-${page}`}
          style={{
            ...(size === 'sm' ? SmPageButtonStyle : MdPageButtonStyle),
            backgroundColor: currentPage === page ? 'var(--darker-gray)' : 'var(--light-gray)',
          }}
        >
          {page}
        </Button>
      ))}
      <Button onClick={handleNext} disabled={currentPage === totalPages} testId="next-button-pagination">
        <GrNext size={20} />
      </Button>
    </div>
  )
};

export default Pagination;
