import { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useCreateModuleCommentMutation, useDeleteModuleCommentMutation, useGetModuleCommentsQuery } from '../../../app/features/api/modules/moduleCommentsApiSlice';
import { Thread } from './CommentsAction';

const useComments = ({ threadId }: { threadId: number }) => {
	const { data: thread, isLoading } = useGetModuleCommentsQuery({ threadId });
	const [createComment, { isLoading: isCommentLoading, isError: isCreateCommentError }] =
		useCreateModuleCommentMutation();
	const [deleteComment, { isLoading: isCommentDeleting, isError: isDeleteCommentError }] =
		useDeleteModuleCommentMutation();
	const [isCommentActive, setIsCommentActive] = useState<boolean>(false);

	const handleCreateComment = useCallback(
		({ content }: { content: string }) => {
			createComment({ threadId, content, parent: null });
		},
		[createComment, threadId],
	);

	const handleCreateReply = useCallback(
		({ content, parent }: { content: string; parent: number }) => {
			createComment({ threadId, content, parent });
		},
		[createComment, threadId],
	);

	const handleDeleteComment = useCallback(
		(id: number) => {
			deleteComment({ commentId: id });
		},
		[deleteComment],
	);

	useEffect(() => {
		if (isCommentActive) setIsCommentActive(false);
	}, [thread]);

	return {
		thread: thread ? new Thread(thread) : null,
		isLoading: isLoading || isCommentLoading || isCommentDeleting,
		isCommentActive,
		setIsCommentActive,
		handleCreateComment,
		handleCreateReply,
		handleDeleteComment,
		isCreateCommentError,
		isDeleteCommentError
	}
}

export default useComments