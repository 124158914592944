import React from 'react';
import Spinner from '../spinner/Spinner';

const LoadingFallback = () => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f3f4f6',
  };

  const loaderStyle = {
    width: '48px',
    height: '48px',
    color: '#3b82f6',
  };

  const textStyle = {
    marginTop: '25px',
    fontSize: '22px',
    fontWeight: '600',
    color: 'var(--primary-deep)',
  };

  return (
    <div style={containerStyle}>
       <div style={loaderStyle}>
        <Spinner isBlack={true} size={48}  />
       </div>
      <p style={textStyle}>Loading...</p>
    </div>
  );
};

export default LoadingFallback;