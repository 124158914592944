import { useEffect, useRef, useState } from "react";
import { SliderSeparator } from "../../utils/customIcons";
import { animate, motion } from "framer-motion";

type CarbonBalanceState = "good" | "bad" | "neutral";
const transition = {
	type: "spring",
	stiffness: 700,
	damping: 30,
	duration: 0.3,
};

const ResultSlider = ({ value }: { value: number }) => {
	const [cbState, setCbState] = useState<CarbonBalanceState>("neutral");
	useEffect(() => {
		const state = value < 0 ? "good" : value > 0 ? "bad" : "neutral";
		setCbState(state);
	}, [value]);

	return (
		<motion.div
			style={{
				justifyContent:
					cbState === "good"
						? "flex-start"
						: cbState === "bad"
							? "flex-end"
							: "center",
			}}
			transition={transition}
			className="result-slider-container"
			layout
		>
			<motion.div
				className="p-1 h-100 w-fit br-6 ff-bold-ext fs-12 text-white"
				style={{
					backgroundColor:
						cbState === "good"
							? "#52A021"
							: cbState === "bad"
								? "#CC1212"
								: "#FF8515",
				}}
				transition={transition}
				layout
			>
				<Counter value={value} />
			</motion.div>
			<Separator />
			<StartLabel />
		</motion.div>
	);
};

const Separator = () => {
	return (
		<div
			className="pos-absolute d-flex align-items-end justify-content-between"
			style={{
				height: 62,
				top: 18,
				left: "50%",
				transform: "translate(-50%, -50%)",
			}}
		>
			<SliderSeparator />
		</div>
	);
};

const StartLabel = () => {
	return (
		<div
			className="pos-absolute d-flex align-items-end justify-content-between"
			style={{
				width: 50,
				height: 60,
				top: 20,
				left: "48%",
				transform: "translate(-50%, -50%)",
			}}
		>
			<span className="text-dark fs-10">Start</span>
			<span className="text-dark fs-10">= 0</span>
		</div>
	);
};

function Counter({ value }: { value: number }) {
	const nodeRef = useRef<HTMLSpanElement>(null);
	const [localValue, setLocalValue] = useState<number>(0);

	useEffect(() => {
		const from = localValue;
		const to = value;
		const node = nodeRef.current;

		const controls = animate(from, to, {
			duration: 0.5,
			onUpdate(value) {
				const fixed = parseInt(value.toFixed(0));
				const options = {  maximumFractionDigits: 2   }  
				const formattedNumber = Intl.NumberFormat("en-US",options).format(fixed); 
				if (node) node.innerHTML = formattedNumber;
			},
		});
		setLocalValue(value);

		return () => controls.stop();
	}, [value]);

	return <span ref={nodeRef} />;
}

export default ResultSlider;
