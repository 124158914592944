import { apiSlice } from "../api/apiSlice";
import type { BEModules } from "../../../utils/moduleList";
import type { DefinitionEntry, DefinitionResponse } from "./definitionsTypes";

const convertDefinitionsToArray = (definitions: { [key: string]: string }): DefinitionEntry[] => {
  return Object.entries(definitions).map(([key, value]) => ({
    name: key,
    definition: value
  }));
};

export const activitiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getModuleDefinitions: builder.query<DefinitionEntry[] | null, BEModules | null>({
      query: (module) => `definitions/?module_type_id=${module}`,
      transformResponse: (response: DefinitionResponse) => {
        return Object.keys(response).length > 0 ? Object.entries(response).map(([key, value]) => ({
          name: key,
          definition: value.description
        })) : null;
      }
    })
  }),
})

export const {
  useGetModuleDefinitionsQuery,
} = activitiesApiSlice
