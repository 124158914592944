import  { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout, selectCurrentUser } from "../../app/features/auth/authSlice";

import useErrorMessage from '../../utils/useErrorMessage';
import { User } from '../../types/interfaces';
import { useDeleteUserMutation } from '../../app/features/auth/authApiSlice';

export const useDeleteUser = () => {
	const user: User | null = useAppSelector(selectCurrentUser);
	const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [deleteUser, { isLoading: isDeleteLoading, isError, error }] =
	useDeleteUserMutation();
    const dispatch = useAppDispatch();

	const { errorMsg } = useErrorMessage({ error, isError });

	const handleCancel = () => {
		setDeleteDialogOpen(false)
	}
    const handleDelete = async () => {
        if (isDeleteLoading || !user) return;
        await deleteUser(user.id);
        setDeleteDialogOpen(false)
        dispatch(logout());
    }

	const showDeleteModal = () => {
		setDeleteDialogOpen(true)
	}

    return {
        isDeleteDialogOpen,
        handleCancel,
        handleDelete,
        showDeleteModal,
        isDeleteLoading,
        deleteErrorMsg: errorMsg,
        isDeleteError: isError,
    }

}
