import { logout } from "../../app/features/auth/authSlice";
import { closeManageUsersModal, closeUserModal } from "../../app/features/header/headerSlice";
import { clearProject } from "../../app/features/project/projectSlice";
import { persistor } from "../../app/store";
import { useAppDispatch } from "../../app/hooks";

export const rightModalVariants = {
	hidden: {
		x: "500px",
		opacity: 0,
	},
	visible: {
		x: "0",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		x: "500px",
		opacity: 0,
	},
};

export const useHandleLogout = () => {
	const dispatch = useAppDispatch();
	const handleLogout = () => {
		dispatch(closeUserModal());
		dispatch(closeManageUsersModal());
		dispatch(clearProject());
		dispatch(logout());
		persistor.purge(); // Purge the persisted state
	};

	return handleLogout
}