import { useEffect } from "react";
import {
	selectCurrentBuilder,
	setIsFetchingData,
} from "../../../app/features/builder/builderSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import type { ErrorType } from "../../../types/interfaces";
import useErrorMessage from "../../../utils/useErrorMessage";

const useModuleHook = ({
	skeletonsLoadingDeps,
	isMutateError,
	mutateError,
}: {
	skeletonsLoadingDeps: boolean[];
	isMutateError: boolean;
	mutateError: ErrorType | null;
}) => {
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);

	const dispatch = useAppDispatch();
	//update isFetching for skeletons
	useEffect(() => {
		dispatch(setIsFetchingData(skeletonsLoadingDeps.some((isLoading) => isLoading)),);
		return () => {
			dispatch(setIsFetchingData(false));
		};
	}, [...skeletonsLoadingDeps, dispatch]); //eslint-disable-line

	const { errorMsg } = useErrorMessage({
		error: mutateError,
		isError: isMutateError,
	});

	return { tabsOpen, errorMsg };
};

export default useModuleHook;
