import { useMemo } from "react";
import { TierTwoIcon } from "../../../../utils/customIcons";
import { Field } from "formik";
import { FuelDefaults, FuelResponse } from "./energyTypes";
import { selectIsFetchingData } from "../../../../app/features/builder/builderSlice";
import { useAppSelector } from "../../../../app/hooks";
import SubModuleInputGroup from "../../../../components/input/SubModuleInputGroup";
import FormRow from "../../../../components/input/formRow/FormRow";
import TranslatableText from "../../../../components/translations/TranslatableText";
import CustomInput from "../../../../components/input/CustomInput";

const CustomEFTierTwoGroup = ({
  entry,
  index,
  defaults
}: {
  entry: FuelResponse;
  index: number;
  disabled?: boolean;
  defaults: FuelDefaults | undefined
}) => {
  const rowChanged = useMemo<boolean>(
    () =>
      !!entry.energy_ef_ch4_t2 ||
      !!entry.energy_ef_co2_t2 ||
      !!entry.energy_ef_n2o_t2,
    [entry],
  );

  //REMOVE
  const isFetching: boolean = useAppSelector(selectIsFetchingData);
  const label = "module.ef_of_fuel"

  return (
    <div className="tiertwo-row">
      <TierTwoIcon />
      <div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
        {rowChanged ? <div className="has-changed-dot" /> : null}
        <TranslatableText className="fs-12" translationKey={label} />
      </div>
      <SubModuleInputGroup
        name=""
        gridTemplateColumns="1fr 1fr 1fr"
        gap="8px"
      >
        <FormRow
          style={{ gridColumn: "1" }}
          topLabel={[
            `Default = ${defaults?.energy_ef_co2_t2_default ?? ""}`,
            "CO2 Emissions",
          ]}
          isFetching={isFetching}
        >
          <Field
            name={`fuels.${index}.energy_ef_co2_t2`}
            id={`fuels.${index}.energy_ef_co2_t2`}
            component={CustomInput}
            label="[tCO2-e/t.d.m.]"
            type="number"
            textend
            classes={entry.energy_ef_co2_t2 ? "tiertwo-input-changed" : ""}
          />
        </FormRow>
        <FormRow
          style={{ gridColumn: "2" }}
          topLabel={[
            `Default = ${defaults?.energy_ef_ch4_t2_default ?? ""}`,
            "N2O Emissions",
          ]}
          isFetching={isFetching}
        >
          <Field
            name={`fuels.${index}.energy_ef_ch4_t2`}
            id={`fuels.${index}.energy_ef_ch4_t2`}
            component={CustomInput}
            label="[tCO2-e/t.d.m.]"
            type="number"
            textend
            classes={entry.energy_ef_ch4_t2 ? "tiertwo-input-changed" : ""}
          />
        </FormRow>
        <FormRow
          style={{ gridColumn: "3" }}
          topLabel={[
            `Default = ${defaults?.energy_ef_n2o_t2_default ?? ""}`,
            "CO2-e Emissions",
          ]}
          isFetching={isFetching}
        >
          <Field
            name={`fuels.${index}.energy_ef_n2o_t2`}
            id={`fuels.${index}.energy_ef_n2o_t2`}
            component={CustomInput}
            label="[tCO2-e/t.d.m.]"
            type="number"
            textend
            classes={entry.energy_ef_n2o_t2 ? "tiertwo-input-changed" : ""}
          />
        </FormRow>
      </SubModuleInputGroup>
    </div>
  );
};

export default CustomEFTierTwoGroup;