import { useMemo } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	ReferenceLine,
	ResponsiveContainer,
} from "recharts";
import { useGetProjectResultsQuery } from "../../app/features/project/projectApiSlice";
import GraphContainer from "./GraphContainer";
import Skeleton from "react-loading-skeleton";
import tinycolor from "tinycolor2";
import { transformCO2ByActivity } from "../../pages/project/results/resultsUtils";
import { roundToTwoDecimals } from "./utils";
import TranslatableText from "../translations/TranslatableText";

const CO2ByActivityHorizontal = ({
	width,
	height,
	className,
	projectId,
}: {
	width?: string | number;
	height: string | number;
	className?: string;
	projectId: number;
}) => {
	const { data, isLoading } = useGetProjectResultsQuery(projectId, {
		refetchOnMountOrArgChange: true,
		selectFromResult: (result) => ({
			...result,
			data: transformCO2ByActivity(result.data),
		}),
	});

	const getColor = (value: number, index: number) => {
		return tinycolor(value > 0 ? "#CC1212" : "#52A021")
			.darken(index * 5)
			.toRgbString();
	};

	type UpdatedData = {
		[name: string]: number;
	};

	const updatedData: UpdatedData[] = useMemo(() => {
		if (!data) return [];
		return [
			data.reduce((prev, curr) => {
				return {
					...prev,
					[curr.name]: curr.value,
				};
			}, {}),
		];
	}, [data]);

	const { dataMin, dataMax } = useMemo(
		() => {
			const dataMin = data
				? data.reduce(
						(prev, curr) => (curr.value < 0 ? curr.value + prev : prev),
						0,
					)
				: 0
			const dataMax = data
				? data.reduce(
						(prev, curr) => (curr.value > 0 ? curr.value + prev : prev),
						0,
					)
				: 0

			return {
				dataMin: roundToTwoDecimals(dataMin),
				dataMax: roundToTwoDecimals(dataMax)
			}
		},
		[data],
	);

	const bars = useMemo(() => {
		return updatedData.map((dataObj) => {
			const positiveBars = Object.keys(dataObj)
				.filter((name) => dataObj[name] > 0)
				.map((name, index) => {
					return (
						<Bar
							key={name}
							dataKey={name}
							stackId="a"
							fill={getColor(updatedData[0][name], index)}
						/>
					);
				});

			const negativeBars = Object.keys(dataObj)
				.filter((name) => dataObj[name] < 0)
				.map((name, index) => {
					return (
						<Bar
							key={name}
							dataKey={name}
							stackId="a"
							fill={getColor(updatedData[0][name], index)}
						/>
					);
				});
			return [...positiveBars, ...negativeBars];
		});
	}, [updatedData]);

	return (
		<>
			<div
				className="mt-4 w-fit"
				style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
			>
				<h2 className="ff-bold fs-13 text-black">
					<TranslatableText translationKey="project.co2_eq_fluxes_per_activity"/>
				</h2>
			</div>
			<GraphContainer height={height} zIndex={10}>
				{isLoading ? (
					<Skeleton width={width ?? "97%"} height={height} />
				) : (
					<ResponsiveContainer
						width={width ?? "97%"}
						height={height}
						className={className}
						style={{ paddingBottom: height }}
					>
						<BarChart
							data={updatedData}
							layout="vertical"
							stackOffset="sign"
							margin={{
								top: 20,
								right: 25,
								left: 15,
								bottom: 5,
							}}
							maxBarSize={40}
						>
							<XAxis
								type="number"
								width={20}
								style={{ fontSize: "12px" }}
								domain={[dataMin, dataMax]}
								ticks={[dataMin, 0, dataMax]}
								interval="preserveStartEnd"
							/>
							<YAxis
								dataKey="name"
								type="category"
								width={60}
								style={{ fontSize: "8px" }}
								hide
							/>
							<Tooltip />

							{bars}

							<ReferenceLine x={dataMax + dataMin} stroke="#000">
								{/* <Label position="top" angle={90}>{dataMax + dataMin}</Label> */}
							</ReferenceLine>
						</BarChart>
					</ResponsiveContainer>
				)}
			</GraphContainer>
		</>
	);
};

export default CO2ByActivityHorizontal;
