import GenericInputGroup from "../input/inputGroup/GenericInputGroup";
import { Form, useFormikContext } from "formik";
import type {
	ClassicInputGroup,
	DropdownInputGroup,
} from "../../types/modulesInterfaces";
import { FieldType } from "../../types/modulesInterfaces";
import Button from "../button/Button";
import { FiLogOut } from "react-icons/fi";
import { BiPlay } from "react-icons/bi";
import { useGetCountriesQuery } from "../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { useMemo } from "react";
import { useHandleLogout } from "./utils";
import TranslatableText from "../translations/TranslatableText";

const UserModalForm = () => {
	return (
		<Form className="w-100 d-flex f-column justify-content-between">
			<PersonalInfoForm />
			<Footer />
		</Form>
	);
};

const PersonalInfoForm = () => {
	const { data: countries, isLoading } = useGetCountriesQuery();

	const piInputs: ClassicInputGroup[] = [
		{
			type: FieldType.CLASSIC,
			inputName: "first_name",
			inputType: "text",
			label: "user.first_name",
		},
		{
			type: FieldType.CLASSIC,
			inputName: "last_name",
			inputType: "text",
			label: "user.last_name",
		},
		{
			type: FieldType.CLASSIC,
			inputName: "organization",
			inputType: "text",
			label: "user.organization",
		},
		// {
		// 	type: FieldType.CLASSIC,
		// 	inputName: "affiliations",
		// 	inputType: "text",
		// 	label: "user.affiliations",
		// 	disabled: true,
		// },
		/* {
			type: FieldType.CLASSIC,
			inputName: "mobileNumber",
			inputType: "text",
			label: "Mobile",
		}, */
		{
			type: FieldType.CLASSIC,
			inputName: "email",
			inputType: "text",
			label: "user.email",
		},
	];

	const countryInput: DropdownInputGroup = useMemo(
		() => ({
			type: FieldType.SELECT,
			inputName: "country",
			label: isLoading ? "main.loading" : "user.user_country",
			dropdownOptions: countries ?? [],
			disabled: isLoading
		}),
		[countries],
	);

	return (
		<section className="d-flex justify-content-center w-100 f-column gap-8">
			<h3 className="pt-2">
				<TranslatableText translationKey="user.personal_information" />
			</h3>
			{piInputs.map((input, i) => (
				<div className="pe-3" key={input.label}>
					<GenericInputGroup inputGroup={input} key={`inputs-${input.label}`} />
				</div>
			))}
			<div className="pe-3">
				<GenericInputGroup inputGroup={countryInput} />
			</div>

			{/* <h3 className="pt-1">
				<TranslatableText translationKey="user.change_password" />
			</h3>
			<GenericInputGroup inputGroup={passwordInput} /> */}
		</section>
	);
};

const Footer = () => {
	const handleLogout = useHandleLogout();
	const { isValid } = useFormikContext()

	return (
		<footer className="w-100 d-flex justify-content-between pt-2 pe-3">
			<Button
				type="submit"
				classes="btn-save mt-1 w-fit"
				onClick={() => { }}
				disabled={!isValid}
				style={{ border: "1px solid #F06320" }}
			>
				<div
					className="h-100 d-flex align-items-center p-1"
					style={{ backgroundColor: "#F06320", opacity: isValid ? 1 : 0.8 }}
				>
					<BiPlay size={20} color="var(--white)" />
				</div>
				<TranslatableText className="label ff-medium" translationKey="main.update_changes" />
			</Button>

			<Button
				style={{ border: "1px solid #999999" }}
				onClick={handleLogout}
				classes="btn-save mt-1 w-fit"
			>
				<div className="h-100 d-flex align-items-center p-1">
					<FiLogOut size={20} color="#5D5555" />
				</div>
				<TranslatableText className="label" style={{ color: "rgba(93, 85, 85, 0.75)" }} translationKey="main.logout" />
			</Button>
		</footer>
	);
};

export default UserModalForm;
