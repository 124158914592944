import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip/Tooltip";
import { ReactNode } from "react";
import { TierTwoIcon } from "../../utils/customIcons";
import { TranslationKey } from "../../types/modulesInterfaces";
import TranslatableText from "../translations/TranslatableText";

const TierTwoRowTooltip = ({
	children,
	links,
	label,
}: {
	children: ReactNode;
	links: string[];
	label: TranslationKey;
}) => {
	return (
		<Tooltip placement="bottom-start">
			<TooltipTrigger className="w-100">{children}</TooltipTrigger>
			{links.length ? (
				<TooltipContent className="tiertwo-tooltip unclickable">
					<div className="d-flex f-column">
						{links.map((link, i) => (
							<span className="fs-10" key={i}>
								{link}
							</span>
						))}
					</div>
					<TierTwoIcon fill="white" />
					<TranslatableText className="fs-10 ff-medium" translationKey={label}/>
				</TooltipContent>
			) : null}
		</Tooltip>
	);
};

export default TierTwoRowTooltip;
