import { useState } from "react";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import { useAppDispatch } from "../../../app/hooks";

const TOOLBAR_OPTIONS = [["bold", "italic"]];

const CommentTextEditor = ({
	note,
	onComment,
}: { note: string; onComment: (note: string) => void }) => {
	return (
		<div className="comment-action-container">
			<span className="fs-12 text-darker-gray">
				<ReactQuill
					className="note-text-editor-container"
					modules={{ toolbar: TOOLBAR_OPTIONS }}
					theme="snow"
					value={note}
					onChange={onComment}
				/>
			</span>
		</div>
	);
};

export default CommentTextEditor;
