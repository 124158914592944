import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearProject, selectCurrentProject, setProject } from "../../app/features/project/projectSlice";
import { useGetProjectByIdQuery } from "../../app/features/project/projectApiSlice";
import { useEffect } from "react";

export const useHandleProjectState = () => {
  const dispatch = useAppDispatch();
  const params = useParams<string>();
  const { projectId } = params;
  const { project } = useAppSelector(selectCurrentProject);

  const shouldFetchProject = projectId && projectId?.toLowerCase() !== "new" && Number(projectId) !== project?.id;
  const { data: projectData, isLoading } = useGetProjectByIdQuery(projectId ?? "", {
    skip: !shouldFetchProject
  });

  useEffect(() => {
    const id = projectId ? Number(projectId) : undefined;
    if (projectData && shouldFetchProject && id && !isNaN(id)) {
      dispatch(clearProject());
      dispatch(setProject(projectData));
    }
  }, [projectId, project, projectData, dispatch]);

  return { project, projectId, isLoading };
}
