import { motion } from "framer-motion";
import { ReactNode, MouseEvent } from "react";

const Backdrop = ({
	children,
	onClick,
	transparent,
}: {
	children: ReactNode;
	onClick: (e?: MouseEvent) => void;
	transparent?: boolean;
}) => {
	return (
		<motion.div
			className={`backdrop d-flex align-items-center justify-content-center ${
				transparent && "backdrop-transparent"
			}`}
			onClick={onClick}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{children}
		</motion.div>
	);
};

export default Backdrop;
