import React, { useEffect, useState } from 'react'
import ErrorModal from '../../../../components/modal/ErrorModal';

const CreateActivityError = ({
	isError,
	message
}: {
	isError: boolean;
	message?: string
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	useEffect(() => {
		if (!isError) return
		setIsOpen(isError)
	}, [isError])
	return (
		<ErrorModal
			isOpen={isOpen}
			handleClose={() => setIsOpen(false)}
			errorMessage={message ? message : "An error occured when creating an activity"}
		/>
	)
}

export default CreateActivityError