import { convertEmptyStringsToNull, convertIncomingPercentage, convertOutgoingPercentage, filterObjectProps } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { includedPropList, includedT2PropList } from "../../../../pages/activityBuilder/modules/irrigation/irrigation";
import type { IrrigationSystemPayload, IrrigationSystemCreatePayload, IrrigationSystemDeletePayload, IrrigationPhaseCreatePayload, IrrigationPhaseDeletePayload, IrrigationPhasePayload, IrrigationPhaseData, IrrigationPayload, IrrigationT2Payload, IrrigationResponse, IrrigationUpdatePayload, IrrigationSystemData, IrrigationSystemDefaults, IrrigationPhaseDefaults } from "../../../../pages/activityBuilder/modules/irrigation/irrigationTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getIrrigations: builder.query<{ parent: IrrigationPayload, tiertwo: IrrigationT2Payload } | null, number>({
      query: (activity) => `irrigations/?activity=${activity}`,
      transformResponse: (response: IrrigationResponse[]) => {
        if (response && response.length > 0) {
          const parent = filterObjectProps(response[0], includedPropList) as IrrigationPayload
          const t2 = filterObjectProps(response[0], includedT2PropList) as IrrigationT2Payload
          return {
            parent,
            tiertwo: t2,
          }
        }
        return null
      },
      providesTags: (_results, _error, activityId) => [{ type: 'Irrigations', id: activityId }]
    }),
    updateIrrigations: builder.mutation<IrrigationResponse, IrrigationUpdatePayload>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest);
        return { url: `irrigations/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [{ type: 'Irrigations', id: activityId }, { type: "ModulesStatuses", id: activityId }]
    }),
    getIrrigationSystem: builder.query<IrrigationSystemData[], number>({
      query: (activityId) => `irrigation-systems/?activity=${activityId}`,
      providesTags: (_res, _error, activityId) => [{ type: 'IrrigationSystems', id: activityId }]
    }),
    updateIrrigationSystem: builder.mutation<IrrigationSystemData, IrrigationSystemPayload>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest);
        return { url: `irrigation-systems/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'IrrigationSystems', id: activityId },
        { type: 'ModuleResults', id: body.parent ? `irrigations-${body.parent}` : undefined },
        { type: "ModuleHistory", id: body.parent ? `irrigations-${body.parent}` : undefined },
        { type: "IrrigationSystemsDefaults", id: body?.id ? body?.id : undefined },
        { type: "ModulesStatuses", id: activityId }]
    }),
    createIrrigationSystem: builder.mutation<IrrigationSystemData, IrrigationSystemCreatePayload>({
      query: ({ body }) => ({ url: "irrigation-systems/", method: 'POST', body }),
      invalidatesTags: (_results, _error, { activityId, body }) => [{ type: 'IrrigationSystems', id: activityId },
      { type: 'ModuleResults', id: body.parent ? `irrigations-${body.parent}` : undefined },
      { type: "ModulesStatuses", id: activityId },
      { type: "ModuleHistory", id: body.parent ? `irrigations-${body.parent}` : undefined },
      ]
    }),
    deleteIrrigationSystem: builder.mutation<IrrigationSystemData, IrrigationSystemDeletePayload>({
      query: ({ id }) => ({ url: `irrigation-systems/${id}/`, method: 'DELETE' }),
      invalidatesTags: (_results, _error, { activityId, parent }) => [{ type: 'IrrigationSystems', id: activityId },
      { type: 'ModuleResults', id: parent ? `irrigations-${parent}` : undefined },
      { type: "ModuleHistory", id: parent ? `irrigations-${parent}` : undefined },
      { type: "ModulesStatuses", id: activityId },
      ]
    }),
    getIrrigationSystemDefaults: builder.query<IrrigationSystemDefaults[], number>({
      query: (uniqueId) => `irrigation-systems/${uniqueId}/defaults/`,
      providesTags: (_res, _error, uniqueId) => [{ type: 'IrrigationSystemsDefaults', id: uniqueId }]
    }),

    getIrrigationPhase: builder.query<IrrigationPhaseData[], number>({
      query: (activityId) => `irrigation-phases/?activity=${activityId}`,
      transformResponse: (response: IrrigationPhaseData[]) => {
        for (const phase of response)
          convertIncomingPercentage(phase, ["transmission_loss_t2", "pumping_efficiency_t2"])
        return response
      },
      providesTags: (_res, _error, activityId) => [{ type: 'IrrigationPhases', id: activityId }]
    }),
    updateIrrigationPhase: builder.mutation<IrrigationPhaseData, IrrigationPhasePayload>({
      query: ({ body }) => {
        const module = { ...body }
        convertEmptyStringsToNull(module);
        convertOutgoingPercentage(module, ["transmission_loss_t2", "pumping_efficiency_t2"])
        const { id, ...rest } = module

        return { url: `irrigation-phases/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [{ type: 'IrrigationPhases', id: activityId },
      { type: 'ModuleResults', id: body.parent ? `irrigations-${body.parent}` : undefined },
      { type: "ModuleHistory", id: body.parent ? `irrigations-${body.parent}` : undefined },
      { type: "IrrigationPhasesDefaults", id: body?.id ? body?.id : undefined },
      { type: "ModulesStatuses", id: activityId }],
    }),
    createIrrigationPhase: builder.mutation<IrrigationPhaseData, IrrigationPhaseCreatePayload>({
      query: ({ body }) => ({ url: "irrigation-phases/", method: 'POST', body }),
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'IrrigationPhases', id: activityId },
        { type: 'ModuleResults', id: body.parent ? `irrigations-${body.parent}` : undefined },
        { type: "ModuleHistory", id: body.parent ? `irrigations-${body.parent}` : undefined },
        { type: "ModulesStatuses", id: activityId }
      ]
    }),
    deleteIrrigationPhase: builder.mutation<IrrigationPhaseData, IrrigationPhaseDeletePayload>({
      query: ({ id }) => ({ url: `irrigation-phases/${id}/`, method: 'DELETE' }),
      invalidatesTags: (_results, _error, { activityId, parent }) => [
        { type: 'IrrigationPhases', id: activityId },
        { type: 'ModuleResults', id: parent ? `irrigations-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `irrigations-${parent}` : undefined },
        { type: "ModulesStatuses", id: activityId }
      ]
    }),
    getIrrigationPhaseDefaults: builder.query<IrrigationPhaseDefaults, number>({
      query: (uniqueId) => `irrigation-phases/${uniqueId}/defaults/`,
      providesTags: (_res, _error, uniqueId) => [{ type: 'IrrigationPhasesDefaults', id: uniqueId }]
    })
  })
})

export const {
  useGetIrrigationsQuery,
  useUpdateIrrigationsMutation,
  useGetIrrigationPhaseQuery,
  useUpdateIrrigationPhaseMutation,
  useCreateIrrigationPhaseMutation,
  useDeleteIrrigationPhaseMutation,
  useGetIrrigationPhaseDefaultsQuery,
  useGetIrrigationSystemQuery,
  useUpdateIrrigationSystemMutation,
  useCreateIrrigationSystemMutation,
  useDeleteIrrigationSystemMutation,
  useGetIrrigationSystemDefaultsQuery
} = moduleApiSlice;