import type { DynamicEmissionsTierTwo } from "../../../types/modulesInterfaces";
import { Field, useFormikContext, getIn } from "formik";
import { useEffect, useState } from "react";
import { TierTwoIcon } from "../../../utils/customIcons";

import FormRow from "../formRow/FormRow";
import SubModuleInputGroup from "../SubModuleInputGroup";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import CustomInput from "../CustomInput";
import { useAppSelector } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";

const TierTwoDynEmissions = ({
	inputName,
	label,
	links,
	propList,
	index,
	autosubmit,
}: DynamicEmissionsTierTwo) => {
	const { values } = useFormikContext();

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const isObj: (obj: any) => boolean = (obj) =>
		typeof obj === "object" &&
		!Array.isArray(obj) &&
		obj !== null &&
		obj !== undefined;

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const [rowValues, setRowValues] = useState<any | undefined>(undefined);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const [name, setName] = useState<string>("");
	const rowChanged = isObj(rowValues)
		? Object.values(rowValues)?.some((val) => val)
		: false;

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	useEffect(() => {
		setRowValues(getIn(values, name));
	}, [values, name]);

	return (
		<TierTwoRowTooltip links={links} label={label}>
			<div
				className={`tiertwo-row ${links?.length ? "tiertwo-row-hover" : ""
					}`}
			>
				<TierTwoIcon fill={links?.length ? "active" : undefined} />
				<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
					{rowChanged ? <div className="has-changed-dot" /> : null}
					<span className="fs-12 text-start ff-medium">{label}</span>
				</div>
				<SubModuleInputGroup
					name=""
					gridTemplateColumns="1fr 1fr 1fr"
					gap="8px"
				>
					{propList.map((prop, index) => (
						<FormRow
							style={{ gridColumn: `${index + 1}` }}
							topLabel={[
								`Default = ${prop.defaultValue?.toString()}`,
								prop.unit,
							]}
							key={prop.column}
							isFetching={isFetchingData}
						>
							<Field
								name={`${name}.${prop.propInputName}`}
								id={`${name}.${prop.propInputName}`}
								component={CustomInput}
								label={prop.column}
								disabled={prop.disabled}
								type="number"
								textend
								autosubmit={autosubmit}
								classes={
									getIn(values, `${name}.${prop.propInputName}`)
										? "tiertwo-input-changed"
										: ""
								}
							/>
						</FormRow>
					))}
				</SubModuleInputGroup>
			</div>
		</TierTwoRowTooltip>
	);
};

export default TierTwoDynEmissions;
