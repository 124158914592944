import * as Yup from "yup";
import type { ClassicInputGroup, DropdownInputGroup, InputGroup } from "../../../types/modulesInterfaces";
import type { RegisterPayload } from "../../../app/features/auth/authTypes";
import { FieldType } from "../../../types/modulesInterfaces";
import { getOptions } from "../../../app/features/dropdownOptions/fetchOptions";

export type RegisterFormType = RegisterPayload & {
	isAgreeToTerms: boolean;
	isAgreeToGDPR: boolean;
};

export const validationSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, "validations.min_8_characters")
		.required("validations.field_required"),
	first_name: Yup.string(),
	last_name: Yup.string(),
	email: Yup.string().email("Invalid email").required("validations.field_required"),
	isAgreeToGDPR: Yup.boolean().oneOf([true], 'validations.gdpr_consent_required'),
	isAgreeToTerms: Yup.boolean().oneOf([true], 'validations.terms_consent_required'),
	})

export const initialValues: RegisterFormType = {
	password: "",
	first_name: "",
	last_name: "",
	email: "",
	organization: "",
	country: null,
	isAgreeToGDPR: false,
	isAgreeToTerms: false,
};

const countries = () => getOptions("country");

export const getRegisterInputGroups: () => InputGroup[] = () => {
	const rows: ClassicInputGroup[] = [
		{
			type: FieldType.CLASSIC,
			inputName: "email",
			inputType: "text",
			label: "user.email_required",
		},
		{
			type: FieldType.CLASSIC,
			inputName: "password",
			inputType: "password",
			label: "user.password_required",
		},
		{
			type: FieldType.CLASSIC,
			inputName: "first_name",
			inputType: "text",
			label: "user.first_name",
		},
		{
			type: FieldType.CLASSIC,
			inputName: "last_name",
			inputType: "text",
			label: "user.last_name",
		},
		{
			type: FieldType.CLASSIC,
			inputName: "organization",
			inputType: "text",
			label: "user.organization",
		},
	];

	const country: DropdownInputGroup = {
		type: FieldType.SELECT,
		inputName: "country",
		label: "user.user_country",
		dropdownOptions: countries,
	};

	return [...rows, country];
};
