import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface HeaderSlice {
	userModalOpen: boolean;
	shareModalOpen: boolean;
	manageUsersModalOpen: boolean;
}

const initialState: HeaderSlice = {
	userModalOpen: false,
	shareModalOpen: false,
	manageUsersModalOpen: false,
};

export const headerSlice = createSlice({
	name: "header",
	initialState,
	reducers: {
		openUserModal: (state) => {
			state.userModalOpen = true;
		},
		closeUserModal: (state) => {
			state.userModalOpen = false;
		},
		openShareModal: (state) => {
			state.shareModalOpen = true;
		},
		closeShareModal: (state) => {
			state.shareModalOpen = false;
		},
		openManageUsersModal: (state) => {
			state.manageUsersModalOpen = true;
		},
		closeManageUsersModal: (state) => {
			state.manageUsersModalOpen = false;
		},
	},
});

export const {
	closeUserModal,
	openUserModal,
	openShareModal,
	closeShareModal,
	openManageUsersModal,
	closeManageUsersModal,
} = headerSlice.actions;
export const selectModals = (state: RootState) => state.header;
export default headerSlice.reducer;
