import { useIrrigationsContext } from '../../../../contexts/IrrigationsProvider'
import { capitalize } from '../../../../utils/helperFunctions'
import ChangesChildAction from './ChangesChildAction'

const IrrigationChanges = () => {
  const context = useIrrigationsContext()
  return (
    <>
      {
        context?.irrigationsSystemIds && (
          context.irrigationsSystemIds.map((irrigationSystemId) => <IrrigationChangeBlock key={irrigationSystemId} itemId={irrigationSystemId} settlementType='irrigation-systems' />)
        )
      }
      {
        context?.operationPhaseIds && (
          context.operationPhaseIds.map((operationPhaseId) => <IrrigationChangeBlock key={operationPhaseId} itemId={operationPhaseId} settlementType='operation-phases' />)
        )
      }
    </>
  )
}

const IrrigationChangeBlock = ({ itemId, settlementType }: { itemId: number, settlementType: 'irrigation-systems' | 'operation-phases' }) => {
  return <ChangesChildAction itemId={itemId} path={settlementType} title={capitalize(settlementType) as string} />
}

export default IrrigationChanges