import { AnimatePresence } from "framer-motion";
import { ButtonProps } from "../button/Button";
import { ReactNode } from "react";
import Modal from "./Modal";
import { TranslationKey } from "../../types/modulesInterfaces";

const AlertModal = ({
	modalOpen,
	handleSubmit,
	handleCancel,
	buttons,
	children,
	size = "sm",
	headerText
}: {
	modalOpen: boolean;
	handleSubmit?: () => void;
	handleCancel: () => void;
	buttons?: ButtonProps[];
	children?: ReactNode;
	size?: "sm" | "md" | "lg" | "auto" | "xlg";
	headerText?: TranslationKey;
}) => {
	return (
		<AnimatePresence mode="wait" onExitComplete={() => null}>
			{modalOpen && (
				<Modal
					headerText={headerText ?? "project.project_restrictions"}
					handleClose={handleCancel}
					footerButtons={buttons}
					isHeaderDark
					/* preventClosing */ //therefore it doensn't need the handleClose
					size={size}
				>
					{children ? (
						children
					) : (
						<div className="h-100 w-100 d-flex justify-content-center align-items-center">
							<span className="fw-600 fs-14">
								Module: Coastal Wetlands cannot be edited based on the current
								project parameters.
							</span>
						</div>
					)}
				</Modal>
			)}
		</AnimatePresence>
	);
};

export default AlertModal;
