import type { ClassicInputGroupTierTwo } from "../../../types/modulesInterfaces";
import { Field, useFormikContext, getIn } from "formik";
import { useEffect, useMemo, useState } from "react";
import { TierTwoIcon } from "../../../utils/customIcons";

import FormRow from "../formRow/FormRow";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import CustomInput from "../CustomInput";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import TranslatableText from "../../translations/TranslatableText";

const TierTwoClassicInput = ({
	label,
	inputName,
	index,
	inputType,
	links,
	unit,
	disabled,
	autosubmit,
}: ClassicInputGroupTierTwo) => {
	const { values, errors, touched } = useFormikContext();
	const [rowValue, setRowValue] = useState<string | number | undefined>(undefined);
	const [defaultValue, setDefaultValue] = useState<string | number | undefined>(undefined);

	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const [name, setName] = useState<string>("");
	const translatedLabel = useCustomTranslation(label);
	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	useEffect(() => {
		setRowValue(getIn(values, name));
	}, [values, name]);

	useEffect(() => {
		const value = getIn(values, `${name}_default`);
		setDefaultValue(value?.toFixed(2));
	}, [values, name]);

	const error = useMemo<string | undefined>(() => {
		return getIn(errors, name) && getIn(touched, name)
			? getIn(errors, name)
			: undefined;
	}, [errors, touched, name]);

	return (
		<TierTwoRowTooltip links={links} label={label}>
			<div
				className={`tiertwo-row ${links?.length ? "tiertwo-row-hover" : ""
					}`}
			>
				<TierTwoIcon fill={links?.length ? "active" : undefined} />
				<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
					{rowValue ? <div className="has-changed-dot" /> : null}
					<TranslatableText className="fs-12" translationKey={label}/>
					<span className="fs-10 op-5">{unit}</span>
				</div>
				<FormRow
					topLabel={`Default = ${defaultValue?.toString() ?? ""}`}
					isFetching={isFetchingData}
					error={error}
				>
					<Field
						name={name}
						id={name}
						label={translatedLabel}
						component={CustomInput}
						type={inputType}
						disabled={disabled}
						classes={rowValue ? "tiertwo-input-changed" : ""}
						autosubmit={autosubmit}
					/>
				</FormRow>
			</div>
		</TierTwoRowTooltip>
	);
};

export default TierTwoClassicInput;
