import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

const NoteTextViewer = ({ note }: { note: string }) => {
	return (
		<>
			<ReactQuill
				className="note-text-editor-container"
				modules={{ toolbar: false }}
				theme="snow"
				value={note}
				readOnly
			/>
		</>
	);
};

export default NoteTextViewer;
