
export const usePaginate = ({ 
    next, 
    previous, 
    dynamicNext,
    fetch,
    setUrl 
}: { 
    next?: string; 
    previous?: string, 
    dynamicNext?: (number: number) => string,
    fetch: () => void, 
    setUrl: (url: string) => void 
}) => {
    const setUrlAsync = (newUrl: string) => new Promise<void>((resolve) => {
		setUrl(newUrl);
		resolve();
	});
    const handleNext = async() => {
        if (next) {
            await setUrlAsync(next);
            fetch();
        }
    };
    const handlePrev = async() => {

        if (previous) {
            await setUrlAsync(previous);
            fetch();
        }
    };

    const handlePageClick = async(page: number) => {
        if (dynamicNext) {
            await setUrlAsync(dynamicNext(page));
            fetch();
        }
    };
    return {
        handleNext,
        handlePrev,
        handlePageClick
    };
};
function extractPathFromURL(url: string): string | null {
    const match = url.match(/api\/(.*)/);
    return match ? match[1] : null;
  }