import { createContext, useContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';
import { Option } from '../types/modulesInterfaces';

interface IForestContext {
    forestTypes: Option[] | undefined;
    setForestTypes: Dispatch<SetStateAction<Option[] | undefined>>;
    lucTypes: Option[] | undefined;
    setLucTypes: Dispatch<SetStateAction<Option[] | undefined>>;
    disturbancesIds?: number[] | undefined;
    setDisturbancesIds?: Dispatch<SetStateAction<number[] | undefined>>
}

export type ForestContextType = IForestContext | undefined

const ForestContext = createContext<ForestContextType>(undefined);

export const useForestContext = () => {
  const context = useContext(ForestContext);
  if (!context) {
    throw new Error('useForestContext must be used within a ForestProvider');
  }
  return context;
};

interface ForestProviderProps {
  children: ReactNode;
}

export const ForestProvider: React.FC<ForestProviderProps> = ({ children }) => {
  const [forestTypes, setForestTypes] = useState<Option[] | undefined>(undefined);
  const [lucTypes, setLucTypes] = useState<Option[] | undefined>(undefined);
  const [disturbancesIds, setDisturbancesIds] = useState<number[] | undefined>(undefined);
  return (
    <ForestContext.Provider 
    value={{ 
        forestTypes, 
        setForestTypes, 
        lucTypes, 
        setLucTypes,
        disturbancesIds,
        setDisturbancesIds
    }}>
      {children}
    </ForestContext.Provider>
  );
};

export default ForestContext;
