import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import type {
	BooleanInputGroup,
	ClassicInputGroup,
	DropdownInputGroup,
	RadioInputGroup,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import type {
	SetupAssistantForm,
	SubmodulePlus,
	SpecialOptions,
} from "./setupAssistant.types";
import { FormStates } from "./setupAssistant.types";
import { FEModules, MODULES_WITH_LAND_USE } from "../../../../utils/moduleList";
import * as Yup from "yup";
import store from "../../../../app/store";
import { validatePositiveNumber } from "../../../activityBuilder/modules/moduleUtils";
import { translate } from "../../../../utils/helperFunctions";

export const mainActivityLandUseOptions: SpecialOptions[] = [
	{
		id: FEModules.ForestManagement,
		name: "project.afforestation_forest_management",
		derivedModules: [FEModules.ForestManagement],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.FloodedRice,
		name: "project.flooded_rice_management",
		derivedModules: [FEModules.FloodedRice],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.PerennialCropland,
		name: "project.perennial_cropland_management",
		derivedModules: [FEModules.PerennialCropland],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.AnnualCropland,
		name: "project.annual_cropland_management",
		derivedModules: [FEModules.AnnualCropland],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.CoastalWetlands,
		name: "project.coastal_wetland_management",
		derivedModules: [FEModules.CoastalWetlands],
		derivedStates: FormStates.includesInputs,
	},
	{
		id: FEModules.Settlements,
		name: "project.infrastructure_creation",
		derivedModules: [FEModules.Settlements],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.SetAside,
		name: "project.set_land_aside",
		derivedModules: [FEModules.SetAside],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.LivestockManagement,
		name: "project.livestock_management",
		derivedModules: [FEModules.LivestockManagement],
		derivedStates: FormStates.livestockFeedOrigin,
	},
	{
		id: FEModules.Waterbodies,
		name: "project.water_body_management",
		derivedModules: [FEModules.Waterbodies],
		derivedStates: FormStates.hectares,
	},
	{
		id: FEModules.SmallFisheries,
		name: "project.marine_inland_fisheries",
		derivedModules: [],
		derivedStates: FormStates.fisheriesSize,
	},
	{
		id: FEModules.Aquaculture,
		name: "project.aquaculture",
		derivedModules: [FEModules.Aquaculture],
		derivedStates: FormStates.fishFeedOrigin,
	},
	{
		id: FEModules.Inputs,
		name: "project.use_of_agricultural_inputs_energy_irrigation",
		derivedStates: FormStates.includesInputs,
	},
	{
		id: FEModules.Grassland,
		name: "project.grassland_management",
		derivedModules: [FEModules.Grassland],
		derivedStates: FormStates.hectares,
	},
];


export const landUseOptions: SpecialOptions[] = [
	{ id: FEModules.ForestManagement, name: "project.forest_management", derivedModules: [FEModules.ForestManagement] },
	{ id: FEModules.FloodedRice, name: "project.flooded_rice", derivedModules: [FEModules.FloodedRice] },
	{
		id: FEModules.PerennialCropland,
		name: "project.perennial_cropland",
		derivedModules: [FEModules.PerennialCropland],
	},
	{
		id: FEModules.AnnualCropland,
		name: "project.annual_cropland",
		derivedModules: [FEModules.AnnualCropland],
	},
	/* {
		id: 4,
		name: "Coastal Wetland",
		derivedModules: [FEModules.CoastalWetlands],
	}, */
	{ id: FEModules.Settlements, name: "project.settlements_and_infrastructure", derivedModules: [FEModules.Settlements] },
	{ id: FEModules.SetAside, name: "project.set_aside_land", derivedModules: [FEModules.SetAside] },
	{ id: FEModules.Grassland, name: "project.grassland", derivedModules: [FEModules.Grassland] },
	{
		id: FEModules.OtherLand,
		name: "project.other_land",
		derivedModules: [FEModules.OtherLand],
	},
];

const fishFeedOriginOptions: SpecialOptions[] = [
	{ id: 1, name: "project.dedicated_crops" },
	{ id: 2, name: "project.crops_residues" },
	{
		id: 3,
		name: "project.other_sources",
		derivedModules: [FEModules.Inputs],
		derivedStates: FormStates.includesInputs,
	},
];
const livestockFeedOriginOptions: SpecialOptions[] = [
	{ id: 1, name: "project.crops_residues" },
	{ id: 2, name: "project.dedicated_crops" },
	{ id: 3, name: "project.grasslands" },
	{
		id: 4,
		name: "project.other_sources",
		derivedModules: [FEModules.Inputs],
		derivedStates: FormStates.includesInputs,
	},
];

const activityTitle: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "activityTitle",
	inputType: "text",
	label: "activity.activity_title",
};
export const activityCost: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "activityCost",
	inputType: "number",
	label: "activity.activity_cost",
	unit: "[USD]",
};
const duration: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "duration",
	inputType: "number",
	label: "activity.duration",
	unit: "[years]",
};
export const dynamicOfChanges: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "changeRate",
	label: "activity.dynamic_of_changes",
	dropdownOptions: () => getOptions("changeRates"),
};
const startYear: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "startYear",
	inputType: "number",
	label: "activity.start_year",
	unit: "[years]",
};
const climate: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "climate",
	label: "activity.climate",
	dropdownOptions: () => getOptions("climate"),
};
const moisture: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "moisture",
	label: "activity.moisture",
	dropdownOptions: () => getOptions("moisture"),
};
const typeOfSoil: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "typeOfSoil",
	label: "activity.soil_type",
	dropdownOptions: () => getOptions("soil"),
};

const fertilizers: BooleanInputGroup = {
	type: FieldType.BOOLEAN,
	inputName: "fertilizers",
	label: "main.fertilizers_pesticides_and_animal_feed",
};
const energy: BooleanInputGroup = {
	type: FieldType.BOOLEAN,
	inputName: "energy",
	label: "main.energy",
};
const irrigation: BooleanInputGroup = {
	type: FieldType.BOOLEAN,
	inputName: "irrigation",
	label: "main.irrigation",
};

const year = new Date().getFullYear();
const project = store.getState().project.project;
const initValues = {
	activityTitle: undefined,
	duration: project?.implementation_years,
	climate: project?.climate,
	moisture: project?.moisture,
	typeOfSoil: project?.soil_type,
	startYear: year,
	activityCost: 0,
	change_rate: 2
};

export const initStep: SubmodulePlus = {
	name: "activity.introductory_activity_description",
	inputGroups: [
		activityTitle,
		duration,
		dynamicOfChanges,
		climate,
		moisture,
		typeOfSoil,
		startYear,
		activityCost,
	],
	initialValues: initValues,
	validationSchema: Yup.object({
		activityTitle: Yup.string().required(),
		duration: validatePositiveNumber.required(),
		climate: validatePositiveNumber.required(),
		moisture: validatePositiveNumber.required(),
		typeOfSoil: validatePositiveNumber.required(),
		startYear: validatePositiveNumber.required().min(1900),
		changeRate: validatePositiveNumber.required(),
		activityCost: validatePositiveNumber.required()
	}),
	step: FormStates.initInfo,
};

export const isOnOrganicSoil: SubmodulePlus = {
	name: "activity.is_activity_happening_on_organic_soil",
	inputGroups: [
		{
			type: FieldType.BOOLEAN,
			inputName: "isOrganicSoil",
			label: "",
		} as BooleanInputGroup,
	],
	initialValues: {
		isOrganicSoil: false,
	},
	validationSchema: Yup.object({ isOrganicSoil: Yup.boolean().required() }),
	step: FormStates.isOrganicSoil,
};

export const fisheriesSize: SubmodulePlus = {
	name: "activity.is_activity_targeting_small_or_large_fisheries",
	inputGroups: [
		{
			type: FieldType.RADIO,
			inputName: "fisheriesSize",
			label: "",
			options: [
				{
					label: "module.small_fisheries",
					value: 1,
				},
				{
					label: "module.large_fisheries",
					value: 2,
				},
			],
		} as RadioInputGroup,
	],
	initialValues: {
		fisheriesSize: undefined,
	},
	validationSchema: Yup.object({ fisheriesSize: validatePositiveNumber.required() }),
	step: FormStates.fisheriesSize,
};

export const mainActivityFocus: SubmodulePlus = {
	name: "activity.what_does_the_project_plan_to_do",
	inputGroups: [
		{
			type: FieldType.SELECT,
			inputName: "mainActivityFocus",
			label: "main.main_activity_focus",
			dropdownOptions: mainActivityLandUseOptions,
			translateOptions: true,
		} as DropdownInputGroup,
	],
	initialValues: {
		mainActivityFocus: undefined,
	},
	validationSchema: Yup.object({
		mainActivityFocus: validatePositiveNumber.required("The Main Activity is required"),
	}),
	step: FormStates.mainActivityFocus,
};

export const hectares: SubmodulePlus = {
	name: "activity.how_many_hectares_is_the_activity_targeting",
	inputGroups: [
		{
			type: FieldType.CLASSIC,
			inputName: "hectares",
			inputType: "number",
			label: "module.hectares",
			unit: "[ha]",
		} as ClassicInputGroup,
	],
	initialValues: {
		hectares: undefined,
	},
	validationSchema: Yup.object({ hectares: validatePositiveNumber.required() }),
	step: FormStates.hectares,
};

export const previousLandUse: SubmodulePlus = {
	name: "activity.what_is_the_land_use_before_the_activity",
	inputGroups: [
		{
			type: FieldType.SELECT,
			inputName: "previousLandUse",
			label: "main.previous_purpose_prior_to_activity",
			dropdownOptions: landUseOptions,
			translateOptions: true,
		} as DropdownInputGroup,
	],
	initialValues: {
		previousLandUse: undefined,
	},
	validationSchema: Yup.object({
		previousLandUse: validatePositiveNumber.required(),
	}),
	step: FormStates.previousLandUse,
};

export const areaWithoutProject: SubmodulePlus = {
	name: "activity.what_purpose_would_the_targeted_area_serve",
	inputGroups: [], //ATTENTION! this is empty because the logic of optionally displaying the select was hard coded (SetupAssistantWizard.tsx)
	initialValues: {
		areaWithoutProject: undefined,
		withoutProjectLandUse: undefined,
	},
	validationSchema: Yup.object({
		areaWithoutProject: validatePositiveNumber.required(),
		withoutProjectLandUse: validatePositiveNumber.when("areaWithoutProject", {
			is: (areaWithoutProject: number) => areaWithoutProject === 1,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required(),
		}),
	}),
	step: FormStates.areaWithoutProject,
};

export const ifNotProject: RadioInputGroup = {
	inputName: "areaWithoutProject",
	label: "",
	options: [
		{ label: "activity.purpose_same_as_previous", value: 0 },
		{ label: "activity.purpose_different_from_previous", value: 1 },
	],
	type: FieldType.RADIO,
};

export const differentPurpose: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "withoutProjectLandUse",
	label: "main.area_purpose_without_the_project",
	dropdownOptions: landUseOptions,
	translateOptions: true,
};

export const livestockFeedOrigin: SubmodulePlus = {
	name: "activity.where_does_the_livestock_feed",
	inputGroups: [
		{
			type: FieldType.SELECT,
			inputName: "livestockFeedOrigin",
			label: "main.select_feed",
			dropdownOptions: livestockFeedOriginOptions,
			translateOptions: true,
		} as DropdownInputGroup,
	],
	initialValues: {
		livestockFeedOrigin: undefined,
	},
	validationSchema: Yup.object({
		livestockFeedOrigin: validatePositiveNumber.required(),
	}),
	step: FormStates.livestockFeedOrigin,
};

export const fishFeedOrigin: SubmodulePlus = {
	name: "activity.where_does_the_fish_feed",
	inputGroups: [
		{
			type: FieldType.SELECT,
			inputName: "fishFeedOrigin",
			label: "main.select_feed",
			dropdownOptions: fishFeedOriginOptions,
			translateOptions: true,
		} as DropdownInputGroup,
	],
	initialValues: {
		fishFeedOrigin: undefined,
	},
	validationSchema: Yup.object({
		fishFeedOrigin: validatePositiveNumber.required(),
	}),
	step: FormStates.fishFeedOrigin,
};

export const includesInputs: SubmodulePlus = {
	name: "activity.is_the_activity_going_to_require_the_use_of_agricultural_inputs",
	inputGroups: [fertilizers, energy, irrigation],
	initialValues: {
		fertilizers: false,
		energy: false,
		irrigation: false,
	},
	validationSchema: Yup.object({
		fertilizers: Yup.boolean().required(),
		energy: Yup.boolean().required(),
		irrigation: Yup.boolean().required(),
	}),
	step: FormStates.includesInputs,
};

export const usesEnergy: SubmodulePlus = {
	name: "activity.will_there_be_energy_consumption_with_or_without_the_project",
	inputGroups: [
		{
			type: FieldType.BOOLEAN,
			inputName: "usesEnergy",
			label: "",
		} as BooleanInputGroup,
	],
	initialValues: {
		usesEnergy: false,
	},
	validationSchema: Yup.object({ usesEnergy: Yup.boolean().required() }),
	step: FormStates.usesEnergy,
};

//this function gets the current state and answers of the current step and returns the moduleId(s) to add to the activity
export const getModule: (
	state: FormStates | undefined,
	values: SetupAssistantForm,
) => FEModules[] | undefined = (state, values) => {
	let res = [] as FEModules[];
	switch (state) {
		case FormStates.isOrganicSoil:
			if (values.isOrganicSoil) return [FEModules.OrganicSoil];
			break;

		case FormStates.mainActivityFocus:
			return mainActivityLandUseOptions.find(
				(mainOption) => mainOption.id === values.mainActivityFocus,
			)?.derivedModules;

		case FormStates.previousLandUse:
			res =
				landUseOptions.find(
					(landOption) => landOption.id === values.previousLandUse,
				)?.derivedModules ?? [];
			if (values.previousLandUse !== values.mainActivityFocus)
				res = [...res, FEModules.LandUseChange];
			return res;

		case FormStates.areaWithoutProject:
			res =
				landUseOptions.find(
					(landOption) => landOption.id === values.withoutProjectLandUse,
				)?.derivedModules ?? [];
			if (values.areaWithoutProject) res = [...res, FEModules.LandUseChange];
			return res;

		case FormStates.livestockFeedOrigin:
			return (
				livestockFeedOriginOptions.find(
					(feedOption) => feedOption.id === values.fishFeedOrigin,
				)?.derivedModules ?? []
			);

		case FormStates.fishFeedOrigin:
			return (
				fishFeedOriginOptions.find(
					(feedOption) => feedOption.id === values.fishFeedOrigin,
				)?.derivedModules ?? []
			);

		case FormStates.fisheriesSize:
			return values.fisheriesSize === 1
				? [FEModules.SmallFisheries]
				: values.fisheriesSize === 2
					? [FEModules.LargeFisheries]
					: [];

		case FormStates.includesInputs:
			if (values.fertilizers) res.push(FEModules.Inputs);
			if (values.energy) res.push(FEModules.Energy);
			if (values.irrigation) res.push(FEModules.Irrigation);
			return res;

		case FormStates.usesEnergy:
			if (values.usesEnergy) return [FEModules.Energy];
			break;

		default:
			return undefined;
	}
};

//TODO: this is just for demo purposes, you may want to have more controls on the values
export const getNext: (
	state: FormStates | undefined,
	values: SetupAssistantForm,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
) => FormStates | undefined = (state: FormStates | undefined, values: any) => {
	switch (state) {
		case FormStates.mainActivityFocus:
			return mainActivityLandUseOptions.find(
				(landUse) => values.mainActivityFocus === landUse.id,
			)?.derivedStates;

		case FormStates.livestockFeedOrigin:
			return (
				livestockFeedOriginOptions.find(
					(feedOptions) => values.livestockFeedOrigin === feedOptions.id,
				)?.derivedStates ?? FormStates.finish
			);

		case FormStates.fishFeedOrigin:
			return (
				fishFeedOriginOptions.find(
					(feedOptions) => values.fishFeedOrigin === feedOptions.id,
				)?.derivedStates ?? FormStates.finish
			);

		default:
			return undefined;
	}
};

export const checkboxesOrder = [FEModules.LandUseChange, FEModules.AnnualCropland, FEModules.PerennialCropland, FEModules.FloodedRice, FEModules.SetAside, FEModules.Grassland, FEModules.ForestManagement, FEModules.Settlements, FEModules.OtherLand, FEModules.OrganicSoil, FEModules.CoastalWetlands, FEModules.Waterbodies, FEModules.LivestockManagement, FEModules.SmallFisheries, FEModules.LargeFisheries, FEModules.Aquaculture, FEModules.Inputs, FEModules.Energy, FEModules.Irrigation]

export const isAreaRequiredModules = [
	...MODULES_WITH_LAND_USE,
	FEModules.Waterbodies,
	FEModules.CoastalWetlands,
];
export const finishValidationSchema = Yup.object({
	modulesList: Yup.array()
		.of(Yup.string())
		.min(1)
		.test(
			"max-land-use-modules",
			translate("validations.max-land-use-modules"),
			(value: (string | undefined)[] | undefined) => {
				const landUseModulesCount = value?.filter((item) => {
					if (!item) return false;
					const numberItem = Number(item);
					const mod = numberItem as unknown as FEModules;
					return MODULES_WITH_LAND_USE.includes(mod);
				}).length;
				return (!landUseModulesCount || landUseModulesCount <= 3) as boolean;
			},
		)
		.test(
			"has-to-include-luc",
			translate("validations.has-to-include-luc"),
			(value: (string | undefined)[] | undefined) => {
				const landUseModulesCount = value?.filter((item) => {
					if (!item) return false;
					const numberItem = Number(item);
					const mod = numberItem as unknown as FEModules;
					return MODULES_WITH_LAND_USE.includes(mod);
				}).length;
				const includesLuc = value?.includes(`${FEModules.LandUseChange}`);
				return (
					!landUseModulesCount
						? true
						: landUseModulesCount > 1 && landUseModulesCount <= 3
							? includesLuc
							: true
				) as boolean;
			},
		)
		.test(
			"livestock-association",
			translate("validations.livestock-association"),
			(value: (string | undefined)[] | undefined) => {
				const isLivestockContained = value?.includes(
					`${FEModules.LivestockManagement}`,
				);

				const otherModulesCount =
					value?.filter((item) => {
						if (!item) return false;
						const numberItem = Number(item);
						const mod = numberItem as unknown as FEModules;
						return ![
							FEModules.LivestockManagement,
							FEModules.Inputs,
							FEModules.Energy,
						].includes(mod);
					}).length ?? 0;
				return (
					!isLivestockContained ? true : otherModulesCount === 0
				) as boolean;
			},
		)
		.test(
			"coastalWetlands-association",
			translate("validations.coastalWetlands-association"),
			(value: (string | undefined)[] | undefined) => {
				const isCoastalContained = value?.includes(
					`${FEModules.CoastalWetlands}`,
				);

				const otherModulesCount =
					value?.filter((item) => {
						if (!item) return false;
						const numberItem = Number(item);
						const mod = numberItem as unknown as FEModules;
						return ![
							FEModules.CoastalWetlands,
							FEModules.Inputs,
							FEModules.Energy,
						].includes(mod);
					}).length ?? 0;
				return (
					!isCoastalContained ? true : otherModulesCount === 0
				) as boolean;
			},
		)
		.test(
			"marineInland-association",
			translate("validations.marineInland-association"),
			(value: (string | undefined)[] | undefined) => {
				const isMarineInlandContained =
					value?.includes(`${FEModules.SmallFisheries}`) ||
					value?.includes(`${FEModules.LargeFisheries}`);

				const otherModulesCount =
					value?.filter((item) => {
						if (!item) return false;
						const numberItem = Number(item);
						const mod = numberItem as unknown as FEModules;
						return ![
							FEModules.SmallFisheries,
							FEModules.LargeFisheries,
							FEModules.Inputs,
							FEModules.Energy,
						].includes(mod);
					}).length ?? 0;
				return (
					!isMarineInlandContained ? true : otherModulesCount === 0
				) as boolean;
			},
		)
		.test(
			"waterBodies-association",
			translate("validations.waterBodies-association"),
			(value: (string | undefined)[] | undefined) => {
				const isWaterBodiesContained = value?.includes(
					`${FEModules.Waterbodies}`,
				);

				const otherModulesCount =
					value?.filter((item) => {
						if (!item) return false;
						const numberItem = Number(item);
						const mod = numberItem as unknown as FEModules;
						return ![
							FEModules.Waterbodies,
							FEModules.Inputs,
							FEModules.Energy,
						].includes(mod);
					}).length ?? 0;
				return (
					!isWaterBodiesContained ? true : otherModulesCount === 0
				) as boolean;
			},
		)
		.test(
			"aquaculture-association",
			translate("validations.aquaculture-association"),
			(value: (string | undefined)[] | undefined) => {
				const isAquacultureContained = value?.includes(
					`${FEModules.Aquaculture}`,
				);

				const otherModulesCount =
					value?.filter((item) => {
						if (!item) return false;
						const numberItem = Number(item);
						const mod = numberItem as unknown as FEModules;
						return ![
							FEModules.Aquaculture,
							FEModules.Inputs,
							FEModules.Energy,
						].includes(mod);
					}).length ?? 0;
				return (
					!isAquacultureContained ? true : otherModulesCount === 0
				) as boolean;
			},
		)
		.test(
			"organicsoil-association",
			translate("validations.organicsoil-association"),
			(value: (string | undefined)[] | undefined) => {
				const isOrganicSoilContained = value?.includes(
					`${FEModules.OrganicSoil}`,
				);

				const lucModulesCount =
					value?.filter((item) => {
						if (!item) return false;
						const numberItem = Number(item);
						const mod = numberItem as unknown as FEModules;
						return MODULES_WITH_LAND_USE.includes(mod);
					}).length ?? 0;
				return (
					!isOrganicSoilContained ? true : lucModulesCount !== 0
				) as boolean;
			},
		),
	landUse_start: validatePositiveNumber.when("modulesList", {
		is: (modulesList: string[]) =>
			modulesList.includes(`${FEModules.LandUseChange}`),
		// biome-ignore lint/suspicious/noThenProperty: <explanation>
		then: validatePositiveNumber
			.required()
			.test(
				"landUse_start-not-equal-to-others",
				translate("validations.landUse_start-not-equal-to-others"),
				function (value) {
					const { landUse_w, landUse_wo } = this.parent;
					return value !== landUse_w || value !== landUse_wo;
				},
			),
	}),
	landUse_w: validatePositiveNumber.when("modulesList", {
		is: (modulesList: string[]) =>
			modulesList.includes(`${FEModules.LandUseChange}`),
		// biome-ignore lint/suspicious/noThenProperty: <explanation>
		then: validatePositiveNumber
			.required()
			.test(
				"landUse_w-not-equal-to-others",
				translate("validations.landUse_w-not-equal-to-others"),
				function (value) {
					const { landUse_start, landUse_wo } = this.parent;
					return value !== landUse_start || value !== landUse_wo;
				},
			),
	}),
	landUse_wo: validatePositiveNumber.when("modulesList", {
		is: (modulesList: string[]) =>
			modulesList.includes(`${FEModules.LandUseChange}`),
		// biome-ignore lint/suspicious/noThenProperty: <explanation>
		then: validatePositiveNumber
			.required()
			.test(
				"landUse_wo-not-equal-to-others",
				translate("validations.landUse_wo-not-equal-to-others"),
				function (value) {
					const { landUse_start, landUse_w } = this.parent;
					return value !== landUse_start || value !== landUse_w;
				},
			),
	}),
	area: validatePositiveNumber.when("modulesList", {
		is: (modulesList: string[]) =>
			modulesList.some((mod) => isAreaRequiredModules.includes(+mod)),
		// biome-ignore lint/suspicious/noThenProperty: <explanation>
		then: validatePositiveNumber.required(
			translate("validations.area_required_when_using_luc"),
		),
	}),
});