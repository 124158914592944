import FormRow from "../formRow/FormRow";

import { useEffect, useState } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import CustomCreatableSelectInput from "../CustomCreatableSelectInput";
import type { CreateDropdownInputGroup } from "../../../types/modulesInterfaces";
import type { FC } from "react";

const CreatableSelect: FC<CreateDropdownInputGroup> = ({
	inputName,
	dropdownOptions,
	disabled,
	label,
	index,
	comment,
	help,
	autosubmit,
	onCreate,
}) => {
	const [name, setName] = useState<string>("");
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const { errors, touched } = useFormikContext();
	const translatedLabel = useCustomTranslation(label);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	return (
		<FormRow
			rowComment={comment}
			rowHelp={help}
			isFetching={isFetchingData}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<Field
				label={translatedLabel}
				name={name}
				id={name}
				component={CustomCreatableSelectInput}
				options={dropdownOptions}
				disabled={disabled}
				autosubmit={autosubmit}
				onCreate={onCreate}
			/>
		</FormRow>
	);
};

export default CreatableSelect;
