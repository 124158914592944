import React, { useState } from 'react'
import { BiXCircle } from 'react-icons/bi'

const MessageNote = ({
    noteTitle,
    noteMessage,
    onCloseCallback
}: {
    noteTitle: string
    noteMessage: string
    onCloseCallback?: () => void
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false)
        onCloseCallback && onCloseCallback()
        return
    }
    
  return (
    
    <>
        {
            isOpen && (
                <div
                className="tiertwo-tooltip fs-14"
                style={{ 
                    marginTop: "1rem",
                    marginRight: "1rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    position: "relative",
                    padding: "20px",
                }}
                >
                    <div 
                        style={{ position: "absolute", top: 0, right: 0, padding: "5px",cursor: "pointer" }}
                        onClick={handleClose}
                    >
                        <BiXCircle size={23}/>
                    </div>
                    <div style={{ fontWeight: "bold",  }}>
                    {noteTitle}
                    </div>
                    {noteMessage}
                </div>
            )
        }
    </>
  )
}

export default MessageNote