import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
	Outlet,
} from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { Suspense } from 'react';
import { AppProvider } from "./context/AppProvider";
import { FEModules, FEModulesObj } from "./utils/moduleList";

import useTabsHook from "./utils/useTabsHook";
import ProtectedLayout from "./ProtectedLayout";
import Header from "./components/header/Header";
import Login from "./pages/accessManagement/login/Login";
import SelectProject from "./pages/selectProject/SelectProject";
import ActivityBuilder from "./pages/activityBuilder/ActivityBuilder";
import ProjectContainer from "./pages/project/ProjectContainer";
import ProjectDescriptionContainer from "./pages/project/description/ProjectDescriptionContainer";
import ProjectActivitiesContainer from "./pages/project/activities/ProjectActivitiesContainer";
import ProjectResultsContainer from "./pages/project/results/ProjectResultsContainer";
import SetupAssistantContainer from "./pages/project/activities/setupAssistant/SetupAssistantContainer";
import EditActivityContainer from "./pages/activity/EditActivityContainer";
import AuthLayout from "./pages/accessManagement/AuthLayout";
import Register from "./pages/accessManagement/register/Register";
import TermAndConditions from "./pages/accessManagement/TermAndConditions";
import ResetPassword from "./pages/accessManagement/resetPassword/ResetPassword";

import AnnualCroplandModule from "./pages/activityBuilder/modules/annualCropland/AnnualCroplandModule";
import LandUseChangeModule from "./pages/activityBuilder/modules/landUseChanges/LandUseChangeModule";
import LargeFisheriesModule from "./pages/activityBuilder/modules/largeFisheries/LargeFisheriesModule";
import SmallFisheriesModule from "./pages/activityBuilder/modules/smallFisheries/SmallFisheriesModule";
import GrasslandManagementModule from "./pages/activityBuilder/modules/grasslandManagement/GrasslandManagementModule";
import PerennialCropland from "./pages/activityBuilder/modules/perennialCropland/PerennialCroplandModule";
import ActivityCompleteStep from "./pages/activityBuilder/ActivityCompleteStep";
import Livestock from "./pages/activityBuilder/modules/livestockManagement/LivestockManagementModule";
import FloodedRice from "./pages/activityBuilder/modules/floodedRice/FloodedRiceModule";
import AquacultureModule from "./pages/activityBuilder/modules/aquaculture/AquacultureModule";
import NoMatch404 from "./pages/accessManagement/NoMatch404";
import InputsModule from "./pages/activityBuilder/modules/inputs/InputsModule";
import ForestManagement from "./pages/activityBuilder/modules/forestManagement/ForestManagementModule";
import SetAside from "./pages/activityBuilder/modules/setAside/SetAsideModule";
import Waterbodies from "./pages/activityBuilder/modules/waterbodies/WaterbodiesModule";
import OtherLand from "./pages/activityBuilder/modules/otherLand/OtherLandModule";
import Energy from "./pages/activityBuilder/modules/energy/EnergyModule";
import OrganicSoil from "./pages/activityBuilder/modules/organicSoil/OrganicSoilModule";
import Settlements from "./pages/activityBuilder/modules/settlements/SettlementsModule";
import Irrigation from "./pages/activityBuilder/modules/irrigation/IrrigationModule";
import CoastalWetlands from "./pages/activityBuilder/modules/coastalWetlands/CoastalWetlandsModule";
import Provider from "./Provider";
import LoadingFallback from "./components/loading/LoadingFallback";
import EditModules from "./pages/activity/EditModules";
import EditActivity from "./pages/activity/EditActivity";
import ErrorBoundary from "./components/ErrorBoundary";
import { Footer } from "./components/footer/Footer";

function App() {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route element={<Root />}>
				<Route path="auth" element={<AuthLayout />}>
					<Route path="login" element={<Login />} />
					<Route path="register" element={<Register />} />
					<Route
						path="terms-and-conditions/:tab"
						element={<TermAndConditions />}
					/>
					<Route path="reset-password" element={<ResetPassword />} />
				</Route>
				<Route element={<ProtectedLayout />}>
					<Route path="builder/:activityId" element={<ActivityBuilder />}>
						<Route path={FEModulesObj[FEModules.AnnualCropland].path} element={<AnnualCroplandModule />} />
						<Route path={FEModulesObj[FEModules.LandUseChange].path} element={<LandUseChangeModule />} />
						<Route path={FEModulesObj[FEModules.LargeFisheries].path} element={<LargeFisheriesModule />} />
						<Route path={FEModulesObj[FEModules.SmallFisheries].path} element={<SmallFisheriesModule />} />
						<Route path={FEModulesObj[FEModules.Grassland].path} element={<GrasslandManagementModule />} />
						<Route path={FEModulesObj[FEModules.PerennialCropland].path} element={<PerennialCropland />} />
						<Route path={FEModulesObj[FEModules.LivestockManagement].path} element={<Livestock />} />
						<Route path={FEModulesObj[FEModules.FloodedRice].path} element={<FloodedRice />} />
						<Route path={FEModulesObj[FEModules.Aquaculture].path} element={<AquacultureModule />} />
						<Route path={FEModulesObj[FEModules.Inputs].path} element={<InputsModule />} />
						<Route path={FEModulesObj[FEModules.ForestManagement].path} element={<ForestManagement />} />
						<Route path={FEModulesObj[FEModules.Waterbodies].path} element={<Waterbodies />} />
						<Route path={FEModulesObj[FEModules.SetAside].path} element={<SetAside />} />
						<Route path={FEModulesObj[FEModules.OtherLand].path} element={<OtherLand />} />
						<Route path={FEModulesObj[FEModules.Energy].path} element={<Energy />} />
						<Route path={FEModulesObj[FEModules.Settlements].path} element={<Settlements />} />
						<Route path={FEModulesObj[FEModules.Irrigation].path} element={<Irrigation />} />
						<Route path={FEModulesObj[FEModules.CoastalWetlands].path} element={<CoastalWetlands />} />
						<Route path={FEModulesObj[FEModules.OrganicSoil].path} element={<OrganicSoil />} />
						<Route path={FEModulesObj[FEModules.ActivityComplete].path} element={<ActivityCompleteStep />} />
					</Route>
					<Route path="/" element={<SelectProject />} />
					<Route path="project/:projectId" element={<ProjectContainer />}>
						<Route
							path="description"
							element={<ProjectDescriptionContainer />}
						/>
						<Route path="activities" element={<ProjectActivitiesContainer />} />
						<Route path="new-activity" element={<SetupAssistantContainer />} />
						<Route path="results" element={<ProjectResultsContainer />} />
					</Route>
					<Route
						path="activity/:activityId"
						element={<EditActivityContainer />}>
						<Route path="edit" element={<EditActivity />} />
						<Route path="edit-modules" element={<EditModules />} />
					</Route>
				</Route>
				<Route path="*" element={<NoMatch404 />} />
			</Route>,
		),
	);

	return <RouterProvider router={router} />;
}

const Root = () => {
	const { isHeaderSmall } = useTabsHook();

	return (
		<AppProvider>
			<div className="page-container">
				<div className="content-wrap">
					<div className={`App ${!isHeaderSmall ? "App-mw" : ""}`}>
						<Header />
						<Provider>
							<ErrorBoundary>
								<Outlet />
							</ErrorBoundary>
						</Provider>
					</div>
					<Footer />
				</div>
			</div>
		</AppProvider>
	);
};

export default function WrappedApp() {
	//TODO: add loading state
	return (
		<Suspense fallback={<LoadingFallback />}>
			<App />
		</Suspense>
	)
}