import type {
	HectaresInputGroup,
	InputGroup,
	ModuleSchema,
	T2Schema,
	StartWithWithoutGeneric,
	Submodule,
	StartWithWithoutTierTwo,
	DropdownInputGroup,
	OptionalSubmodule,
	TranslationKey,
	BelongsToLuc,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import * as Yup from "yup";
import type {
	ForestManagementPayload,
	ForestManagementT2Payload,
} from "./forestManagementTypes";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BEModules } from "../../../../utils/moduleList";
import { createDegradationValidation, createLoggingValidation, createRotationValidation, createdDisturbanceValidation } from "./validation";
import { standardLucValidation, UnitManager, UnitType, validatePercentage, validatePositiveNumber } from "../moduleUtils";

//TODO: work on this, quick solution to handling names in forest, go to commit for more context
export enum ForestManagementSections {
	ROTATIONS = "module.rotations",
	LOGGING = "module.harvesting",
	DISTURBANCES = "module.disturbances",
	DEGRADATION = "module.aggregate_impact_of_changes_in_forest_conditions"
}

export const includedPropList = [
	"id",
	"status",
	"land_use_change",
	"note",
	"area",
	"forest_condition_type",
	"land_use_type_start",
	"forest_type",
	"rotation_length_yrs_start",
	"rotation_length_yrs_w",
	"rotation_length_yrs_wo",
	"rotation_percentage_biomass_for_energy_start",
	"rotation_percentage_biomass_for_energy_w",
	"rotation_percentage_biomass_for_energy_wo",
	"logging_recurrence_yrs_start",
	"logging_recurrence_yrs_w",
	"logging_recurrence_yrs_wo",
	"logging_percentage_agb_logged_start",
	"logging_percentage_agb_logged_w",
	"logging_percentage_agb_logged_wo",
	"logging_percentage_biomass_for_energy_start",
	"logging_percentage_biomass_for_energy_w",
	"logging_percentage_biomass_for_energy_wo",
	"average_yearly_degradation_percentage_start",
	"average_yearly_degradation_percentage_w",
	"average_yearly_degradation_percentage_wo",
	"land_use_type_thread",
	"rotation_length_yrs_thread",
	"rotation_percentage_biomass_for_energy_thread",
	"logging_recurrence_yrs_thread",
	"logging_percentage_agb_logged_thread",
	"logging_percentage_biomass_for_energy_thread",
	"average_yearly_degradation_percentage_thread",
];
export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"agb_t2_start",
	"agb_t2_w",
	"agb_t2_wo",
	"bgb_t2_start",
	"bgb_t2_w",
	"bgb_t2_wo",
	"litter_t2_start",
	"litter_t2_w",
	"litter_t2_wo",
	"deadwood_t2_start",
	"deadwood_t2_w",
	"deadwood_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo",
	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"agb_growth_rate_le_20_yrs_t2_start",
	"agb_growth_rate_le_20_yrs_t2_w",
	"agb_growth_rate_le_20_yrs_t2_wo",
	"agb_growth_rate_gt_20_yrs_t2_start",
	"agb_growth_rate_gt_20_yrs_t2_w",
	"agb_growth_rate_gt_20_yrs_t2_wo",
	"bgb_growth_rate_le_20_yrs_t2_start",
	"bgb_growth_rate_le_20_yrs_t2_w",
	"bgb_growth_rate_le_20_yrs_t2_wo",
	"bgb_growth_rate_gt_20_yrs_t2_start",
	"bgb_growth_rate_gt_20_yrs_t2_w",
	"bgb_growth_rate_gt_20_yrs_t2_wo",
	"rotation_start_year_t2_start",
	"rotation_start_year_t2_w",
	"rotation_start_year_t2_wo",
	"logging_start_year_t2_start",
	"logging_start_year_t2_w",
	"logging_start_year_t2_wo",
	"logging_dry_matter_logged_t2_start",
	"logging_dry_matter_logged_t2_w",
	"logging_dry_matter_logged_t2_wo",
	"degradation_dry_matter_impacted_t2_start",
	"degradation_dry_matter_impacted_t2_w",
	"degradation_dry_matter_impacted_t2_wo",
];

const requiredNumberValidation = (message: TranslationKey, scenario: 'start' | 'w' | 'wo') => {
	return standardLucValidation(message, scenario, BEModules.ForestManagement)
}

export const validationSchema = (belongsToLuc?: BelongsToLuc | undefined) => {
	const rotationValidation = createRotationValidation(belongsToLuc);
	const loggingValidation = createLoggingValidation(belongsToLuc);
	const disturbanceValidation = createdDisturbanceValidation(belongsToLuc);
	const degradationValidation = createDegradationValidation(belongsToLuc);
	return Yup.object().shape({
		parent: Yup.lazy(values => {
			const {
				rotation_length_yrs_start,
				rotation_length_yrs_w,
				rotation_length_yrs_wo,
				rotation_percentage_biomass_for_energy_start,
				rotation_percentage_biomass_for_energy_w,
				rotation_percentage_biomass_for_energy_wo,
				logging_recurrence_yrs_start,
				logging_recurrence_yrs_w,
				logging_recurrence_yrs_wo,
				logging_percentage_agb_logged_start,
				logging_percentage_agb_logged_w,
				logging_percentage_agb_logged_wo,
				logging_percentage_biomass_for_energy_start,
				logging_percentage_biomass_for_energy_w,
				logging_percentage_biomass_for_energy_wo,
			} = values;
			const fields = {
				rotation_length_yrs_start: validatePositiveNumber,
				rotation_length_yrs_w: validatePositiveNumber,
				rotation_length_yrs_wo: validatePositiveNumber,
				rotation_percentage_biomass_for_energy_start: validatePercentage,
				rotation_percentage_biomass_for_energy_w: validatePercentage,
				rotation_percentage_biomass_for_energy_wo: validatePercentage,
	
				logging_recurrence_yrs_start: validatePositiveNumber,
				logging_recurrence_yrs_w: validatePositiveNumber,
				logging_recurrence_yrs_wo: validatePositiveNumber,
				logging_percentage_agb_logged_start: validatePositiveNumber,
				logging_percentage_agb_logged_w: validatePercentage,
				logging_percentage_agb_logged_wo: validatePercentage,
				logging_percentage_biomass_for_energy_start: validatePercentage,
				logging_percentage_biomass_for_energy_w: validatePercentage,
				logging_percentage_biomass_for_energy_wo: validatePercentage,
	
				average_yearly_degradation_percentage_start: validatePercentage,
				average_yearly_degradation_percentage_w: validatePercentage,
				average_yearly_degradation_percentage_wo: validatePercentage,
				forest_type: validatePositiveNumber.required("Forest Type is required")
	
			}
			const rotationIsTriggered =
				!!rotation_length_yrs_start ||
				!!rotation_length_yrs_w ||
				!!rotation_length_yrs_wo ||
				!!rotation_percentage_biomass_for_energy_start ||
				!!rotation_percentage_biomass_for_energy_w ||
				!!rotation_percentage_biomass_for_energy_wo
	
			const loggingIsTriggered =
				!!logging_recurrence_yrs_start ||
				!!logging_recurrence_yrs_w ||
				!!logging_recurrence_yrs_wo ||
				!!logging_percentage_agb_logged_start ||
				!!logging_percentage_agb_logged_w ||
				!!logging_percentage_agb_logged_wo ||
				!!logging_percentage_biomass_for_energy_start ||
				!!logging_percentage_biomass_for_energy_w ||
				!!logging_percentage_biomass_for_energy_wo;
			if (rotationIsTriggered) {
				fields.rotation_length_yrs_start = rotationValidation.call(this, 'start', 'rotation_length_yrs');
				fields.rotation_length_yrs_w = rotationValidation.call(this, 'w', 'rotation_length_yrs');
				fields.rotation_length_yrs_wo = rotationValidation.call(this, 'wo', 'rotation_length_yrs');
				fields.rotation_percentage_biomass_for_energy_start = rotationValidation.call(this, 'start', 'rotation_percentage_biomass_for_energy', true);
				fields.rotation_percentage_biomass_for_energy_w = rotationValidation.call(this, 'w', 'rotation_percentage_biomass_for_energy', true);
				fields.rotation_percentage_biomass_for_energy_wo = rotationValidation.call(this, 'wo', 'rotation_percentage_biomass_for_energy', true);
			}
			if (loggingIsTriggered) {
				fields.logging_recurrence_yrs_start = loggingValidation.call(this, 'start', 'logging_recurrence_yrs');
				fields.logging_recurrence_yrs_w = loggingValidation.call(this, 'w', 'logging_recurrence_yrs');
				fields.logging_recurrence_yrs_wo = loggingValidation.call(this, 'wo', 'logging_recurrence_yrs');
				fields.logging_percentage_agb_logged_start = loggingValidation.call(this, 'start', 'logging_percentage_agb_logged');
				fields.logging_percentage_agb_logged_w = loggingValidation.call(this, 'w', 'logging_percentage_agb_logged');
				fields.logging_percentage_agb_logged_wo = loggingValidation.call(this, 'wo', 'logging_percentage_agb_logged');
				fields.logging_percentage_biomass_for_energy_start = loggingValidation.call(this, 'start', 'logging_percentage_biomass_for_energy', true);
				fields.logging_percentage_biomass_for_energy_w = loggingValidation.call(this, 'w', 'logging_percentage_biomass_for_energy', true);
				fields.logging_percentage_biomass_for_energy_wo = loggingValidation.call(this, 'wo', 'logging_percentage_biomass_for_energy', true);
			}
	
			// if (!rotationIsTriggered && !loggingIsTriggered) {
			// 	fields.average_yearly_degradation_percentage_start = degradationValidation.call(this, 'start', 'average_yearly_degradation_percentage', true);
			// 	fields.average_yearly_degradation_percentage_w = degradationValidation.call(this, 'w', 'average_yearly_degradation_percentage', true);
			// 	fields.average_yearly_degradation_percentage_wo = degradationValidation.call(this, 'wo', 'average_yearly_degradation_percentage', true);
			// }
	
			return Yup.object().shape({
				...fields,
			});
		}),
		disturbances: Yup.array().of(Yup.object().shape({
			disturbance_type: requiredNumberValidation('validations.disturbance_type_required', 'start'),
			recurrence_yrs_start: disturbanceValidation.call(this, 'start', 'recurrence_yrs'),
			recurrence_yrs_w: disturbanceValidation.call(this, 'w', 'recurrence_yrs'),
			recurrence_yrs_wo: disturbanceValidation.call(this, 'wo', 'recurrence_yrs'),
			percentage_biomass_destruction_start: disturbanceValidation.call(this, 'start', 'percentage_biomass_destruction', true),
			percentage_biomass_destruction_w: disturbanceValidation.call(this, 'w', 'percentage_biomass_destruction', true),
			percentage_biomass_destruction_wo: disturbanceValidation.call(this, 'wo', 'percentage_biomass_destruction', true),
		}))
	});
}

export const initialValues: ForestManagementPayload = {
	id: null,
	status: null,
	land_use_change: null,
	note: null,

	area: null,
	forest_type: null,
	forest_condition_type: null,
	land_use_type_start: null,
	rotation_length_yrs_start: null,
	rotation_length_yrs_w: null,
	rotation_length_yrs_wo: null,
	rotation_percentage_biomass_for_energy_start: null,
	rotation_percentage_biomass_for_energy_w: null,
	rotation_percentage_biomass_for_energy_wo: null,
	logging_recurrence_yrs_start: null,
	logging_recurrence_yrs_w: null,
	logging_recurrence_yrs_wo: null,
	logging_percentage_agb_logged_start: null,
	logging_percentage_agb_logged_w: null,
	logging_percentage_agb_logged_wo: null,
	logging_percentage_biomass_for_energy_start: null,
	logging_percentage_biomass_for_energy_w: null,
	logging_percentage_biomass_for_energy_wo: null,
	average_yearly_degradation_percentage_start: null,
	average_yearly_degradation_percentage_w: null,
	average_yearly_degradation_percentage_wo: null,

	activity: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
};

const disturbanceTypes = () => getOptions("disturbanceTypes");

const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "parent.area",
		disabled: true,
	};
	return [hectaresInputGroup];
};

const getMandatorySubmodules: () => Submodule[] = () => {
	const lengthOfRotation: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "parent.rotation_length_yrs",
		label: "module.length_of_rotation",
		unit: "[yrs]",
	};
	const biomassPercentage: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "parent.rotation_percentage_biomass_for_energy",
		label: "module.percentage_of_harvest_for_energy",
		unit: "[%]",
	};

	const loggingRecurrence: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "parent.logging_recurrence_yrs",
		label: "module.harvesting_recurrence",
		unit: "[yrs]",
	};
	const loggingAgb: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "parent.logging_percentage_agb_logged",
		label: "module.timber_harvested",
		unit: "[%AGB Logged]",
	};
	const loggingPercentage: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "parent.logging_percentage_biomass_for_energy",
		label: "module.biomass_used_for_energy",
		unit: "[%]",
	};

	return [
		{
			name: "module.rotation",
			inputGroups: [lengthOfRotation, biomassPercentage],
		},
		{
			name: "module.harvesting",
			inputGroups: [loggingRecurrence, loggingAgb, loggingPercentage],
		},
	];
};

const getDisturbanceSchema: () => Submodule = () => {
	const distType: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.disturbance_type",
		inputName: (index: number) => `disturbances.${index}.disturbance_type`,
		dropdownOptions: disturbanceTypes,
	};
	const distRecurrence: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.disturbance_recurrence",
		unit: "[yrs]",
		inputName: (index: number) => `disturbances.${index}.recurrence_yrs`,
	};
	const biomassDistruction: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.biomass_destruction",
		unit: "[%]",
		inputName: (index: number) =>
			`disturbances.${index}.percentage_biomass_destruction`,
	};

	return {
		name: "module.disturbance",
		inputGroups: [distType, distRecurrence, biomassDistruction],
	};
};


/* const getOptionalSubmodules: () => OptionalSubmodule<any>[] = () => {
	const distType: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "Disturbance Type",
		inputName: (index: number) => `module.disturbances.${index}.disturbanceType`,
		dropdownOptions: [],
	};
	const distRecurrence: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "Disturbance Recurrence",
		unit: "[unit]",
		inputName: (index: number) => `module.disturbances.${index}.disturbanceRecurrence`,
	};
	const areaImpactedByDisturbance: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "Area impacted by Disturbance",
		inputName: (index: number) => `module.disturbances.${index}.areaImpactedByDisturbance`,
	};

	return [

		{
			name: "Disturbance",
			inputName: "module.disturbances",
			inputGroups: [distType, distRecurrence, areaImpactedByDisturbance],
			repeatable: true,
			initialValues: {
				disturbanceType: "",
				disturbanceRecurrence: null,
				areaImpactedByDisturbance: null
			},
			linkedTierTwoInputs: ["tiertwo.disturbances"],
			max: 3
		},
	];
}; */

/* T2 */
export const t2InitialValues: ForestManagementT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	agb_t2_start: null,
	agb_t2_w: null,
	agb_t2_wo: null,
	bgb_t2_start: null,
	bgb_t2_w: null,
	bgb_t2_wo: null,
	litter_t2_start: null,
	litter_t2_w: null,
	litter_t2_wo: null,
	deadwood_t2_start: null,
	deadwood_t2_w: null,
	deadwood_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,
	agb_growth_rate_le_20_yrs_t2_start: null,
	agb_growth_rate_le_20_yrs_t2_w: null,
	agb_growth_rate_le_20_yrs_t2_wo: null,
	agb_growth_rate_gt_20_yrs_t2_start: null,
	agb_growth_rate_gt_20_yrs_t2_w: null,
	agb_growth_rate_gt_20_yrs_t2_wo: null,
	bgb_growth_rate_le_20_yrs_t2_start: null,
	bgb_growth_rate_le_20_yrs_t2_w: null,
	bgb_growth_rate_le_20_yrs_t2_wo: null,
	bgb_growth_rate_gt_20_yrs_t2_start: null,
	bgb_growth_rate_gt_20_yrs_t2_w: null,
	bgb_growth_rate_gt_20_yrs_t2_wo: null,
	rotation_start_year_t2_start: null,
	rotation_start_year_t2_w: null,
	rotation_start_year_t2_wo: null,
	logging_start_year_t2_start: null,
	logging_start_year_t2_w: null,
	logging_start_year_t2_wo: null,
	logging_dry_matter_logged_t2_start: null,
	logging_dry_matter_logged_t2_w: null,
	logging_dry_matter_logged_t2_wo: null,
	degradation_dry_matter_impacted_t2_start: null,
	degradation_dry_matter_impacted_t2_w: null,
	degradation_dry_matter_impacted_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
	const forestManagementRows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.soc_t2",
			label: "module.soil_carbon",
			unit: "[tC/ha]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.flu_t2",
			label: "module.land_use_factor",
			unit: "[#]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.agb_t2",
			label: "module.above_ground_biomass",
			unit: "[tC/ha]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.bgb_t2",
			label: "module.below_ground_biomass",
			unit: "[tC/ha]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.agb_growth_rate_le_20_yrs_t2",
			label: "module.agb_growth_rate_under_20",
			unit: "[tC/ha/yr]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.agb_growth_rate_gt_20_yrs_t2",
			label: "module.agb_growth_rate_over_20",
			unit: "[tC/ha/yr]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.bgb_growth_rate_le_20_yrs_t2",
			label: "module.bgb_growth_rate_under_20",
			unit: "[tC/ha/yr]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.bgb_growth_rate_gt_20_yrs_t2",
			label: "module.bgb_growth_rate_over_20",
			unit: "[tC/ha/yr]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.litter_t2",
			label: "module.litter",
			unit: "[tC/ha]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.deadwood_t2",
			label: "module.dead_wood",
			unit: "[tC/ha]",
			links: ["Type of Forest"],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
	];

	const degradation: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: "tiertwo.degradation_dry_matter_impacted_t2",
		label: "module.dry_matter_impacted",
		unit: "[%]",
		links: [],
		startProps: { inputType: "number" },
		withoutProps: { inputType: "number" },
		withProps: { inputType: "number" },
	};

	return [
		{
			name: "",
			inputGroups: [...forestManagementRows],
		},
		// {
		// 	name: ForestManagementSections.DEGRADATION,
		// 	inputGroups: [degradation],
		// },
	];
};

// const getT2OptionalSubmodules: () => OptionalSubmodule<DisturbanceT2 | LoggingT2>[] = () => {
const getT2OptionalSubmodules: () => OptionalSubmodule<any>[] = () => {
	const fractionAffected: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: (index: number) =>
			`disturbances.${index}.dry_matter_impacted_t2`,
		label: "module.dry_matter_impacted",
		unit: "[%]",
		links: [],
		startProps: { inputType: "number" },
		withoutProps: { inputType: "number" },
		withProps: { inputType: "number" },
	};
	const startYearDisturbance: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: (index: number) => `disturbances.${index}.start_year_t2`,
		label: "module.start_year_of_disturbance",
		unit: UnitManager.getUnit(UnitType.Number_of_Years),
		links: [],
		startProps: { inputType: "number" },
		withoutProps: { inputType: "number" },
		withProps: { inputType: "number" },
	};
	const startYearRotations: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: (index: number) => "tiertwo.rotation_start_year_t2",
		label: "module.start_year_of_rotations",
		unit: UnitManager.getUnit(UnitType.Number_of_Years),
		links: [],
		startProps: { inputType: "number" },
		withoutProps: { inputType: "number" },
		withProps: { inputType: "number" },
	};

	const startYearLogging: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: (index: number) => "tiertwo.logging_start_year_t2",
		label: "module.start_year_of_harvesting",
		unit: UnitManager.getUnit(UnitType.Number_of_Years),
		links: [],
		startProps: { inputType: "number" },
		withoutProps: { inputType: "number" },
		withProps: { inputType: "number" },
	};

	const logging: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: "tiertwo.dryMatterLogged",
		label: "module.dry_matter_harvested",
		unit: "[t]",
		links: [],
		startProps: { inputType: "number" },
		withoutProps: { inputType: "number" },
		withProps: { inputType: "number" },
	};

	return [
		{
			name: "module.rotations",
			inputName: "tiertwo.rotations",
			inputGroups: [startYearRotations],
		},
		{
			name: "module.harvesting",
			inputName: "tiertwo.logging",
			inputGroups: [startYearLogging /*, logging*/],
			/*initialValues: {
				dryMatterLogged: "",
			},*/
		},
		{
			name: "module.disturbance",
			inputName: "disturbances",
			inputGroups: [startYearDisturbance, /*fractionAffected*/],
			/*initialValues: {
				fractionAffected: "",
			},*/
		},
	];
};



export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: getMandatorySubmodules(),
	};
	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
		optionalSubmodules: getT2OptionalSubmodules(),
	};

	const disturbanceSchema = getDisturbanceSchema();

	return { moduleSchema, t2Schema, disturbanceSchema };
};
