import type { FC, ReactNode, SetStateAction } from "react";
import { useState, useEffect } from "react";
import SubModuleInputGroup from "../SubModuleInputGroup";
import FormRow from "../formRow/FormRow";
import CustomSelectInput from "../CustomSelectInput";
import CustomInput from "../CustomInput";
import CustomToggle from "../CustomToggle";
import { CommentIcon } from "../../../utils/customIcons";

import { Field, getIn, useFormikContext } from "formik";
import { GroupArrowIcon } from "../../../utils/customIcons";
import type {
	DropdownOptions,
	StartWithWithout,
	StartWithWithoutGeneric,
	TranslationKey,
} from "../../../types/modulesInterfaces";
import { capitalize } from "../../../utils/helperFunctions";
import {
	useAppDispatch,
	useAppSelector,
	useCustomTranslation,
	useScrollToElement,
} from "../../../app/hooks";
import {
	selectIsFetchingData,
	setActiveAction,
	setActiveThread,
	toggleTabsOpen,
} from "../../../app/features/builder/builderSlice";
import { FieldType } from "../../../types/modulesInterfaces";
import { Actions } from "../../../types/interfaces";
import { motion } from "framer-motion";
import CommentTextEditorContainer from "../../actions/comments/CommentTextEditorContainer";
import useComments from "../../actions/comments/useComments";

const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;

const StartWithWithoutGenericGroup: FC<StartWithWithoutGeneric> = ({
	inputName,
	label,
	startProps,
	withProps,
	withoutProps,
	index,
	type,
	unit,
	comment,
	help,
	thread,
	autosubmit,
	showCommentIcon,
	placeholder,
}) => {
	const [name, setName] = useState<string>("");
	const [threadId, setThreadId] = useState<number | null>(null);
	const [error, setError] = useState<string | undefined>(undefined);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const { setFieldValue, errors, touched, values } = useFormikContext();
	const translatedLabel = useCustomTranslation(label);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);
		return () => {
			setName("");
		};
	}, [index, inputName]);

	useEffect(() => {
		if (!name || !values) return;
		setThreadId(getIn(values, `${name}_thread`));
	}, [name, values]);

	useEffect(() => {
		const errorPath = [`${name}_start`, `${name}_w`, `${name}_wo`].find(
			(inputName) => {
				const errorMessage = getIn(errors, inputName);
				const wasTouched = getIn(touched, inputName);
				return errorMessage?.length && wasTouched;
			},
		);
		if (errorPath) setError(getIn(errors, errorPath));

		return () => setError(undefined);
	}, [name, errors, touched]);

	//t1 defaults logic
	const [defaultValues, setDefaultValues] = useState<StartWithWithout | undefined>(undefined);
	useEffect(() => {
		const start = getIn(values, `${name}_start_default`);
		const withField = getIn(values, `${name}_w_default`);
		const without = getIn(values, `${name}_wo_default`);
		setDefaultValues({
			start: isFloat(start) ? start.toFixed(2) : start,
			with: isFloat(withField) ? withField.toFixed(2) : withField,
			without: isFloat(without) ? without.toFixed(2) : without,
		});
	}, [values, name]);

	const fillType: { inputType: "boolean" | "drop" | "free" | "number" } =
		type === FieldType.SWW_BOOL
			? { inputType: "boolean" }
			: type === FieldType.SWW_SELECT
				? { inputType: "drop" }
				: { inputType: "number" };

	if (
		fillType.inputType === "drop" &&
		!(
			startProps?.dropdownOptions &&
			withProps?.dropdownOptions &&
			withoutProps?.dropdownOptions
		)
	)
		return null;

	return (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<div style={{ width: "100%" }}>
				<SubModuleInputGroup
					name={`${translatedLabel ?? label} ${unit ?? ""}`}
					gridTemplateColumns="minmax(0, 1fr) 55px minmax(0, 1fr)"
					gridTemplateRows="1fr 1fr"
					comment={comment}
					help={help}
					error={error}
				>
					<FormRow
						style={{ gridColumn: "1", gridRow: "1 / span 2" }}
						borderColor="tertiary"
						isFetching={isFetchingData}
					>
						<DynamicField
							name={name}
							position="start"
							props={{ ...fillType, ...startProps }}
							setFieldValue={setFieldValue}
							autosubmit={autosubmit}
							placeholder={defaultValues?.start ? `${defaultValues.start}` : placeholder}
						/>
					</FormRow>
					<div
						style={{ gridColumn: "2", gridRow: "1 / span 2" }}
						className="d-flex align-items-center justify-content-center unselectable"
					>
						<GroupArrowIcon />
					</div>
					<FormRow
						style={{ gridColumn: "3", gridRow: "1" }}
						borderColor="quaternary"
						isFetching={isFetchingData}
					>
						<DynamicField
							name={name}
							position="with"
							props={{ ...fillType, ...withProps }}
							setFieldValue={setFieldValue}
							autosubmit={autosubmit}
							placeholder={defaultValues?.with ? `${defaultValues.with}` : placeholder}
						/>
					</FormRow>
					<FormRow
						style={{ gridColumn: "3", gridRow: "2" }}
						borderColor="primary"
						isFetching={isFetchingData}
					>
						<DynamicField
							name={name}
							position="without"
							props={{ ...fillType, ...withoutProps }}
							setFieldValue={setFieldValue}
							autosubmit={autosubmit}
							placeholder={defaultValues?.without ? `${defaultValues.without}` : placeholder}
						/>
					</FormRow>
				</SubModuleInputGroup>
			</div>
			<div
				style={{
					width: "10%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{showCommentIcon && !isFetchingData && threadId && (
					<InteractiveCommentIcon threadId={threadId} />
				)}
			</div>
		</div>
	);
};

const InteractiveCommentIcon = ({ threadId }: { threadId: number }) => {
	const dispatch = useAppDispatch();
	const { thread, isCommentActive, setIsCommentActive, handleCreateComment } =
		useComments({ threadId });
	const { scrollToElementById } = useScrollToElement();
	const activateComments = () => {
		dispatch(setActiveAction(Actions.Comments));
		dispatch(setActiveThread(threadId));
	};

	const openTabs = () => dispatch(toggleTabsOpen(true));

	const handleCommentClick = () => {
		activateComments();
		openTabs();
		scrollToElementById(`side-${threadId}`);
	};

	const handleEmptyCommentClick = () => {
		activateComments();
		openTabs();
		setIsCommentActive(!isCommentActive);
	};
	return (
		<div id={threadId.toString()}>
			{thread?.hasComments() ? (
				<CommentIcon
					fill={"yes"}
					style={{ paddingTop: "0.2rem" }}
					onClick={handleCommentClick}
					className="clickable"
				/>
			) : (
				<TooltipContainer
					text="module.add_comment"
					isCommentActive={isCommentActive}
					cancelCallback={setIsCommentActive}
					okCallback={(content) => {
						setIsCommentActive(false);
						handleCreateComment(content);
					}}
				>
					<CommentIcon
						fill={""}
						style={{ paddingTop: "0.2rem" }}
						onClick={handleEmptyCommentClick}
					/>
				</TooltipContainer>
			)}
		</div>
	);
};

const TooltipContainer = ({
	text,
	children,
	isCommentActive,
	cancelCallback,
	okCallback,
}: {
	text: TranslationKey;
	children: ReactNode;
	isCommentActive?: boolean;
	cancelCallback: React.Dispatch<SetStateAction<boolean>>;
	okCallback: ({ content }: { content: string }) => void;
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const translatedLabel = useCustomTranslation(text);

	return (
		<div
			style={{
				display: "inline-block",
				position: "relative",
				cursor: "pointer",
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{children}
			{isHovered && !isCommentActive && (
				<AddCommentTooltip>{translatedLabel}</AddCommentTooltip>
			)}

			{isCommentActive && (
				<AddCommentTooltip style={{ minWidth: 500 }}>
					<CommentTextEditorContainer
						isCommentActive={isCommentActive}
						cancelCallback={() => {
							cancelCallback(false);
							if (isHovered) setIsHovered(!isHovered);
						}}
						okCallback={okCallback}
					/>
				</AddCommentTooltip>
			)}
		</div>
	);
};

const AddCommentTooltip = ({
	children,
	style,
}: { children: ReactNode; style?: React.CSSProperties }) => {
	return (
		<motion.div
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -10 }}
			style={{
				position: "absolute",
				bottom: "125%",
				left: "50%",
				transform: "translateX(-50%)",
				padding: "10px",
				backgroundColor: "#fff",
				color: "#333",
				borderRadius: "8px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				whiteSpace: "nowrap",
				zIndex: "100",
				border: "1px solid #ddd",
				...style,
			}}
		>
			{children}
		</motion.div>
	);
};

const DynamicField = ({
	position,
	props: { inputType, disabled, dropdownOptions },
	name,
	setFieldValue,
	autosubmit,
	placeholder,
}: {
	position: "start" | "with" | "without";
	props: {
		inputType: "drop" | "free" | "boolean" | "number" | "percentage";
		dropdownOptions?: DropdownOptions;
		disabled?: boolean;
	};
	name: string;
	setFieldValue?: (
		field: string,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		value: any,
		shouldValidate?: boolean | undefined,
	) => void;
	autosubmit?: boolean;
	placeholder?: string;
}) => {
	const fieldProps: {
		setFieldValue?: (
			field: string,
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			value: any,
			shouldValidate?: boolean | undefined,
		) => void;
		type?: "text" | "password" | "number" | "percentage";
	} =
		inputType === "boolean"
			? { setFieldValue: setFieldValue }
			: inputType === "free"
				? { type: "text" }
				: inputType === "percentage" || inputType === "number"
					? { type: inputType }
					: { type: "number" };

	const nameSuffix =
		position === "with" ? "w" : position === "without" ? "wo" : "start";

	return (
		<Field
			label={`module.${position}`}
			name={`${name}_${nameSuffix}`}
			id={`${name}_${nameSuffix}`}
			component={
				inputType === "drop"
					? CustomSelectInput
					: inputType === "boolean"
						? CustomToggle
						: CustomInput
			}
			options={dropdownOptions}
			disabled={disabled}
			autosubmit={autosubmit}
			placeholder={placeholder}
			{...fieldProps}
		/>
	);
};

export default StartWithWithoutGenericGroup;
