import {
	convertIncomingPercentage,
	convertOutgoingPercentage,
	filterObjectProps,
	convertEmptyStringsToNull
} from "../../../../pages/activityBuilder/modules/inputs/utils";
import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/livestockManagement/livestockManagement";
import type {
	LivestockManagementDefaults,
	LivestockManagementPayload,
	LivestockManagementResponse,
	LivestockmanagementT2,
	ModuleFull,
	UpdateLivestockManagementPayload,
} from "../../../../pages/activityBuilder/modules/livestockManagement/livestockManagementTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getLivestocks: builder.query<ModuleFull | null, number>({
			query: (activityId) => `livestocks/?activity=${activityId}`,
			transformResponse: (response: LivestockManagementResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						{ ...response[0] },
						includedPropList,
					) as LivestockManagementPayload;

					const t2 = filterObjectProps(
						{ ...response[0] },
						includedT2PropList,
					) as LivestockmanagementT2;

					convertIncomingPercentage(module, ["prp_percentage_t2"]);

					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "Livestock", id: activityId },
			],
		}),
		updateLivestocks: builder.mutation<
			LivestockManagementResponse,
			UpdateLivestockManagementPayload
		>({
			query: ({ data, activityId }) => {
				const payload = {
					...data.module,
					...data.tiertwo,
				} as unknown as LivestockManagementResponse;
				const { id, ...rest } = payload;

				convertOutgoingPercentage(rest, ["prp_percentage_t2"]);
				convertEmptyStringsToNull(rest);

				const body = { ...rest, activity: activityId };
				return { url: `livestocks/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "Livestock", id: results?.activity.id },
				{ type: "ModulesStatuses", id: results?.activity.id },
				{ type: "ModuleResults", id: results?.id ? `livestocks-${results?.id}` : undefined },
				{ type: "ModuleHistory", id: results?.id ? `livestocks-${results?.id}` : undefined },
				{ type: "LivestockDefaults", id: results?.id ? `livestocks-${results?.id}` : undefined },
			],
		}),
		createLivestocks: builder.mutation<
			LivestockManagementResponse,
			UpdateLivestockManagementPayload
		>({
			query({ data, activityId }) {
				const payload = {
					...data.module,
					...data.tiertwo,
				} as unknown as LivestockManagementResponse;
				const body = { ...payload, activity: activityId };

				convertOutgoingPercentage(payload, ["prp_percentage_t2"]);

				return { url: "livestocks/", method: "POST", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "Livestock", id: results?.activity.id },
			],
		}),
		getLivestockDefaults: builder.query<LivestockManagementDefaults, number>({
			query: (uniqueId) => `livestocks/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [
				{ type: "LivestockDefaults", id: `livestocks-${uniqueId}` },
			],
		}),
	}),
});

export const {
	useGetLivestocksQuery,
	useUpdateLivestocksMutation,
	useCreateLivestocksMutation,
	useGetLivestockDefaultsQuery
} = moduleApiSlice;
