import type {
	InputGroup,
	Option,
	StartWithWithoutGeneric,
} from "../../../../types/modulesInterfaces";

import { getIn } from 'formik';

export interface Errors {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	[key: string]: any;
}

export interface Touched {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	[key: string]: any;
}

export const checkErrorsTouched = (errors: Errors, touched: Touched): boolean => {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const checkNestedErrors = (nestedErrors: any, nestedTouched: any): boolean => {
		for (const key in nestedErrors) {
			const errorValue = nestedErrors[key];
			const touchedValue = getIn(nestedTouched, key);

			if (Array.isArray(errorValue)) {
				for (let i = 0; i < errorValue.length; i++) {
					if (checkNestedErrors(errorValue[i], getIn(nestedTouched, `${key}[${i}]`))) {
						return true;
					}
				}
			} else if (typeof errorValue === 'object' && errorValue !== null) {
				if (checkNestedErrors(errorValue, touchedValue))
					return true;
			} else {
				if (touchedValue === true)
					return true;
			}
		}
		return false;
	};

	return checkNestedErrors(errors, touched);
};

export const fertilizers = {
	limestone: { label: "Limestone", unit: "t/yr" },
	dolomite: { label: "Dolomite", unit: "t/yr" },
	urea: { label: "Urea", unit: "t/yr" },
	syntheticFertilizer: { label: "Synthetic N-Fertilizer", unit: "tN/yr" },
	phosphorus: { label: "Phosphorus", unit: "tP2O5/yr" },
	potassium: { label: "Potassium", unit: "tK2O/yr" },
	inIrrigatedRice: {
		label: "N-Fertilizer in Continuously irrigated Rice",
		unit: "tN/yr",
	},
	inWetDryRice: {
		label: "N-Fertilizer in Wet-Dry Irrigated Rice",
		unit: "tN/yr",
	},
	sewage: { label: "Sewage", unit: "tN/yr" },
	//compost: { label: "Compost", unit: "tN/yr"" },
	wasteAngGuano: { label: "Waste and Guano", unit: "tN/yr" },
	//userDefined: { label: "User Defined", unit: "t/yr" },
};

export const pesticides = {
	fungicides: { label: "Fungicides", unit: "tAI/yr" },
	herbicides: { label: "Herbicides", unit: "tAI/yr" },
	insecticides: { label: "Insecticides", unit: "tAI/yr" },
};

export const animalFeed = {
	livestockFeed: { label: "Livestock Feed", unit: "tFeed/yr" },
	fishFeed: { label: "Fish Feed", unit: "tFeed/yr" },
	/* userDefined: {label: "User Defined", unit: "t/yr"},*/
};

const initValues = {
	type: "",
	units: { start: 0, with: 0, without: 0 },
};

export const filterObjectProps = (
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	rawObj: { [key: string]: any },
	props: string[],
) =>
	Object.keys(rawObj)
		.filter((key) => props.includes(key))
		.reduce((obj, key) => {
			// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
			return { ...obj, [key]: rawObj[key] };
		}, {});

export const submodules = [
	{
		name: "Fertilizers",
		inputName: "module.fertilizers",
		initialValues: initValues,
		map: fertilizers,
	},
	{
		name: "Pesticides",
		inputName: "module.pesticides",
		initialValues: initValues,
		map: pesticides,
	},
	{
		name: "Animal Feed",
		inputName: "module.animalFeed",
		initialValues: initValues,
		map: animalFeed,
	},
];

export interface InputModuleValues {
	type: string;
	units: {
		start: number;
		with: number;
		without: number;
	};
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const convertIncomingPercentage = (obj: any, props: string[]) => {
	for (const key in obj) {
		const hasToBeConverted = props.some((prop) =>
			key.startsWith(prop) &&
			["start", "w", "wo"]
				.map((status) => key.endsWith(status))
				.some((status) => status),
		) && obj[key];

		if (key !== "id" && hasToBeConverted && obj[key] !== null)
			obj[key] = +(obj[key] * 100).toFixed(5)
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const convertOutgoingPercentage = (obj: any, props: string[]) => {
	for (const key in obj) {
		const hasToBeConverted = props.some((prop) =>
			key.startsWith(prop) &&
			["start", "w", "wo"]
				.map((status) => key.endsWith(status))
				.some((status) => status),
		) && obj[key];

		if (key !== "id" && hasToBeConverted && obj[key] !== null)
			obj[key] = obj[key] / 100
	}
}

type MutateBody = Record<string, unknown>
export const convertEmptyStringsToNull: (obj: MutateBody) => MutateBody = (obj) => {
	// Iterate over each property in the object
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];

			// If the value is an empty string, convert it to null
			if (value === "") {
				obj[key] = null;
			}
		}
	}

	return obj;
}

export const replaceSWWOptions = (
	inputGroup: StartWithWithoutGeneric,
	optionList: Option[],
): StartWithWithoutGeneric => {
	return {
		...inputGroup,
		startProps: { dropdownOptions: optionList },
		withProps: { dropdownOptions: optionList },
		withoutProps: { dropdownOptions: optionList },
	};
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const deepEqual = (obj1: any, obj2: any): boolean => {
	// Check if both objects are null or undefined
	if (
		obj1 === null ||
		obj1 === undefined ||
		obj2 === null ||
		obj2 === undefined
	) {
		return obj1 === obj2;
	}

	// Check if both objects are of the same type
	if (typeof obj1 !== "object" || typeof obj2 !== "object") {
		return obj1 === obj2;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
};

export const instanceOfSWW = (
	obj: InputGroup,
): obj is StartWithWithoutGeneric => {
	return "startProps" in obj || "withProps" in obj || "withoutProps" in obj;
};

export const canBeNumber = (value: string | undefined) => {
	return !!value && !Number.isNaN(value) && value.trim() !== "";
}

export function isNumeric(value: string | undefined): boolean {
	return !!value && !Number.isNaN(Number(value)) &&
		!Number.isNaN(Number.parseFloat(value)) &&
		/^-?\d+(\.\d+)?$/.test(value) &&
		value.trim() !== "";
}

interface JustId { id: number | null }
export function getListIntersection<T extends JustId>({ currentChangesList, incomingList }: { currentChangesList: T[], incomingList: T[] }) {
	const currentIds = new Set<number>();
	const resultList: T[] = [];

	for (const submodule of currentChangesList) {
		if (submodule.id !== null)
			currentIds.add(submodule.id);
		resultList.push(submodule);
	}

	for (const submodule of incomingList) {
		if (submodule.id === null || !currentIds.has(submodule.id)) {
			resultList.push(submodule);
			if (submodule.id !== null) {
				currentIds.add(submodule.id);
			}
		}
	}

	return resultList;
}

export const preventFormSubmit = (event: React.KeyboardEvent) => {
	if (event.key === 'Enter' && event.currentTarget.tagName !== 'TEXTAREA') {
		event.preventDefault();
	}
};

export const roundToDecimals = (value: number, decimals: 1 | 2 | 3 | 4): number => {
	const factor = Math.pow(10, decimals);
	return Math.round(value * factor) / factor;
}

export const checkNullorUndefined = (value: number | null | undefined): boolean => {
	return value === null || value === undefined;
}