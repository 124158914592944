import { useState } from 'react'
import { useDeleteMembershipMutation } from '../../app/features/project/projectApiSlice';

const useRemovememberFromProject = (refetch?: () => void) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [toDeleteId, setToDeleteId] = useState<number | null>(null);

  const [removeUser, { isLoading: isLoadingDelete }] = useDeleteMembershipMutation();

  const handleDelete = async () => {
    if (isLoadingDelete || !toDeleteId) return;
    console.log("remove membership", toDeleteId)
    await removeUser(toDeleteId).unwrap();
    setDeleteDialogOpen(false)
    if (refetch) refetch()
  }

  const showDeleteModal = (memberId: number) => {
    setDeleteDialogOpen(true)
    setToDeleteId(memberId)
  }

  const handleCancel = () =>
    setDeleteDialogOpen(false)

  return {
    isDeleteDialogOpen,
    handleCancel,
    handleDelete,
    showDeleteModal,
    isLoadingDelete
  }

}

export default useRemovememberFromProject