import { useNavigate } from "react-router-dom";
import { useId } from "./EditActivityContainer";
import { SetupAssistantForm } from "../project/activities/setupAssistant/setupAssistant.types";
import { useGetActivitiesByIdQuery } from "../../app/features/activities/activityApiSlice";
import { useEffect, useState } from "react";
import SetupAssistantFinish from "../project/activities/setupAssistant/SetupAssistantFinish";

const EditModules = () => {
  const navigate = useNavigate()
  const handleReturn = () => navigate(-1)
  const { id } = useId()
  const { data: activityData, isLoading: activityIsLoading } = useGetActivitiesByIdQuery(id ?? 0, { skip: !id });
  const [values, setValues] = useState<SetupAssistantForm>({})

  useEffect(() => {
    if (activityData)
      setValues({
        activityTitle: activityData.name,
        changeRate: activityData.change_rate?.id,
        climate: activityData.climate_t2?.id,
        moisture: activityData.moisture_t2?.id,
        typeOfSoil: activityData.soil_type_t2?.id,
        startYear: activityData.start_year_t2 ?? undefined,
        duration: activityData.duration_t2 ?? undefined,
        activityCost: activityData.cost ?? undefined,
      })
  }, [activityData])

  return (
    <section>
      <div className="module-group">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-14 ff-medium-ext module-header w-fit">
            Edit Activity Modules
          </h2>
          <div className="module-number header-number ff-light-ext">{id}</div>
        </div>

        <div className="d-flex f-column" style={{ maxWidth: "450px" }}>
          <SetupAssistantFinish modules={[]} onReturn={handleReturn} values={values} />
        </div>
      </div>
    </section>
  );
}

export default EditModules;
