import Hectares from "./Hectares";
import Dropdown from "./Dropdown";
import ClassicInput from "./ClassicInput";
import StartWithWithoutGroup from "./StartWithWithoutGroup";
import BooleanInput from "./BooleanInput";
import RadioGroupInput from "./RadioGroupInput";
import TierTwoSWWGroup from "./TierTwoSWWGroup";
import TierTwoDropdown from "./TierTwoDropdown";
import TierTwoClassicInput from "./TierTwoClassicInput";
import TierTwoEmissions from "./TierTwoEmissions";
import TierTwoDynEmissions from "./TierTwoDynEmissions";
import CalendarInput from "./CalendarInput";
import CheckBoxesInputGroup from "./CheckBoxesInputGroup";
import CreatableSelect from "./CreatableSelect";
import TierTwoBooleanInput from "./TierTwoBooleanInput";

import { FieldType } from "../../../types/modulesInterfaces";
import type {
	BooleanInputGroup,
	ClassicInputGroup,
	ClassicInputGroupTierTwo,
	DropdownInputGroup,
	DropdownInputGroupTierTwo,
	DynamicEmissionsTierTwo,
	EmissionsTierTwo,
	HectaresInputGroup,
	InputGroup,
	RadioInputGroup,
	StartWithWithoutGeneric,
	StartWithWithoutTierTwo,
	DatepickerInputGroup,
	CheckboxesInputGroup,
	CreateDropdownInputGroup,
	BooleanInputGroupTierTwo,
} from "../../../types/modulesInterfaces";


const GenericInputGroup = ({ inputGroup, showCommentIcon }: { inputGroup: InputGroup, showCommentIcon: boolean }) => {
	switch (inputGroup.type) {
		case FieldType.HECTARES:
			return <Hectares {...(inputGroup as HectaresInputGroup)} />;
		case FieldType.SWW:
		case FieldType.SWW_BOOL:
		case FieldType.SWW_SELECT:
			return (
				<StartWithWithoutGroup {...(inputGroup as StartWithWithoutGeneric)} showCommentIcon={showCommentIcon} />
			);
		case FieldType.SELECT:
		case FieldType.SELECT_LUC:
			return <Dropdown {...(inputGroup as DropdownInputGroup)} />;
		case FieldType.CREATEABLE_SELECT:
			return <CreatableSelect {...(inputGroup as CreateDropdownInputGroup)} />;
		case FieldType.CLASSIC:
			return <ClassicInput {...(inputGroup as ClassicInputGroup)} />;
		case FieldType.BOOLEAN:
			return <BooleanInput {...(inputGroup as BooleanInputGroup)} />;
		case FieldType.DATEPICKER:
			return <CalendarInput {...(inputGroup as DatepickerInputGroup)} />;
		case FieldType.RADIO:
			return <RadioGroupInput {...(inputGroup as RadioInputGroup)} />;
		case FieldType.CHECKBOXES:
			return <CheckBoxesInputGroup {...(inputGroup as CheckboxesInputGroup)} />;
		case FieldType.SWW_T2:
			return <TierTwoSWWGroup {...(inputGroup as StartWithWithoutTierTwo)} />;
		case FieldType.SELECT_T2:
			return <TierTwoDropdown {...(inputGroup as DropdownInputGroupTierTwo)} />;
		case FieldType.CLASSIC_T2:
			return (
				<TierTwoClassicInput {...(inputGroup as ClassicInputGroupTierTwo)} />
			);
		case FieldType.BOOLEAN_T2:
			return (
				<TierTwoBooleanInput {...(inputGroup as BooleanInputGroupTierTwo)} />
			);
		case FieldType.EMISSIONS_T2:
			return <TierTwoEmissions {...(inputGroup as EmissionsTierTwo)} />;
		case FieldType.DYN_EMISSIONS_T2:
			return (
				<TierTwoDynEmissions {...(inputGroup as DynamicEmissionsTierTwo)} />
			);
		default:
			return <></>;
	}
};

export default GenericInputGroup;

GenericInputGroup.defaultProps = {
	showCommentIcon: true,
}