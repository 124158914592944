import { Formik, Form, Field } from "formik";
import CustomInput from "../input/CustomInput";

import { MdSearch } from "react-icons/md";

const Searchbar = ({ label, handleChange }: { label: string, handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void  }) => {
	return (
		<Formik initialValues={{ search: "" }} onSubmit={() => {}}>
			<Form>
				<Field
					label={`Search ${label}`}
					name="search"
					id="search"
					icon={MdSearch}
					component={CustomInput}
					onChange={handleChange}
				/>
			</Form>
		</Formik>
	);
};

export default Searchbar;
