import React, { useContext } from 'react'

interface SettlementContextType {
    buildingsIds: number[] | null
    setBuildingsIds: React.Dispatch<React.SetStateAction<number[] | null>>
    roadsId: number[] | null
    setRoadsIds: React.Dispatch<React.SetStateAction<number[] | null>>
    othersIds: number[] | null
    setOthersIds: React.Dispatch<React.SetStateAction<number[] | null>>

}

export const SettlementContext = React.createContext<SettlementContextType | null>(null)

export const useSettlementContext = () => {
    const context = useContext(SettlementContext);
    if (!context) {
      throw new Error('useSettlementContext must be used within a ForestProvider');
    }
    return context;
  };

export const SettlementProvider = ({ children }: { children: React.ReactNode }) => {
    const [buildingsIds, setBuildingsIds] = React.useState<number[] | null>(null)
    const [roadsId, setRoadsIds] = React.useState<number[] | null>(null)
    const [othersIds, setOthersIds] = React.useState<number[] | null>(null)
  return (
    <SettlementContext.Provider
      value={{
        buildingsIds,
        setBuildingsIds,
        roadsId,
        setRoadsIds,
        othersIds,
        setOthersIds
      }}
    >
      {children}
    </SettlementContext.Provider>
  )
}

