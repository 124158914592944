import TranslatableText from "../../../components/translations/TranslatableText";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = () => {
	return (
		<>
			<section className="d-flex f-column p-2">
				<h3
					className="fs-13 ff-black-ext pb-1 mb-1 w-fit"
					style={{ borderBottom: "8px solid var(--tertiary-bright)" }}
				>
					<TranslatableText translationKey="main.tool_name" />
				</h3>
			</section>
			<ResetPasswordForm />
		</>
	);
};

export default ResetPassword;
