import { useSettlementContext } from '../../../../contexts/SettlementProvider'
import { capitalize } from '../../../../utils/helperFunctions'
import ChangesChildAction from './ChangesChildAction'

const SettelementsChanges = () => {
  const context = useSettlementContext()
  return (
    <>
      {
        context?.buildingsIds && (
          context.buildingsIds.map((buildingId) => <SettlementChangeBlock key={buildingId} itemId={buildingId} settlementType='buildings' />)
        )
      }
      {
        context?.roadsId && (
          context.roadsId.map((roadId) => <SettlementChangeBlock key={roadId} itemId={roadId} settlementType='roads' />)
        )
      }
      {
        context?.othersIds && (
          context.othersIds.map((otherId) => <SettlementChangeBlock key={otherId} itemId={otherId} settlementType='other-infrastructures' />)
        )
      }
    </>

  )
}

const SettlementChangeBlock = ({ itemId, settlementType }: { itemId: number, settlementType: 'buildings' | 'roads' | 'other-infrastructures' }) => {
  return <ChangesChildAction itemId={itemId} path={settlementType} title={capitalize(settlementType) as string} />
}

export default SettelementsChanges
