import type { EmissionsTierTwo } from "../../../types/modulesInterfaces";
import { Field, useFormikContext, getIn } from "formik";
import { useEffect, useMemo, useState } from "react";
import { TierTwoIcon } from "../../../utils/customIcons";

import FormRow from "../formRow/FormRow";
import SubModuleInputGroup from "../SubModuleInputGroup";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import CustomInput from "../CustomInput";
import { useAppSelector } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";

interface Emissions {
	co2: number;
	n2o: number;
	co2e: number;
}

const TierTwoEmissions = ({
	inputName,
	label,
	links,
	co2Props,
	n2oProps,
	co2eProps,
	index,
}: EmissionsTierTwo) => {
	const { values, errors, touched } = useFormikContext();

	const [rowValues, setRowValues] = useState<Emissions | undefined>(undefined);
	const [defaultValues, setDefaultValues] = useState<Emissions | undefined>(undefined);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const [name, setName] = useState<string>("");
	const rowChanged = rowValues?.co2 || rowValues?.n2o || rowValues?.co2e;

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	useEffect(() => {
		setRowValues(getIn(values, name));
	}, [values, name]);

	useEffect(() => {
		setDefaultValues(getIn(values, `${name}_default`));
	}, [values, name]);

	const error = useMemo<string | undefined>(() => {
		return getIn(errors, name) && getIn(touched, name)
			? getIn(errors, name)
			: undefined;
	}, [errors, touched, name]);

	return (
		<TierTwoRowTooltip links={links} label={label}>
			<div
				className={`tiertwo-row ${links?.length ? "tiertwo-row-hover" : ""
					}`}
			>
				<TierTwoIcon fill={links?.length ? "active" : undefined} />
				<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
					{rowChanged ? <div className="has-changed-dot" /> : null}
					<span className="fs-12 text-start ff-medium">{label}</span>
					{/* <span className="fs-10 op-5">{unit}</span> */}
				</div>
				<SubModuleInputGroup
					name=""
					gridTemplateColumns="1fr 1fr 1fr"
					gap="8px"
				>
					<FormRow
						style={{ gridColumn: "1" }}
						isFetching={isFetchingData}
						error={error}
						topLabel={[
							`Default = ${defaultValues?.co2?.toString() ?? ""}`,
							co2Props.unit,
						]}
					>
						<Field
							name={`${name}.co2`}
							id={`${name}.co2`}
							component={CustomInput}
							label="CO2"
							disabled={co2Props.disabled}
							type="number"
							textend
							classes={rowValues?.co2 ? "tiertwo-input-changed" : ""}
						/>
					</FormRow>
					<FormRow
						style={{ gridColumn: "2" }}
						topLabel={[
							`Default = ${defaultValues?.n2o?.toString() ?? ""}`,
							n2oProps.unit,
						]}
						isFetching={isFetchingData}
					>
						<Field
							name={`${name}.n2o`}
							id={`${name}.n2o`}
							component={CustomInput}
							label="N2O"
							disabled={n2oProps.disabled}
							type="number"
							textend
							classes={rowValues?.n2o ? "tiertwo-input-changed" : ""}
						/>
					</FormRow>
					<FormRow
						style={{ gridColumn: "3" }}
						topLabel={[
							`Default = ${defaultValues?.co2e?.toString() ?? ""}`,
							co2eProps.unit,
						]}
						isFetching={isFetchingData}
					>
						<Field
							name={`${name}.co2e`}
							id={`${name}.co2e`}
							component={CustomInput}
							label="CO2-e"
							disabled={co2eProps.disabled}
							type="number"
							textend
							classes={rowValues?.co2e ? "tiertwo-input-changed" : ""}
						/>
					</FormRow>
				</SubModuleInputGroup>
			</div>
		</TierTwoRowTooltip>
	);
};

export default TierTwoEmissions;
