import Button from "../button/Button";
import { NoteIcon, CommentIcon, HelpIcon } from "../../utils/customIcons";
import { motion, AnimatePresence } from "framer-motion";
import { BiError } from "react-icons/bi";
import { Tooltip, TooltipTrigger, TooltipContent } from "../tooltip/Tooltip";
import { useAppDispatch, useAppSelector, useCustomTranslation } from "../../app/hooks";
import { selectCurrentBuilder, setActiveAction } from "../../app/features/builder/builderSlice";
import { Actions } from "../../types/interfaces";

export const NoteButton = ({ active, onClick }: { active?: boolean, onClick?: () => void }) => {
	const dispatch = useAppDispatch();
	const { activeAction } = useAppSelector(selectCurrentBuilder);


	const fill = active ? "fill" : "";
	const handleShowNote = () => {
		if (activeAction !== Actions.Notes) {			
			dispatch(setActiveAction(Actions.Notes))
		}
	};
	return (
		<Button onClick={handleShowNote}>
			<NoteIcon fill={fill} />
		</Button>
	);
};

export const CommentButton = ({ active }: { active?: boolean }) => {
	const fill = active ? "fill" : "";
	return (
		<Button>
			<CommentIcon fill={fill} />
		</Button>
	);
};

export const HelpButton = ({ type }: { type: "info" | "video" }) => {
	const fill = type === "video" ? "fill" : "";
	return (
		<Button>
			<HelpIcon fill={fill} />
		</Button>
	);
};

export const ErrorWithTooltip = ({
	showError,
	errorLabel,
	style
}: {
	showError: boolean;
	errorLabel: string | undefined;
	style?: React.CSSProperties
}) => {
	const translatedLabel = useCustomTranslation(errorLabel);
	return (
		<AnimatePresence>
			{showError ? (
				<Tooltip placement="right">
					<TooltipTrigger>
						<motion.div
							className="form-row-icon clickable"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							style={style}
						>
							<BiError size={24} color="var(--error)" />
						</motion.div>
					</TooltipTrigger>
					<TooltipContent className="error-tooltip unclickable">
						{translatedLabel ?? errorLabel}
					</TooltipContent>
				</Tooltip>
			) : null}
		</AnimatePresence>
	);
};
