import { ActivityUpdatePayload } from "../../app/features/activities/activityTypes";
import { getOptions } from "../../app/features/dropdownOptions/fetchOptions";
import { ClassicInputGroup, ClimateOption, DropdownInputGroup, FieldType, InputGroup, Option, Submodule } from "../../types/modulesInterfaces";

export const initValues: ActivityUpdatePayload = {
    id: 0,
    activity: {
      name: "",
      duration_t2: null,
      climate_t2: null,
      moisture_t2: null,
      soil_type_t2: null,
      start_year_t2: null,
      cost: null,
      change_rate: null,
    },
  };
  
  export enum EditActivityFields {
    name = "activity.name",
    duration_t2 = "activity.duration_t2",
    climate_t2 = "activity.climate_t2",
    moisture_t2 = "activity.moisture_t2",
    soil_type_t2 = "activity.soil_type_t2",
    start_year_t2 = "activity.start_year_t2",
    cost = "activity.cost",
    change_rate = "activity.change_rate",
    capitalization_years = "activity.capitalization_years",
  }
  
  export const activityTitle: ClassicInputGroup = {
    type: FieldType.CLASSIC,
    inputName: "activity.name",
    inputType: "text",
    label: "activity.activity_title",
  };
  export const duration: ClassicInputGroup = {
    type: FieldType.CLASSIC,
    inputName: EditActivityFields.duration_t2,
    inputType: "number",
    label: "activity.duration",
    unit: "[years]",
  };
  export const startYear: ClassicInputGroup = {
    type: FieldType.CLASSIC,
    inputName: EditActivityFields.start_year_t2,
    inputType: "number",
    label: "activity.start_year",
    unit: "[years]",
  };
  export const climate: DropdownInputGroup = {
    type: FieldType.SELECT,
    inputName: "activity.climate_t2",
    label: "activity.climate",
    dropdownOptions: () => getOptions("climate"),
  };
  export const moisture: DropdownInputGroup = {
    type: FieldType.SELECT,
    inputName: EditActivityFields.moisture_t2,
    label: "activity.moisture",
    dropdownOptions: [],
  };
  export const typeOfSoil: DropdownInputGroup = {
    type: FieldType.SELECT,
    inputName: "activity.soil_type_t2",
    label: "activity.soil_type",
    dropdownOptions: () => getOptions("soil"),
  };
  export const dynamicOfChanges: DropdownInputGroup = {
    type: FieldType.SELECT,
    inputName: "activity.change_rate",
    label: "activity.dynamic_of_changes",
    dropdownOptions: () => getOptions("changeRates"),
  };
  
  export const activityCost: ClassicInputGroup = {
    type: FieldType.CLASSIC,
    inputName: "activity.cost",
    inputType: "number",
    label: "activity.activity_cost",
  };
  export const capitalizationPhase: ClassicInputGroup = {
      type: FieldType.CLASSIC,
      inputName: EditActivityFields.capitalization_years,
      inputType: "number",
      label: "project.capitalization_years",
      textend: true,
      disabled: true,
  };
 export const editActivity: Submodule = {
    name: "activity.introductory_activity_description",
    inputGroups: [
      activityTitle,
      duration,
      dynamicOfChanges,
      climate,
      moisture,
      typeOfSoil,
      startYear,
      activityCost,
      capitalizationPhase
    ],
  };

  export function isMoistureInput (input:InputGroup): input is DropdownInputGroup  {
    return input.inputName === EditActivityFields.moisture_t2
  }
  export function isClimateInput (input:InputGroup): input is DropdownInputGroup  {
    return input.inputName === EditActivityFields.climate_t2
  }
  