import type { INumerableList } from './sideBarTypes';
import { BaseSideBarText, HighlightedCollapsibleSideBarText, HighlightedSideBarText } from './SideBarText';
import CollapsibleItem from './CollapsibleItem';

const ListItem = ({ item, prefix }: { item: INumerableList; prefix?: string | number }) => {
	// Helper function to render child items
	const renderChildren = (children: INumerableList[], collapsible: boolean) => (
		<div>
			{children.map((child, index) => (
				<ListItem
					key={child.name}
					item={child}
					// Only pass prefix for non-collapsible items to maintain numbering
					prefix={collapsible ? undefined : `${prefix}.${index + 1}`}
				/>
			))}
		</div>
	);

	// Function to determine what content to render based on item properties
	const renderContent = () => {
		// If there are no children, render BaseSideBarText
		if (!item.children) {
			return (
				<BaseSideBarText
					prefix={prefix}
					label={item.name}
					hasRedirect={item.hasRedirect}
					onClick={item.onClick}
				/>
			);
		}

		// If item is not collapsible, render HighlightedSideBarText and its children
		if (!item.isCollapsible) {
			return (
				<>
					<HighlightedSideBarText
						prefix={prefix}
						label={item.name}
						hasRedirect={item.hasRedirect}
						onClick={item.onClick}
					/>
					{renderChildren(item.children, false)}
				</>
			);
		}

		// If item is collapsible, render CollapsibleItem with its children
		return (
			<CollapsibleItem
				label={
					<HighlightedCollapsibleSideBarText
						prefix={prefix}
						label={item.name}
						style={{ flexDirection: 'column' }}
						hasRedirect={item.hasRedirect}
						onClick={item.onClick}
					/>
				}
			>
				{renderChildren(item.children, true)}
			</CollapsibleItem>
		);
	};

	// Render the content based on the determined logic
	return <div>{renderContent()}</div>;
};

export default ListItem