const GraphContainer = ({
	children,
	height,
	zIndex,
}: {
	children: JSX.Element;
	height: string | number;
	zIndex?: number;
}) => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				position: "relative",
				marginBottom: height,
				paddingBottom: 30,
				zIndex: zIndex ?? 1,
			}}
		>
			<div
				style={{
					width: "100%",
					height: "100%",
					position: "absolute",
					top: 0,
					left: 0,
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default GraphContainer;
