import type {
	Submodule,
	StartWithWithoutGeneric,
	InputGroup,
	// DropdownInputGroup,
	ModuleSchema,
	T2Schema,
	StartWithWithoutTierTwo,
} from "../../../../types/modulesInterfaces";
import type { AquaculturePayload, AquacultureT2Payload } from "./aquacultureTypes";
import { FieldType } from "../../../../types/modulesInterfaces";
// import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import * as Yup from "yup";

export const includedPropList = [
	"id",
	"activity",
	"annual_production_start",
	"annual_production_w",
	"annual_production_wo",
	"note",
	"fish_type",
	"status",
];
export const includedT2PropList = [
	"n2o_from_production_t2_start",
	"n2o_from_production_t2_w",
	"n2o_from_production_t2_wo",
	"electricity_used_t2_start",
	"electricity_used_t2_w",
	"electricity_used_t2_wo",
];

export const validationSchema = Yup.object({
	module: Yup.object().shape({
		// fish_type: Yup.number().nullable().required("Fish Category is required"),
		annual_production_start: Yup.number()
			.nullable()
			.required("validations.production_required"),
		annual_production_w: Yup.number()
			.nullable()
			.required("validations.production_required"),
		annual_production_wo: Yup.number()
			.nullable()
			.required("validations.production_required"),
	}),
});
export const initialValues: AquaculturePayload = {
	id: null,
	status: null,
	note: null,
	fish_type: null,
	annual_production_start: null,
	annual_production_w: null,
	annual_production_wo: null,
	activity: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
};

// const fish = () => getOptions("fish");

const getInitInputGroups: () => InputGroup[] = () => {
	/* const fishCategory: DropdownInputGroup = {
		type: FieldType.SELECT,
		inputName: "module.fish_type",
		label: "Fish Category",
		dropdownOptions: fish,
	}; */
	const production: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "module.annual_production",
		label: "module.production",
		unit: "[t/yr]",
	};
	return [production];
};

/* T2 */
export const t2InitialValues: AquacultureT2Payload = {
	n2o_from_production_t2_start: null,
	n2o_from_production_t2_w: null,
	n2o_from_production_t2_wo: null,
	electricity_used_t2_start: null,
	electricity_used_t2_w: null,
	electricity_used_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.n2o_from_production_t2",
			label: "module.n20_from_fish_production",
			unit: "[tN2O-N/t of Production]",
			links: ["Fish Category"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.electricity_used_t2",
			label: "module.electricity_used_for_fish_production",
			unit: "[kwh/t of Production]",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	return [
		{
			name: "",
			inputGroups: [...rows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: [],
		optionalSubmodules: [],
	};

	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
