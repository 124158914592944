import { TranslationKey } from "../../types/modulesInterfaces";

export interface INumerableList {
	name: string;
	children: INumerableList[] | null;
    isCollapsible: boolean;
    hasRedirect: boolean;
    onClick?: () => void
}
export abstract class NumerableList implements INumerableList {
	constructor(
        public name: string,
        public children: NumerableList [] | null, 
        public isCollapsible: boolean, 
        public hasRedirect: boolean,
        public onClick?: () => void
    ) {}
}

export class NavigationItem extends NumerableList {
    constructor(name: TranslationKey, onClick?: (params?: any) => void) {
        super(name, null, false, true, onClick);
    }
}

export class BaseNumerableList extends NumerableList {
    constructor(name: TranslationKey,children: NumerableList [] | undefined) {
        super(name, children !== undefined ? children : null, false, false);
    }
}

export class RedirectableList extends NumerableList {
    
    constructor(name: string,children: NumerableList [] | undefined, onClick?: () => void) {
        super(name, children !== undefined ? children : null, true, true, onClick);
    }
}

