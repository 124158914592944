import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import type {
	AquaculturePayload,
	AquacultureResponse,
	AquaclutureT2,
	AquacultureUpdatePayload,
	ModuleFull,
	AquacultureDefaults
} from "../../../../pages/activityBuilder/modules/aquaculture/aquacultureTypes";
import { apiSlice } from "../apiSlice";
import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/aquaculture/aquaculture";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAquacultures: builder.query<ModuleFull | null, number>({
			query: (activityId) => `aquacultures/?activity=${activityId}`,
			transformResponse: (response: AquacultureResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						{ ...response[0] },
						includedPropList,
					) as AquaculturePayload;

					const t2 = filterObjectProps(
						{ ...response[0] },
						includedT2PropList,
					) as AquaclutureT2;

					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "Aquaculture", id: activityId },
			],
		}),
		updateAquacultures: builder.mutation<
			AquacultureResponse,
			AquacultureUpdatePayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module };
				const tiertwo = { ...data.tiertwo };

				const payload = {
					...module,
					...tiertwo,
				} as unknown as AquacultureResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId };
				convertEmptyStringsToNull(body)
				return { url: `aquacultures/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { activityId }) => [
				{ type: "Aquaculture", id: activityId },
				{ type: "ModuleResults", id: results?.id ? `aquacultures-${results?.id}` : undefined },
				{ type: "ModuleHistory", id: results?.id ? `aquacultures-${results?.id}` : undefined },
				{ type: "AquacultureDefaults", id: results?.id ? results?.id : undefined },
				{ type: "ModulesStatuses", id: activityId }
			],
		}),
		getAquacultureDefaults: builder.query<AquacultureDefaults, number>({
			query: (uniqueId) => `aquacultures/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: "AquacultureDefaults", id: uniqueId }],
		})
	}),
});

export const { useGetAquaculturesQuery, useUpdateAquaculturesMutation, useGetAquacultureDefaultsQuery } =
	moduleApiSlice;
