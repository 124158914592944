import LoginImage from "./LoginImage";
import Button from "../../components/button/Button";
import { MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentToken } from "../../app/features/auth/authSlice";
import TranslatableText from "../../components/translations/TranslatableText";
import { TranslationKey } from "../../types/modulesInterfaces";

const NoMatch404 = ({ translationKey1 = "main.tool_name", translationKey2 = "main.not_found", translationKey3 = "main.not_found_action" }: { translationKey1?: TranslationKey, translationKey2?: TranslationKey, translationKey3?: TranslationKey }) => {
	const navigate = useNavigate();
	const accessToken: string | null = useAppSelector(selectCurrentToken);

	const handleClick = () => {
		navigate(accessToken ? "/" : "auth/login");
	};

	return (
		<div className="login-container">
			<LoginImage />

			<article
				className="bg-gray d-flex f-column p-2"
				style={{ minHeight: 170 }}
			>
				<h3
					className="fs-13 ff-black-ext pb-1 mb-1 w-fit"
					style={{ borderBottom: "8px solid var(--tertiary-bright)" }}
				>
					<TranslatableText translationKey={translationKey1} />
				</h3>

				<section className="w-100 h-100 d-flex f-column align-items-center justify-content-center">
					<h2 className="ff-black fs-13 pb-1">
						<TranslatableText translationKey={translationKey2} />
					</h2>
					<p className="fs-11">
						<TranslatableText translationKey={translationKey3} />
					</p>
					<div className="w-100 d-flex justify-content-end">
						<Button type="button" classes="btn-login " onClick={handleClick}>
							<TranslatableText className="bg-quat-de ff-medium px-1 label" translationKey="main.start_page" />
							<div className="bg-quat-br icon">
								<MdArrowForward size={20} color="var(--white)" />
							</div>
						</Button>
					</div>
				</section>
			</article>
		</div>
	);
};

export default NoMatch404;
