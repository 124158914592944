import {
	ReactElement,
	useState,
	createContext,
	useContext,
	useLayoutEffect,
	useRef,
	useEffect,
} from "react";
import {
	ErrorWithTooltip,
	CommentButton,
	HelpButton,
} from "../../components/tabsButtons/TabsButtons";
import { motion } from "framer-motion";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentBuilder } from "../../app/features/builder/builderSlice";
import { Actions } from "../../types/interfaces";

export const SubModuleInputGroupContext = createContext<{
	setIsGroupFocused: (value: boolean) => void;
}>({
	setIsGroupFocused: () => {},
});

const SubModuleInputGroup = ({
	name,
	children,
	gridTemplateColumns,
	gridTemplateRows,
	error,
	help,
	comment,
	gap,
}: {
	name: string;
	children: ReactElement[] | ReactElement;
	gridTemplateColumns?: string;
	gridTemplateRows?: string;
	comment?: { active: boolean; onClick: () => void };
	help?: { onClick: () => void; type: "info" | "video" };
	error?: string | undefined;
	gap?: string;
}) => {
	const labelRef = useRef<HTMLHeadingElement | null>(null);
	const [isLabelOverflown, setIsLabelOverflown] = useState<boolean>(false);

	useLayoutEffect(() => {
		if (labelRef.current)
			setIsLabelOverflown(labelRef.current.offsetHeight > 28);
	});

	//this is necessary just for the border styling..
	const [isGroupFocused, setIsGroupFocused] = useState<boolean>(false);
	const [showError, setShowError] = useState<boolean>(false);
	const [showComments, setShowComments] = useState<boolean>(false);
	const [showHelp, setShowHelp] = useState<boolean>(false);

	const { activeAction } = useAppSelector(selectCurrentBuilder);

	useEffect(
		() => setShowError(error?.length !== undefined && error.length > 0),
		[error, activeAction],
	);
	useEffect(
		() =>
			setShowComments(
				comment !== undefined &&
					comment?.active &&
					activeAction === Actions.Comments,
			),
		[comment, activeAction],
	);
	useEffect(() => setShowHelp(activeAction === Actions.Help), [help]);

	return (
		<SubModuleInputGroupContext.Provider value={{ setIsGroupFocused }}>
			<div className="d-flex f-column pos-relative">
				<h3
					ref={labelRef}
					className="fs-12 ff-bold lh-1 pb-2 pos-absolute"
					style={{
						top: isLabelOverflown ? "0" : "13px",
						left: "16px",
						maxWidth: "200px",
					}}
				>
					{name}
				</h3>
				<div
					className={`submodule-input-group mt-1 ${
						isGroupFocused && "submodule-input-group-focused"
					}`}
					style={{
						gridTemplateColumns: gridTemplateColumns ?? "1fr",
						gridTemplateRows: gridTemplateRows ?? "1fr",
						gap: gap ?? "0px",
					}}
				>
					{children}
				</div>

				<ErrorWithTooltip showError={showError} errorLabel={error ?? ""} style={{marginLeft: "35px"}} />
				{!showError ? (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="form-row-icon clickable"
					>
						{showComments ? (
							<CommentButton active={comment?.active} />
						) : showHelp && help ? (
							<HelpButton type={help.type} />
						) : (
							<></>
						)}
					</motion.div>
				) : (
					<></>
				)}
			</div>
		</SubModuleInputGroupContext.Provider>
	);
};

export default SubModuleInputGroup;
