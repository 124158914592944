import { useState, useEffect, FC } from "react";
import FormRow from "../formRow/FormRow";
import CustomToggle from "../CustomToggle";

import { Field, getIn, useFormikContext } from "formik";
import { BooleanInputGroupTierTwo } from "../../../types/modulesInterfaces";
import { useAppSelector } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import { TierTwoIcon } from "../../../utils/customIcons";
import TranslatableText from "../../translations/TranslatableText";

const TierTwoBooleanInput: FC<BooleanInputGroupTierTwo> = ({
	inputName,
	label,
	index,
	unit,
	disabled,
	comment,
	help,
	autosubmit,
	links,
}) => {
	const [name, setName] = useState<string>("");
	const { errors, touched, setFieldValue, values } = useFormikContext();
	const [rowValue, setRowValue] = useState<boolean>(false);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);

	useEffect(() => {
		setRowValue(getIn(values, name));
	}, [values, name]);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	return (
		<TierTwoRowTooltip links={links} label={label}>
			<div
				className={`tiertwo-row ${
					links && links.length ? "tiertwo-row-hover" : ""
				}`}
			>
				<TierTwoIcon fill={links && links.length ? "active" : undefined} />
				<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
					{rowValue ? <div className="has-changed-dot" /> : null}
					<TranslatableText className="fs-12" translationKey={label}/>
					<span className="fs-10 op-5">{unit}</span>
				</div>
				<FormRow
					rowComment={comment}
					rowHelp={help}
					isFetching={isFetchingData}
					error={
						getIn(errors, name) && getIn(touched, name)
							? getIn(errors, name)
							: undefined
					}
				>
					<Field
						label={``}
						name={name}
						id={name}
						component={CustomToggle}
						disabled={disabled}
						setFieldValue={setFieldValue}
						autosubmit={autosubmit}
					/>
				</FormRow>
			</div>
		</TierTwoRowTooltip>
	);
};

export default TierTwoBooleanInput;
