import type {
	HectaresInputGroup,
	InputGroup,
	StartWithWithoutGeneric,
	ModuleSchema,
	Submodule,
	StartWithWithoutTierTwo,
	BelongsToLuc,
} from "../../../../types/modulesInterfaces";
import type { FloodedRicePayload, FloodedRiceT2Payload } from "./floodedRiceTypes";
import * as Yup from "yup";
import { FieldType } from "../../../../types/modulesInterfaces";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BEModules } from "../../../../utils/moduleList";
import { createStandardLucValidation } from "../moduleUtils";

export const includedPropList = [
	"id",
	"area",
	"note",
	"cultivation_period_t2_start",
	"cultivation_period_t2_w",
	"cultivation_period_t2_wo",
	"water_management_type_before_cultivation_start",
	"water_management_type_before_cultivation_w",
	"water_management_type_before_cultivation_wo",
	"water_management_type_after_cultivation_start",
	"water_management_type_after_cultivation_w",
	"water_management_type_after_cultivation_wo",
	"organic_amendment_type_start",
	"organic_amendment_type_w",
	"organic_amendment_type_wo",
	"crop_yield_t2_start",
	"crop_yield_t2_w",
	"crop_yield_t2_wo",
	"land_use_change",

	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"efc_t2_start",
	"efc_t2_w",
	"efc_t2_wo",
	"sfw_t2_start",
	"sfw_t2_w",
	"sfw_t2_wo",
	"sfp_t2_start",
	"sfp_t2_w",
	"sfp_t2_wo",
	"sfo_t2_start",
	"sfo_t2_w",
	"sfo_t2_wo",
	"efi_t2_start",
	"efi_t2_w",
	"efi_t2_wo",
	"rice_straw_t2_start",
	"rice_straw_t2_w",
	"rice_straw_t2_wo",
	"biomass_t2_start",
	"biomass_t2_w",
	"biomass_t2_wo",
];
export const includedT2PropList = [
	"biomass_t2_start",
	"biomass_t2_w",
	"biomass_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo",
	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"main_biomass_factor_t2_start",
	"main_biomass_factor_t2_w",
	"main_biomass_factor_t2_wo",
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"land_use_factor_t2_start",
	"land_use_factor_t2_w",
	"land_use_factor_t2_wo",
	"efc_t2_start",
	"efc_t2_w",
	"efc_t2_wo",
	"sfw_t2_start",
	"sfw_t2_w",
	"sfw_t2_wo",
	"sfp_t2_start",
	"sfp_t2_w",
	"sfp_t2_wo",
	"sfo_t2_start",
	"sfo_t2_w",
	"sfo_t2_wo",
	"efi_t2_start",
	"efi_t2_w",
	"efi_t2_wo",
	"rice_straw_t2_start",
	"rice_straw_t2_w",
	"rice_straw_t2_wo",
];

export const validationSchema = (belongsToLuc?: BelongsToLuc | undefined) => {
	const standardValidation = createStandardLucValidation(belongsToLuc);

	const seasonValidation = Yup.object().shape({
		cultivation_period_t2_start: standardValidation('validations.cultivation_days_required', 'start', BEModules.FloodedRice).max(365, 'validations.days_less_than_365'),
		cultivation_period_t2_w: standardValidation('validations.cultivation_days_required', 'w', BEModules.FloodedRice).max(365, 'validations.days_less_than_365'),
		cultivation_period_t2_wo: standardValidation('validations.cultivation_days_required', 'wo', BEModules.FloodedRice).max(365, 'validations.days_less_than_365'),
		water_management_type_before_cultivation_start: standardValidation('validations.water_mgmt_before_cultivation_required', 'start', BEModules.FloodedRice),
		water_management_type_before_cultivation_w: standardValidation('validations.water_mgmt_before_cultivation_required', 'w', BEModules.FloodedRice),
		water_management_type_before_cultivation_wo: standardValidation('validations.water_mgmt_before_cultivation_required', 'wo', BEModules.FloodedRice),
		water_management_type_after_cultivation_start: standardValidation('validations.water_mgmt_after_cultivation_required', 'start', BEModules.FloodedRice),
		water_management_type_after_cultivation_w: standardValidation('validations.water_mgmt_after_cultivation_required', 'w', BEModules.FloodedRice),
		water_management_type_after_cultivation_wo: standardValidation('validations.water_mgmt_after_cultivation_required', 'wo', BEModules.FloodedRice),
		organic_amendment_type_start: standardValidation('validations.organic_amendment_required', 'start', BEModules.FloodedRice),
		organic_amendment_type_w: standardValidation('validations.organic_amendment_required', 'w', BEModules.FloodedRice),
		organic_amendment_type_wo: standardValidation('validations.organic_amendment_required', 'wo', BEModules.FloodedRice),
	});
	return Yup.object({
		seasons: Yup.array().of(seasonValidation),
		parent: seasonValidation
	});
}
export const initialValues: FloodedRicePayload = {
	id: null,
	land_use_change: null,
	activity: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	status: null,

	area: null,
	note: null,
	cultivation_period_t2_start: null,
	cultivation_period_t2_w: null,
	cultivation_period_t2_wo: null,
	water_management_type_before_cultivation_start: null,
	water_management_type_before_cultivation_w: null,
	water_management_type_before_cultivation_wo: null,
	water_management_type_after_cultivation_start: null,
	water_management_type_after_cultivation_w: null,
	water_management_type_after_cultivation_wo: null,
	organic_amendment_type_start: null,
	organic_amendment_type_w: null,
	organic_amendment_type_wo: null,
	crop_yield_t2_start: null,
	crop_yield_t2_w: null,
	crop_yield_t2_wo: null,

	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
	efc_t2_start: null,
	efc_t2_w: null,
	efc_t2_wo: null,
	sfw_t2_start: null,
	sfw_t2_w: null,
	sfw_t2_wo: null,
	sfp_t2_start: null,
	sfp_t2_w: null,
	sfp_t2_wo: null,
	sfo_t2_start: null,
	sfo_t2_w: null,
	sfo_t2_wo: null,
	efi_t2_start: null,
	efi_t2_w: null,
	efi_t2_wo: null,
	rice_straw_t2_start: null,
	rice_straw_t2_w: null,
	rice_straw_t2_wo: null,
};

const waterMngmTypesAC = () => getOptions("waterMngmTypesAC");
const waterMngmTypesBC = () => getOptions("waterMngmTypesBC");
const orgAmendmentTypes = () => getOptions("organicAmendmentTypes");

const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "parent.area",
		disabled: true,
	};
	return [hectaresInputGroup];
};

/* T2 */
export const t2InitialValues: FloodedRiceT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
	efc_t2_start: null,
	efc_t2_w: null,
	efc_t2_wo: null,
	sfw_t2_start: null,
	sfw_t2_w: null,
	sfw_t2_wo: null,
	sfp_t2_start: null,
	sfp_t2_w: null,
	sfp_t2_wo: null,
	sfo_t2_start: null,
	sfo_t2_w: null,
	sfo_t2_wo: null,
	efi_t2_start: null,
	efi_t2_w: null,
	efi_t2_wo: null,
	rice_straw_t2_start: null,
	rice_straw_t2_w: null,
	rice_straw_t2_wo: null,

	biomass_t2_start: null,
	biomass_t2_w: null,
	biomass_t2_wo: null,
	land_use_factor_t2_start: null,
	land_use_factor_t2_w: null,
	land_use_factor_t2_wo: null,
	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,
	main_biomass_factor_t2_start: null,
	main_biomass_factor_t2_w: null,
	main_biomass_factor_t2_wo: null,
};

const getParentT2: () => Submodule[] = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "parent.soc_t2",
			label: "module.soil_carbon",
			unit: "[tC/ha]",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "parent.flu_t2",
			label: "module.land_use_factor",
			unit: "[#]",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	const firstSeason: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.efc_t2',
			label: "module.efc",
			unit: "",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.sfw_t2',
			label: "module.sfw",
			unit: "",
			links: ["Water management during cultivation"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.sfp_t2',
			label: "module.sfp",
			unit: "",
			links: ["Water management before cultivation"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.sfo_t2',
			label: "module.sfo",
			unit: "",
			links: ["Organic amendment"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.efi_t2',
			label: "module.efi",
			unit: "",
			links: ["EFc", "SFw", "SFp", "SFo"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.rice_straw_t2',
			label: "module.rice_straw",
			unit: "[tdm/ha]",
			links: ["Organic amendment", "Yield"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: 'parent.biomass_t2',
			label: "module.biomass",
			unit: "[tC/ha]",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	]

	return [
		{
			name: "",
			inputGroups: [...rows],
		},
		{
			name: "module.first_season",
			inputGroups: [...firstSeason]
		}
	];
};

const getSeasonT2: () => Submodule = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `seasons.${index}.efc_t2`,
			label: "module.efc",
			unit: "",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `seasons.${index}.sfw_t2`,
			label: "module.sfw",
			unit: "",
			links: ["Water management during cultivation"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `seasons.${index}.sfp_t2`,
			label: "module.sfp",
			unit: "",
			links: ["Water management before cultivation"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `seasons.${index}.sfo_t2`,
			label: "module.sfo",
			unit: "",
			links: ["Organic amendment"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `seasons.${index}.efi_t2`,
			label: "module.efi",
			unit: "",
			links: ["EFc", "SFw", "SFp", "SFo"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `seasons.${index}.rice_straw_t2`,
			label: "module.rice_straw",
			unit: "[tdm/ha]",
			links: ["Organic amendment", "Yield"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	return {
		name: "module.season",
		inputGroups: [...rows],
	};
};

const getFirstSeasonSchema: () => Submodule = () => {
	const cultivationPeriod: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.cultivation_period_required",
		unit: "[days]",
		inputName: "parent.cultivation_period_t2",
	};
	const waterManagementBefore: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.water_mgmt_before_cultivation_required",
		inputName: "parent.water_management_type_before_cultivation",
		startProps: { dropdownOptions: waterMngmTypesBC },
		withProps: { dropdownOptions: waterMngmTypesBC },
		withoutProps: { dropdownOptions: waterMngmTypesBC },
	};
	const waterManagementAfter: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.water_mgmt_during_cultivation_required",
		inputName: "parent.water_management_type_after_cultivation",
		startProps: { dropdownOptions: waterMngmTypesAC },
		withProps: { dropdownOptions: waterMngmTypesAC },
		withoutProps: { dropdownOptions: waterMngmTypesAC },
	};
	const organicAmendment: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.organic_amendment_required",
		inputName: "parent.organic_amendment_type",
		startProps: { dropdownOptions: orgAmendmentTypes },
		withProps: { dropdownOptions: orgAmendmentTypes },
		withoutProps: { dropdownOptions: orgAmendmentTypes },
	};
	const seasonYield: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.yield",
		unit: "[tdm/ha/yr]",
		inputName: "parent.crop_yield_t2",
	};

	return {
		name: "module.first_season",
		inputGroups: [
			cultivationPeriod,
			waterManagementBefore,
			waterManagementAfter,
			organicAmendment,
			seasonYield,
		]
	}
}

const getSeasonSchema: () => Submodule = () => {
	const cultivationPeriod: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.cultivation_period_required",
		unit: "[days]",
		inputName: (index: number) => `seasons.${index}.cultivation_period_t2`,
	};
	const waterManagementBefore: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.water_mgmt_before_cultivation_required",
		inputName: (index: number) =>
			`seasons.${index}.water_management_type_before_cultivation`,
		startProps: { dropdownOptions: waterMngmTypesBC },
		withProps: { dropdownOptions: waterMngmTypesBC },
		withoutProps: { dropdownOptions: waterMngmTypesBC },
	};
	const waterManagementAfter: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.water_mgmt_during_cultivation_required",
		inputName: (index: number) =>
			`seasons.${index}.water_management_type_after_cultivation`,
		startProps: { dropdownOptions: waterMngmTypesAC },
		withProps: { dropdownOptions: waterMngmTypesAC },
		withoutProps: { dropdownOptions: waterMngmTypesAC },
	};
	const organicAmendment: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.organic_amendment_required",
		inputName: (index: number) => `seasons.${index}.organic_amendment_type`,
		startProps: { dropdownOptions: orgAmendmentTypes },
		withProps: { dropdownOptions: orgAmendmentTypes },
		withoutProps: { dropdownOptions: orgAmendmentTypes },
	};
	const seasonYield: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.yield",
		unit: "[tdm/ha/yr]",
		inputName: (index: number) => `seasons.${index}.crop_yield_t2`,
	};

	return {
		name: "module.season",
		inputGroups: [
			cultivationPeriod,
			waterManagementBefore,
			waterManagementAfter,
			organicAmendment,
			seasonYield,
		],
	};
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: [getFirstSeasonSchema()],
	};

	const seasonSchema = getSeasonSchema();
	const parentT2Schema = getParentT2();
	const seasonT2Schema = getSeasonT2();

	return { moduleSchema, seasonSchema, parentT2Schema, seasonT2Schema };
};
