import { ReactNode, useContext, useEffect, useState } from "react";
import { FormStates, SubmodulePlus } from "./setupAssistant.types";
import { differentPurpose, ifNotProject } from "./setupAssistantUtils";
import { BiChevronRight } from "react-icons/bi";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import Accordion, {
	AccordionContext,
} from "../../../../components/accordion/Accordion";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import check from "../../../../assets/icons/check.svg";
import { TranslationKey } from "../../../../types/modulesInterfaces";
import TranslatableText from "../../../../components/translations/TranslatableText";

const SetupAssistantStep = ({
	step,
	isComplete,
	index,
	children,
}: {
	step: SubmodulePlus;
	index: number;
	isComplete?: boolean;
	children?: ReactNode;
}) => {
	return (
		<>
			<Accordion
				initiallyOpen={!isComplete}
				header={
					<StepHeader
						label={step.name ?? ""}
						isComplete={isComplete}
						index={index}
					/>
				}
			>
				<div className="d-flex f-column">
					{step.step !== FormStates.areaWithoutProject ? (
						step.inputGroups.map((input, index) => (
							<div className="py-1" key={index}>
								<GenericInputGroup
									inputGroup={{
										...input,
										disabled: isComplete,
									}}
								/>
							</div>
						))
					) : (
						<AreaWithoutProjectStep isComplete={isComplete} />
					)}
				</div>
			</Accordion>
			{children ?? null}
		</>
	);
};

const StepHeader = ({
	label,
	isComplete,
	index,
}: {
	label: TranslationKey;
	index: number;
	isComplete?: boolean;
}) => {
	const { isOpen, toggleOpen } = useContext(AccordionContext);
	useEffect(() => {
		if (isComplete) toggleOpen(false);
	}, [isComplete, toggleOpen]);

	return (
		<div
			onClick={() => toggleOpen()}
			className={`submodule-header w-100 pos-relative ${
				isComplete ? "completed" : "mt-2"
			}`}
		>
			<div className="submodule-header-arrow">
				<motion.div
					animate={{
						rotate: isOpen ? 90 : 0,
					}}
					className="h-100 d-flex align-items-center justify-content-center"
				>
					<BiChevronRight size={24} color="rgba(28, 27, 31, 0.5)" />
				</motion.div>
			</div>

			<div className="d-flex align-items-center">
				{isComplete ? (
					<img
						src={check}
						alt="completed question"
						className="unclickable ps-1"
					/>
				) : null}
				<h2 className="ff-bold fs-13 px-1">Q{index + 1}:</h2>
				<h2 className="fs-13">
					<TranslatableText translationKey={label}/>
				</h2>
			</div>
		</div>
	);
};

interface AWP {
	areaWithoutProject?: number;
	withoutProjectLandUse?: number;
}
//ATTENTION! this is hard-coded to dinamically display the select input
const AreaWithoutProjectStep = ({ isComplete }: { isComplete?: boolean }) => {
	const [showSelect, setShowSelect] = useState(false);
	const { values } = useFormikContext<AWP>();

	useEffect(() => {
		setShowSelect(values?.areaWithoutProject === 1);
	}, [values]);

	return (
		<div className="d-flex f-column">
			<div className="py-1">
				<GenericInputGroup
					inputGroup={{
						...ifNotProject,
						disabled: isComplete,
					}}
				/>
				{showSelect ? (
					<GenericInputGroup
						inputGroup={{
							...differentPurpose,
							disabled: isComplete,
						}}
					/>
				) : null}
			</div>
		</div>
	);
};

export default SetupAssistantStep;
