import { motion } from "framer-motion";
import { useAppContext } from "../../context/AppProvider";

const variants = {
	enter: (direction: number) => {
		return {
			x: direction > 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => {
		return {
			zIndex: 0,
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
};


const LoginImage = () => {
	const bucketUrl = useAppContext();

	const imgProps = {
		src: `${bucketUrl}/login_bg.jpg`,
		alt: "Farmer Rahim Sah harvesting his cabbages after he was supported with FAO’s certified vegetable seed package"
	}
	return (
		<div className="login-image-container ov-hidden f-shrink-0">
			<motion.img
				{...imgProps}
				custom={variants}
				style={{ position: "absolute", maxWidth: "100vw", aspectRatio: "700 / 383", objectFit: "cover", objectPosition: "bottom" }}
				initial="enter"
				animate="center"
				exit="exit"
				transition={{
					x: { type: "spring", stiffness: 300, damping: 30 },
					opacity: { duration: 0.2 },
				}}
			/>
		</div>
	);
};

export default LoginImage;