import { useLoginMutation } from "../../../app/features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { MdPersonOutline } from "react-icons/md";
import { useAppDispatch } from "../../../app/hooks";
import { setCredentials } from "../../../app/features/auth/authSlice";
import type { LoginPayload } from "../../../app/features/auth/authTypes";

import LoginForm from "./LoginForm";
import Button from "../../../components/button/Button";
import useErrorMessage from "../../../utils/useErrorMessage";
import TranslatableText from "../../../components/translations/TranslatableText";

const Login = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [login, { isLoading, error, isError }] = useLoginMutation();
	const { errorMsg } = useErrorMessage({ isError, error });

	const onLogin = async (payload: LoginPayload) => {
		if (payload.email && payload.password) {
			const {
				refresh_token: refresh,
				access_token: access,
				user,
			} = await login(payload).unwrap();
			dispatch(
				setCredentials({
					accessToken: access,
					refreshToken: refresh,
					user: user,
				}),
			);
			navigate("/");
		}
	};

	return (
		<>
			<LoginDescription />
			<LoginForm onLogin={onLogin} errorMsg={errorMsg} isLoading={isLoading} />
		</>
	);
};

const LoginDescription = () => {
	const navigate = useNavigate();

	const onRegister = () => {
		navigate("../register");
	};
	return (
		<section className="d-flex f-column p-2">
			<h3
				className="fs-13 ff-black-ext pb-1 mb-1 w-fit"
				style={{ borderBottom: "8px solid var(--tertiary-bright)" }}
			>
				<TranslatableText translationKey="main.tool_name"/>
			</h3>
			<p className="pb-2 fs-11 pe-1">
				<TranslatableText translationKey="main.tool_description"/>
			</p>

			<Button
				classes="btn-register d-flex align-items-center"
				onClick={onRegister}
			>
				<div className="h-100 d-flex align-items-center bg-darker-gray p-1">
					<MdPersonOutline color="var(--dark-gray)" size={24} />
				</div>
				<TranslatableText className="h-100 fs-11 ff-medium text-dark d-flex align-items-center justify-content-center p-1" translationKey="main.register_for_exact_account"/>
			</Button>
		</section>
	);
};

export default Login;
