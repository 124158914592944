import type React from 'react'
import ModuleContext, { type ModuleContextType } from './pages/activityBuilder/ModuleContext'
import { useLocation, useParams } from 'react-router-dom'
import { useGetLandUseChangesQuery } from './app/features/api/modules/landUseChangeApiSlice'
import { useEffect, useMemo, useState } from 'react'
import type { BelongsToLuc } from './types/modulesInterfaces'
import { BEModules, getModuleFromUrl, isLandUseModule } from './utils/moduleList'
import { ForestProvider } from './contexts/ForestContext'
import { SettlementProvider } from './contexts/SettlementProvider'
import EnergyProvider from './contexts/EnergyProvider'
import IrrigationsProvider from './contexts/IrrigationsProvider'
import InputsProvider from './contexts/InputsProvider'

interface ProviderProps {
    children: React.ReactNode
}


const Provider: React.FC<ProviderProps> = (
    { children }
) => {
    const params = useParams();
    const location = useLocation()
    const [belongsToLuc, setBelongsToLuc] = useState<BelongsToLuc | undefined>(
        undefined,
    );
    const FEModule = useMemo(() => getModuleFromUrl(location.pathname), [location])
    const isLUCModule = useMemo(()=> isLandUseModule(FEModule?.beModuleId), [FEModule?.beModuleId])
    const { data: lucData } = useGetLandUseChangesQuery(params.activityId ? Number.parseInt(params.activityId) : 0, {
        refetchOnMountOrArgChange: true,
        skip: !params.activityId,
    });

    useEffect(() => {
        if (lucData && FEModule?.beModuleId !== BEModules.LandUseChange && isLUCModule) {
            setBelongsToLuc({
                is_start:
                    FEModule?.beModuleId === lucData.module.module_type_start,
                is_w: FEModule?.beModuleId === lucData.module.module_type_w,
                is_wo: FEModule?.beModuleId === lucData.module.module_type_wo,
            });
        }
        if (!lucData || !isLUCModule) setBelongsToLuc(undefined)
    }, [lucData, FEModule, params.activityId, isLUCModule]);

    const context: ModuleContextType = {
        belongsToLuc
    }

    return (
        <ModuleContext.Provider value={context}>
            {
                FEModule?.beModuleId === BEModules.ForestManagement ? (
                    <ForestProvider>
                        {children}
                    </ForestProvider>
                ) : FEModule?.beModuleId === BEModules.Settlements ? (
                    <SettlementProvider>
                        {children}
                    </SettlementProvider>
                ) : FEModule?.beModuleId === BEModules.Energy ? (
                    <EnergyProvider>
                        {children}
                    </EnergyProvider>
                ) : FEModule?.beModuleId === BEModules.Irrigation ? (
                    <IrrigationsProvider>
                        {children}
                    </IrrigationsProvider>
                ) : FEModule?.beModuleId === BEModules.Inputs ? (
                    <InputsProvider>
                        {children}
                    </InputsProvider>
                ) : children
            }
        </ModuleContext.Provider>
    )
}

export default Provider

