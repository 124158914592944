import { apiSlice } from "../api/apiSlice";
import type { Activity, Module } from "../../../types/interfaces";
import type {
	ActivityCreatePayload,
	ActivityDeletePayload,
	ActivityResponse,
	ActivityResultsResponse,
	ActivityUpdatePayload,
	FastActivityResponse,
	GetStatusesResponse,
	ModuleResponse,
} from "./activityTypes";
import type { BEModules } from "../../../utils/moduleList";
import { BEModulesObj } from "../../../utils/moduleList";
import { EmissionsTotal } from "../../../pages/project/results/resultTypes";

export const activitiesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getActivitiesByProject: builder.query<ActivityResponse, string>({
			query: (url) => url,
			transformResponse: (response: ActivityResponse) => {
				response.results.sort((a, b) => a.id > b.id ? -1 : 1)
				return response
			},
			providesTags: (res, error, arg) =>
				res
					? [
						{ type: "Activities", id: "LIST" },
						...res.results.map((a) => ({ type: "Activities" as const, id: a.id })),
					]
					: [{ type: "Activities", id: "LIST" }],
		}),
		getFastActivitiesByProject: builder.query<FastActivityResponse, { url: string, projectId: number }>({
			query: ({ url, projectId }) => url ? `${url}&summary=true` : `activities/?project_id=${projectId}&summary=true`,
			providesTags: (_res, _error, { projectId }) => [{ type: "FastActivities", id: projectId }],
		}),
		getModulesByActivityId: builder.query<Module[], number>({
			query: (id) => `activities/${id}/modules/?page_size=100`,
			transformResponse: (response: ModuleResponse) => response.results,
			providesTags: (_res, _error, id) => [{ type: "Modules", id }]
		}),
		getActivitiesById: builder.query<Activity, number>({
			query: (id) => `activities/${id}/`,
			providesTags: (res, error, id) => [{ type: "Activities", id }],
		}),
		updateActivity: builder.mutation<Activity, ActivityUpdatePayload>({
			query: ({ id, activity }) => ({
				url: `activities/${id}/`,
				method: "PATCH",
				body: activity,
			}),
			// Optimistic update
			async onQueryStarted({ id, activity }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					activitiesApiSlice.util.updateQueryData('getActivitiesById', id, (draft) => {
						Object.assign(draft, activity)
					})
				)
				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
			invalidatesTags: (result, _error, { id }) => [
				{ type: "Activities", id },
				{ type: "FastActivities", id: result?.project?.id },
				{ type: "Modules", id },
				{ type: "Activities", id: "LIST" },
			],
		}),
		createActivity: builder.mutation<Activity, ActivityCreatePayload>({
			query: (activity) => ({
				url: "activities/build/",
				method: "POST",
				body: activity,
			}),
			invalidatesTags: (result, _error, arg) => [
				{ type: "Activities", id: "LIST" },
				...(arg.activity_id ? [{ type: "Activities", id: arg.activity_id }] : []),
				{ type: "FastActivities", id: result?.project?.id },
			],
		}),
		deleteActivity: builder.mutation<Activity, ActivityDeletePayload>({
			query: ({ id }) => ({ url: `activities/${id}/`, method: "DELETE" }),
			invalidatesTags: (result, error, arg) => [
				{ type: "Activities", id: arg.id },
				{ type: "Activities", id: "LIST" },
				{ type: "FastActivities", id: result?.project?.id },
			],
		}),
		createActivityCopy: builder.mutation<Activity, number>({
			query: (id) => ({ url: `activities/${id}/copy/`, method: "POST" }),
			invalidatesTags: (result, _error, id) => [
				{ type: "Activities", id },
				{ type: "FastActivities", id: result?.project?.id },
			],
		}),
		getModulesStatues: builder.query<GetStatusesResponse[], number>({
			query: (activityId) => `activities/${activityId}/modules/`,
			transformResponse: (response: ModuleResponse) => response.results?.map((mod) => {
				const beModuleId = mod.module_type.id as BEModules;
				const feModuleId = BEModulesObj[beModuleId];
				return { id: mod.id, status: mod.status, feModuleId }
			}),
			providesTags: (_res, _error, activityId) => [{ type: "ModulesStatuses", id: activityId }]
		}),
		getActivityResults: builder.query<EmissionsTotal, number>({
			query: (id) => `activities/${id}/results/`,
			providesTags: (_res, _error, id) => [{ type: "ActivityResults", id }],
			transformResponse: (response: ActivityResultsResponse) => {
				return response.modules.reduce((acc, curr) => {
					if (!curr.results || 'details' in curr.results) return acc;
					if ('total_w' in curr.results) {
						acc.total_w += curr.results.total_w ?? 0;
						acc.total_wo += curr.results.total_wo ?? 0;
						acc.balance += curr.results.balance ?? 0;
					}
					return acc;
				}, { total_w: 0, total_wo: 0, balance: 0 });
			}
		})
	}),
});

export const {
	useGetActivitiesByProjectQuery,
	useUpdateActivityMutation,
	useCreateActivityMutation,
	useDeleteActivityMutation,
	useGetActivitiesByIdQuery,
	useCreateActivityCopyMutation,
	useGetModulesByActivityIdQuery,
	useLazyGetModulesByActivityIdQuery,
	useGetModulesStatuesQuery,
	useGetActivityResultsQuery,
	useGetFastActivitiesByProjectQuery,
	useLazyGetFastActivitiesByProjectQuery,
	endpoints,
} = activitiesApiSlice;
