import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import HeaderActions from "./HeaderActions";
import faoLogo from "../../assets/images/FAO_Logo.svg";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentToken } from "../../app/features/auth/authSlice";
import { selectCurrentProject } from "../../app/features/project/projectSlice";

const Header = () => {
	const { pathname } = useLocation();
	const [isHeaderSmall, setIsHeaderSmall] = useState(true);
	useEffect(() => {
		setIsHeaderSmall(!!(pathname === "/" || pathname.startsWith("/auth")));
	}, [pathname]);


	return (
		<div
			className={`${isHeaderSmall && "d-flex justify-content-center"} w-100`}
		>
			<header className={`main-header ${isHeaderSmall && "header-login"}`}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div
						className="d-flex align-items-start"
						style={{
							borderRight: '1px solid',
							borderImage: 'linear-gradient(to bottom, transparent, var(--primary-dark), transparent) 1',
							paddingRight: '0.6rem',
						}}>
						<FaoLogo />
					</div>
					<div
						className="d-flex f-column justify-content-center"
						style={{
							padding: '1.2rem',
							color: 'var(--primary-dark)',
						}}>
						<span className="montserrat-medium fs-13">EX-ACT</span>
						<span className="montserrat-normal fs-11">Environmental eXternalities ACcounting Tool</span>
					</div>
				</div>
				<HeaderActions isHeaderSmall={isHeaderSmall} />
			</header>
		</div>
	);
};

export default Header;


const FaoLogo = () => {
	const accessToken: string | null = useAppSelector(selectCurrentToken);
	const { project } = useAppSelector(selectCurrentProject);
	const navigate = useNavigate();

	const handleLogoClick = () => {
		if (accessToken && project) {
			navigate(`/project/${project?.id}/activities`)
		} else if (accessToken)
			navigate("/");
		else {
			navigate("/auth/login");
		}
	};
	return (
		<img src={faoLogo} alt="Fao logo" className="logo-fao" onClick={handleLogoClick} style={{ cursor: "pointer" }} />
	)
}

