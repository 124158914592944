import { ReactNode, useCallback, useState } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import CommentTextEditor from "./CommentTextEditor";
import { ClickableIcon } from "./CommentsAction";

interface ContentTextEditorContainerProps {
	isCommentActive: boolean;
	cancelCallback: () => void;
	okCallback: ({ content }: { content: string }) => void;
}
const CommentTextEditorContainer: React.FC<ContentTextEditorContainerProps> = ({
	isCommentActive,
	cancelCallback,
	okCallback,
}) => {
	const [comment, setComment] = useState<string>("");

	const handleCommentChange = useCallback(
		(comment: string) => {
			setComment(comment);
		},
		[setComment],
	);

	return (
		<>
			{isCommentActive ? (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "space-center",
							alignSelf: "flex-end",
						}}
					>
						<ClickableIcon clickHandler={() => cancelCallback()}>
							<BiXCircle size={20} color="" />
						</ClickableIcon>
						<ClickableIcon
							clickHandler={() => okCallback({ content: comment })}
						>
							<BiCheckCircle size={20} color="" />
						</ClickableIcon>
					</div>
					<CommentTextEditor note={comment} onComment={handleCommentChange} />
				</>
			) : null}
		</>
	);
};

export default CommentTextEditorContainer;
