import {
	fetchBaseQuery,
	createApi
} from "@reduxjs/toolkit/query/react";
import { setCredentials, logout } from "../auth/authSlice";
import type {
	BaseQueryApi,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import { persistor, type RootState } from "../../store";
import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import i18n from 'i18next'; // Import i18n to access the current language

const baseQuery = fetchBaseQuery({
	baseUrl: "https://fao-exact.ew.r.appspot.com/api/",
	//credentials: 'include', //to send cookie, not useful if we don't keep refresh token there
	prepareHeaders: (headers, { getState }) => {
		const state: RootState = getState() as RootState;
		const token = state.auth.accessToken;
		if (token) headers.set("authorization", `Bearer ${token}`);
		headers.set("Content-Type", "application/json");
		headers.set("Accept-Language", i18n.language); // Add Accept-Language header
		return headers;
	},
});

const baseQueryWithReauth = async (
	args: string | FetchArgs,
	api: BaseQueryApi,
	// biome-ignore lint/complexity/noBannedTypes: <explanation>
	extraOptions: {},
) => {
	let result = await baseQuery(args, api, extraOptions);
	const state = api.getState() as RootState;

	if (result?.error?.status === 401 || result?.error?.status === 403) {
		const refreshToken = state.auth.refreshToken;
		const query = {
			url: "/accounts/token/refresh/",
			method: "POST",
			body: { refresh: refreshToken },
		};
		const refreshResult: QueryReturnValue<
			{ access_token: string, refresh_token: string, uid: string },
			FetchBaseQueryError,
			FetchBaseQueryMeta
		> = (await baseQuery(query, api, extraOptions)) as QueryReturnValue<
			{ access_token: string, refresh_token: string, uid: string },
			FetchBaseQueryError,
			FetchBaseQueryMeta
		>;
		if (refreshResult?.data) {
			const user = state.auth.user;
			//store the new token
			api.dispatch(
				setCredentials({
					accessToken: refreshResult.data?.access_token,
					refreshToken: refreshResult.data?.refresh_token,
				}),
			);
			//retry the original query
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logout());
			persistor.purge();
		}
	}
	return result;
};

const tagTypes = ['Activities', 'ActivityResults', 'AnnualCropland', 'AnnualCroplandDefaults', 'LandUseChange', 'Grassland', "GrasslandDefaults", 'SmallFisheries', 'SmallFisheriesDefaults', 'LargeFisheries', 'LargeFisheriesDefaults', 'Projects', 'FundingAgencies', 'ExecutingAgencies', 'PerennialCropland', 'Livestock', 'FloodedRice', 'FloodedRiceDefaults', 'FloodedRiceSeasonDefaults', 'Aquaculture', 'AquacultureDefaults', 'InputEntries', 'InputEntriesDefaults', 'ModuleResults', 'ModuleHistory', 'FloodedSeasons', 'PerennialSeasons', 'Invitations', 'ProjectUsers', 'ForestManagement', 'ForestManagementDefaults', 'ForestDisturbance', 'ForestDisturbanceDefaults', 'SetAside', 'SetAsideDefaults', 'ModuleNotes', 'ModuleComments', 'OtherLand', 'OtherLandDefaults', 'Waterbodies', 'WaterbodiesDefaults', 'Electricities', 'ElectricitiesDefaults', 'Fuels', 'FuelsDefaults', 'Settlements', 'SettlementsDefaults', 'Roads', 'RoadsDefaults', 'CoastalWetlands', 'CoastalWetlandsDefaults', 'Buildings', 'BuildingsDefaults', 'OrganicSoil', 'OrganicSoilDefaults', 'IrrigationSystems', 'IrrigationSystemsDefaults', 'IrrigationPhases', 'IrrigationPhasesDefaults', 'Modules', "Others", 'OthersDefaults', "Energies", "Inputs", "Irrigations", 'ModulesStatuses', 'PerennialCroplandDefaults', 'LivestockDefaults', 'FastActivities']
export type TagTypes = (typeof tagTypes)[number];

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({}),
	tagTypes
	/* ,
 extractRehydrationInfo(action, { reducerPath }) {
  if (action.type === REHYDRATE) {
   return action.payload[reducerPath]
  }
 }, */,
});
