import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector, usePermissions } from "../../app/hooks";
import { selectCurrentUser } from "../../app/features/auth/authSlice";
import Button from "../button/Button";

import { MdTranslate, MdGroup } from "react-icons/md";
import {
	BiCaretDown,
	BiChevronDown,
	BiShareAlt,
	BiUserCircle,
} from "react-icons/bi";
import {
	openUserModal,
	openManageUsersModal,
} from "../../app/features/header/headerSlice";
import {
	selectCurrentProject,
} from "../../app/features/project/projectSlice";
import {
	useGetProjectsQuery,
} from "../../app/features/project/projectApiSlice";
import { useNavigate } from "react-router-dom";
import type { User } from "../../types/interfaces";
import OutsideClickSub from "../outsideClickSub/OutsideClickSub";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../../types/modulesInterfaces";
import BaseLabel from "../label/BaseLabel";
import TranslatableText from "../translations/TranslatableText";
import { FastProjectsResponse } from "../../app/features/project/projectTypes";
// import { IconType } from "react-icons";

interface HeaderAction {
	id: string;
	value: TranslationKey | null;
	icon: ReactNode;
	disabled?: boolean;
	hasDrop?: boolean;
	isHighlighted?: boolean;
	lines?: Line[];
	onClick?: () => void;
	rowClick?: (line: Line) => void;
}

const HeaderActions = ({ isHeaderSmall }: { isHeaderSmall: boolean }) => {
	// const { width } = useWindowDimetions();
	const dispatch = useAppDispatch();
	const user: User | null = useAppSelector(selectCurrentUser);
	const { canShare } = usePermissions();
	const { project } = useAppSelector(selectCurrentProject);
	const isProjectSelected = useMemo(() => !!project?.id, [project]);

	interface HeaderAction {
		id: string;
		value: TranslationKey | null;
		icon: ReactNode;
		hasDrop?: boolean;
		onClick?: () => void;
	}

	const actions: HeaderAction[] = useMemo(() => {
		const userBtn: HeaderAction = {
			id: "user",
			value: null,
			icon: <BiUserCircle size={20} />,
			hasDrop: true,
			onClick: () => dispatch(openUserModal()),
		};

		const manageUsersBtn: HeaderAction = {
			id: "manageUsers",
			value: "main.users_management",
			icon: <MdGroup size={20} className="me-1" />,
			hasDrop: true,
			onClick: () => dispatch(openManageUsersModal()),
		};
		return user && canShare && isProjectSelected
			? [manageUsersBtn, userBtn]
			: user
				? [userBtn]
				: [];
	}, [user, canShare, isProjectSelected, dispatch]);

	return (
		<div className="h-100 d-flex">
			{isHeaderSmall ? null : <SwitchProjectButton />}
			{[...actions].map((action) => {
				return (
					<div
						key={action.id}
						className="h-100 d-flex align-items-center"
						style={{ paddingRight: "9px" }}
					>
						<HeaderButton {...action} />
					</div>
				);
			})}
			<SwitchLanguageButton />
		</div>
	);
};

export const HeaderButton = ({
	id,
	value,
	icon,
	hasDrop,
	isHighlighted,
	disabled,
	onClick,
	lines,
}: HeaderAction) => {
	const [isModalShown, setIsModalShown] = useState(false);
	const showModal = () => setIsModalShown(true);

	return (
		<div className="pos-relative">
			<Button
				id={id}
				onClick={onClick ? onClick : showModal}
				classes={`btn-header ${isHighlighted && "highlighted"} br-6`}
				disabled={disabled}
			>
				{icon}
				{value && <TranslatableText translationKey={value} />}
				{hasDrop ? <BiCaretDown size={16} className="ms-1" /> : null}
			</Button>
			{isModalShown ? (
				<Modal
					handleClose={() => setIsModalShown(false)}
					lines={lines ? lines : []}
				/>
			) : null}
		</div>
	);
};

const SwitchProjectButton = () => {
	const [isModalShown, setIsModalShown] = useState(false);
	const showModal = () => setIsModalShown(true);

	const { project } = useAppSelector(selectCurrentProject);
	const { data } = useGetProjectsQuery({ fast: true }, {
		refetchOnMountOrArgChange: true,
	});
	const projectsList = data as FastProjectsResponse;

	const navigate = useNavigate();

	const projectLines: Line[] =
		projectsList
			? [
				...projectsList.results.map((project) => {
					return {
						label: project.name,
						id: project.id,
					};
				}),
				{ label: "main.go_to_project_list", id: -1 },
			]
			: [];

	const handleProjectClick = (line: Line) => {
		if (line.id === -1) return navigate("/");

		const selectedProject = projectsList?.results?.find((proj) => proj.id === line.id);
		if (selectedProject && selectedProject.id !== project?.id)
			navigate(`project/${selectedProject.id}/activities`);
	};

	return (
		<div className="h-100 d-flex align-items-center pe-1">
			<div className="pos-relative">
				<Button classes="btn-switch-project" onClick={showModal}>
					<TranslatableText className="no-wrap" translationKey={"main.switch_projects"} />
					<BiChevronDown size={20} className="ms-1" />
				</Button>
				{isModalShown ? (
					<Modal
						handleClose={() => setIsModalShown(false)}
						lines={projectLines}
						onLineClick={handleProjectClick}
					/>
				) : null}
			</div>
		</div>
	);
};


const SwitchLanguageButton = () => {
	const [isModalShown, setIsModalShown] = useState(false);
	const showModal = () => setIsModalShown(true);

	const projectLines: Line[] = [
		{ label: "English", id: 1 },
		{ label: "Español", id: 2 },
	]
	const { i18n } = useTranslation();
	const changeLanguage = (line: Line) => {
		switch (line.id) {
			case 1:
				i18n.changeLanguage("en");
				break;
			case 2:
				i18n.changeLanguage("es");
				break;
			default:
				break;
		}
	};

	return (
		<div className="h-100 d-flex align-items-center pe-1">
			<div className="pos-relative">
				<Button classes="btn-switch-project" onClick={showModal}>
					<MdTranslate size={20} className="me-1" />
					<span className="no-wrap">{i18n.language}</span>
					<BiChevronDown size={20} className="ms-1" />
				</Button>
				{isModalShown ? (
					<Modal
						handleClose={() => setIsModalShown(false)}
						lines={projectLines}
						onLineClick={changeLanguage}
					/>
				) : null}
			</div>
		</div>
	);
};

const Modal = ({
	lines,
	handleClose,
	onLineClick,
}: {
	lines: Line[];
	handleClose: () => void;
	activeColor?: string;
	onLineClick?: (line: Line) => void;
}) => {
	return (
		<OutsideClickSub className="drop-menu" callback={handleClose}>
			<>
				{lines.map((line) => (
					<ModalLine
						line={line}
						handleClose={handleClose}
						key={line.id}
						onLineClick={onLineClick ? onLineClick : () => { }}
					/>
				))}
			</>
		</OutsideClickSub>
	);
};

export interface Line {
	id: number;
	label: string;
}

const ModalLine = ({
	line,
	handleClose,
	activeColor,
	onLineClick,
}: {
	line: Line;
	handleClose: () => void;
	onLineClick: (line: Line) => void;
	activeColor?: string;
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const { project } = useAppSelector(selectCurrentProject);
	const isCurrentProject = useMemo(() => project?.id === line.id, [project, line]);

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			className={"clickable drop-menu-line px-2"}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{
				backgroundColor: isHovered
					? activeColor
						? activeColor
						: "var(--gray)"
					: isCurrentProject
						? "var(--primary-bright)"
						: "white",
			}}
			onClick={() => {
				handleClose();
				onLineClick(line);
			}}
		>
			<BaseLabel
				className={`fs-11 ff-medium-cond ${isCurrentProject && !isHovered ? "text-white" : ""
					}`}
				label={line.label}
			/>
		</div>
	);
};

export default HeaderActions;
