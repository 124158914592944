import type { unstable_Blocker as Blocker } from "react-router-dom";
import { motion } from "framer-motion";
import Backdrop from "../modal/Backdrop";
import ModuleButton from "../button/ModuleButton";
import { TranslationKey } from "../../types/modulesInterfaces";
import TranslatableText from "../translations/TranslatableText";

const dropIn = {
	hidden: {
		y: "-100vh",
		opacity: 0,
	},
	visible: {
		y: "0",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: "-100vh",
		opcaity: 0,
	},
};

const ConfirmNavigation = ({
	blocker,
	title,
	description,
	blockerText,
	proceedText,
	handleFirstOptionClick
}: {
	blocker: Blocker;
	handleFirstOptionClick?: () => void;
	title?: string;
	description?: string;
	blockerText?: TranslationKey;
	proceedText?: TranslationKey;
}) => {
	if (!blocker || blocker.state !== "blocked") return null;
	return (
		<Backdrop onClick={() => { }}>
			<motion.div
				onClick={(e) => e.stopPropagation()}
				className="modal confirm-nav"
				variants={dropIn}
				initial="hidden"
				animate="visible"
				exit="exit"
				style={{ maxWidth: 500 }}
			>
				<header className="w-100">
					<h2 className="fs-18">
						{title ? title : <TranslatableText translationKey="main.leave_alert" />}
					</h2>
				</header>
				<article className="w-100 h-100 ov-y-scroll p-2">
					{description ? (
						<span>{description}</span>
					) : (
						<>
							<TranslatableText translationKey="main.leave_confirmation" />
							<TranslatableText translationKey="main.leave_confirmation_2" />
						</>
					)}
				</article>
				<footer className="d-flex justify-content-evenly w-100 p-2 bg-light-gray">
					<ModuleButton handleClick={handleFirstOptionClick ? handleFirstOptionClick : () => blocker.reset?.()} labelKey={blockerText ? blockerText : "main.stay"}/>
					<ModuleButton handleClick={() => blocker.proceed?.()} labelKey={proceedText ? proceedText : "main.leave_anyway"} outlined/>
				</footer>
			</motion.div>
		</Backdrop>
	);
};

export default ConfirmNavigation;
