import { BiRefresh } from "react-icons/bi";
import { useCustomTranslation, useDebounce, useHasFormErrors } from "../../../app/hooks";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";
import TranslatableText from "../../../components/translations/TranslatableText";

const LoadDefaultsButton = ({ disabled }: { disabled?: boolean }) => {
  const { isSubmitting, errors, touched } = useFormikContext()
  const debouncedErrors = useDebounce(errors, 500);
  const debouncedTouched = useDebounce(touched, 500);
  const hasFormErrors = useHasFormErrors({
    errors: debouncedErrors,
    touched: debouncedTouched,
  });
  const translatedLabel = useCustomTranslation("module.load_t2_defaults");

  //const disabled = errors && Object.keys(errors).length > 0;
  return (
    <>
      <Button
        type="submit"
        disabled={disabled || isSubmitting}
        classes={`btn-save w-fit ${disabled ? "op-8" : ""}`}
      >
        <div className="bg-tert-de save-icon">
          {isSubmitting ? (
            <Spinner size={36} padding="0.5rem" />
          ) : (
            <BiRefresh size={20} color="var(--dark)" />
          )}
        </div>
        <span
          className="label ff-medium"
          style={{
            backgroundColor: "var(--tertiary-bright)",
            color: "var(--dark)",
          }}>
          {translatedLabel}
        </span>
      </Button>

      <motion.p
        animate={{ paddingBottom: hasFormErrors ? "0.5rem" : 0, paddingTop: hasFormErrors ? "0.5rem" : 0 }}
        className={`text-error fs-13`}
      >
        {hasFormErrors
          ? <TranslatableText translationKey="main.form_error" />
          : ""}
      </motion.p>
    </>
  );
};

export default LoadDefaultsButton;