import type {
	TopbarStep,
} from "../../types/interfaces";
import { RiArrowRightFill } from "react-icons/ri";
import { BiCheck, BiRightArrow } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { FEModules, activityCompleteInfo } from "../../utils/moduleList";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import { useGetModulesStatuesQuery } from "../../app/features/activities/activityApiSlice";
import { useMemo } from "react";
import Button from "../button/Button";
import BaseLabel from "../label/BaseLabel";
import Skeleton from "react-loading-skeleton";

interface TopbarProps {
	projectLabel?: string;
	activityLabel?: string;
	stepsList: TopbarStep[];
	isLoading?: boolean;
}

const BuilderTopbar = (props: TopbarProps) => {
	return (
		<div className="builder-top">
			<TopbarSteps {...props} />
		</div>
	);
};

const TopbarSteps = ({
	projectLabel,
	activityLabel,
	stepsList,
	isLoading,
}: TopbarProps) => {
	const pathList = useLocation().pathname.split("/");
	const isInBuilder = pathList[1] === "builder";
	const finalPath = pathList?.pop();

	const { activeActivityId, project } = useAppSelector(selectCurrentProject);
	const { data: moduleStatuses } = useGetModulesStatuesQuery(
		activeActivityId ?? 0,
		{ skip: !activeActivityId },
	);

	const areAllModulesReady = useMemo(() =>
		moduleStatuses?.filter((status) => status.feModuleId !== FEModules.ActivityComplete).every((status) => status.status.name_en === "READY"),
		[moduleStatuses]
	);
	const areSomeModulesReady = useMemo(() =>
		moduleStatuses?.filter((status) => status.feModuleId !== FEModules.ActivityComplete).some((status) => status.status.name_en === "READY"),
		[moduleStatuses]
	);

	return (
		<div className="steps-container fs-13">
			<div className="steps-labels">
				{activityLabel && (
					<Link to={`/project/${project?.id ?? "new"}/activities`} className="no-decoration">
						{isLoading ?
							<Skeleton width={150} height={20} />
							:
							<Button whileHoverProps={{ backgroundColor: "var(--gray)" }} classes="me-1 p-1 ff-normal-ext fs-12 lh-90 br-8">
								{activityLabel}
							</Button>
						}
					</Link>
				)}
				{projectLabel && (
					<>
						{isLoading ?
							<Skeleton width={120} height={20} className="ms-2" /> :
							<div className="d-flex">
								<span className="fs-13 ff-bold-ext project-label">{projectLabel}</span>
							</div>
						}
						{stepsList.length > 0 && <div className="mx-1"><BiRightArrow size={24} /></div>}
					</>
				)}
			</div>
			{(isInBuilder ? [...stepsList, activityCompleteInfo] : stepsList).map((step, index) => {
				const hasNext = isInBuilder ? index < stepsList.length : index < stepsList.length - 1;
				const isActive = finalPath === step.path;
				const statusName = step.id === FEModules.ActivityComplete
					? (areAllModulesReady ? "READY" : areSomeModulesReady ? "IN PROGRESS" : "EMPTY")
					: moduleStatuses?.find((status) => status.feModuleId === step.id)?.status.name_en;

				return (
					<Link to={`../${step.path}`} relative="path" className="no-decoration" key={step.id}>
						<div className="d-flex h-100 align-items-center">
							<TopbarStepIcon stepId={step.id} isActive={isActive} index={index} statusName={statusName} />
							<BaseLabel label={step.name} className={`capitalize step-label ${isActive ? "ff-medium-ext" : "ff-normal-ext"}`} />
							{hasNext && <div className="ms-1"><RiArrowRightFill size={20} /></div>}
						</div>
					</Link>
				);
			})}
		</div>
	);
};

const TopbarStepIcon = ({
	stepId,
	isActive,
	index,
	statusName,
}: {
	stepId: number;
	isActive: boolean;
	index: number;
	statusName?: string;
}) => {
	const location = useLocation();
	const isInBuilder = location.pathname.startsWith("/builder");

	const isNotFirst = stepId !== 0;
	const buttonClasses = `${isNotFirst && "ms-2"} ${isActive && "step-number-active"
		} ${statusName === "READY" && isInBuilder
			? "step-number-done"
			: /* : isActive
				? "step-number-touched" */
			""
		} step-number ff-bold`;

	return (
		<div>
			{stepId === FEModules.ActivityComplete ? (
				<Button classes={buttonClasses}>
					<BiCheck size={20} />
				</Button>
			) : (
				<Button classes={buttonClasses}>{index + 1}</Button>
			)}
		</div>
	);
};

export default BuilderTopbar;