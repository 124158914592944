import { useInputsContext } from '../../../../contexts/InputsProvider'
import { capitalize } from '../../../../utils/helperFunctions'
import ChangesChildAction from './ChangesChildAction'

const InputsChanges = () => {
  const context = useInputsContext()
  return (
    <>
      {
        context?.inputsEntriesIds && (
          context.inputsEntriesIds.map((inputEntryId) => <InputsChangeBlock key={inputEntryId} itemId={inputEntryId} entryType='input-entries' />)
        )
      }
    </>
  )
}

const InputsChangeBlock = ({ itemId, entryType }: { itemId: number, entryType: 'input-entries' }) => {
  return <ChangesChildAction itemId={itemId} path={entryType} title={capitalize(entryType) as string} />
}

export default InputsChanges
