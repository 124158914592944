import React from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../types/modulesInterfaces';

interface TranslationParams {
    [key: string]: string
}

const TranslatableText = ({ 
    translationKey,
    translationParams,
    style,
    className
 }: { 
    translationKey: TranslationKey,
    style?: React.CSSProperties,
    className?: string
    translationParams?: TranslationParams | {},
}) => {
    const { t } = useTranslation();

    return <span style={style} className={className}>{t(translationKey, translationParams)}</span>;
}

export default TranslatableText
