import { useState, useEffect, FC } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { RadioInputGroup } from "../../../types/modulesInterfaces";
import FormRow from "../formRow/FormRow";
import { useAppSelector } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import Skeleton from "react-loading-skeleton";
import TranslatableText from "../../translations/TranslatableText";

const RadioGroupInput: FC<RadioInputGroup> = ({
	inputName,
	index,
	comment,
	help,
	options,
	label,
	autosubmit,
	disabled,
}) => {
	const [name, setName] = useState<string>("");
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	const { setFieldValue, errors, touched, submitForm } = useFormikContext();
	const handleChange = (value: string | number) => {
		if (disabled) return;
		setFieldValue(name, value);
		if (autosubmit && submitForm) submitForm();
	};

	return (
		<FormRow
			style={{ height: "fit-content" }}
			rowComment={comment}
			rowHelp={help}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<div role="group" aria-labelledby={label} className="d-flex f-column">
				{isFetchingData ? (
					<Skeleton height="100%" count={3} />
				) : (
					options.map((option, index) => (
						<label
							className="clickable d-flex align-items-center pb-1"
							key={index}
							onClick={() => handleChange(option.value)}
						>
							<Field
								type="radio"
								name={name}
								id={name}
								value={option.value}
								onClick={() => handleChange(option.value)}
								disabled={disabled}
							/>
							<TranslatableText className="ps-1 fs-12 ff-medium" translationKey={option.label}/>
						</label>
					))
				)}
			</div>
		</FormRow>
	);
};

export default RadioGroupInput;
