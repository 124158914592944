import type { CommentsResponse } from "../../../../components/actions/comments/CommentsAction";
import { apiSlice } from "../apiSlice";

interface ModuleCommentsBasePayload {
	threadId: number;
}

interface ModuleCommentsEditPayload extends ModuleCommentsBasePayload {
	content: string;
	parent: number | null;
}

export const moduleCommentsApisSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getModuleComments: builder.query<
			CommentsResponse,
			ModuleCommentsBasePayload
		>({
			query: ({ threadId }) => {
				return `threads/${threadId}/`;
			},
			providesTags: (_res, _error, { threadId }) => [
				{ type: "ModuleComments", id: threadId },
			],
		}),
		createModuleComment: builder.mutation<
			CommentsResponse,
			ModuleCommentsEditPayload
		>({
			query: ({ threadId, content, parent }) => {
				return {
					url: "comments/",
					method: "POST",
					body: { content, thread: threadId, parent },
				};
			},
			invalidatesTags: (_res, _error, { threadId }) => [
				{ type: "ModuleComments", id: threadId },
			],
		}),
		deleteModuleComment: builder.mutation<
			CommentsResponse,
			{ commentId: number }
		>({
			query: ({ commentId }) => ({
				url: `comments/${commentId}/`,
				method: "DELETE",
			}),
			invalidatesTags: (_res, _error) => [{ type: "ModuleComments" }],
		}),
	}),
});

export const {
	useGetModuleCommentsQuery,
	useCreateModuleCommentMutation,
	useDeleteModuleCommentMutation,
} = moduleCommentsApisSlice;
