type NumericObject = { [key: string]: number | string };

export function roundPropertiesToTwoDecimals(obj: NumericObject | undefined): NumericObject | undefined {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  const newObj: NumericObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = parseFloat(obj[key] as string).toFixed(2);
    }
  }
  return newObj;
}

export function roundToTwoDecimals(number:number):number {
    return parseFloat(number.toFixed(2));
}