import React from "react";

interface SideBarContextValue {
    isSideBarOpen: boolean
    toggleSidebar: ()=>void
}

const SideBarContext = React.createContext<SideBarContextValue>({
    isSideBarOpen: false,
    toggleSidebar: ()=>{}
});
const SideBarProvider = ({ children }: { children: React.ReactNode }) => {
    const [isSideBarOpen, setIsSideBarOpen] = React.useState(false);
    const toggleSidebar = () => {
        setIsSideBarOpen(!isSideBarOpen);
      };

    return <SideBarContext.Provider value={{ isSideBarOpen, toggleSidebar }}>{children}</SideBarContext.Provider>;
};
export  {SideBarProvider, SideBarContext}