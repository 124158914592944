import {
  useGetActivitiesByIdQuery,
  useUpdateActivityMutation,
} from "../../app/features/activities/activityApiSlice";
import { useEffect, useMemo, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import type { ActivityUpdatePayload } from "../../app/features/activities/activityTypes";
import { FieldType } from "../../types/modulesInterfaces";
import { getOptions } from "../../app/features/dropdownOptions/fetchOptions";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import GenericInputGroup from "../../components/input/inputGroup/GenericInputGroup";
import ModuleButton from "../../components/button/ModuleButton";
import useErrorMessage from "../../utils/useErrorMessage";
import { validatePositiveNumber } from "../activityBuilder/modules/moduleUtils";
import { useId } from "./EditActivityContainer";
import TranslatableText from "../../components/translations/TranslatableText";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import { useAppSelector } from "../../app/hooks";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { getCapitalizationYears } from "../../utils/projectUtils";
import { initValues } from "./utils";
import EditActivityContent from "./EditActivityContent";
import { BiSave } from "react-icons/bi";
const EditActivity = () => {
  const { id } = useId()
  const { project } = useAppSelector(selectCurrentProject);
  const [initialvalues, setInitialvalues] =
    useState<ActivityUpdatePayload>(initValues);
  const navigate = useNavigate();

  const {
    data: activity,
    isFetching,
    isError,
    error,
    isLoading: isFetchLoading,
  } = useGetActivitiesByIdQuery(id ?? 0, { skip: !id });
  const [
    updateActivity,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateActivityMutation();

  const { errorMsg: blockingError } = useErrorMessage({ isError, error });
  const { errorMsg: updateErrorMessage } = useErrorMessage({
    isError: isUpdateError,
    error: updateError,
  });

  const validationSchema = useMemo(() =>
    Yup.object().shape({
      id: validatePositiveNumber.required(),
      activity: Yup.object().shape({
        name: Yup.string().required("validations.activity_name_required"),
        duration_t2: validatePositiveNumber.required().test(
          "start-year-duration-check",
          "Activity Start Year + Duration cannot exceed Project Start Year + Implementation Phase",
          function (value) {
            const { start_year_t2, duration_t2 } = this.parent;
            console.log("parent", this.parent)
            const projectStartYear = project?.start_year_of_activities; // Adjust this line based on your project structure
            const implementationYears = project?.implementation_years;

            console.log("project", start_year_t2, duration_t2, project?.start_year_of_activities, project?.implementation_years, "condition check", start_year_t2 + duration_t2 <= (projectStartYear ?? 0) + (implementationYears ?? 0))
            return start_year_t2 + duration_t2 <= (projectStartYear ?? 0) + (implementationYears ?? 0);
          }
        ),
        climate_t2: Yup.string().nullable().required(),
        moisture_t2: Yup.string().nullable().required(),
        soil_type_t2: Yup.string().nullable().required(),
        start_year_t2: validatePositiveNumber.nullable().required(),
        change_rate: validatePositiveNumber.nullable().required(),
        cost: validatePositiveNumber.nullable().required(),
      }),
    }), [project])

  useEffect(() => {
    const initValues: ActivityUpdatePayload = {
      id: id ?? 0,
      activity: {
        name: activity?.name ?? "",
        duration_t2: activity?.duration_t2,
        start_year_t2: activity?.start_year_t2,
        climate_t2: activity?.climate_t2?.id,
        moisture_t2: activity?.moisture_t2?.id,
        soil_type_t2: activity?.soil_type_t2?.id,
        cost: activity?.cost,
        change_rate: activity?.change_rate?.id,
        capitalization_years: getCapitalizationYears(activity?.start_year_t2, activity?.duration_t2, project?.last_year_of_accounting),
      },
    };
    setInitialvalues(initValues);

  }, [activity, id, project]);


  const handleSubmit = (values: ActivityUpdatePayload) => {
    try {
      updateActivity(values);
    } catch (error) {
      console.error(error);
    }
  };

  return blockingError ? (
    <section>
      <div className="module-group">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-14 ff-medium-ext module-header w-fit">
            <TranslatableText translationKey="main.edit_activity" />
          </h2>
          <div className="module-number header-number ff-light-ext">{id}</div>
        </div>

        <span className="error fs-13 pb-2">{blockingError}</span>
      </div>
    </section>
  ) : (
    <section>
      <div className="module-group">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-14 ff-medium-ext module-header w-fit">
            <TranslatableText translationKey="main.edit_activity" />
          </h2>
          {/* <div className="module-number header-number ff-light-ext"></div> */}
        </div>

        <div className="d-flex f-column" style={{ maxWidth: "550px" }}>
          <Formik
            initialValues={initialvalues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isValid, setFieldValue, values }) => (
              <Form>
                <EditActivityContent isFetching={isFetching} />
                <p
                  className={`text-error pt-2 fs-13 ${updateErrorMessage && updateErrorMessage.length > 0
                    ? "pb-2"
                    : null
                    }`}
                >
                  {updateErrorMessage}
                </p>
                <div className="pb-2 d-flex align-items-center gap-8">
                  <ModuleButton
                    disabled={isUpdateLoading}
                    isLoading={isUpdateLoading}
                    buttonType="button"
                    handleClick={() => navigate("../edit-modules", { relative: "path" })}
                    labelKey="main.change_modules"
                  />
                  <ModuleButton
                    disabled={isUpdateLoading || !isValid}
                    isLoading={isUpdateLoading}
                    icon={BiSave}
                    labelKey="main.save_changes"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {
        isUpdateSuccess && <EditActivitySuccessModal />
      }
    </section>
  )
};

export default EditActivity;

const EditActivitySuccessModal = () => {
  const { project } = useAppSelector(selectCurrentProject);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleConfirmation = () => {
    setIsModalOpen(false);
    navigate(`/project/${project?.id ?? "new"}/activities`);
  };

  return (
    <ConfirmationModal
      onConfirm={handleConfirmation}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      message={<TranslatableText translationKey="main.edit_activity_success" />}
      confirmationMessage="main.back_to_activities"
      cancelButtonMessage="main.keep_editing"
    />
  )
}
