import { motion, AnimatePresence } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
	selectCurrentBuilder,
	toggleTabsOpen,
} from "../../app/features/builder/builderSlice";
import TranslatableText from "../translations/TranslatableText";

const BuilderMainTabs = () => {
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);
	const dispatch = useAppDispatch();

	return (
		<div className="builder-main-tabs pe-4">
			<div className="bmt-container">
				<div className="d-flex align-items-center">
					<TranslatableText className="px-2 fs-13 no-wrap" translationKey="activity.builder_assessment_editor"/>
				</div>
				<div className="bmt-toggle-t2">
					<SwitchButton
						isOn={!tabsOpen}
						toggleIsOn={() => dispatch(toggleTabsOpen())}
					/>
				</div>
				<motion.div
					className="bmt-t2 clickable"
					onClick={() => dispatch(toggleTabsOpen())}
					whileHover={{ opacity: 0.95 }}
				>
					<TranslatableText className="px-2 fs-13 no-wrap" translationKey="activity.tier_two_parameters"/>
				</motion.div>
			</div>
		</div>
	);
};

const SwitchButton = ({
	isOn,
	toggleIsOn,
}: {
	isOn: boolean;
	toggleIsOn: () => void;
}) => {
	return (
		<div
			className="bmt-switch-container"
			onClick={() => toggleIsOn()}
			style={{ justifyContent: isOn ? "flex-end" : "flex-start" }}
		>
			<motion.div layout className="bmt-switch-handle">
				<AnimatePresence initial={false}>
					<motion.i
						className={`icon far fa-${isOn ? "moon" : "sun"}`}
						key={isOn ? "moon" : "sun"}
						initial={{ y: -30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: 30, opacity: 0 }}
						transition={{ duration: 0.2 }}
					/>
				</AnimatePresence>
			</motion.div>
		</div>
	);
};

export default BuilderMainTabs;
