import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/otherLand/otherLand";
import type {
	OtherLandDefaults,
	OtherLandPayload,
	OtherLandResponse,
	OtherLandT2,
	OtherLandUploadPayload,
	ModuleFull,
} from "../../../../pages/activityBuilder/modules/otherLand/otherLandTypes";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getOtherLand: builder.query<ModuleFull | null, number>({
			query: (activityId) => `/other-lands/?activity=${activityId}`,
			transformResponse: (response: OtherLandResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						response[0],
						includedPropList,
					) as OtherLandPayload;
					const t2 = filterObjectProps(
						response[0],
						includedT2PropList,
					) as OtherLandT2;

					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "OtherLand", activityId },
			],
		}),
		updateOtherLand: builder.mutation<
			OtherLandResponse,
			OtherLandUploadPayload
		>({
			query({ activityId, data }) {
				const module = { ...data.module };
				const tiertwo = { ...data.tiertwo };
				const lucId = data.module.land_use_change;

				const payload = {
					...module,
					...tiertwo,
				} as unknown as OtherLandResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };

				convertEmptyStringsToNull(body);
				return { url: `other-lands/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { activityId }) => [
				{ type: "OtherLand", activityId },
				{ type: "ModuleResults", id: results?.id ? `other-lands-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `other-lands-${results?.id}` : undefined },
				{ type: "OtherLandDefaults", id: results?.id },
				{ type: "ModulesStatuses", id: activityId }
			],
		}),
		getOtherLandDefaults: builder.query<OtherLandDefaults, number>({
			query: (uniqueId) => `/other-lands/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: "OtherLandDefaults", id: uniqueId }],
		})
	}),
});

export const { useGetOtherLandQuery, useUpdateOtherLandMutation, useGetOtherLandDefaultsQuery } =
	moduleApiSlice;
