import type { BelongsToLuc, FieldType, Submodule, TranslationKey } from "../../../types/modulesInterfaces";
import { TranslatedAddAccordionButton } from "../../../components/input/AddAccordionButton";
import { TranslatedFormSubmodule } from "../../../components/formSubmodule/TranslatedFormSubmodule";
import { handleDisableSww } from "./moduleUtils";
import { FieldArray } from "formik";
import GenericInputGroup from "../../../components/input/inputGroup/GenericInputGroup";
import Spinner from "../../../components/spinner/Spinner";
import TranslatableText from "../../../components/translations/TranslatableText";

interface OptionalProps<T extends { id: number }> {
  isOptionalLoading?: boolean;
  optionalSchema: Submodule;
  createHandler: () => void;
  isCreateLoading?: boolean;
  removeHandler: (remove: <T>(index: number) => T | undefined, index: number, id?: number | null) => void
  isLoading?: boolean;
  values: T[] | null;
  addLabel: TranslationKey;
  belongsToLuc?: BelongsToLuc
  isSWW?: (inputType: FieldType) => boolean
  fieldArrayName: string
  isReadOnly: boolean
}
const GenericOptionalSubModule = <T extends { id: number },>({ isOptionalLoading, optionalSchema, isLoading, values, removeHandler, createHandler, addLabel, belongsToLuc, isSWW, fieldArrayName, isReadOnly, isCreateLoading }: OptionalProps<T>) => {
  return (
    <div>
      {
        isOptionalLoading ?
          <div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
            <TranslatableText className="text-white fs-12" translationKey="main.loading" />
            <Spinner size={36} padding={8} />
          </div>
          : <FieldArray name={fieldArrayName}>
            {({ remove }) => (
              <>
                {values && values.length > 0
                  ? values.map((value, index) => (
                    <TranslatedFormSubmodule
                      key={`${value.id}-${index}`}
                      submoduleName={optionalSchema.name}
                      removeHandler={() => removeHandler(remove, index, value.id)}
                      removeLoading={isLoading}
                      itemId={value.id}
                      itemIndex={index + 1}
                    >
                      {
                        optionalSchema?.inputGroups.map((input, i) => {
                          const curatedInput = handleDisableSww({ input, belongsToLuc, index, isReadOnly });
                          return (
                            <div className="py-1" key={`${input.label}-${i}`}>
                              <GenericInputGroup inputGroup={{ ...curatedInput, disabled: isReadOnly, index: index ?? input.index }} />
                            </div>
                          )
                        })
                      }
                    </TranslatedFormSubmodule>
                  ))
                  : null}
              </>
            )}
          </FieldArray>
      }

      <TranslatedAddAccordionButton
        label={addLabel}
        clickHandler={createHandler}
        disabled={isReadOnly}
        loading={isCreateLoading}
      />
    </div>
  )
}

export default GenericOptionalSubModule