import React from 'react'
import TranslatableText from '../../translations/TranslatableText'
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../types/modulesInterfaces';

const Definition = ({ translatableKey, value }: { translatableKey: string, value: string }) => {
  const { i18n } = useTranslation();
  const translationExists = i18n.exists(translatableKey);
  if (!translationExists) return null

  const isValueTranslatable = i18n.exists(value);

  return (
    <li key={translatableKey} className="pt-1">
      <div className='d-flex pb-1 fs-16'>
        <TranslatableText translationKey={translatableKey as TranslationKey} /> :
      </div> {value && isValueTranslatable ?
        <TranslatableText translationKey={value as TranslationKey} />
        : value ? <div dangerouslySetInnerHTML={{ __html: formatText(value) }} className='op-8 fs-14' /> : "N/A"}
    </li>
  )
}

export default Definition


function formatText(text: string) {
  const isNumberedList = /^\d+\./.test(text);

  if (isNumberedList) {
    let formattedText = text.replace(/(\d+\.)/g, '\n$1');
    formattedText = formattedText.replace(/- /g, '\n- ');
    formattedText = formattedText.split('\n').map(item => `<p style="padding: 2px">${item?.trim()}</p>`).join('\n');
    return formattedText
  } else {
    return `<p>${text?.trim()}</p>`
  }
}
