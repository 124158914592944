import { useState, useEffect, FC } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { CheckboxesInputGroup } from "../../../types/modulesInterfaces";
import FormRow from "../formRow/FormRow";
import { useAppSelector } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import Skeleton from "react-loading-skeleton";

const CheckBoxesInputGroup: FC<CheckboxesInputGroup> = ({
	inputName,
	index,
	comment,
	help,
	options,
	label,
	autosubmit,
	disabled,
}) => {
	const [name, setName] = useState<string>("");
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	const { errors, touched } = useFormikContext();

	return (
		<FormRow
			style={{ height: "fit-content" }}
			rowComment={comment}
			rowHelp={help}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<div role="group" aria-labelledby={label} className="d-flex f-column">
				{isFetchingData ? (
					<Skeleton height="100%" count={3} />
				) : (
					options.map((option) => (
						<label
							className="clickable d-flex align-items-center pb-1"
							key={option.id}
						>
							<Field
								type="checkbox"
								name={name}
								id={name}
								value={option.id}
								disabled={disabled}
							/>
							<span className="ps-1 fs-12 ff-medium">{option.name}</span>
						</label>
					))
				)}
			</div>
		</FormRow>
	);
};

export default CheckBoxesInputGroup;
