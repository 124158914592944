import { ReactNode, useContext, MouseEvent, useMemo } from "react";
import Accordion, { AccordionContext } from "../accordion/Accordion";
import { motion } from "framer-motion";
import { BiChevronRight, BiTrash, BiQuestionMark } from "react-icons/bi";
import { Note } from "../../types/modulesInterfaces";
import { useAppDispatch, useAppSelector, useCustomTranslation } from "../../app/hooks";
import { selectCurrentBuilder, setActiveAction } from "../../app/features/builder/builderSlice";
import { Actions } from "../../types/interfaces";
import { useLocation } from "react-router-dom";

/* TODO: add possible icons for comments/notes/help */

const FormSubmodule = ({
	children,
	submoduleName,
	removeHandler,
	note,
	removeLoading,
	className,
}: {
	children: ReactNode;
	submoduleName: string;
	removeHandler?: () => void;
	note?: Note;
	removeLoading?: boolean;
	className?: string;
}) => {
	const header = submoduleName ? (
		<SubmoduleHeader
			label={submoduleName}
			removeHandler={removeHandler}
			note={note}
			removeLoading={removeLoading}
		/>
	) : null;
	return (
		<Accordion header={header} className={className}>
			<div className="d-flex f-column">{children}</div>
		</Accordion>
	);
};

const SubmoduleHeader = ({
	label,
	removeHandler,
	note,
	removeLoading,
}: {
	label: string;
	removeHandler?: () => void;
	note?: Note;
	removeLoading?: boolean;
}) => {
	const { isOpen, toggleOpen } = useContext(AccordionContext);
	const translatedLabel = useCustomTranslation(label);
	const handleClick = (e: MouseEvent<SVGElement>) => {
		e.stopPropagation();
		if (removeHandler) removeHandler();
	};

	const { pathname } = useLocation()
	const showQuestionMark = useMemo(() => {
		return pathname.split("/")[1] === "builder"
	}, [pathname])

	const dispatch = useAppDispatch();
	const { activeAction } = useAppSelector(selectCurrentBuilder);
	const handleQuestionmarkClick = (e: MouseEvent) => {
		e.stopPropagation();
		if (activeAction !== Actions.Definitions)
			dispatch(setActiveAction(Actions.Definitions));
	}

	return (
		<div className="submodule-header pos-relative" onClick={() => toggleOpen()}>
			<div className="submodule-header-arrow">
				<motion.div
					animate={{
						rotate: isOpen ? 90 : 0,
					}}
					className="h-100 d-flex align-items-center justify-content-center"
				>
					<BiChevronRight size={24} color="rgba(28, 27, 31, 0.5)" />
				</motion.div>
			</div>
			<h2 className="ff-medium fs-13">{translatedLabel ?? label}</h2>
			{removeHandler ? (
				<BiTrash
					className="ms-1"
					size={20}
					color="var(--gray)"
					onClick={removeLoading ? undefined : handleClick}
				/>
			) : null}

			{
				showQuestionMark ?
					<div className="definitions-questionmark">
						<BiQuestionMark
							className="ms-1"
							size={20}
							color="#50A2A7"
							onClick={handleQuestionmarkClick} />
					</div>
					: null}

			{/* <div
				className="pos-absolute d-flex align-items-center h-100 ps-2"
				style={{ top: 0, left: "calc(100% + 8px)" }}
			>
				{showNote ? <NoteButton active={note?.active} /> : <></>}
			</div> */}
		</div>
	);
};

export default FormSubmodule;
