import { motion } from "framer-motion";
import { ReactNode, MouseEvent, CSSProperties } from "react";

export type ButtonProps = {
	children: ReactNode;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => any;
	classes?: string;
	whileTapProps?: {};
	whileHoverProps?: {};
	disabled?: boolean;
	type?: "button" | "submit" | "reset" | undefined;
	id?: string;
	style?: CSSProperties;
	testId?: string;
};

const Button = ({
	onClick,
	children,
	classes,
	whileTapProps,
	whileHoverProps,
	disabled,
	type,
	id,
	style,
	testId,
}: ButtonProps) => {
	return (
		<motion.button
			onClick={onClick}
			id={id}
			data-testid={testId}
			disabled={disabled}
			type={type ? type : "button"}
			className={
				disabled
					? classes
					: `clickable bg-transparent ${classes ?? ""} ${
							disabled ? " op-8" : ""
						}`
			}
			whileHover={disabled ? {} : { scale: 1.05, ...whileHoverProps }}
			whileTap={disabled ? {} : { scale: 0.95, ...whileTapProps }}
			style={style}
		>
			{children}
		</motion.button>
	);
};

export default Button;
