import { useAppSelector } from "../../app/hooks";
import {
	selectCurrentActions,
	selectCurrentBuilder,
} from "../../app/features/builder/builderSlice";
import { Actions } from "../../types/interfaces";
import { ActionsComponent } from "../../utils/useTabsHook";
import MapAction from "../actions/map/MapAction";

const BuilderActions = () => {
	const actions = useAppSelector(selectCurrentActions);
	const { activeAction } = useAppSelector(selectCurrentBuilder);

	const isActiveActionValid = (activeAction !== undefined && activeAction !== null) && actions?.includes(activeAction);
	const isMapActive = activeAction === Actions.Map;

	return (
		<div className="builder-actions">
			{!isMapActive && isActiveActionValid ?
				<Action action={activeAction} /> : null
			}
			<MapAction style={isMapActive ? { display: "block" } : { display: "none" }} />
		</div>
	);
};

const Action = ({ action }: { action: Actions }) => {
	return ActionsComponent[action]
};

export default BuilderActions;
