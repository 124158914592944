import type { StartWithWithoutGeneric } from "../../../types/modulesInterfaces";
import { FieldType } from "../../../types/modulesInterfaces";
import { useGetLandUseTypesQuery } from "../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import { filterLucTypesByClimateMoisture } from "./moduleUtils";

const useLandUseTypeInput = ({ inputName }: { inputName: string }) => {
  const { data: landUseTypes } = useGetLandUseTypesQuery({});
  const { activity } = useAppSelector(selectCurrentProject);

  const filteredLucTypes = useMemo(() => filterLucTypesByClimateMoisture(activity, landUseTypes), [activity, landUseTypes]);
  const landUseChangeInput: StartWithWithoutGeneric = useMemo(
    () => ({
      type: FieldType.SWW_SELECT,
      inputName: inputName,
      label: "module.type_of_land_use",
      startProps: { dropdownOptions: filteredLucTypes, disabled: true },
      withProps: { dropdownOptions: filteredLucTypes, disabled: true },
      withoutProps: { dropdownOptions: filteredLucTypes, disabled: true },
    }),
    [filteredLucTypes, inputName],
  );

  return landUseChangeInput
}

export default useLandUseTypeInput