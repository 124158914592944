import * as Yup from "yup";
import type {
	BelongsToLuc,
	Option,
} from "../../../../types/modulesInterfaces";
import type {
	AnnualCroplandPayload,
	AnnualCroplandT2Payload,
} from "./annualCroplandTypes";
import { BEModules } from "../../../../utils/moduleList";
import { createStandardLucValidation, standardLucValidation } from "../moduleUtils";

export const includedPropList = [
	"id",
	"status",
	"ha_start",
	"ha_w",
	"ha_wo",
	"land_use_type_start",
	"land_use_type_w",
	"land_use_type_wo",
	"land_use_type_thread",
	"tillage_management_type_start",
	"tillage_management_type_w",
	"tillage_management_type_wo",
	"tillage_management_type_thread",
	"organic_input_type_start",
	"organic_input_type_w",
	"organic_input_type_wo",
	"organic_input_type_thread",
	"residue_management_type_start",
	"residue_management_type_w",
	"residue_management_type_wo",
	"residue_management_type_thread",
	"crop_yield_t2_start",
	"crop_yield_t2_w",
	"crop_yield_t2_wo",
	"crop_yield_t2_thread",
	"minorSeason",
	"activity", //!!!
	"land_use_change",
	"note",
	"area"
];

export const minorSeasonPropsList = [
	"minor_land_use_type_start",
	"minor_land_use_type_w",
	"minor_land_use_type_wo",
	"minor_residue_management_type_start",
	"minor_residue_management_type_w",
	"minor_residue_management_type_wo",
	"minor_yield_start",
	"minor_yield_w",
	"minor_yield_wo",
];

export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo",
	"biomass_t2_start",
	"biomass_t2_w",
	"biomass_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"residue_availability_t2_start",
	"residue_availability_t2_w",
	"residue_availability_t2_wo",
	"minorSeason",
];

export const validationSchema = (belongsToLuc?: BelongsToLuc | undefined) => {
	const standardValidation = createStandardLucValidation(belongsToLuc);

	return Yup.object().shape({
		module: Yup.object().shape({
			land_use_type_start: standardValidation('validations.main_season_crop_required', 'start', BEModules.AnnualCropland),
			land_use_type_w: standardValidation('validations.main_season_crop_required', 'w', BEModules.AnnualCropland),
			land_use_type_wo: standardValidation('validations.main_season_crop_required', 'wo', BEModules.AnnualCropland),
			tillage_management_type_start: standardValidation('validations.tillage_management_required', 'start', BEModules.AnnualCropland),
			tillage_management_type_w: standardValidation('validations.tillage_management_required', 'w', BEModules.AnnualCropland),
			tillage_management_type_wo: standardValidation('validations.tillage_management_required', 'wo', BEModules.AnnualCropland),
			organic_input_type_start: standardValidation('validations.organic_material_required', 'start', BEModules.AnnualCropland),
			organic_input_type_w: standardValidation('validations.organic_material_required', 'w', BEModules.AnnualCropland),
			organic_input_type_wo: standardValidation('validations.organic_material_required', 'wo', BEModules.AnnualCropland),
			residue_management_type_start: standardValidation('validations.residue_management_required', 'start', BEModules.AnnualCropland),
			residue_management_type_w: standardValidation('validations.residue_management_required', 'w', BEModules.AnnualCropland),
			residue_management_type_wo: standardValidation('validations.residue_management_required', 'wo', BEModules.AnnualCropland),
			minorSeason: Yup.object().shape({
				minor_land_use_type_start: Yup.number()
					.when(['minor_residue_management_type_start', 'minor_yield_start'], {
						is: (residue: number | null, yieldValue: number | null) => residue !== null || yieldValue !== null,
						then: standardValidation('validations.land_use_type_required', 'start', BEModules.AnnualCropland),
						otherwise: Yup.number().nullable(),
					}),
				minor_land_use_type_w: Yup.number()
					.when(['minor_residue_management_type_w', 'minor_yield_w'], {
						is: (residue: number | null, yieldValue: number | null) => residue !== null || yieldValue !== null,
						then: standardValidation('validations.land_use_type_required', 'w', BEModules.AnnualCropland),
						otherwise: Yup.number().nullable(),
					}),
				minor_land_use_type_wo: Yup.number()
					.when(['minor_residue_management_type_wo', 'minor_yield_wo'], {
						is: (residue: number | null, yieldValue: number | null) => residue !== null || yieldValue !== null,
						then: standardValidation('validations.land_use_type_required', 'wo', BEModules.AnnualCropland),
						otherwise: Yup.number().nullable(),
					}),
			}),
		}),
	});
}

export const initialValues: AnnualCroplandPayload = {
	id: null,
	status: null,
	ha_start: null,
	ha_w: null,
	ha_wo: null,
	land_use_type_start: null,
	land_use_type_w: null,
	land_use_type_wo: null,
	tillage_management_type_start: null,
	tillage_management_type_w: null,
	tillage_management_type_wo: null,
	organic_input_type_start: null,
	organic_input_type_w: null,
	organic_input_type_wo: null,
	residue_management_type_start: null,
	residue_management_type_w: null,
	residue_management_type_wo: null,
	crop_yield_t2_start: null,
	crop_yield_t2_w: null,
	crop_yield_t2_wo: null,
	minorSeason: {
		minor_land_use_type_start: null,
		minor_land_use_type_w: null,
		minor_land_use_type_wo: null,
		minor_residue_management_type_start: null,
		minor_residue_management_type_w: null,
		minor_residue_management_type_wo: null,
		minor_yield_start: null,
		minor_yield_w: null,
		minor_yield_wo: null,
	},
	note: null,
	area: null,
	activity: null,
	start_year: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
};
/* T2 */
export const t2InitialValues: AnnualCroplandT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,
	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null,
	biomass_t2_start: null,
	biomass_t2_w: null,
	biomass_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
	residue_availability_t2_start: null,
	residue_availability_t2_w: null,
	residue_availability_t2_wo: null,
	minorSeason: {
		minor_biomass_factor_t2_start: null,
		minor_biomass_factor_t2_w: null,
		minor_biomass_factor_t2_wo: null,
	},
};
export interface DropdownOptionLists {
	cropTypes: Option[];
	tillageTypes: Option[];
	organicInputTypes: Option[];
	residueTypes: Option[];
}


