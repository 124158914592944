import { Field, Form, Formik } from "formik";
import { MdArrowForward, MdOutlinePersonOutline } from "react-icons/md";
import { BiRedo } from "react-icons/bi";
import { Link } from "react-router-dom";
import FormRow from "../../../components/input/formRow/FormRow";
import CustomInput from "../../../components/input/CustomInput";
import FooterError from "../../activityBuilder/modules/FooterError";

import forgotPass from "../../../assets/icons/forgot_password.svg";
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";
import { useEffect, useState } from "react";
import { useResetPasswordMutation } from "../../../app/features/auth/authApiSlice";
import * as Yup from "yup";
import useErrorMessage from "../../../utils/useErrorMessage";
import TranslatableText from "../../../components/translations/TranslatableText";
import BackToLoginButton from "../../../components/button/BackToLoginButton";

const validationSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("validations.email_required"),
});

const ResetPasswordForm = () => {
	const [resetPassword, { isLoading, isError, error }] =
		useResetPasswordMutation();
	const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
	const [timer, setTimer] = useState<number>(0);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	useEffect(() => {
		let interval: NodeJS.Timer;
		if (isDisabled && timer > 0)
			interval = setInterval(() => {
				setTimer((prevTimer) => prevTimer - 1);
			}, 1000);
		else if (timer === 0) setIsDisabled(false);

		return () => clearInterval(interval);
	}, [timer, isDisabled]);

	const sendEmail = async ({ email }: { email: string }) => {
		if (isDisabled || timer > 0) return;
		try {
			await resetPassword({ email }).unwrap();
			setIsEmailSent(true);
			setIsDisabled(true);
			setTimer(30);
		} catch (error) {
			console.error(error);
			setIsEmailSent(false);
		}
	};

	const generateSupportEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		window.location.href = "mailto:ex-act@fao.org";
	};

	const handleResend = () => setIsEmailSent(false);
	const { errorMsg } = useErrorMessage({ isError, error });

	return (
		<section className="reset-form-container pe-3">
			<h1 className="fs-13 ff-medium pb-2" style={{ color: "#000" }}>
				<TranslatableText translationKey="user.password_reset" />
			</h1>
			<p
				className="fs-11 pb-2"
				style={{ lineHeight: "16px", color: "var(--dark)" }}
			>
				<TranslatableText translationKey={isEmailSent ? "user.is_email_sent" : "user.send_email_description"} />
			</p>

			{isEmailSent ? (
				<div className="w-100 pb-2">
					<Button onClick={handleResend} classes="d-flex align-items-center">
						<BiRedo size={24} color="var(--dark-gray)" />
						<TranslatableText className="px-1 text-dark-gray ff-medium fs-11" translationKey="user.resend_link" />
					</Button>
				</div>
			) : (
				<Formik
					initialValues={{
						email: "",
					}}
					onSubmit={sendEmail}
					validationSchema={validationSchema}
				>
					{({ errors, touched }) => {
						return (
							<Form className="me-2 pb-2">
								<FormRow
									error={touched.email ? errors.email : undefined}
									classes="mb-1"
								>
									<Field
										label="Email"
										name="email"
										id="email"
										component={CustomInput}
										icon={MdOutlinePersonOutline}
										type="text"
									/>
								</FormRow>
								<FooterError
									footerError={errorMsg}
									errors={errors}
									touched={touched}
								/>


								<Link
									to="#"
									onClick={generateSupportEmail}
									className="forgot-password w-fit no-decoration justify-content-between"
								>
									<div className="d-flex pe-2">
										<img src={forgotPass} alt="" className="pe-1" />
										<div className="d-flex f-column text-dark-gray">
											<TranslatableText className="fs-11 no-wrap" translationKey="user.cannot_login_1" />
											<TranslatableText className="fs-11 no-wrap" translationKey="user.cannot_login_2" />
										</div>
									</div>
								</Link>
								<div className="w-100 d-flex align-items-center justify-content-between pt-2">
									<BackToLoginButton />
									<Button
										type="submit"
										classes={`btn-login ${isDisabled ? "op-8" : ""}`}
										style={{ minWidth: 118 }}
										disabled={isDisabled}
									>
										<span className="bg-quat-de label no-wrap ff-medium">
											{isDisabled
												? `Please wait ${timer}s`
												: isLoading
													? "Sending..."
													: "Send Link"}
										</span>
										<div className="bg-quat-br icon">
											{isLoading ? (
												<Spinner size={36} padding={8} />
											) : (
												<MdArrowForward size={20} color="var(--light-gray)" />
											)}
										</div>
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			)}
		</section>
	);
};

export default ResetPasswordForm;
