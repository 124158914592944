import { motion } from "framer-motion";
import { ReactNode } from "react";
import { ButtonProps } from "../button/Button";
import Backdrop from "./Backdrop";
import Button from "../button/Button";
import TranslatableText from "../translations/TranslatableText";
import { TranslationKey } from "../../types/modulesInterfaces";

/* import cross from "../../../assets/icons/cross.svg";
import whiteCross from "../../../assets/icons/white-cross.svg"; */

interface ModalProps {
	children: ReactNode;
	size: string;
	headerText: TranslationKey;
	handleClose?: () => void;
	footerButtons?: ButtonProps[];
	isHeaderDark?: boolean;
	preventClosing?: boolean;
}

/* animation config */
const dropIn = {
	hidden: {
		y: "-100vh",
		opacity: 0,
	},
	visible: {
		y: "0",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: "-100vh",
		opcaity: 0,
	},
};

/* default button prop */
const defaultFooterButtons: (handleClose?: () => void) => ButtonProps[] = (handleClose?: () => void) => {
	return [
		{
			onClick: handleClose ? handleClose : () => { },
			children: <TranslatableText translationKey="main.done" />,
			type: "button",
		},
	];
}

const Modal = ({
	children,
	handleClose,
	size = "md",
	headerText,
	footerButtons = defaultFooterButtons(handleClose),
	isHeaderDark = false,
	preventClosing = false,
}: ModalProps) => {
	const modalSizeClass =
		size === "sm"
			? "modal-sm"
			: size === "md"
				? "modal-md"
				: size === "lg"
					? "modal-lg"
					: size === "auto"
						? ""
						: "modal-xlg";

	return (
		<Backdrop onClick={handleClose && !preventClosing ? handleClose : () => { }}>
			<motion.div
				onClick={(e) => e.stopPropagation()}
				className={`modal ${modalSizeClass}`}
				variants={dropIn}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<header
					className={`d-flex justify-content-between w-100 p-2 ${isHeaderDark && "bg-prim-de"
						}`}
				>
					<TranslatableText translationKey={headerText} className={`text-headline-secondary ${isHeaderDark ? "text-white" : "text-midtone-gray"}`} />
					{/* {!preventClosing && (
      <img
       onClick={handleClose}
       src={isHeaderDark ? whiteCross : cross}
       alt="close modal"
       className="ps-2 clickable ms-auto"
      />
     )} */}
				</header>
				<article className={"w-100 h-100 ov-y-scroll pos-relative"}>
					{children}
				</article>
				<footer
					className={`modal-footer d-flex ${size === "sm" ? "justify-content-evenly" : "justify-content-end"
						} w-100 p-2 bg-light-gray`}
				>
					{footerButtons.map((button, index) => {
						return (
							<div className={size !== "sm" ? "me-2" : ""} key={index}>
								<Button {...button} />
							</div>
						);
					})}
				</footer>
			</motion.div>
		</Backdrop>
	);
};

export default Modal;
