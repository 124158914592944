import CustomCalendarInput from "../CustomCalendarInput";
import FormRow from "../formRow/FormRow";
import { FC, useEffect, useState } from "react";
import { DatepickerInputGroup } from "../../../types/modulesInterfaces";
import { Field, useFormikContext, getIn } from "formik";
import { useAppSelector } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";

const CalendarInput: FC<DatepickerInputGroup> = ({
	label,
	inputName,
	index,
	comment,
	help,
	disabled,
	autosubmit,
}) => {
	const [name, setName] = useState<string>(
		typeof inputName === "function" ? inputName(index ?? 0) : inputName,
	);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);
		return () => {
			setName("");
		};
	}, [inputName]);
	const { errors, touched } = useFormikContext();

	return (
		<FormRow
			rowComment={comment}
			rowHelp={help}
			isFetching={isFetchingData}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<Field
				name={name}
				id={name}
				label={label}
				component={CustomCalendarInput}
				comment={comment}
				help={help}
				disabled={disabled}
				autosubmit={autosubmit}
			/>
		</FormRow>
	);
};

export default CalendarInput;
