import { includedPropList, includedT2PropList } from "../../../../pages/activityBuilder/modules/energy/energy";
import type { ElectricityPayload, ElectricityResponse, ElectricityCreatePayload, ElectricityDeletePayload, FuelCreatePayload, FuelDeletePayload, FuelPayload, FuelResponse, EnergiesResponse, EnergiesUpdatePayload, EnergiesPayload, EnergiesT2Payload, ElectricityDefaults, FuelDefaults } from "../../../../pages/activityBuilder/modules/energy/energyTypes";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEnergies: builder.query<{ module: EnergiesPayload; tiertwo: EnergiesT2Payload } | null, number>({
      query: (activityId) => `energies/?activity=${activityId}`,
      transformResponse: (response: EnergiesResponse[]) => {
        if (response && response.length > 0) {
          const module = filterObjectProps(response[0], includedPropList) as EnergiesPayload
          const t2 = filterObjectProps(response[0], includedT2PropList) as EnergiesT2Payload
          return {
            module,
            tiertwo: t2
          }
        }
        return null
      },
      providesTags: (_results, _error, activityId) => [{ type: 'Energies', id: activityId }]
    }),
    updateEnergies: builder.mutation<EnergiesResponse, EnergiesUpdatePayload>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `energies/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId }) => [{ type: 'Energies', id: activityId }, { type: "ModulesStatuses", id: activityId }]
    }),
    getElectricity: builder.query<ElectricityResponse[], number>({
      query: (activityId) => `electricities/?activity=${activityId}`,
      providesTags: (_results, _error, activityId) => [{ type: 'Electricities', id: activityId }]
    }),
    updateElectricity: builder.mutation<ElectricityResponse, ElectricityPayload>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `electricities/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [{ type: 'Electricities', id: activityId },
      { type: 'ModuleResults', id: body.parent ? `energies-${body.parent}` : undefined },
      { type: "ModuleHistory", id: body.parent ? `energies-${body.parent}` : undefined },
      { type: "ModulesStatuses", id: activityId }, { type: "ElectricitiesDefaults", id: `electricities-${body.parent}` },]
    }),
    createElectricity: builder.mutation<ElectricityResponse, ElectricityCreatePayload>({
      query: ({ body }) => ({ url: "electricities/", method: 'POST', body }),
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'Electricities', id: activityId }, { type: "ModulesStatuses", id: activityId },
        { type: 'ModuleResults', id: body.parent ? `energies-${body.parent}` : undefined },
        { type: "ModuleHistory", id: body.parent ? `energies-${body.parent}` : undefined },
        { type: "ElectricitiesDefaults", id: `electricities-${body.parent}` },
      ]
    }),
    deleteElectricity: builder.mutation<ElectricityResponse, ElectricityDeletePayload>({
      query: ({ id }) => ({ url: `electricities/${id}/`, method: 'DELETE' }),
      invalidatesTags: (_results, _error, { activityId, parent }) => [
        { type: 'Electricities', id: activityId }, { type: "ModulesStatuses", id: activityId },
        { type: 'ModuleResults', id: parent ? `energies-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `energies-${parent}` : undefined },
        { type: "ElectricitiesDefaults", id: `electricities-${parent}` },
      ]
    }),
    getElectricityDefaults: builder.query<ElectricityDefaults, number>({
      query: (uniqueId) => `electricities/${uniqueId}/defaults/`,
      providesTags: (_results, _error, uniqueId) => [
        { type: "ElectricitiesDefaults", id: `electricities-${uniqueId}` },
      ],
    }),

    getFuel: builder.query<FuelResponse[], number>({
      query: (activityId) => `fuels/?activity=${activityId}`,
      providesTags: (_results, _error, activityId) => [{ type: 'Fuels', id: activityId }]
    }),
    updateFuel: builder.mutation<FuelResponse, FuelPayload>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `fuels/${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [{ type: 'Fuels', id: activityId },
      { type: 'ModuleResults', id: body.parent ? `energies-${body.parent}` : undefined },
      { type: "ModuleHistory", id: body.parent ? `energies-${body.parent}` : undefined },
      { type: "ModulesStatuses", id: activityId }, { type: "FuelsDefaults", id: `fuels-${body.parent}` }]
    }),
    createFuel: builder.mutation<FuelResponse, FuelCreatePayload>({
      query: ({ body }) => ({ url: "fuels/", method: 'POST', body }),
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'Fuels', id: activityId }, { type: "ModulesStatuses", id: activityId },
        { type: 'ModuleResults', id: body.parent ? `energies-${body.parent}` : undefined },
        { type: "ModuleHistory", id: body.parent ? `energies-${body.parent}` : undefined },
        { type: "FuelsDefaults", id: `fuels-${body.parent}` }
      ]
    }),
    deleteFuel: builder.mutation<FuelResponse, FuelDeletePayload>({
      query: ({ id }) => ({ url: `fuels/${id}/`, method: 'DELETE' }),
      invalidatesTags: (_results, _error, { activityId, parent }) => [
        { type: 'Fuels', id: activityId }, { type: "ModulesStatuses", id: activityId },
        { type: 'ModuleResults', id: parent ? `energies-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `energies-${parent}` : undefined },
        { type: "FuelsDefaults", id: `fuels-${parent}` }]
    }),
    getFuelDefaults: builder.query<FuelDefaults, number>({
      query: (uniqueId) => `fuels/${uniqueId}/defaults/`,
      providesTags: (_results, _error, uniqueId) => [
        { type: "FuelsDefaults", id: `fuels-${uniqueId}` },
      ],
    }),
  })
})

export const {
  useGetEnergiesQuery,
  useUpdateEnergiesMutation,
  useGetElectricityQuery,
  useUpdateElectricityMutation,
  useCreateElectricityMutation,
  useDeleteElectricityMutation,
  useGetElectricityDefaultsQuery,
  useGetFuelQuery,
  useUpdateFuelMutation,
  useCreateFuelMutation,
  useDeleteFuelMutation,
  useGetFuelDefaultsQuery
} = moduleApiSlice;
