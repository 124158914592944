import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import type { FormikHelpers } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button/Button";
import CustomInput from "../../../components/input/CustomInput";
import FormRow from "../../../components/input/formRow/FormRow";
import Spinner from "../../../components/spinner/Spinner";

import {
	MdOutlinePersonOutline,
	MdArrowForward,
	MdPassword,
} from "react-icons/md";
import forgotPass from "../../../assets/icons/forgot_password.svg";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { SerializedError } from "@reduxjs/toolkit";
import type { BackendError } from "../../../types/interfaces";
import TranslatableText from "../../../components/translations/TranslatableText";

interface LoginValues {
	email: string;
	password: string;
}

const validationSchema = Yup.object({
	email: Yup.string().required("validations.field_required"), //.email("Invalid email address")
	password: Yup.string()
		/* .min(8, "Must be at least 8 characters") */
		.required("validations.field_required"),
});

const LoginForm = ({
	onLogin,
	errorMsg,
	isLoading,
}: {
	onLogin: (payload: LoginValues) => void;
	errorMsg: string;
	isLoading: boolean;
}) => {
	const handleSubmit = (values: LoginValues) => onLogin(values);

	return (
		<section className="login-form-container">
			<Formik
				initialValues={{
					email: "",
					password: "",
				}}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched }) => {
					return (
						<Form className="me-2 px-3">
							<FormRow
								error={
									errorMsg ? errorMsg : touched.email ? errors.email : undefined
								}
								classes="mb-1"
							>
								<Field
									label="Email"
									name="email"
									id="email"
									component={CustomInput}
									icon={MdOutlinePersonOutline}
									type="text"
								/>
							</FormRow>
							<FormRow
								error={touched.password ? errors.password : undefined}
								classes="mb-1"
							>
								<Field
									label="Password"
									name="password"
									id="password"
									component={CustomInput}
									icon={MdPassword}
									type="password"
								/>
							</FormRow>
							<div className="w-100 d-flex justify-content-between">
								<Link
									to="../reset-password"
									relative="path"
									className="forgot-password w-fit no-decoration"
								>
									<img src={forgotPass} alt="" className="pe-1" />
									<TranslatableText className="fs-11" translationKey="user.forgot_password"/>
								</Link>
								<Button type="submit" classes="btn-login">
									<TranslatableText className="bg-quat-de label" translationKey="main.login"/>
									<div className="bg-quat-br icon">
										{isLoading ? (
											<Spinner size={36} padding={8} />
										) : (
											<MdArrowForward size={20} color="var(--light-gray)" />
										)}
									</div>
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</section>
	);
};

export default LoginForm;
