import { Form, useFormikContext, getIn, FieldArray } from "formik";
import type { ReactNode } from "react";
import { useEffect } from "react";
import FormSubmodule from "../../../../components/formSubmodule/FormSubmodule";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import type {
	OptionalSubmodule,
	Submodule,
	T2Schema,
	TranslationKey,
} from "../../../../types/modulesInterfaces";
import { motion } from "framer-motion";
import type {
	ForestDisturbanceData,
	ModuleFull,
} from "./forestManagementTypes";
import { useGetForestDisturbanceDefaultsQuery } from "../../../../app/features/api/modules/forestManagementApiSlice";
import { ForestManagementSections } from "./forestManagement";
import { useCustomTranslation, usePermissions } from "../../../../app/hooks";
import { handleDisableSww } from "../moduleUtils";
import { preventFormSubmit } from "../inputs/utils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import useErrorMessage from "../../../../utils/useErrorMessage";
import DefaultsErrorNote from "../../../../components/messageNote/DefaultsErrorNote";
import LoadDefaultsButton from "../LoadDefaultsButton";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};
const ForestManagementTierTwo = ({
	title,
	t2Schema,
	tabsOpen,
	removeTitleNumber,
	setInitValues,
	warningMessage
}: {
	title: TranslationKey;
	t2Schema: T2Schema;
	positionBeforeModule?: string;
	tabsOpen: boolean;
	removeTitleNumber?: boolean;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	warningMessage?: string | null
}) => {
	const degradationSubmodules: Submodule[] = [];
	const mainSubmodules: Submodule[] = [];
	const { isReadOnly } = usePermissions();
	const translatedTitle = useCustomTranslation(title);
	for (const submodule of t2Schema.submodules) {
		if (submodule.name === ForestManagementSections.DEGRADATION) degradationSubmodules.push(submodule);
		else mainSubmodules.push(submodule);
	}

	if (tabsOpen) return null;

	return (
		<motion.div initial={false} variants={variants}>
			<Form onKeyDown={preventFormSubmit} className="py-2">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-13 ff-normal-ext module-header w-fit">
						{translatedTitle} - Tier 2
					</h2>
					{
						warningMessage && <DefaultsErrorNote noteMessage={warningMessage} />
					}
				</div>

				<LoadDefaultsButton />

				{mainSubmodules?.map((submodule, index) => (
					<FormSubmodule submoduleName={submodule.name} key={submodule.name} className="pt-2">
						<TierTwoBlock>
							{submodule.inputGroups.map((input: any, index: any) => {
								const curatedInput = handleDisableSww({ input, isReadOnly, index });
								return (
									<GenericInputGroup
										key={index}
										inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
									/>
								)
							})}
						</TierTwoBlock>
					</FormSubmodule>
				))}
				{t2Schema.optionalSubmodules?.map((submodule, index) => (
					<FormOptionalSubmodule
						submodule={submodule}
						key={`${submodule.inputName}-${index}`}
						removeTitleNumber={removeTitleNumber}
						setInitValues={setInitValues}
						disabled={isReadOnly}
					/>
				))}
				{degradationSubmodules.map((submodule, index) => (
					<FormSubmodule submoduleName={submodule.name} key={submodule.name} className="pt-2">
						<TierTwoBlock>
							{submodule.inputGroups.map((input: any, index: any) => {
								const curatedInput = handleDisableSww({ input, isReadOnly, index });
								return (
									<GenericInputGroup key={index} inputGroup={{ ...curatedInput, disabled: isReadOnly }} />
								)
							})}
						</TierTwoBlock>
					</FormSubmodule>
				))}
			</Form>
		</motion.div>
	);
};

const FormOptionalSubmodule = <T,>({
	submodule,
	removeTitleNumber,
	setInitValues,
	disabled
}: {
	submodule: OptionalSubmodule<T>;
	removeTitleNumber?: boolean;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	disabled?: boolean;
}) => {
	const { values } = useFormikContext();
	const submoduleValues = getIn(values, submodule.inputName);
	return Array.isArray(submoduleValues) ? (
		<>
			<FieldArray name={submodule.inputName}>
				{() => (
					<>
						{submoduleValues.map((el, index) => {
							const submoduleName = removeTitleNumber
								? submodule.name
								: `${index + 1}. ${submodule.name}`;

							return submodule.inputName === "disturbances" ? (
								<DisturbanceT2
									disturbance={el}
									index={index}
									key={`${index + 1}. ${submodule.name}`}
									setInitValues={setInitValues}
									submodule={submodule}
									disabled={disabled}
								/>
							) : (
								<FormSubmodule
									submoduleName={submoduleName}
									key={`${index + 1}. ${submodule.name}`}
									className="pt-2"
								>
									<TierTwoBlock>
										{submodule.inputGroups.map((input, i) => {
											const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index });
											return (
												<GenericInputGroup
													key={`${input.inputName}-${i}`}
													inputGroup={{ ...curatedInput, index, disabled }} //, autosubmit: true
												/>
											)
										})}
									</TierTwoBlock>
								</FormSubmodule>
							);
						})}
					</>
				)}
			</FieldArray>
		</>
	) : (
		<FormSubmodule submoduleName={submodule.name} className="pt-2">
			<TierTwoBlock>
				{submodule.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index: i });
					return (
						<GenericInputGroup
							key={`${input.inputName}-${i}`}
							inputGroup={{ ...curatedInput, disabled }} //, autosubmit: true
						/>
					)
				})}
			</TierTwoBlock>
		</FormSubmodule>
	);
};

const DisturbanceT2 = <T,>({
	submodule,
	setInitValues,
	disturbance,
	index,
	disabled
}: {
	disturbance: ForestDisturbanceData;
	submodule: OptionalSubmodule<T>;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	index: number;
	disabled?: boolean;
}) => {
	const { data: defaults, isError: isDefaultsError, error: defaultsError } = useGetForestDisturbanceDefaultsQuery(
		disturbance.id,
		{ skip: !disturbance.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });

	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				disturbances: prev.disturbances.map((dis, i) =>
					dis.id === disturbance.id ? { ...dis, ...defaults } : dis,
				),
			}));
	}, [defaults, setInitValues, disturbance]);

	return (
		<>
			{
				defaultsErrorMsg &&
				<DefaultsErrorNote noteMessage={defaultsErrorMsg} />
			}
			<TranslatedFormSubmodule
				submoduleName={submodule.name}
				key={`${index + 1}. ${submodule.name}`}
				itemId={disturbance?.id}
				itemIndex={index + 1}
				className="pt-2"
			>
				<TierTwoBlock>
					{submodule.inputGroups.map((input, i) => {
						const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index: i });
						return (
							<GenericInputGroup
								key={`${input.inputName}-${i}`}
								inputGroup={{ ...curatedInput, index, disabled }}
							/>
						)
					})}
				</TierTwoBlock>
			</TranslatedFormSubmodule>
		</>
	);
};

export const TierTwoBlock = ({ children }: { children: ReactNode }) => {
	return <div className="tiertwo-block">{children}</div>;
};

export default ForestManagementTierTwo;
