import {
	BiArrowFromLeft,
	BiLeftArrowAlt,
	BiRightArrowAlt,
} from "react-icons/bi";
import Button from "./Button";
import { TranslationKey } from "../../types/modulesInterfaces";
import TranslatableText from "../translations/TranslatableText";

const SetupAssistantButton = ({
	handleClick,
	type,
}: {
	handleClick?: () => void;
	type?: "next" | "back" | "skip";
}) => {
	const labelType = (): TranslationKey | undefined =>{
		switch (type) {
			case "next":
				return "main.next";
			case "back":
				return "main.back";
			case "skip":
				return "main.skip";
			default:
				return type;
		}
	}
	return (
		<Button
			type={type === "next" ? "submit" : "button"}
			classes="btn-save mt-1 w-fit "
			onClick={handleClick}
			style={{ border: "1px solid var(--primary-deep)" }}
		>
			<div className="h-100 d-flex align-items-center bg-prim-de p-1">
				{type === "next" ? (
					<BiRightArrowAlt size={20} color="var(--white)" />
				) : type === "back" ? (
					<BiLeftArrowAlt size={20} color="var(--white)" />
				) : (
					<BiArrowFromLeft size={20} color="var(--white)" />
				)}
			</div>
			<TranslatableText className="label ff-medium capitalize" translationKey={labelType() ?? ""}/>
		</Button>
	);
};

export default SetupAssistantButton;
