import {
	convertIncomingPercentage,
	convertOutgoingPercentage,
	filterObjectProps,
	convertEmptyStringsToNull
} from "../../../../pages/activityBuilder/modules/inputs/utils";
import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/smallFisheries/smallFisheries";
import type {
	SmallFisheriesResponse,
	SmallFisheriesPayload,
	SmallFisheriesT2,
	ModuleFull,
	UpdateSmallFisheriesPayload,
	SmallFishieriesDefaults,
} from "../../../../pages/activityBuilder/modules/smallFisheries/smallFisheriesTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSmallFisheries: builder.query<ModuleFull | null, number>({
			query: (activityId) => `small-fisheries/?activity=${activityId}`,
			transformResponse: (response: SmallFisheriesResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						response[0],
						includedPropList,
					) as SmallFisheriesPayload;
					const t2 = filterObjectProps(
						response[0],
						includedT2PropList,
					) as SmallFisheriesT2;
					convertIncomingPercentage(module, [
						"ice_preserved_catch_pc",
						"refrigerant_pc",
					]);
					return {
						module: module,
						tiertwo: t2,
					} as ModuleFull;
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "SmallFisheries", id: activityId }
			],
		}),
		updateSmallFisheries: builder.mutation<
			SmallFisheriesResponse,
			UpdateSmallFisheriesPayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module };
				const lucId = data.module.land_use_change;
				//converting back the percentages
				convertOutgoingPercentage(module, [
					"ice_preserved_catch_pc",
					"refrigerant_pc",
				]);
				const payload = {
					...module,
					...data.tiertwo,
				} as SmallFisheriesResponse;

				const { id: moduleId, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };
				convertEmptyStringsToNull(body)
				return { url: `small-fisheries/${moduleId}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { activityId }) => [
				{ type: "SmallFisheries", id: activityId },
				{ type: "ModulesStatuses", id: activityId },
				{ type: "ModuleResults", id: results?.id ? `small-fisheries-${results?.id}` : undefined },
				{ type: "ModuleHistory", id: results?.id ? `small-fisheries-${results?.id}` : undefined },
				{ type: "SmallFisheriesDefaults", id: results?.id ? `small-fisheries-${results?.id}` : undefined },
			],
		}),
		getSmallFisheriesDefaults: builder.query<SmallFishieriesDefaults, number>({
			query: (uniqueId) => `small-fisheries/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [
				{ type: "SmallFisheriesDefaults", id: `small-fisheries-${uniqueId}` },
			],
		}),
	}),
});

export const { useGetSmallFisheriesQuery, useUpdateSmallFisheriesMutation, useGetSmallFisheriesDefaultsQuery } =
	moduleApiSlice;
