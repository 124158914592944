const Spinner = ({
	size,
	padding,
	isBlack = false,
}: { size: number; padding?: string | number, isBlack?: boolean }) => {
	return (
		<div style={{ width: size, height: size, padding }}>
			<span className={`loader ${isBlack ? "black" : ""}`} />
		</div>
	);
};

export default Spinner;
