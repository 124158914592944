import { useEnergyContext } from '../../../../contexts/EnergyProvider'
import { capitalize } from '../../../../utils/helperFunctions'
import ChangesChildAction from './ChangesChildAction'

const EnergyChanges = () => {
  const context = useEnergyContext()
  return (
    <>
      {
        context?.electricityIds && (
          context.electricityIds.map((electricityId) => <EnergyChangeBlock key={electricityId} itemId={electricityId} energyType='electricities' />)
        )
      }
      {
        context?.fuelsIds && (
          context.fuelsIds.map((fuelId) => <EnergyChangeBlock key={fuelId} itemId={fuelId} energyType='fuels' />)
        )
      }
    </>
  )
}


const EnergyChangeBlock = ({ itemId, energyType }: { itemId: number, energyType: 'electricities' | 'fuels' }) => {
  return <ChangesChildAction itemId={itemId} path={energyType} title={capitalize(energyType) as string} />
}

export default EnergyChanges