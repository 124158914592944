import React, { useState } from 'react'

interface PaginationProps {
    totalItems: number;
    itemsPerPage?: number;
    onPageChange?: (page: number) => void;
    onNext?: () => void;
    onPrevious?: () => void;
    onPageClick?: (page: number) => void;
}
const usePages = ({
    totalItems, 
    itemsPerPage = 10, 
    onPageChange, 
    onNext, 
    onPrevious,
    onPageClick,
}: PaginationProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
  
    const handleNext = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        if (onPageChange) onPageChange(currentPage + 1);
        if (onNext) onNext()
      }
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        if (onPageChange) onPageChange(currentPage - 1);
        if (onPrevious) onPrevious()
      }
    };
  
    const handlePageClick = (page: number) => {
      setCurrentPage(page);
      if (onPageChange) onPageChange(page);
      if (onPageClick && currentPage !== page ) onPageClick(page)
    };

    return { currentPage, totalPages, handleNext, handlePrevious, handlePageClick }
}

export default usePages