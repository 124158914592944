import { motion } from "framer-motion";
// import { Formik } from "formik";
import { useState } from "react";
import { rightModalVariants } from "./utils";

import Backdrop from "../modal/Backdrop";

const ShareProjectModal = ({ handleClose }: { handleClose: () => void }) => {
	return (
		<Backdrop onClick={handleClose}>
			<motion.div
				onClick={(e) => e.stopPropagation()}
				variants={rightModalVariants}
				className="right-side-modal align-items-start"
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<div className="w-100 d-flex f-column p-2">
					<h2
						className="ff-medium fs-18 text-new-gray pb-2"
						style={{ textAlign: "left" }}
					>
						Share Project
					</h2>
					<p className="text-black fs-12" style={{ lineHeight: "18px" }}>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur hic
						commodi, a, distinctio corporis dicta culpa velit itaque totam
						doloremque praesentium, unde dolorem ipsa? Aperiam aliquam quam
						veritatis quibusdam optio!
					</p>

					<h3 className="fs-18 ff-medium py-2" style={{ color: "#5D5555" }}>
						Share with users:
					</h3>
				</div>
			</motion.div>
		</Backdrop>
	);
};

export default ShareProjectModal;
