import type { TopbarStep } from "../types/interfaces";
export const initialProjectList: TopbarStep[] = [
	{
		id: 0,
		name: "project.project_description",
		path: "description",
	},
	{
		id: 1,
		name: "project.project_activities",
		path: "activities",
	},
	{
		id: 2,
		name: "project.project_results",
		path: "results",
	},
];
