import { Component, ReactNode } from 'react';
import NoMatch404 from '../pages/accessManagement/NoMatch404';

interface ErrorBoundaryProps {
  children: ReactNode; // Define children prop
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <NoMatch404 translationKey1="main.error_captured" translationKey2="main.error_occurred" />;
    }

    return this.props.children; // Render children if no error
  }
}

export default ErrorBoundary;
