import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	initialValues,
	RegisterFormType,
} from "../../../pages/accessManagement/register/registerUtils";
import { RootState } from "../../store";

const initialState: RegisterState = {
	form: initialValues,
};

const registerSlice = createSlice({
	name: "register",
	initialState: initialState,
	reducers: {
		setForm: (state, action: PayloadAction<Partial<RegisterFormType>>) => {
			state.form = { ...state.form, ...action.payload } as RegisterFormType;
		},
		clearForm: (state) => {
			state.form = null;
		},
	},
});

export const { setForm, clearForm } = registerSlice.actions;
export const selectCurrentForm = (state: RootState) => state.register;

export default registerSlice.reducer;

interface RegisterState {
	form?: RegisterFormType | null;
}
