import type { ReactNode, MutableRefObject, CSSProperties } from "react";
import { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function useOutsideClickSub(
	ref: MutableRefObject<HTMLDivElement | null>,
	callback: () => void,
) {
	useEffect(() => {
		function handleClickOutside({ target }: MouseEvent) {
			if (ref.current && !ref.current.contains(target as Node)) callback();
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, callback]);
}

function OutsideClickSub({
	children,
	callback,
	className,
	onMouseEnter,
	onMouseLeave,
	style
}: {
	children: ReactNode;
	callback: () => void;
	className?: string;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	style?: CSSProperties
}) {
	const wrapperRef = useRef(null);
	useOutsideClickSub(wrapperRef, callback);

	return (
		<div
			className={className}
			style={style}
			ref={wrapperRef}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{children}
		</div>
	);
}

OutsideClickSub.propTypes = {
	children: PropTypes.element.isRequired,
};

export default OutsideClickSub;
