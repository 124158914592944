import { useMemo } from "react";
import { TranslationKey } from "../../../types/modulesInterfaces";
import TranslatableText from "../../../components/translations/TranslatableText";
import Skeleton from "react-loading-skeleton";

interface Props {
	country?: string;
	climate?: string;
	moisture?: string;
	soilType?: string;
	implDuration?: number;
	capDuration?: number;
	activityDuration?: number | null;
	isLoading?: boolean;
}

const DescriptionBlock = ({
	country,
	climate,
	moisture,
	soilType,
	implDuration,
	capDuration,
	isLoading,
}: Props) => {
	const columnOneLabels: TranslationKey[] = [
		"main.country",
		"main.climate",
		"main.moisture",
		"main.soil_type",
	];
	const columnTwoLabels: TranslationKey[] = ["main.implementation_phase", "main.capitalization_phase"];

	const totalDuration = useMemo(() => {
		return (implDuration ?? 0) + (capDuration ?? 0);
	}, [implDuration, capDuration]);

	return (
		<>
			<div
				style={{ gridColumn: 1, gridRow: 1 }}
				className="d-flex f-column justify-content-around"
			>
				{columnOneLabels.map((column) => (
					<div className="d-flex" key={column}>
						<TranslatableText key={column} translationKey={column} />:
					</div>
				))}
			</div>
			<div
				className="d-flex f-column pe-2 justify-content-around"
				style={{ gridColumn: 2, gridRow: 1 }}
			>	{
					isLoading ?
						<>
							<Skeleton width={100} height={14} />
							<Skeleton width={100} height={14} />
							<Skeleton width={100} height={14} />
							<Skeleton width={100} height={14} />
						</> :
						<>
							<span className="capitalize ff-bold">{country ?? "N/A"}</span>
							<span className="capitalize ff-bold">{climate ?? "N/A"}</span>
							<span className="capitalize ff-bold">{moisture ?? "N/A"}</span>
							<span className="capitalize ff-bold">{soilType ?? "N/A"}</span>
						</>
				}
			</div>
			<div
				className="d-flex f-column justify-content-between"
				style={{ gridColumn: 3, gridRow: 1 }}
			>
				<div className="d-flex f-column pe-1">
					{columnTwoLabels.map((column) => (
						<div className="d-flex" key={column}>
							<TranslatableText translationKey={column} /> :
						</div>
					))}
				</div>
				<div className="d-flex">
					<TranslatableText translationKey="main.total_duration_of_accounting" /> :
				</div>
			</div>

			<div
				className="d-flex f-column justify-content-between"
				style={{ gridColumn: 4, gridRow: 1 }}
			>
				<div className="d-flex f-column pe-1">
					{
						isLoading ?
							<>
								<Skeleton width={40} height={14} />
								<Skeleton width={40} height={14} />
							</> :
							<>
								<span className="capitalize ff-bold"></span>
								<span className="capitalize ff-bold">{implDuration}</span>
								<span className="capitalize ff-bold">{capDuration}</span>
							</>
					}
					<span className="capitalize ff-bold"></span>
				</div>
				{
					isLoading ?
						<Skeleton width={40} height={14} /> :
						<span className="capitalize ff-bold">{totalDuration}</span>
				}
			</div>
		</>
	);
};

export default DescriptionBlock;
