import { ChangeBlock } from '../../ChangesAction'
import { ForestManagementPayload } from '../../../../../pages/activityBuilder/modules/forestManagement/forestManagementTypes'
import useForestTypesValues from './useForestTypesValues'

export interface ForestParentChanges {
    field: keyof ForestManagementPayload
    old: string | null
    new: string | null
}

const ForestChangeBlock = ({ change }: { change: ForestParentChanges }) => {
  const { formattedNew, formattedOld } = useForestTypesValues({ change })
  return (
    <ChangeBlock change={{ ...change, new: formattedNew, old: formattedOld}} key={change?.field} />
  )
}

export default ForestChangeBlock