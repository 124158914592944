import React, { useCallback, useEffect } from 'react'
import { useAppSelector } from '../../../app/hooks';
import { selectCurrentToken } from '../../../app/features/auth/authSlice';
import { Project } from '../../../types/interfaces';

const useDownloadExcel = ({ project }: { project: Project | null | undefined }) => {
  const user = useAppSelector(selectCurrentToken)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

  const handleCancel = () => {
    setIsOpen(false)
  }

  const downloadExcel = useCallback(async () => {
    if (!project?.id) return
    setIsLoading(true)
    try {
      const response = await fetch(`https://fao-exact.ew.r.appspot.com/api/projects/${project?.id ?? 0}/report/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user}`,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
      });
      setIsLoading(false)

      if (!response.ok) {
        console.log("response not ok")
        const body = await response.json();
        if (body?.details) {
          setErrorMsg(body.details)
        } else {
          setErrorMsg("An error occurred while downloading the report")
        }
        setIsOpen(true);
        return
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers.get('Content-Disposition');
      let fileName = `${project?.name}-results-report`;

      if (contentDisposition && contentDisposition.includes('filename=')) {
        fileName = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
      }
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the report:", error);
      setErrorMsg("An unexpected error occurred while downloading the report");
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  }, [project?.id, project?.name, user])

  return {
    isLoading,
    downloadExcel,
    handleCancel,
    isOpen,
    errorMsg
  }
}

export default useDownloadExcel
