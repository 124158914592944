import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import type {
    DropdownInputGroup,
    StartWithWithoutGeneric,
    ClassicInputGroup,
    StartWithWithoutTierTwo,
    DropdownInputGroupTierTwo,
    ClassicInputGroupTierTwo,
    Submodule,
    LucDropdownInputGroup,
} from "../../../../types/modulesInterfaces";
import type { IrrigationPayload, IrrigationT2Payload, ModuleFull } from "./irrigationTypes";
import { FieldType } from "../../../../types/modulesInterfaces";
import { validatePercentage, validatePositiveNumber } from "../moduleUtils";
import * as Yup from "yup";

export const includedPropList = [
    "id",
    "note",
    "activity",
];
export const includedT2PropList = [
    "soc_t2_start",
    "soc_t2_w",
    "soc_t2_wo",
]


export const parentInitialValues: IrrigationPayload = {
    id: null,
    note: null,
    activity: null,
    start_year: null,
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    status: null,
}
export const T2InitialValues: IrrigationT2Payload = {
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null
}
export const validationSchema = Yup.object({
    irrigations: Yup.array().of(
        Yup.object().shape({
            irrigation_system_type: validatePositiveNumber.required('validations.irrigation_system_required'),
            ha_start: validatePositiveNumber.required('validations.hectares_required'),
            ha_w: validatePositiveNumber.required('validations.hectares_required'),
            ha_wo: validatePositiveNumber.required('validations.hectares_required'),
        })
    ),
    irrigationPhases: Yup.array().of(
        Yup.object().shape({
            irrigation_system_type: validatePositiveNumber.required('validations.irrigation_system_required'),
            fuel_type: validatePositiveNumber.required('validations.fuel_type_required'),
            ha_start: validatePositiveNumber.required('validations.hectares_required'),
            ha_w: validatePositiveNumber.required('validations.hectares_required'),
            ha_wo: validatePositiveNumber.required('validations.hectares_required'),
            gross_irrigation_water_start: validatePositiveNumber.required('validations.gross_irrigation_water_required'),
            gross_irrigation_water_w: validatePositiveNumber.required('validations.gross_irrigation_water_required'),
            gross_irrigation_water_wo: validatePositiveNumber.required('validations.gross_irrigation_water_required'),
            transmission_loss_t2_start: validatePercentage,
            transmission_loss_t2_w: validatePercentage,
            transmission_loss_t2_wo: validatePercentage,
            pumping_efficiency_t2_start: validatePercentage,
            pumping_efficiency_t2_w: validatePercentage,
            pumping_efficiency_t2_wo: validatePercentage,
        })
    ),
});

export const initialValues: ModuleFull = {
    irrigations: [],
    irrigationPhases: [],
    parent: parentInitialValues,
    tiertwo: T2InitialValues
};

const irrigationSystemTypes = (module_types: number) => getOptions("irrigationSystemTypes", { module_types });
const fuelTypes = () => getOptions("fuelTypes");
const countries = () => getOptions("country");

const getIrrigationSystemSchema: () => Submodule = () => {
    const systemType: LucDropdownInputGroup = {
        type: FieldType.SELECT_LUC,
        label: "module.type_of_irrigation_required",
        inputName: (index: number) => `irrigations.${index}.irrigation_system_type`,
        dropdownOptions: (module_types: number) => irrigationSystemTypes(module_types),
    };
    const systemHectares: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.hectares_required",
        unit: "[ha]",
        inputName: (index: number) => `irrigations.${index}.ha`,
    };

    return {
        name: "module.new_irrigation_system",
        inputGroups: [systemType, systemHectares],
    };
};

const getOperationPhaseSchema: () => Submodule = () => {
    const operationType: LucDropdownInputGroup = {
        type: FieldType.SELECT_LUC,
        label: "module.type_of_irrigation_required",
        inputName: (index: number) => `irrigationPhases.${index}.irrigation_system_type`,
        dropdownOptions: (module_types: number) => irrigationSystemTypes(module_types),
    };
    const sourceOfEnergy: DropdownInputGroup = {
        type: FieldType.SELECT,
        label: "module.source_of_energy_required",
        inputName: (index: number) => `irrigationPhases.${index}.fuel_type`,
        dropdownOptions: fuelTypes,
    };
    const depthOfWell: ClassicInputGroup = {
        type: FieldType.CLASSIC,
        label: "module.depth_of_well",
        inputName: (index: number) => `irrigationPhases.${index}.well_depth`,
        unit: "[m]",
        inputType: "number",
    };
    const operationHectares: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.hectares_required",
        unit: "[ha]",
        inputName: (index: number) => `irrigationPhases.${index}.ha`,
    };
    const grossIrrigationWater: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.gross_irrigation_water_required",
        unit: "[mm/yr]",
        inputName: (index: number) => `irrigationPhases.${index}.gross_irrigation_water`,
    };

    return {
        name: "module.operation_phase_of_irrigation",
        inputGroups: [operationType, sourceOfEnergy, depthOfWell, operationHectares, grossIrrigationWater],
    };
};

const getT2IrrigationSchema: () => Submodule = () => {
    const efNewIrrigation: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        label: "module.ef_new_irrigation",
        inputName: (index: number) => `irrigations.${index}.ef_t2`,
        unit: "[kg CO2-e/ha]",
        links: ["Type of Irrigation"],
        startProps: { inputType: "number", defaultValue: 0 },
        withoutProps: { inputType: "number", defaultValue: 0 },
        withProps: { inputType: "number", defaultValue: 0 },
    };

    return {
        name: "module.new_irrigation_system",
        inputName: (index: number) => `irrigations.${index}.ef_t2`,
        inputGroups: [efNewIrrigation],
    };
};

const getT2OperationPhaseSubmodules: () => Submodule = () => {
    const powerCountryOfOrigin: DropdownInputGroupTierTwo = {
        type: FieldType.SELECT_T2,
        label: "module.power_country_of_origin",
        inputName: (index: number) => `irrigationPhases.${index}.power_origin_country_t2`,
        dropdownOptions: countries,
        defaultValue: "value",
        unit: "",
        links: [],
    };
    const efCo2Irrigation: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        label: "module.ef_of_fuel_used_co2",
        inputName: (index: number) => `irrigationPhases.${index}.ef_co2_t2`,
        unit: "[t CO2-e/m3]",
        links: ["Type of Irrigation"],
        startProps: { inputType: "number", defaultValue: 0 },
        withoutProps: { inputType: "number", defaultValue: 0 },
        withProps: { inputType: "number", defaultValue: 0 },
    };
    const efCh4Irrigation: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        label: "module.ef_of_fuel_used_ch4",
        inputName: (index: number) => `irrigationPhases.${index}.ef_ch4_t2`,
        unit: "[t CH4-e/m3]",
        links: ["Type of Irrigation"],
        startProps: { inputType: "number", defaultValue: 0 },
        withoutProps: { inputType: "number", defaultValue: 0 },
        withProps: { inputType: "number", defaultValue: 0 },
    };
    const efN2oIrrigation: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        label: "module.ef_of_fuel_used_n2o",
        inputName: (index: number) => `irrigationPhases.${index}.ef_n2o_t2`,
        unit: "[t N2O-e/m3]",
        links: ["Source of Energy"],
        startProps: { inputType: "number", defaultValue: 0 },
        withoutProps: { inputType: "number", defaultValue: 0 },
        withProps: { inputType: "number", defaultValue: 0 },
    };
    const transmissionLoss: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        inputName: (index: number) => `irrigationPhases.${index}.transmission_loss_t2`,
        label: "module.transmission_loss",
        unit: "[%]",
        links: [],
        startProps: { inputType: "number", defaultValue: 0 },
        withoutProps: { inputType: "number", defaultValue: 0 },
        withProps: { inputType: "number", defaultValue: 0 },
    };
    const averagePressure: ClassicInputGroupTierTwo = {
        type: FieldType.CLASSIC_T2,
        inputName: (index: number) => `irrigationPhases.${index}.average_pressure_t2`,
        inputType: "number",
        label: "module.average_pressure",
        unit: "[bar]",
        links: ["Type of Irrigation"],
        defaultValue: "value",
    };
    const totalDynamicHead: ClassicInputGroupTierTwo = {
        type: FieldType.CLASSIC_T2,
        inputName: (index: number) => `irrigationPhases.${index}.total_dynamic_head_t2`,
        inputType: "number",
        label: "module.total_dynamic_head",
        unit: "[m/head]",
        links: ["Type of Irrigation"],
        defaultValue: "value",
    };
    const pumpingEfficiency: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        inputName: (index: number) => `irrigationPhases.${index}.pumping_efficiency_t2`,
        label: "module.pumping_efficiency",
        unit: "[%]",
        links: [],
        startProps: { inputType: "number", defaultValue: 0 },
        withoutProps: { inputType: "number", defaultValue: 0 },
        withProps: { inputType: "number", defaultValue: 0 },
    };

    return {
        name: "module.operation_phase_of_irrigation",
        inputGroups: [
            powerCountryOfOrigin,
            efCo2Irrigation,
            efCh4Irrigation,
            efN2oIrrigation,
            transmissionLoss,
            averagePressure,
            totalDynamicHead,
            pumpingEfficiency,
        ],
    };
};


/* T2 */
export const getSchemas = () => {
    const irrigationSchema = getIrrigationSystemSchema();
    const operationPhaseSchema = getOperationPhaseSchema();
    const t2IrrigationSchema = getT2IrrigationSchema();
    const t2OperationPhaseSchema = getT2OperationPhaseSubmodules();

    return {
        irrigationSchema,
        operationPhaseSchema,
        t2IrrigationSchema,
        t2OperationPhaseSchema,
    };
};
