import FormSubmodule from "../../../components/formSubmodule/FormSubmodule";
import GenericInputGroup from "../../../components/input/inputGroup/GenericInputGroup";
import ModuleButton from "../../../components/button/ModuleButton";

import * as utils from "./projectDescriptionUtils";
import {
	useNavigate,
	useParams,
	unstable_useBlocker as useBlocker,
} from "react-router-dom";
import {
	useCreateProjectMutation,
	useGetProjectByIdQuery,
	useGetSOCrefQuery,
	useUpdateProjectMutation,
} from "../../../app/features/project/projectApiSlice";
import type { FormikHelpers } from "formik";
import { Formik, useFormikContext, Form } from "formik";
import { ClimateOption, FieldType, Option, type DropdownInputGroup, type InputGroup } from "../../../types/modulesInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useCreateExecutingAgencyMutation,
	useCreateFundingAgencyMutation,
	useGetCountriesQuery,
	useGetExecutingAgenciesQuery,
	useGetFundingAgenciesQuery,
	useGetGWPSourcesQuery,
} from "../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import type { ProjectPayload } from "../../../app/features/project/projectTypes";
import type { Project } from "../../../types/interfaces";
import { useAppDispatch, useClimateAndMoistureFormHandlers , usePermissions } from "../../../app/hooks";
import { setProject } from "../../../app/features/project/projectSlice";
import { preventFormSubmit, isNumeric } from "../../activityBuilder/modules/inputs/utils";
import ConfirmNavigation from "../../../components/confirmNavigation/ConfirmNavigation";
import useErrorMessage from "../../../utils/useErrorMessage";
import { TranslatedFormSubmodule } from "../../../components/formSubmodule/TranslatedFormSubmodule";
import TranslatableText from "../../../components/translations/TranslatableText";
import { setIsFetchingData } from "../../../app/features/builder/builderSlice";
import { getCapitalizationYears } from "../../../utils/projectUtils";
import { BiSave } from "react-icons/bi";

const ProjectDescriptionContainer = () => {
	const { projectId } = useParams<string>();
	const [
		createProject,
		{ isLoading: isCreateLoading, isError: isCreateError, error: createError },
	] = useCreateProjectMutation();
	const [
		updateProject,
		{ isLoading: isUpdateLoading, isError: isUpdateError, error: updateError },
	] = useUpdateProjectMutation();
	const {
		data: project,
		isError,
		error,
		isLoading: isGetProjectLoading
	} = useGetProjectByIdQuery(projectId ?? "", {
		skip: projectId === "new" || !projectId || !isNumeric(projectId)
	});

	const navigate = useNavigate();
	const [localProject, setLocalProject] = useState<Project | null>();
	const [formInitValues, setFormInitValues] = useState<utils.ProjectForm>(
		utils.initialValues,
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isNumeric(projectId) && project) {
			dispatch(setProject(project));
			setLocalProject(project);
		}
	}, [project, projectId, dispatch]);

	const isExistingProject = useMemo<boolean>(
		() => !!projectId && isNumeric(projectId) && !!localProject,
		[projectId, localProject],
	);

	useEffect(() => {
		dispatch(setIsFetchingData(isGetProjectLoading));
		return () => {
			dispatch(setIsFetchingData(false));
		};
	}, [dispatch, isGetProjectLoading]);

	useEffect(() => {
		isExistingProject
			? setFormInitValues({
				name: localProject?.name ?? "",
				code: localProject?.code ?? "",
				cost: localProject?.cost ?? null,
				funding_agency: localProject?.funding_agency
					? localProject?.funding_agency
					: null,
				executing_agency: localProject?.executing_agency
					? localProject?.executing_agency
					: null,
				country: localProject?.country?.id ?? null,
				climate: localProject?.climate?.id ?? null,
				moisture: localProject?.moisture?.id ?? null,
				soil_type: localProject?.soil_type?.id ?? null,
				implementation_years: localProject?.implementation_years ?? null,
				start_year_of_activities: localProject?.start_year_of_activities ?? null,
				last_year_of_accounting: localProject?.last_year_of_accounting ?? null,
				// capitalization_years: localProject?.capitalization_years ?? null,
				total_duration_of_accounting: null,
				soc_ref_t2: localProject?.soc_ref_t2 ?? null,
				gw_potential: { source: localProject?.gw_potential?.id ?? -1 },
				status: localProject?.status?.id ?? null,
			})
			: setFormInitValues(utils.initialValues);
	}, [isExistingProject, localProject]);

	const { errorMsg } = useErrorMessage({ isError, error });
	const { errorMsg: createErrorMsg } = useErrorMessage({
		isError: isCreateError,
		error: createError,
	});
	const { errorMsg: updateErrorMsg } = useErrorMessage({
		isError: isUpdateError,
		error: updateError,
	});

	const handleSubmit = async (
		values: utils.ProjectForm,
		{ resetForm }: FormikHelpers<utils.ProjectForm>,
	) => {
		/* -1 are just for typescript, validation is done before handleSubmit is called */
		const payload: ProjectPayload = {
			name: values.name,
			code: values.code,
			cost: values.cost,
			funding_agency: values.funding_agency, //IN REALTA' SONO STRING AD ORA
			executing_agency: values.executing_agency,
			country: values.country ?? -1,
			climate: values.climate ?? -1,
			moisture: values.moisture ?? -1,
			soil_type: values.soil_type ?? -1,
			implementation_years: values.implementation_years ?? -1,
			start_year_of_activities: values.start_year_of_activities ?? -1,
			last_year_of_accounting: values.last_year_of_accounting ?? -1,
			soc_ref_t2: values.soc_ref_t2,
			gw_potential: values.gw_potential.source,
			status: values.status,
		};

		isExistingProject
			? updateProject({
				id: Number(projectId),
				body: payload,
			})
				.unwrap()
				.then((project) => {
					resetForm({ values });
					dispatch(setProject(project));
					setTimeout(
						() => navigate(`/project/${project.id}/activities`),
						50,
					);
				})
				.catch((err) => console.error(err))
			: createProject(payload)
				.unwrap()
				.then((project) => {
					resetForm({ values });
					dispatch(setProject(project));
					setTimeout(
						() => navigate(`/project/${project.id}/activities`),
						50,
					);
				})
				.catch((err) => console.error(err));
	};

	return errorMsg || createErrorMsg || updateErrorMsg ? (
		<section>
			<div className="module-group">
				<span className="error fs-13 pb-2">
					{errorMsg
						? errorMsg
						: createErrorMsg
							? createErrorMsg
							: updateErrorMsg
								? updateErrorMsg
								: `An error occurred while fetching for project ${projectId}`}
				</span>
			</div>
		</section>
	) : (
		<Formik
			initialValues={formInitValues}
			validationSchema={utils.validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<section>
				<Form onKeyDown={preventFormSubmit}>
					<div className="module-group">
						<div className="pos-relative mb-2 pb-1">
							<h2 className="fs-14 ff-medium-ext module-header w-fit">
								<TranslatableText translationKey="project.project_description" />
							</h2>
							<div className="module-number header-number ff-light-ext">
								{localProject?.id ? localProject?.id : "0.0"}
							</div>
						</div>

						<DescriptionSubmodule />
						<SiteAndDurationSubmodule isExistingProject={isExistingProject} />
						<GlobalWarmingSubmodule />
						<ReferenceSoilSubmodule />
					</div>

					<NavBlocker />
					<FormFooter
						isLoading={isCreateLoading || isUpdateLoading || isGetProjectLoading}
						isExistingProject={isExistingProject}
					/>
				</Form>
			</section>
		</Formik>
	);
};

const DescriptionSubmodule = () => {
	const { setErrors, errors } = useFormikContext();

	const { data: fundingAgencies } = useGetFundingAgenciesQuery();
	const { data: executingAgencies } = useGetExecutingAgenciesQuery();

	const [createExec] = useCreateExecutingAgencyMutation();
	const [createFund] = useCreateFundingAgencyMutation();

	const handleCreateExec = async (newOption: string) =>
		await createExec(newOption)
			.unwrap()
			.then((option) => option)
			.catch((err) => {
				setErrors({
					...errors,
					test_executing_agency: "An error occurred while creating the agency",
				});
			});

	const handleCreateFund = async (newOption: string) =>
		await createFund(newOption)
			.unwrap()
			.then((option) => option)
			.catch((err) => {
				setErrors({
					...errors,
					test_funding_agency: "An error occurred while creating the agency",
				});
			});

	return (
		<TranslatedFormSubmodule submoduleName="project.project_description">
			<div className="pt-1" />
			<PaddedGenericInputGroup inputGroup={utils.projectTitle} />
			<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
				<div style={{ gridColumn: 1 }} className="pe-2">
					<PaddedGenericInputGroup inputGroup={utils.projectCode} />
				</div>
				<div style={{ gridColumn: 2 }}>
					<PaddedGenericInputGroup inputGroup={utils.projectCost} />
				</div>
			</div>
			<PaddedGenericInputGroup
				inputGroup={
					{
						...utils.fundingAgency,
						onCreate: handleCreateFund,
						dropdownOptions: fundingAgencies,
					} as InputGroup
				}

			/>
			<PaddedGenericInputGroup
				inputGroup={
					{
						...utils.executingAgency,
						onCreate: handleCreateExec,
						dropdownOptions: executingAgencies,
					} as InputGroup
				}

			/>
			<PaddedGenericInputGroup inputGroup={utils.status} />
		</TranslatedFormSubmodule>
	);
};

const SiteAndDurationSubmodule = ({
	isExistingProject,
}: {
	isExistingProject?: boolean;
}) => {
	const { values, setFieldValue } = useFormikContext<utils.ProjectForm>();
	const { data: countries } = useGetCountriesQuery();
	const {handleClimateInputChange,getMoistureOptions,climateTypes} = useClimateAndMoistureFormHandlers();

	const moistureInput: DropdownInputGroup = useMemo(() => ({
		type: FieldType.SELECT,
		inputName: "moisture",
		label: "project.moisture",
		dropdownOptions: getMoistureOptions(values.climate),
	}), [values.climate, getMoistureOptions]);

	const climateInput: DropdownInputGroup = {
		type: FieldType.SELECT,
		inputName: "climate",
		label: "project.climate",
		dropdownOptions: climateTypes ?? [],
		onChange: (e: any) => {
			handleClimateInputChange(e, values.moisture, "moisture");
		}
	}

	useEffect(() => {
		if (values.start_year_of_activities && values.last_year_of_accounting && values.implementation_years) {
			setFieldValue("capitalization_years", getCapitalizationYears(values.start_year_of_activities, values.implementation_years, values.last_year_of_accounting));
		}
	}, [setFieldValue, values.implementation_years, values.last_year_of_accounting, values.start_year_of_activities]);

	return (
		<TranslatedFormSubmodule submoduleName="project.project_site_and_duration">
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					gridTemplateRows: "repeat(8, auto)",
				}}
				className="pt-1"
			>
				<div style={{ gridColumn: "1 / span 2", gridRow: 2 }}>
					<PaddedGenericInputGroup
						inputGroup={
							countries
								? { ...utils.country, dropdownOptions: countries }
								: utils.country
						}
					/>
				</div>
				<div style={{ gridColumn: "1 / span 2", gridRow: 3 }}>
					<PaddedGenericInputGroup inputGroup={climateInput} />
				</div>
				<div style={{ gridColumn: "1 / span 2", gridRow: 4 }}>
					<PaddedGenericInputGroup inputGroup={moistureInput} />
				</div>
				<div style={{ gridColumn: "1 / span 3", gridRow: 5 }}>
					<PaddedGenericInputGroup inputGroup={utils.soilType} />
				</div>
				<div
					style={{ gridColumn: 1, gridRow: "6 / span 3" }}
					className="d-flex f-column pe-2 pt-1"
				>
					<span className="fs-13">Project Duration</span>
					<span className="fs-12 ff-light">[years]</span>
				</div>
				<div style={{ gridColumn: "2 / span 2", gridRow: 6 }}>
					<PaddedGenericInputGroup inputGroup={utils.startYearOfActivities} />
				</div>
				<div style={{ gridColumn: "2 / span 2", gridRow: 7 }}>
					<PaddedGenericInputGroup inputGroup={utils.implementationPhase} />
				</div>
				<div style={{ gridColumn: "2 / span 2", gridRow: 8 }}>
					<PaddedGenericInputGroup inputGroup={utils.lastYearOfAccounting} />
				</div>
				<div style={{ gridColumn: "2 / span 2", gridRow: 9 }}>
					<PaddedGenericInputGroup inputGroup={utils.capitalizationPhase} />
				</div>
			</div>
		</TranslatedFormSubmodule>
	);
};

const GlobalWarmingSubmodule = () => {
	const { values, setFieldValue } = useFormikContext<utils.ProjectForm>();
	const { data: gwpOptions } = useGetGWPSourcesQuery();

	useEffect(() => {
		if (values.gw_potential?.source && gwpOptions?.length) {
			const gwp = gwpOptions.find(
				(option) => option.id === values.gw_potential.source,
			);
			setFieldValue("gw_potential.co2", gwp?.co2);
			setFieldValue("gw_potential.ch4", gwp?.ch4);
			setFieldValue("gw_potential.n2o", gwp?.n2o);
		}
	}, [values.gw_potential?.source, gwpOptions, setFieldValue]);

	const isDisableGWPValues = useMemo(() => {
		const source = values.gw_potential?.source;
		const option = gwpOptions?.find((option) => option.id === source);
		const isUserDefinedOption = option?.name === "User Defined";
		return !isUserDefinedOption;
	}, [gwpOptions, values.gw_potential?.source]);

	return (
		<TranslatedFormSubmodule submoduleName="project.global_warming_potential">
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "2fr 4fr",
					gridTemplateRows: "repeat(4, auto)",
				}}
				className="pt-1"
			>
				<div style={{ gridColumn: "1 / span 2" }}>
					<PaddedGenericInputGroup inputGroup={utils.source} />
				</div>
				<div style={{ gridColumn: 2, gridRow: 2 }}>
					<PaddedGenericInputGroup inputGroup={{ ...utils.co2, disabled: isDisableGWPValues }} />
				</div>
				<div style={{ gridColumn: 2, gridRow: 3 }}>
					<PaddedGenericInputGroup inputGroup={{ ...utils.ch4, disabled: isDisableGWPValues }} />
				</div>
				<div style={{ gridColumn: 2, gridRow: 4 }}>
					<PaddedGenericInputGroup inputGroup={{ ...utils.n2o, disabled: isDisableGWPValues }} />
				</div>
			</div>
		</TranslatedFormSubmodule>
	);
};

const ReferenceSoilSubmodule = () => {
	const [climate, setClimate] = useState<number | null>(null);
	const [moisture, setMoisture] = useState<number | null>(null);
	const [soilType, setSoilType] = useState<number | null>(null);
	const { values, setFieldValue } = useFormikContext<utils.ProjectForm>();
	const { data: socRef } = useGetSOCrefQuery(
		{ climate, moisture, soil_type: soilType },
		{
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		setClimate(values.climate ? values.climate : null);
	}, [values.climate]);
	useEffect(() => {
		setMoisture(values.moisture ? values.moisture : null);
	}, [values.moisture]);
	useEffect(() => {
		setSoilType(values.soil_type ? values.soil_type : null);
	}, [values.soil_type]);

	useEffect(() => {
		setFieldValue("soc_ref", socRef?.value);
	}, [socRef, setFieldValue]);

	return (
		<TranslatedFormSubmodule submoduleName="project.reference_soil_organic_carbon_stock">
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "2fr 4fr",
					gridTemplateRows: "repeat(4, auto)",
				}}
				className="pt-1"
			>
				<div
					style={{ gridColumn: 1, gridRow: 1 }}
					className="d-flex f-column pe-2 pt-1"
				>
					<span className="fs-13">SOCref</span>
					<span className="fs-12 ff-light">[tC/ha]</span>
				</div>
				<div
					style={{ gridColumn: 1, gridRow: 2 }}
					className="d-flex f-column pe-2 pt-1"
				>
					<span className="fs-13">SOCref Tier 2</span>
					<span className="fs-12 ff-light">[tC/ha]</span>
				</div>
				<div style={{ gridColumn: 2, gridRow: 1 }}>
					<PaddedGenericInputGroup inputGroup={utils.socref} />
				</div>
				<div style={{ gridColumn: 2, gridRow: 2 }}>
					<PaddedGenericInputGroup inputGroup={utils.socreft2} />
				</div>
			</div>
		</TranslatedFormSubmodule>
	);
};

/* const LaunchBIntactButton = ({ handleClick }: { handleClick: () => void }) => {
	return (
		<Button
			classes="btn-new-project d-flex align-items-center"
			onClick={handleClick}
		>
			<div className="h-100 d-flex align-items-center btn-arrow bg-quat-de p-1">
				<MdOpenInNew size={20} color="var(--white)" />
			</div>
			<span className="h-100 fs-11 px-2 d-flex align-items-center ff-medium bg-white">
				Launch B-Intact
			</span>
		</Button>
	);
}; */

const NavBlocker = () => {
	const { dirty, touched } = useFormikContext();

	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);
	const blocker = useBlocker(shouldBlock);

	return <ConfirmNavigation blocker={blocker} />;
};

const FormFooter = ({
	isLoading,
	isExistingProject,
}: {
	isLoading: boolean;
	isExistingProject?: boolean;
}) => {
	const { dirty } = useFormikContext<utils.ProjectForm>();
	const { canMutateProject } = usePermissions()

	return (
		<footer className="module-group-footer">
			<div className="module-group">
				<ModuleButton icon={BiSave} isLoading={isLoading} disabled={!dirty || !canMutateProject} labelKey={isExistingProject ? "project.update_project" : "project.create_project"} />
			</div>
		</footer>
	);
};

const PaddedGenericInputGroup = ({
	inputGroup,
}: {
	inputGroup: InputGroup;
}) => {
	const { canMutateProject } = usePermissions();
	const isDisabled = !canMutateProject || inputGroup.disabled;

	return (
		<div className="py-1">
			<GenericInputGroup inputGroup={{ ...inputGroup, disabled: isDisabled }} />
		</div>
	);
};

export default ProjectDescriptionContainer;
