import React, { useCallback, useEffect, useRef } from 'react'

const usePreventCloseOnDragOutside = ({isError, handleClose}: {isError: boolean, handleClose: () => void}) => {
	const inputRef = useRef< HTMLTextAreaElement | null >(null);
	const isMouseDownInside = useRef(false);
	const handleMouseDownInside = () =>{
		isMouseDownInside.current = true
	};
	const handleMouseUp = useCallback((e:any) => {
		if (!isMouseDownInside.current) {
		  handleClose();
		}
		isMouseDownInside.current = false
	  }, [handleClose]);
	
	  useEffect(() => {
		document.addEventListener('mouseup', handleMouseUp);
		return () => {
		  document.removeEventListener('mouseup', handleMouseUp);
		};
	  }, [handleMouseUp]);

	if (isError) {
		isMouseDownInside.current = true
	}

    return { inputRef, handleMouseDownInside }
}

export default usePreventCloseOnDragOutside