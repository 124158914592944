import Sidebar from "../../components/sidebar/Sidebar";
import BuilderTopbar from "../../components/topbar/BuilderTopbar";
import BuilderMain from "../../components/main/BuilderMain";
import BuilderActions from "../../components/builderActions/BuilderActions";
import BuilderActionsTabs from "../../components/builderActions/BuilderActionsTabs";

import { motion } from "framer-motion";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import type { NavigateFunction } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentBuilder } from "../../app/features/builder/builderSlice";
import { Actions } from "../../types/interfaces";
import { useEffect } from "react";
import { useHandleProjectState } from "./useHandleProjectState";

const ProjectContainer = () => {
	const navigate: NavigateFunction = useNavigate();
	const location = useLocation();

	const { project, projectId, isLoading } = useHandleProjectState();

	const {
		activeAction: activeTabId,
		tabsOpen,
		projectList,
	} = useAppSelector(selectCurrentBuilder);


	const actionsGridClass = !tabsOpen
		? "actions-close"
		: [
			Actions.Comments,
			Actions.Help,
			Actions.Changes,
			Actions.Definitions,
		].includes(activeTabId)
			? "actions-small"
			: "";

	useEffect(() => {
		if (location) {
			/* check if you are in the correct space */
			const elements = location.pathname.split("/");
			if (elements[1] === "project" && (!elements[3] || elements[3] === "")) {
				elements[2] === "new"
					? navigate("/project/new/description", { replace: true })
					: navigate(`/project/${projectId}/activities`, { replace: true });
			}
		}
	}, [location, navigate, projectId]);

	return (
		<motion.div className={`builder-container ${actionsGridClass}`}>
			<Sidebar />
			<BuilderTopbar
				projectLabel={project ? project.name : "New Project"}
				isLoading={isLoading}
				stepsList={projectList}
			/>
			<BuilderMain className="bt-1-common-dark">
				<Outlet />
			</BuilderMain>
			{/* TODO, check if you are in results -> we will just have the map without tabs */}
			<BuilderActionsTabs />
			<BuilderActions />
		</motion.div>
	);
};

export default ProjectContainer;
