import { useMemo } from "react";

const MapIframe = () => {
  const isProd = process.env.REACT_ENV === 'PROD';
  // const url = useMemo(() => 
  //   isProd ? "https://data.apps.fao.org/ex-act/?lang=en" : "https://data.apps.fao.org/ex-act/?lang=en",
  //   [isProd]
  // );
  const url = "https://data.apps.fao.org/ex-act/?lang=en";

  return (
    <iframe
      src={url}
      style={{
        width: '100%',
        height: '700px',
        border: 'none',
      }}
      title="FAO Ex-Act Map"
      allowFullScreen
    />
  );
}

export default MapIframe;
