import { createSlice } from "@reduxjs/toolkit";
import { initialProjectList } from "../../../utils/builderUtils";
import { Actions } from "../../../types/interfaces";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { TopbarStep } from "../../../types/interfaces";
import type { RootState } from "../../store";
import type { Builder } from "./builderTypes";
import type { CommentGroup, Note } from "../../../types/modulesInterfaces";

const initialState: Builder = {
 tabsOpen: true,
 activeAction: Actions.LiveUpdates,
 actionsList: [],
 moduleList: [],
 projectList: initialProjectList,
 isFetchingData: false,
 currentModuleNote: null,
 newCurrentModuleNotes: null,
 currentModuleThreads: [],
 activeThread: null,
}

export const BuilderSlice = createSlice({
 name: "builder",
 initialState,
 reducers: {
  setBuilder: (state, action: PayloadAction<Builder>) => {
   const { projectList, tabsOpen } = action.payload;
   state.projectList = projectList
   state.tabsOpen = tabsOpen
  },
  toggleTabsOpen: (state, action: PayloadAction<undefined | boolean>) => { state.tabsOpen = action.payload !== undefined ? action.payload : !state.tabsOpen },
  setModuleList: (state, action: PayloadAction<TopbarStep[]>) => { state.moduleList = action.payload },
  setActionList: (state, action: PayloadAction<Actions[]>) => { state.actionsList = action.payload },
  setActiveAction: (state, action: PayloadAction<Actions>) => { state.activeAction = action.payload },
  setIsFetchingData: (state, action: PayloadAction<boolean>) => { state.isFetchingData = action.payload },
  setCurrentModuleNotes: (state, action: PayloadAction<Note | null>) => { state.currentModuleNote = action.payload },
  setNewCurrentModuleNotes: (state, action: PayloadAction<string | null>) => { state.newCurrentModuleNotes = action.payload },
  setCurrentModuleThreads: (state, action: PayloadAction<number[]>) => { state.currentModuleThreads = action.payload },
  setActiveThread: (state, action: PayloadAction<number | null>) => { state.activeThread = action.payload },
 }
})

export const { setBuilder, setActiveAction, toggleTabsOpen, setModuleList, setIsFetchingData, setActionList, setCurrentModuleNotes, setNewCurrentModuleNotes, setActiveThread, setCurrentModuleThreads } = BuilderSlice.actions
export const selectCurrentBuilder = (state: RootState) => state.builder;
export const selectCurrentActions = (state: RootState) => state.builder.actionsList;
export const selectIsFetchingData = (state: RootState) => state.builder.isFetchingData;
export const selectCurrentModuleNotes = (state: RootState) => state.builder.currentModuleNote;
export const selectNewCurrentModuleNotes = (state: RootState) => state.builder.newCurrentModuleNotes;
export const selectActiveThread = (state: RootState) => state.builder.activeThread;
export const selectCurrentModuleThreads = (state: RootState) => state.builder.currentModuleThreads;

export default BuilderSlice.reducer; 