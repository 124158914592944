import { createContext } from 'react';
import type { BelongsToLuc } from '../../types/modulesInterfaces';

export interface ModuleContextType {
    belongsToLuc: BelongsToLuc | undefined
}

const Context = createContext<ModuleContextType>({ belongsToLuc: undefined });

export default Context;
