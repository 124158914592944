import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useGetActivitiesByIdQuery } from "../../app/features/activities/activityApiSlice";
import { useMemo } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import BuilderTopbar from "../../components/topbar/BuilderTopbar";
import { useCustomTranslation } from "../../app/hooks";

type ContextType = { id: number | null };

const EditActivityContainer = () => {
	const params = useParams<string>();
	const { activityId } = params;
	const id = useMemo(() => (activityId ? +activityId : null), [activityId]);
    const translatedLoading = useCustomTranslation('main.loading');
    const translatedAct = useCustomTranslation("main.activity");

	const {
		data: activity,
		isLoading: isFetchLoading,
	} = useGetActivitiesByIdQuery(id ?? 0, { skip: !id });

	return (
		<div className="edit-activity-container actions-close">
			<Sidebar />
			<BuilderTopbar
				activityLabel={
					isFetchLoading ? `${translatedLoading}...`: `${translatedAct} ${activity?.id}.`
				}
				projectLabel={activity?.name}
				stepsList={[]}
			/>
			<div className="builder-main">
				<Outlet context={{ id } satisfies ContextType} />
			</div>
		</div >
	);
};

export function useId() {
	return useOutletContext<ContextType>();
}

export default EditActivityContainer;
