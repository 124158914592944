import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
	initialValues,
	validationSchema,
	getSchemas,
	t2InitialValues,
} from "./forestManagement";
import {
	useAppSelector,
	useThreadsProcessor,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/moduleList";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetForestDisturbancesQuery,
	useGetForestManagementDefaultsQuery,
	useGetForestManagementQuery,
	useUpdateForestDisturbanceMutation,
	useUpdateForestManagementMutation,
} from "../../../../app/features/api/modules/forestManagementApiSlice";
import type {
	ForestDisturbancePayload,
	ModuleFull,
} from "./forestManagementTypes";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import ForestManagementTierOne from "./ForestManagementTierOne";
import ForestManagementTierTwo from "./ForestManagementTierTwo";
import { useGetLandUseChangesQuery } from "../../../../app/features/api/modules/landUseChangeApiSlice";
import ModuleContext from "../../ModuleContext";
import { getThreadIds } from "../moduleUtils";

const ForestManagement = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: forestData,
		isLoading,
		isError,
		error,
	} = useGetForestManagementQuery(activeActivityId ?? 0);
	const {
		data: disturbancesData,
		isLoading: isLoadingDisturbances,
		isError: isErrorDisturbances,
		error: errorDisturbances,
	} = useGetForestDisturbancesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateForest,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateForestManagementMutation();
	const [
		updateDisturbance,
		{
			error: updateErrorDisturbance,
			isError: isUpdateErrorDisturbance,
			isLoading: isLoadingUpdateDisturbance,
		},
	] = useUpdateForestDisturbanceMutation();

	const { data: parentDefaults, isError: isDefaultsError, error: defaultsError } = useGetForestManagementDefaultsQuery(
		forestData?.parent.id ?? -1,
		{
			skip: !forestData?.parent.id,
		},
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });
	const { belongsToLuc } = useContext(ModuleContext)
	const { data: lucData } = useGetLandUseChangesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	const { moduleSchema, t2Schema, disturbanceSchema } = useMemo(
		() => getSchemas(),
		[],
	);
	const [initValues, setInitValues] = useState<ModuleFull>({
		parent: initialValues,
		tiertwo: t2InitialValues,
		disturbances: [],
	});

	const {processModuleThreads} = useThreadsProcessor<ModuleFull>();

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isLoadingDisturbances],
		isMutateError: isUpdateError || isUpdateErrorDisturbance,
		mutateError: updateError ?? updateErrorDisturbance,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });
	const { errorMsg: distBlockingError } = useErrorMessage({
		isError: isErrorDisturbances,
		error: errorDisturbances,
	});


	const { notes } = useSyncNotesWithStore({
		notes: forestData?.parent.note ?? null,
	});

	useEffect(() => {
		if (forestData)
			setInitValues((cur) => ({
				...cur,
				parent: forestData.parent,
				tiertwo: { ...forestData.tiertwo, ...parentDefaults },
			}));
			
	}, [forestData, lucData, parentDefaults]);

	useEffect(() => {
		if (!disturbancesData || !forestData) return;
		processModuleThreads({
			parent: forestData?.parent,
			disturbances: disturbancesData
		});
	}, [disturbancesData, processModuleThreads,forestData]);

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const parent = { ...values.parent, notes };
		const parentPayload: ModuleFull = JSON.parse(
			JSON.stringify({ ...values, parent }),
		);

		try {
			const promises = [];

			// Update parent
			if (values.parent.id) {
				promises.push(
					updateForest({ ...parentPayload, activityId: activeActivityId }),
				);
			}

			// Update disturbances
			for (const disturbance of values.disturbances) {
				const disturbancePayload: ForestDisturbancePayload = {
					body: disturbance,
					activityId: activeActivityId ?? 0,
				};

				if (disturbance.id) {
					promises.push(updateDisturbance(disturbancePayload));
				}
			}

			await Promise.all(promises);
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema(belongsToLuc)}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<ForestManagementTierOne
					title="project.forest_management"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					disturbanceSchema={disturbanceSchema}
					isDisturbanceLoading={
						isLoadingDisturbances ||
						isLoadingUpdate ||
						isLoadingUpdateDisturbance
					}
					beModuleType={BEModules.ForestManagement}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError ?? distBlockingError}
					isError={isUpdateError || isUpdateErrorDisturbance}
					belongsToLuc={belongsToLuc}
					setInitValues={setInitValues}
					note={notes?.content ?? null}
				/>

				{!tabsOpen ? (
					<ForestManagementTierTwo
						title="project.forest_management"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						setInitValues={setInitValues}
						warningMessage={defaultsErrorMsg}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default ForestManagement;
