import { FieldArray, Form, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";

import FormSubmodule from "../../../../components/formSubmodule/FormSubmodule";
import { TranslatedAddAccordionButton } from "../../../../components/input/AddAccordionButton";
import ModuleButton from "../../../../components/button/ModuleButton";
import { NoteButton } from "../../../../components/tabsButtons/TabsButtons";
import type {
	DropdownInputGroup,
	FetchOptionsKit,
	ModuleProps,
	StartWithWithoutGeneric,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import {
	useCreateInputEntryMutation,
	useDeleteInputEntryMutation,
	useGetInputEntriesQuery,
	useGetInputTypesQuery,
	useGetMacroInputTypesQuery,
} from "../../../../app/features/api/modules/inputsApiSlice";
import type { InputEntryCreatePayload, ModuleFull } from "./inputsTypes";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import { initNewEntryFormValues, validationCreateSchema } from "./inputs";
import { BiMinusCircle, BiSave } from "react-icons/bi";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	BEModulesObj,
	FEModules,
	moduleDescriptions,
} from "../../../../utils/moduleList";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import Spinner from "../../../../components/spinner/Spinner";
import { motion } from "framer-motion";
import { getListIntersection, preventFormSubmit } from "./utils";
import { useInputsContext } from "../../../../contexts/InputsProvider";
import TranslatableText from "../../../../components/translations/TranslatableText";
import ModuleFooter from "../ModuleFooter";

const MAX_ENTRIES = 20;

const InputsTierOne = ({
	title,
	note,
	isLoading: isEntriesLoading,
	footerError,
	blockingError,
	beModuleType,
	isError,
	setInitValues,
}: Omit<ModuleProps, "moduleSchema" | "t2Schema"> & {
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}) => {
	const moduleContext = useInputsContext();
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const [nextState, setNextState] = useState<boolean>(false);
	const { isReadOnly } = usePermissions();
	const {
		values,
		dirty,
		touched,
		isValid,
		handleSubmit,
		validateForm,
		isSubmitting,
		errors,
	} = useFormikContext<ModuleFull>();

	const feModuleType = BEModulesObj[beModuleType];
	const description = moduleDescriptions[feModuleType];
	const number = useMemo(() => {
		const currentModule = moduleList.find((step) => step.id === feModuleType);
		return currentModule?.uniqueId ?? "0.0";
	}, [moduleList, feModuleType]);

	const parentId = moduleList.find((m) => m.id === FEModules.Inputs)?.uniqueId;

	const [deleteEntry, { isLoading }] = useDeleteInputEntryMutation();

	const handleRemove = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		remove(index);
		console.log("delete entry with id: ", id);

		if (id) {
			deleteEntry({ id, activityId: activeActivityId ?? 0, parent: parentId });
		}
	};

	const { data: inputTypes, isLoading: isLoadingInputTypes } =
		useGetInputTypesQuery(null, {
			refetchOnMountOrArgChange: true,
		});

	const inputTypeSelect: DropdownInputGroup = useMemo(
		() => ({
			type: FieldType.SELECT,
			label: "module.input_type_required",
			inputName: (index: number) => `entries.${index}.input_type`,
			dropdownOptions: inputTypes ?? [],
			disabled: isReadOnly,
		}),
		[inputTypes, isReadOnly],
	);
	const valuesSww: StartWithWithoutGeneric = useMemo(
		() => ({
			type: FieldType.SWW,
			label: "module.value",
			unit: "[t/yr]",
			inputName: (index: number) => `entries.${index}.value`,
			startProps: { disabled: isReadOnly },
			withoutProps: { disabled: isReadOnly },
			withProps: { disabled: isReadOnly },
		}),
		[isReadOnly],
	);

	const navigate = useNavigate();
	const location = useLocation();

	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);

	const handleSaveAndProceed = async () => {
		await validateForm();
		if (isValid) {
			handleSubmit();
			setNextState(true);
		} else {
			console.log("not valid");
		}
	};

	/* resetting the form is essetial to reset the "dirty" state that's used to control navigation */
	const handleNext = useCallback(async () => {
		// if (!isValid) return;
		const currentModulePath = location.pathname.split("/").pop();
		const currentIndex = moduleList.findIndex(
			(step) => step.path === currentModulePath,
		);
		const nextModulePath =
			currentIndex === moduleList.length - 1
				? "complete"
				: `${moduleList[currentIndex + 1].path}`;
		navigate(`/builder/${activeActivityId}/${nextModulePath}`);
	}, [location.pathname, moduleList, navigate, activeActivityId]);

	useEffect(() => {
		if (!nextState) return;
		if (
			Object.keys(touched).length === 0 &&
			!dirty &&
			!isError &&
			!isSubmitting
		) {
			setNextState(false);
			handleNext();
		}
	}, [nextState, touched, dirty, handleNext, isSubmitting, isError]);

	/* create entry form states */
	const showFormState = useState<boolean>(false);

	/* ADD SUBMODULE LOGIC */
	const { data: entriesData } = useGetInputEntriesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const entries = useMemo(
		() =>
			getListIntersection({
				currentChangesList: values.entries,
				incomingList: entriesData ?? [],
			}),
		[entriesData],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (entries)
			setInitValues(() => ({
				...values,
				entries: entries,
				new_entry: initNewEntryFormValues,
			}));
		if (moduleContext) moduleContext.setInputsEntriesIds(entries.map((e) => e.id) as number[]);
	}, [entries, setInitValues, initNewEntryFormValues]);

	return blockingError ? (
		<section>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey={title} />
					</h2>
					<div className="module-number header-number ff-light-ext">
						{number}
					</div>
				</div>

				<span className="error fs-13 pb-2">{blockingError}</span>
			</div>
		</section>
	) : (
		<section>
			<Form onKeyDown={preventFormSubmit}>
				<div className="module-group">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-14 ff-medium-ext module-header w-fit">
							<TranslatableText translationKey={title} />
						</h2>
						<div className="module-number header-number ff-light-ext">
							{number}
						</div>
					</div>

					<div className="pos-relative mb-3">
						<p className="fs-14">
							<TranslatableText translationKey={description} />
						</p>
						<div
							className="pos-absolute d-flex align-items-center h-100"
							style={{ top: 0, left: "100%" }}
						>
							<NoteButton active={note !== null} />
						</div>
					</div>

					{isEntriesLoading || isLoadingInputTypes ? (
						<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
							<span className="text-white fs-12">Input Entries loading...</span>
							<Spinner size={36} padding={8} />
						</div>
					) : (
						<FieldArray name="entries">
							{({ remove }) => (
								<>
									{values.entries.length > 0
										? values.entries.map((entry, index) => (
											<FormSubmodule
												key={`${entry.id}-${index}`}
												submoduleName={`${index + 1}. ${inputTypes?.find(
													(type) => type.id === entry.input_type,
												)?.macro_input_type.name ?? "Input"
													}`}
												removeHandler={() =>
													handleRemove(() => remove(index), index, entry.id)
												}
												removeLoading={isLoading}
											>
												<div className="py-1">
													<GenericInputGroup
														inputGroup={{ ...inputTypeSelect, index }}
													/>
												</div>
												<div className="py-1">
													<GenericInputGroup
														inputGroup={{ ...valuesSww, index }}
													/>
												</div>
											</FormSubmodule>
										))
										: null}
								</>
							)}
						</FieldArray>
					)}

					<AddNewEntrySection showFormState={showFormState} isReadOnly={isReadOnly} />
				</div>

				<ModuleFooter
					footerError={footerError}
					errors={errors}
					touched={touched}
					isLoading={isLoading || isSubmitting}
					isDisabled={isLoading || isSubmitting || isReadOnly || showFormState[0]}
					handleSaveAndProceed={handleSaveAndProceed}
					handleNext={handleNext}
					shouldBlock={shouldBlock}
				/>
			</Form>
		</section>
	);
};

interface AddNewEntryProps {
	showFormState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	isReadOnly?: boolean;
}

const AddNewEntrySection = ({ showFormState, isReadOnly }: AddNewEntryProps) => {
	const [showForm, setShowForm] = showFormState;

	const { values, setFieldValue } = useFormikContext<ModuleFull>();
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const parentId = useMemo(
		() => moduleList.find((m) => m.id === FEModules.Inputs)?.uniqueId,
		[moduleList],
	);

	const [createEntry, { isLoading: isLoadingCreateEntry }] =
		useCreateInputEntryMutation();

	const { data: macroInputTypes, isLoading: isLoadingMacroInputTypes } =
		useGetMacroInputTypesQuery();
	const { data: inputTypes, isFetching: isLoadingInputTypes } =
		useGetInputTypesQuery(values.new_entry.macro_input_type, {
			refetchOnMountOrArgChange: true,
		}); //TODO: test if this is triggered when macro changes

	const inputTypesList = useMemo(() => {
		const existingInputTypes = values.entries.map((entry) => entry.input_type);
		return inputTypes?.filter((t) => !existingInputTypes.includes(t.id));
	}, [inputTypes, values.entries]);

	useEffect(() => {
		/* reset input_type if macro changes */
		if (values.new_entry.macro_input_type)
			setFieldValue("new_entry.input_type", null);
	}, [values.new_entry.macro_input_type, setFieldValue]);

	const isDisabled = useMemo<boolean>(() => {
		return (
			isLoadingCreateEntry ||
			!values.new_entry.input_type ||
			values.new_entry.value_start === null ||
			values.new_entry.value_start < 0 ||
			values.new_entry.value_w === null ||
			values.new_entry.value_w < 0 ||
			values.new_entry.value_wo === null ||
			values.new_entry.value_wo < 0
		);
	}, [values.new_entry, isLoadingCreateEntry]);

	const handleToggleForm = () =>
		setShowForm((prev) => {
			if (values.entries.length >= MAX_ENTRIES) return false;
			if (prev) setFieldValue("new_entry", initNewEntryFormValues);
			return !prev;
		});

	const handleCreateNewEntry = async () => {
		const { macro_input_type, ...body } = {
			parent: parentId,
			...values.new_entry,
		};

		const createPayload = {
			activityId: activeActivityId ?? 0,
			body,
		} as InputEntryCreatePayload;

		try {
			await validationCreateSchema.validate(values);
			await createEntry(createPayload).unwrap();
			setFieldValue("new_entry", initNewEntryFormValues);
			handleToggleForm();
		} catch (err) {
			console.error(err);
		}
	};

	const macroInputTypeSelect: DropdownInputGroup = useMemo(
		() => ({
			type: FieldType.SELECT,
			label: "module.macro_input_type",
			inputName: "new_entry.macro_input_type",
			dropdownOptions: async () =>
				({
					data: macroInputTypes ?? [],
					isLoading: isLoadingMacroInputTypes,
				}) as FetchOptionsKit,
		}),
		[macroInputTypes, isLoadingMacroInputTypes],
	);
	const inputTypeSelect: DropdownInputGroup = useMemo(
		() => ({
			type: FieldType.SELECT,
			label: "module.input_type_required",
			inputName: "new_entry.input_type",
			dropdownOptions: async () =>
				({
					data: inputTypesList ?? [],
					isLoading: isLoadingInputTypes,
				}) as unknown as FetchOptionsKit,
		}),
		[inputTypesList, isLoadingInputTypes],
	);
	const valuesSww: StartWithWithoutGeneric = useMemo(
		() => ({
			type: FieldType.SWW,
			label: "module.value",
			unit: "[t/yr]",
			inputName: "new_entry.value",
		}),
		[],
	);

	return (
		<>
			<TranslatedAddAccordionButton
				label={showForm ? "main.close" : "module.add_new_entry"}
				clickHandler={handleToggleForm}
				icon={showForm ? BiMinusCircle : undefined}
				disabled={values.entries.length >= MAX_ENTRIES || isReadOnly}
			/>
			{showForm ? (
				<>
					<div className="py-1">
						<GenericInputGroup inputGroup={macroInputTypeSelect} />
					</div>
					<div className="py-1">
						<GenericInputGroup inputGroup={inputTypeSelect} />
					</div>
					<div className="py-1">
						<GenericInputGroup inputGroup={valuesSww} />
					</div>

					<motion.div
						className="w-fit py-1"
						animate={!isDisabled ? { scale: [1, 1.1, 1] } : {}} // Scale animation from 1 to 1.2 and back to 1
						transition={{
							ease: "easeInOut",
							duration: 1.5,
							repeat: Number.POSITIVE_INFINITY,
							repeatDelay: 1,
						}}
					>
						<ModuleButton
							icon={BiSave}
							buttonType="button"
							disabled={isDisabled}
							isLoading={isLoadingCreateEntry}
							handleClick={handleCreateNewEntry}
							labelKey="main.create_new_entry"
						/>
					</motion.div>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default InputsTierOne;
