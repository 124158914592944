import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../app/features/project/projectSlice'
import { BaseNumerableList, NavigationItem, RedirectableList } from './sideBarTypes'
import type { FastActivity, } from '../../types/interfaces'
import { ProjectNavigation } from '../../types/interfaces'
import { getModuleNameFromBEId } from '../../utils/moduleList'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setModuleList } from '../../app/features/builder/builderSlice'
import { useNavigate } from 'react-router-dom'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SideBarContext } from './provider'
import { useLazyGetFastActivitiesByProjectQuery } from '../../app/features/activities/activityApiSlice'

const useGetMenuContent = () => {
	const { project } = useSelector(selectCurrentProject)
	const [activities, setActivities] = useState<FastActivity[]>([])
	const [
		getFastActivitiesByProject,
		{
			data: activitiesRes,
			isLoading,
			isFetching,
			isError,
			error,
		}
	] = useLazyGetFastActivitiesByProjectQuery()	;
	const { activity: currentActivity } = useAppSelector(selectCurrentProject)
	const { toggleSidebar } = useContext(SideBarContext)
	const dispatch = useAppDispatch()
	const navigate = useNavigate();

	const enumerableProject = new BaseNumerableList(
		"project.project",
		[
			new NavigationItem(ProjectNavigation.Description, () => navigate(`/project/${project?.id}/description`)),
			new NavigationItem(ProjectNavigation.Activities, () => navigate(`/project/${project?.id}/activities`)),
			new NavigationItem(ProjectNavigation.Results, () => navigate(`/project/${project?.id}/results`))
		]
	)


	const handleActivityClick = useCallback((activity: FastActivity, subModule?: string) => {
		//TODO: review new topbar logic and replicate the below comemented logic into the new logic or refactor

		// const feModuleList = getFEModuleListFromActivity(activity);
		// const subModulePath = subModule ? feModuleList.filter(el => el.name === subModule)[0]?.path : null;
		// dispatch(setActivity(activity));
		// dispatch(setModuleList(feModuleList));
		// const path = subModulePath ? `/builder/${activity.id}/${subModulePath}` : `/builder/${activity.id}/${feModuleList[0].path}`
		// navigate(feModuleList.length > 0 ? path : `/project/${activity.id}/complete`);

		navigate(`/builder/${activity.id}`)
		if (activity.id !== currentActivity?.id)
			dispatch(setModuleList([]))
		toggleSidebar()
	}, [currentActivity?.id, dispatch, navigate, toggleSidebar]);

	const handleLoadMore = useCallback(() => {
		if (activitiesRes?.next) {
			getFastActivitiesByProject({ url: activitiesRes?.next, projectId: Number(project?.id) })
		}
	}, [activitiesRes?.next, getFastActivitiesByProject, project?.id])	
		
	useEffect(() => {
		if (project?.id) {
			setActivities([])
			getFastActivitiesByProject({ url: `/activities/?page=1&page_size=5&project_id=${project?.id}`, projectId: Number(project?.id) })
		}
	}, [project?.id, getFastActivitiesByProject])

	useEffect(() => {
		if (activitiesRes) {
			setActivities((prev) => [...prev, ...activitiesRes.results])
		}
	}, [activitiesRes])

	const enumerableActivities = useMemo(()=>{
		return new BaseNumerableList(
			"activity.activities",
			activities.map(
				activity => new RedirectableList(
					activity.name,
					activity.module_types.map(
						module_type => new NavigationItem(getModuleNameFromBEId(module_type), () => handleActivityClick(activity))
					),
					() => handleActivityClick(activity)
				)
			),
		)
	}, [activities, handleActivityClick])
	return {
		enumerableProject,
		enumerableActivities,
		isLoading: isLoading || isFetching,
		loadMore: handleLoadMore,
	}
}

export default useGetMenuContent