import React from 'react'

interface IrrigationsContextType {
    irrigationsSystemIds: number[] | null
    setIrrigationsSystemIds: React.Dispatch<React.SetStateAction<number[] | null>>

    operationPhaseIds: number[] | null
    setOperationPhaseIds: React.Dispatch<React.SetStateAction<number[] | null>>
}

const IrrigationsContext = React.createContext<IrrigationsContextType | null>(null)

export const useIrrigationsContext = () => {
    const context = React.useContext(IrrigationsContext);
    if (context == null) {
      throw new Error('useIrrigationsContext must be used within a IrrigationsProvider');
    }
    return context;
}

const IrrigationsProvider = ({ children }: { children: React.ReactNode }) => {
    const [irrigationsSystemIds, setIrrigationsSystemIds] = React.useState<number[] | null>(null)
    const [operationPhaseIds, setOperationPhaseIds] = React.useState<number[] | null>(null)
  return (
    <IrrigationsContext.Provider value={{
        irrigationsSystemIds,
        setIrrigationsSystemIds,
        operationPhaseIds,
        setOperationPhaseIds
    }}>
      {children}
    </IrrigationsContext.Provider>
  )
}

export default IrrigationsProvider