import { BiSave } from "react-icons/bi";
import { Errors, Touched } from "./inputs/utils";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { RiSkipForwardLine } from "react-icons/ri";
import ModuleButton from "../../../components/button/ModuleButton";
import ConfirmNavigation from "../../../components/confirmNavigation/ConfirmNavigation";
import FooterError from "./FooterError";
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";

interface ModuleFooterProps {
  footerError: string | undefined;
  errors: Errors;
  touched: Touched;
  isLoading: boolean;
  isDisabled: boolean;
  handleSaveAndProceed: () => void;
  handleNext: () => void;
  shouldBlock: () => boolean;
  closingNotes?: string;
}

const ModuleFooter = ({ footerError, errors, touched, isLoading, isDisabled, handleSaveAndProceed, handleNext, shouldBlock, closingNotes }: ModuleFooterProps) => {
  const blocker = useBlocker(shouldBlock);

  return (
    <footer className="module-group-footer">
      <div className="module-group">
        {closingNotes ? (<span className="ff-light fs-12 pb-2">{closingNotes}</span>) : null}

        <FooterError
          footerError={footerError}
          errors={errors}
          touched={touched}
        />

        <div className="d-flex gap-8">
          <Button type="submit" classes={`br-8 bg-quat-br ${isLoading ? "op-8" : "px-1"}`} disabled={isLoading}>
            {isLoading ?
              <Spinner size={36} padding="0.5rem" /> :
              <BiSave size={20} color="var(--white)" />}
          </Button>
          <ModuleButton
            buttonType="submit"
            disabled={isDisabled || isLoading}
            isLoading={isLoading}
            handleClick={handleSaveAndProceed}
            labelKey="main.save_module_and_proceed"
          />
          <ModuleButton icon={RiSkipForwardLine} buttonType="button" handleClick={handleNext} labelKey="main.skip_module" styleType="secondary" />
        </div>

        <ConfirmNavigation blocker={blocker} />
      </div>
    </footer>
  );
}

export default ModuleFooter;