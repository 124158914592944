import { motion } from "framer-motion";
import { ReactNode, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const TermAndConditions = () => {
  const params = useParams<string>();
  const { tab } = params;

  const navigate = useNavigate();

  useEffect(() => {
    if (tab !== "terms" && tab !== "gdpr") navigate("/");
  }, [tab, navigate]);

  return (
    <>
      <section className="d-flex f-column p-2 gap-8">
        <div className="d-flex gap-8">
          <Link
            to="../terms"
            relative='path'
            className="fs-13 ff-black-ext pb-1 mb-1 no-decoration"
            style={{ borderBottom: `8px solid ${tab === 'terms' ? "var(--tertiary-bright)" : "var(--darker-gray)"}` }}
          >
            Terms of Use for the EX-ACT: Environmental eXternalities ACcounting Tool
          </Link>
          <Link
            to="../gdpr"
            relative='path'
            className="fs-13 ff-black-ext pb-1 mb-1 no-decoration"
            style={{ borderBottom: `8px solid ${tab === 'gdpr' ? "var(--tertiary-bright)" : "var(--darker-gray)"}` }}
          >
            Data Protection and Privacy Notice for the EX-ACT Application
          </Link>
        </div>

        <motion.div
          transition={{ duration: 0.8, ease: "easeIn" }}
          key={tab}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {tab === "terms" ? <TermsContent /> : <GdprContent />}
        </motion.div>
      </section>
    </>
  );
};

export default TermAndConditions;

const GdprContent = () => {
  const paragraphs: ReactNode[] = [
    <p>The Food and Agriculture Organization of the United Nations (FAO) is committed to processing your personal data fairly, with integrity, responsibility, security and transparency, in accordance with its internal data protection rules, policies and procedures.</p>,
    <div>
      <p>FAO collects and processes your personal data for the purposes of:</p>
      <ul className="ps-2">
        <li>Sending regular communications on updates to the EX-ACT Application</li>
        <li>Developing aggregated, anonymised statistics on the use of the Application.</li>
      </ul>
    </div>,
    <p>In this regard, FAO will be processing your full name, contact details, organization, country, as well as information you provide that is available in the projects created in the application.</p>,
    <p>If you have any questions or concerns related to your personal data being processed by FAO, please contact us at: <a role="link" href="mailto:FAO-Data-Privacy@fao.org" className="text-quat-de">FAO-Data-Privacy@fao.org</a>.</p>,
    <p>For additional information on how FAO processes your personal data, please consult the <a href="https://www.fao.org/contact-us/data-protection-and-privacy/en/" className="text-quat-de">FAO Data Protection and Privacy Notice</a></p>,
    <p>By using this application, I agree with the above notice and hereby provide consent to FAO to process my personal data for the purposes listed above.</p>,
  ]

  return (<div className="d-flex f-column">
    {paragraphs.map((par, i) => <div key={i} className="pb-2">{par}</div>)}
  </div>)
}

const TermsContent = () => {
  const paragraphs: ReactNode[] = [
    <h3 className="ff-medium">General Provisions</h3>,
    <h4>Introduction</h4>,
    <p>The Food and Agriculture Organization of the United Nations (FAO) has developed the EX-ACT online Application (hereinafter, the “Application”) to support efforts towards sustainable investments in agrifood systems.</p>,
    <h4>Description of the Application</h4>,
    <p>The Application, developed and owned by FAO, is an appraisal system that estimates the climate and biodiversity effects of agriculture and forestry development projects, programmes and policies.</p>,
    <h4>Application Capabilities</h4>,
    <p>The Application generates evidence on the outcomes of agrifood interventions at any scale and geographical level, complementing the information available to decisions makers. It helps to quantify the amount of greenhouse gas released or sequestered from agricultural production, analyze the outcomes of activities from agrifood investments along a selected agricultural value chains and assess the impact of agricultural activities on local biodiversity.</p>,
    <h4>Acceptance of Terms of Use and Terms and Conditions</h4>,
    <p>By using the Application, you acknowledge and agree to be bound by these Terms of Use and FAO's <a href="https://www.fao.org/contact-us/terms-and-conditions/en/" className="text-quat-de">Terms and Conditions</a>. If you do not agree to these Terms of Use and FAO's Terms and Conditions, do not use the Application. In case of any conflict or inconsistency between these Terms of Use and FAO's Terms and Conditions, the Terms of Use shall prevail.</p>,
    <h4>Amendment to Terms of Use and Terms and Conditions</h4>,
    <p>FAO reserves the right to amend these Terms of Use and FAO's Terms and Conditions at any time without notice. Notifications of any significant amendments to the FAO's Terms and Conditions will be posted on the Terms and Conditions webpage. Notifications of any significant amendments to the Terms of Use will be made available to the users on the Application website. Continued use of the Application following such amendments constitutes your consent to such amendments.</p>,
    <h4>Definitions</h4>,
    <div>
      <p>The following definitions shall apply to these Terms of Use:</p>
      <ul className="ps-2">
        <li className="pt-1"><b>'Users'</b> refer to individuals who have registered on the Application website to the Application.</li>
        <li className="pt-1"><b>'Personal Data'</b> means any information relating to a person who is or can be identified either from the data, or from the data in conjunction with other information that is in, or is likely to come into, the possession of FAO and/or its authorized vendors or subcontractors. Personal Data may include but is not limited to, an individual's (a) name and/or surname, (b) place/date of birth, (c) age, (d) gender, (e) nationality, (f) place of residence, (g) job title, (h) personal contacts (e.g. postal address, email address, telephone number, etc.).</li>
        <li className="pt-1"><b>'Processing'</b> means any operation or set of operations which is performed on or using personal data, whether or not by automatic means, such as collection, recording, organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, blocking, erasure or destruction.</li>
      </ul>
    </div>,

    <h4 className="ff-medium">Using the Tool</h4>,
    <h5>Updates</h5>,
    <p>The Application is updated by FAO on a regular basis. All Users should ensure they are using the latest version of the Application or download the latest version of the Application and its add-ons following the registration instructions available on the Application website.</p>,
    <h5>Right to Modify or Discontinue</h5>,
    <p>FAO reserves the right, at any time, to modify or discontinue, temporarily or permanently, the Application, including any means of accessing or utilizing it, at its sole discretion with or without prior notice to you.</p>,
    <h5>Cancellation of Registration</h5>,
    <p>FAO may, at its sole discretion, under any circumstances, for any or no reason whatsoever and with or without prior notice to you, cancel the User registration in the Application. Reasons for termination of an account include, and are not limited to, prolonged account inactivity, intellectual property concerns, or fraudulent activity.</p>,

    <h4 className="ff-medium">Responsibility of Users</h4>,
    <h5>Data Submission and Processing</h5>,
    <p>When registering in the Application, an individual must provide accurate personal data and consent to its processing by FAO. Please read the <a href="https://www.fao.org/contact-us/data-protection-and-privacy/en/" className="text-quat-de">Data Protection and Privacy Notice</a> for the EX-ACT Application for information on how FAO processes Users’ personal data.  The User may, at any time, request FAO to delete or update any such provided personal data by writing to <a href="mailto:FAO-Data-Privacy@fao.org" className="text-quat-de">FAO-Data-Privacy@fao.org</a>.</p>,
    <h5>User Responsibilities</h5>,
    <p>When registering in and using the Application, the Users acknowledge and agree that they shall:</p>,
    <ul className="ps-2">
      <li>comply with all applicable laws, including data protection, anti-spam, and intellectual property laws;</li>
      <li>ensure the integrity and accuracy of the data that they provide when registering on the app, including any personal data; any false or misrepresented identity, affiliation with a person or entity, past or present, or your current or previous positions and qualifications is not allowed;</li>
      <li>ensure that they do not violate any third-party rights over the information they provide;</li>
      <li>not represent or imply that FAO is participating in, or has sponsored, approved or endorsed their use of the Application;</li>
      <li>not delete any attributions, legal notices or proprietary designations or labels in any reports generated by the Application;</li>
      <li>assume full responsibility for independently assessing the relevance, accuracy, completeness, and suitability of the reports generated by the Application, and acknowledge that the assessments performed using the Application are based on the User’s knowledge and experience;</li>
      <li>use the Application only for the purposes indicated in the General Provisions above;</li>
      <li>not use the Application for commercial purposes, including but not limited to, use of results, reports and materials provided for financial gain through product and service provision.</li>
    </ul>,

    <h4 className="ff-medium">Disclaimer</h4>,
    <p>All information contained in the Application is provided “as is”. FAO makes no representations or warranties, express or implied, regarding the correctness, completeness, or timeliness of such information. FAO is not responsible for updating such information and assumes no liability for any errors or omissions in the information contained in or generated through the Application. The Application is not guaranteed to be free from errors or deficiencies, and FAO is not liable for damages that may arise from the use of the Application. Users are encouraged to report any errors or deficiencies in the Application to <a href="mailto:ex-act@fao.org" className="text-quat-de">ex-act@fao.org</a>.</p>,
    <p>FAO expressly disclaims all warranties, whether express or implied, regarding the correctness, accuracy, completeness, timeliness, and reliability of the text, graphics, links to other sites accessed through or provided by the Application. FAO does not warrant that the services will be uninterrupted, error-free, or free from viruses or other harmful components.</p>,
    <p>The designations employed and the presentation of material in the Application do not imply the expression of any opinion whatsoever on the part of FAO concerning the legal or development status of any country, territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.</p>,
    <p>Links to third-party websites are provided solely for Users' convenience and do not imply any FAO’s endorsement of, or responsibility on the part of FAO for, the content, opinions, or products presented therein, nor does FAO guarantee the accuracy of information on these websites.</p>,
    <p>Under no circumstances shall FAO be liable for any loss, damage, liability or expense suffered that is claimed to result from the use of, or reliance on the information contained in the Application. This includes, but is not limited to, damage from misuse, errors, disclosure, undue transfer, data loss or destruction of information that may occur.</p>,
    <p>FAO shall not be liable for incomplete information transmission due to the internet connection or software failures.</p>,

    <h4 className="ff-medium">Copyright and Reuse of Web Content</h4>,
    <p>The content of the Application is subject to the <a href="https://www.fao.org/contact-us/terms-and-conditions/en/" className="text-quat-de">FAO Terms and Conditions</a> for use of its website. Except as otherwise stated in these Terms and Conditions, the information available in the Application including text, logos, graphics, maps, images, audio clips and electronic downloads, is the property of FAO. Users may download and copy materials contained in or generated through the Application for personal, non-commercial use only, with no right to resell, redistribute, compile or create derivative works therefrom for commercial purposes. Copyright in user-submitted materials remain with the original holder. Posted materials must only include content that the user has the right to distribute electronically. Users providing information acknowledge and agree that the material which they post on the Application may be reused and redistributed by FAO for non-commercial purposes, without prior written permission.</p>,
    <p>Links to other websites are provided for your convenience only and do not constitute endorsement by FAO of material at those sites, or any associated organization, product or service.</p>,

    <h4 className="ff-medium">Privileges and Immunities</h4>,
    <p>Nothing in these Terms of Use shall be construed as constituting a waiver, express or implied, of the privileges and immunities of FAO, or as its acceptance of the jurisdiction of the courts of any country over disputes arising out of these Terms of Use.</p>
  ];

  return (<div className="d-flex f-column">
    {paragraphs.map((par, i) => <div key={i} className="pb-2">{par}</div>)}
  </div>)
};
