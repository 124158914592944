import type { IconType } from "react-icons";
import OutsideClickSub from "../outsideClickSub/OutsideClickSub";
import { createElement, useState } from "react";
import { motion } from "framer-motion";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { TranslationKey } from "../../types/modulesInterfaces";
import { useCustomTranslation } from "../../app/hooks";

export interface Line {
	id: number;
	label: TranslationKey;
	icon: IconType;
	clickHandler: (id?: number) => void;
	activeColor?: string;
}

const KebabMenu = ({
	lines,
	activeColor,
	buttonClasses,
	position
}: {
	lines: Line[];
	activeColor: string;
	buttonClasses?: string;
	position?: "left" | "right"
}) => {
	const [isModalShown, setIsModalShown] = useState<boolean>(false);

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsModalShown(true)
	}

	return (
		<div className="pos-relative">
			<ThreeDots
				handler={handleClick}
				color="var(--darker-gray)"
				classes={buttonClasses}
			/>
			{isModalShown && (
				<Modal
					handleClose={() => setIsModalShown(false)}
					lines={lines}
					activeColor={activeColor}
					position={position}
				/>
			)}
		</div>
	);
};

const ThreeDots = ({
	handler,
	color,
	classes,
}: {
	handler: (e: React.MouseEvent) => void;
	classes?: string;
	color?: string;
}) => {
	return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-center">
			<motion.button
				onClick={handler}
				type="button"
				className={`clickable bg-transparent ${classes}`}
				style={{ padding: "0.2rem" }}
				whileHover={{
					scale: 1,
				}}
				whileTap={{
					backgroundColor: "var(--gray)",
					borderRadius: "4px",
				}}
			>
				<BiDotsVerticalRounded size={20} color={color ? color : "inherit"} />
			</motion.button>
		</div>
	);
};

const Modal = ({
	lines,
	handleClose,
	activeColor,
	position
}: {
	lines: Line[];
	handleClose: () => void;
	activeColor: string;
	position?: "left" | "right"
}) => {
	return (
		<OutsideClickSub className="kebab-menu" style={position === "left" ? { right: 0 } : { left: 0 }} callback={handleClose}>
			<>
				{lines.map((line) => (
					<ModalLine
						line={line}
						handleClose={handleClose}
						key={line.id}
						activeColor={activeColor}
					/>
				))}
			</>
		</OutsideClickSub>
	);
};

const ModalLine = ({
	line,
	handleClose,
	activeColor,
}: {
	line: Line;
	handleClose: () => void;
	activeColor: string;
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const translatedLabel = useCustomTranslation(line.label);
	const handleClick = () => {
		handleClose();
		line.clickHandler(line.id);
	};

	return (
		<button
			type="button"
			className={"clickable kebab-menu-line"}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={handleClick}
			style={{
				backgroundColor: isHovered && line.activeColor ? line.activeColor : isHovered ? activeColor : "white",
				color: isHovered ? "white" : "inherit",
			}}
		>
			<div className="icon">
				{createElement(line.icon, {
					color: isHovered ? "#fff" : "#666",
					size: 18,
				})}
			</div>
			<span className="fs-11 ff-medium-cond">{translatedLabel}</span>
		</button>
	);
};

export default KebabMenu;
