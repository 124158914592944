import { selectActiveThread, selectCurrentModuleThreads, selectCurrentBuilder, selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import React, { useState, ReactNode, useMemo, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Comment } from "./CommentsBlock";
import TranslatableText from "../../translations/TranslatableText";
import CommentContent from "./CommentsContent";
import Spinner from "../../spinner/Spinner";

export interface CommentsResponse {
  id: number;
  comments: Comment[];
}

export class Thread implements CommentsResponse {
  id: number;
  comments: Comment[];

  constructor(response: CommentsResponse) {
    this.id = response.id;
    this.comments = response.comments;
  }
  hasComments(): boolean {
    return this.comments.length > 0;
  }
}

export type ThreadKeysLength = {
  [key: number]: number
}

const CommentsAction = () => {
  const activeThread = useAppSelector(selectActiveThread)
  const moduleThreads = useAppSelector(selectCurrentModuleThreads)
  const [threadKeysLength, setThreadKeysLength] = useState<ThreadKeysLength>({});
  const [isAtLeastOneThreadNotNull, setIsAtLeastOneThreadNotNull] = useState(false);

  const isFetchingData = useAppSelector(selectIsFetchingData)

  const threadKeys: number[] | undefined = useMemo(() => {
    if (!moduleThreads) return []
    setThreadKeysLength(moduleThreads.reduce((acc, threadKey) => ({
      ...acc,
      [threadKey]: 0
    }), {}));
  }, [moduleThreads])

  useEffect(() => {
    if (threadKeysLength)
      setIsAtLeastOneThreadNotNull(Object.values(threadKeysLength).some((length) => length > 0))
  }, [threadKeysLength])

  const handleThreadCommentsLengthChange = (threadId: number, length: number) => {
    if (threadKeysLength[threadId] !== length)
      setThreadKeysLength((prev) => ({
        ...prev,
        [threadId]: length
      }))
  }

  if (isFetchingData) {
    return (
      <div className="py-2 px-3 d-flex justify-content-center">
        <Spinner size={32} padding={4} isBlack />
      </div>
    )
  }

  if (moduleThreads.length === 0) {
    return (
      <div className="py-2 px-3">
        <TranslatableText translationKey="module.comments_not_available" />
      </div>
    )
  }
  if (moduleThreads.length > 0) {
    return (
      <>
        {moduleThreads.map((thread) => (
          <CommentContent
            key={thread}
            threadId={thread}
            groupLabel={''}
            isActive={activeThread === thread}
            handleThreadCommentsLengthChange={handleThreadCommentsLengthChange}
          />
        ))}
        {
          !isAtLeastOneThreadNotNull && !isFetchingData ? (
            <div className="py-2 px-3">
              <TranslatableText translationKey="module.no_comments_yet" />
            </div>
          ) : null
        }
     </>
    )
  }
  return null
};

export const ClickableIcon = ({ children, clickHandler }: { children: ReactNode; clickHandler: () => void }) => {
  return (
    <div className="clickable" onClick={clickHandler}>
      {children}
    </div>
  );
};

const withCommentsContainer = (WrappedComponent: React.FC) => {
  return function WithCommentsContainer(props: any) {
    return (
      <section className="comments-action-container h-100 py-1">
        <WrappedComponent {...props} />
      </section>
    );
  };
};
const CommentsActionWithContainer = withCommentsContainer(CommentsAction);

export default CommentsActionWithContainer;
