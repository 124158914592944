import React from 'react'

interface EnergyContextType {
    electricityIds: number[] | null
    setElectricityIds: React.Dispatch<React.SetStateAction<number[] | null>>
    fuelsIds: number[] | null
    setFuelsIds: React.Dispatch<React.SetStateAction<number[] | null>>
}

const EnergyContext = React.createContext<EnergyContextType | null>(null)

export const  useEnergyContext =  () => {
    const context = React.useContext(EnergyContext);
    if (context == null) {
      throw new Error('useEnergyContext must be used within a EnergyProvider');
    }
    return context;
}
const EnergyProvider = ({ children }: { children: React.ReactNode }) => {
    const [electricityIds, setElectricityIds] = React.useState<number[] | null>(null)
    const [fuelsIds, setFuelsIds] = React.useState<number[] | null>(null)
  return (
    <EnergyContext.Provider value={{
        electricityIds,
        setElectricityIds,
        fuelsIds,
        setFuelsIds
    }}>
      {children}
    </EnergyContext.Provider>
  )
}

export default EnergyProvider