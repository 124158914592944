import { useFormikContext } from 'formik'
import React, { useCallback } from 'react'
import { editActivity, EditActivityFields, isClimateInput, isMoistureInput } from './utils'
import { getCapitalizationYears } from '../../utils/projectUtils'
import { ActivityUpdatePayload } from '../../app/features/activities/activityTypes'
import GenericInputGroup from '../../components/input/inputGroup/GenericInputGroup'
import { useAppSelector, useClimateAndMoistureFormHandlers } from '../../app/hooks'
import { selectCurrentProject } from '../../app/features/project/projectSlice'

interface EditActivityContentProps {
  isFetching: boolean
}
const EditActivityContent: React.FC<EditActivityContentProps> = ({ isFetching }) => {
  const { project } = useAppSelector(selectCurrentProject);
  const {values, setFieldValue} = useFormikContext<ActivityUpdatePayload>()
  const {handleClimateInputChange,getMoistureOptions} = useClimateAndMoistureFormHandlers();

  return (
    <div>
      {editActivity.inputGroups.map((input, index) => {
        if (input.inputName === EditActivityFields.start_year_t2) {
          input.onChange = (e) => {
            const newValue = e.target.value
            const updatedCapitalizationYears = getCapitalizationYears(parseInt(newValue), values.activity.duration_t2, project?.last_year_of_accounting);
            setFieldValue(EditActivityFields.capitalization_years, updatedCapitalizationYears);
          }
        }
        if (input.inputName === EditActivityFields.duration_t2) {
          input.onChange = (e) => {
            const newValue = e.target.value
            const updatedCapitalizationYears = getCapitalizationYears(values.activity.start_year_t2,parseInt(newValue), project?.last_year_of_accounting);
            setFieldValue(EditActivityFields.capitalization_years, updatedCapitalizationYears);
          }
        }
        if (isMoistureInput(input)) {
          input.dropdownOptions = getMoistureOptions(values.activity.climate_t2)
        }
        if (isClimateInput(input)) {
          input.onChange = (e: any) => {
            handleClimateInputChange(e, values.activity.moisture_t2, EditActivityFields.moisture_t2)
          }
        }
        return (
          <div className="py-1" key={`${input.label}-${index}`}>
            <GenericInputGroup
              inputGroup={{ ...input, disabled: isFetching || input.disabled }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default EditActivityContent