import { useContext } from 'react'
import Accordion, { AccordionContext } from '../accordion/Accordion';
import { motion } from 'framer-motion';
import { FiMinus } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import Button from '../button/Button';

const CollapsibleItem = ({ label, children,onToggle }: { label: React.ReactNode, children: React.ReactNode, onToggle?: () => void }) => {
	return (
		<div style={{ backgroundColor: "#0f3a54" }}>
			<Accordion
				header={<CollapsibleHeader label={label} isActive={false} onToggle={onToggle} />}
				initiallyOpen={false}
			>
				{children}
			</Accordion>
		</div>
	)
}

const CollapsibleHeader = ({
	label,
	onToggle,
}: { label: React.ReactNode; isActive: boolean, onToggle?: () => void }) => {
	const { isOpen, toggleOpen } = useContext(AccordionContext);
	
	return (
		<div className="d-flex align-items-center h-100">
			<Button onClick={() => {
				 toggleOpen()
				 if (onToggle && !isOpen) onToggle()
			}} classes="px-1">
				{isOpen ? (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}
					>
						<FiMinus size={15} color="var(--tertiary-bright)" />
					</motion.div>
				) : (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}
					>
						<GoPlus size={15} color="var(--tertiary-bright)" />
					</motion.div>
				)}
			</Button>
			{label}
		</div>
	);
};

export default CollapsibleItem