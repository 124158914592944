import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/grasslandManagement/grasslandManagement";
import type {
	ModuleFull,
	GrasslandPayload,
	GrasslandResponse,
	GrasslandUpdatePayload,
	GrasslandDefaults,
	GrasslandsT2
} from "../../../../pages/activityBuilder/modules/grasslandManagement/grasslandTypes";
import {
	convertIncomingPercentage,
	convertOutgoingPercentage,
	filterObjectProps,
	convertEmptyStringsToNull
} from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getGrasslands: builder.query<ModuleFull | null, number>({
			query: (activityId) => `grasslands/?activity=${activityId}`,
			transformResponse: (response: GrasslandResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						{ ...response[0] },
						includedPropList,
					) as GrasslandPayload;

					const t2 = filterObjectProps(
						{ ...response[0] },
						includedT2PropList,
					) as GrasslandsT2;

					convertIncomingPercentage(module, ["fire_impact"]);
					convertIncomingPercentage(t2, ["combustion_factor_t2"]);

					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_result, _error, activityId) => [
				{
					type: "Grassland",
					id: activityId,
				},
			],
		}),
		updateGrasslands: builder.mutation<
			GrasslandResponse,
			GrasslandUpdatePayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module };
				const tiertwo = { ...data.tiertwo };
				const lucId = data.module.land_use_change;
				convertOutgoingPercentage(module, ["fire_impact"]);
				convertOutgoingPercentage(tiertwo, ["combustion_factor_t2"]);

				const payload = {
					...module,
					...tiertwo,
				} as unknown as GrasslandResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };

				convertEmptyStringsToNull(body);

				return { url: `grasslands/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { data, activityId }) => [
				{ type: "Grassland", id: activityId },
				{ type: "ModuleResults", id: results?.id ? `grasslands-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `grasslands-${results?.id}` : undefined },
				{ type: "GrasslandDefaults", id: data.module.id ?? undefined },
				{ type: "ModulesStatuses", id: activityId }
			],
		}),
		createGrasslands: builder.mutation<
			GrasslandResponse,
			GrasslandUpdatePayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module };
				const tiertwo = { ...data.tiertwo };
				const lucId = data.module.land_use_change;
				convertOutgoingPercentage(module, ["fire_impact"]);
				convertOutgoingPercentage(tiertwo, ["combustion_factor_t2"]);

				const payload = {
					...module,
					...tiertwo,
				} as unknown as GrasslandResponse;
				const body = {
					...payload,
					activity: activityId,
					land_use_change: lucId,
				};
				return { url: "grasslands/", method: "POST", body };
			},
			invalidatesTags: (results, _error, { data, activityId }) => [
				{ type: "Grassland", id: activityId },
				{ type: "ModuleResults", id: results?.id ? `grasslands-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `grasslands-${results?.id}` : undefined },
			],
		}),
		getGrasslandsDefaults: builder.query<GrasslandDefaults, number>({
			query: (uniqueId) => `grasslands/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [
				{ type: "GrasslandDefaults", id: uniqueId },
			],
		}),
	}),
});

export const {
	useGetGrasslandsQuery,
	useUpdateGrasslandsMutation,
	useCreateGrasslandsMutation,
	useGetGrasslandsDefaultsQuery
} = moduleApiSlice;
