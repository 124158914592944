import { IoArrowForward } from 'react-icons/io5';
import Button from '../button/Button';
import TranslatableText from '../translations/TranslatableText';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../types/modulesInterfaces';
import BaseLabel from '../label/BaseLabel';

interface SideBarTextProps {
	prefix: string | number | undefined;
	label: string;
	hasRedirect?: boolean;
	style?: React.CSSProperties;
	textColor?: string;
	children?: React.ReactNode
	onClick?: () => void
}

const SideBarText = ({ prefix, label, hasRedirect, style, textColor, children, onClick }: SideBarTextProps) => {
	const Wrapper = hasRedirect ? Button : "span"
	const isCollapsible = style?.flexDirection === "column"

	return (
		<Wrapper onClick={onClick ?? undefined} classes="bg-transparent w-100" style={{ color: textColor }}>
			<div className="fs-13 d-flex justify-content-between align-items-center p-1">
				<span className='d-flex' style={{ alignItems: isCollapsible ? "flex-start" : "center", ...style }}>
					{children}
					{prefix && <span style={{ paddingRight: "5px", fontSize: isCollapsible ? "11px" : "inherit" }}>{prefix}.</span>}
					<BaseLabel label={label} className={isCollapsible ? "fw-600" : ""} />
				</span>
				{hasRedirect &&
					<div className='pe-1'>
						<IoArrowForward />
					</div>}
			</div>
		</Wrapper >
	)
}

const BaseSideBarText = (props: SideBarTextProps) => {
	return (
		<div style={{ paddingLeft: "1.5rem" }}>
			<SideBarText {...props} textColor="var(--white)" style={{ fontSize: "12px" }} />
		</div>
	)
}

const HighlightedCollapsibleSideBarText = (props: SideBarTextProps) => {
	return <SideBarText {...props} textColor="var(--tertiary-bright)" style={{ ...props.style, fontSize: "13px" }} />
}
const HighlightedSideBarText = (props: SideBarTextProps) => {
	return <SideBarText {...props} textColor="var(--tertiary-bright)" style={{ ...props.style, fontSize: "16px" }} />
}

export { BaseSideBarText, HighlightedSideBarText, SideBarText, HighlightedCollapsibleSideBarText }