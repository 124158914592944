export enum DisturbanceRecurrence {
    requiredMessage = 'validations.disturbance_recurrence_required',
    conditionsMessage = 'validations.disturbance_recurrence_conditions',
}
export enum DisturbanceBiomass {
    requiredMessage = 'validations.disturbance_biomass_required',
    conditionsMessage = 'validations.disturbance_biomass_conditions',
}

enum RotationLength {
    requiredMessage = 'validations.rotation_length_required',
    conditionsMessage = 'validations.rotation_length_conditions',
}

enum RotationPercentage {
    requiredMessage = 'validations.rotation_percentage_required',
    conditionsMessage = 'validations.rotation_percentage_conditions',
}

enum LoggingRecurrence {
    requiredMessage = 'validations.logging_recurrence_required',
    conditionsMessage = 'validations.logging_recurrence_conditions',
}

enum LoggingPercentageAGB {
    requiredMessage = 'validations.logging_percentage_agb_required',
    conditionsMessage = 'validations.logging_percentage_agb_conditions',
}

enum LoggingPercentageBiomass {
    requiredMessage = 'validations.logging_percentage_biomass_required',
    conditionsMessage = 'validations.logging_percentage_biomass_conditions',
}

enum AverageYearlyDegradation {
    requiredMessage = 'validations.average_yearly_degradation_required',
    conditionsMessage = 'validations.average_yearly_degradation_conditions',
}
export const messages = {
    recurrence_yrs: DisturbanceRecurrence,
    percentage_biomass_destruction: DisturbanceBiomass,
    rotation_length_yrs: RotationLength,
    rotation_percentage_biomass_for_energy: RotationPercentage,
    logging_recurrence_yrs:  LoggingRecurrence,
    logging_percentage_agb_logged: LoggingPercentageAGB,
    logging_percentage_biomass_for_energy: LoggingPercentageBiomass,
    average_yearly_degradation_percentage: AverageYearlyDegradation,
} as const;