import ModuleButton from '../../../components/button/ModuleButton'
import { SiMicrosoftexcel } from "react-icons/si";
import useDownloadExcel from './useDownloadExcel';
import AlertModal from '../../../components/modal/AlertModal';
import TranslatableText from '../../../components/translations/TranslatableText';
import useErrorMessage from '../../../utils/useErrorMessage';
import { useAppSelector } from '../../../app/hooks';
import { selectCurrentProject } from '../../../app/features/project/projectSlice';


const ProjectResultsReport = () => {
  const { project } = useAppSelector(selectCurrentProject)
  const { downloadExcel, isLoading, handleCancel, errorMsg, isOpen } = useDownloadExcel({project})

  return (
    <div className='d-flex f-column gap-2 pb-2'>
      <ModuleButton
        icon={SiMicrosoftexcel}
        buttonType="button"
        handleClick={downloadExcel}
        labelKey="main.downlad_excels"
        isLoading={isLoading}
        disabled={!project?.id}
      />

      <AlertModal
        modalOpen={isOpen}
        handleSubmit={handleCancel}
        handleCancel={handleCancel}
      >
        <div className='p-2'>
          {
            errorMsg ? errorMsg : <TranslatableText translationKey="project.report_error" />
          }
        </div>
      </AlertModal>
    </div>

  )
}

export default ProjectResultsReport