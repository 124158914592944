import { TripleCubes } from "../../../utils/customIcons";
import {
	FEModules,
	FEModulesObj,
	moduleDescriptions,
} from "../../../utils/moduleList";
import TranslatableText from "../../translations/TranslatableText";

const ModuleCard = ({
	module,
	showDescription,
}: {
	module: FEModules;
	showDescription?: boolean;
}) => {
	const name = FEModulesObj[module]?.name;
	const description = moduleDescriptions[module];

	return (
		<div className="module-card">
			<div className="h-100 d-flex aling-items-center justify-content-center p-1">
				<TripleCubes />
			</div>
			<div className="d-flex f-column" style={{ width: 200 }}>
				<TranslatableText className="text-dark-gray fs-10" translationKey="main.module"/>
				<h3 className="ff-medium fs-13 text-quat-de">
					<TranslatableText translationKey={name}/>
				</h3>
			</div>
			{showDescription ? (
				<p className="text-darker-gray fs-9 ov-y-scroll">
					<TranslatableText translationKey={description}/>
				</p>
			) : null}
		</div>
	);
};

export default ModuleCard;
