const CustomLegend = (props: any) => {
	const { payload } = props;
	return (
        <div style={{ display: "flex", justifyContent: "center",alignItems: "center" }}>
		<ul style={{ listStyleType: "none", paddingLeft: 0}}>
			{payload.map((entry: any, index: number) => {
				const { dataKey, color } = entry;

				const formattedLabel = {
					CO2: "CO<sub>2</sub>",
					CH4: "CH<sub>4</sub>",
					N2O: "N<sub>2</sub>O",
				}[dataKey as 'CO2' | 'CH4' | 'N2O'] || dataKey;

				return (
					<li key={`item-${index}`} style={{ color, margin: "0 10px", display: "inline-block" }}>
						<span
							style={{ color }}
							dangerouslySetInnerHTML={{ __html: formattedLabel }}
						/>
					</li>
				);
			})}
		</ul>
        </div>
	);
};

export default CustomLegend;