import { useEffect, useState } from "react";
import { getIn } from "formik";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import type { SingleValue } from "react-select";
import type { FC } from "react";
import type { DropdownOptions, Option } from "../../types/modulesInterfaces";
import type { FieldProps } from "formik";


interface CustomSelectProps {
	label: string;
	type: "text" | "password" | "select";
	options: DropdownOptions;
	classes?: string;
	disabled?: boolean;
	autosubmit?: boolean;
	onCreate?: (value: string) => Promise<Option | undefined>;
}

interface ReadOnlyOption {
	readonly label: string;
	readonly value: number;
}

const createOption = (option: Option): ReadOnlyOption => ({
	label: option.name,
	value: option.id,
});

function determineIfOption(
	option?: undefined | Option | undefined,
): option is Option {
	return !!option && !("isError" in option);
}

const CustomCreatableSelectInput: FC<CustomSelectProps & FieldProps> = ({
	label,
	field,
	form,
	type,
	options,
	classes,
	disabled,
	form: { touched, errors, setTouched, values },
	autosubmit,
	onCreate,
	...props
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [ddOptions, setDdOptions] = useState<ReadOnlyOption[]>([]);
	const error = getIn(errors, field.name);
	const wasTouched = getIn(touched, field.name);

	useEffect(() => {
		const getOptions = async () => {
			let res: Option[] = [];
			setIsLoading(true);
			if (typeof options === "function") {
				const { data } = await options();
				res = data;
			} else if (options) res = options;
			setDdOptions(res.map(createOption));
			setIsLoading(false);
		};

		getOptions();
	}, [options]);

	const handleCreate = async (inputValue: string) => {
		setIsLoading(true);
		const option = await onCreate?.(inputValue);
		if (option && determineIfOption(option)) {
			const newOption = createOption(option);
			setIsLoading(false);
			//  setDdOptions((prev) => [...prev, newOption]);
			const event = {
				persist: () => { },
				target: {
					type: "change",
					id: field.name,
					name: field.name,
					value: newOption?.value,
				},
			};
			field.onChange(event);
		}
		setIsLoading(false);
	};

	const handleSelect = (newValue: SingleValue<ReadOnlyOption>) => {
		if (newValue?.value) {
			const event = {
				persist: () => { },
				target: {
					type: "change",
					id: field.name,
					name: field.name,
					value: newValue?.value,
				},
			};
			field.onChange(event);
		}
	};

	const animatedComponents = makeAnimated();

	const labelEl = (hasValue: boolean) => ({
		":before": {
			content: `"${label}"`,
			transform: `translateY(${hasValue ? "-5px" : 0})`,
			display: hasValue ? "block" : "none",
			fontSize: 10,
			color: "var(--dark-gray)",
			position: "absolute" as const,
			top: 8,
			left: 10,
			zIndex: 100,
		},
	});

	return (
		<CreatableSelect
			isMulti={false}
			placeholder={label}
			components={animatedComponents}
			isDisabled={isLoading || disabled}
			isLoading={isLoading}
			onChange={handleSelect}
			onFocus={() => setTouched({ [field.name]: true })}
			onCreateOption={handleCreate}
			options={ddOptions}
			value={ddOptions?.find((option) => option.value === field.value)}
			menuPortalTarget={document.body}
			styles={{
				indicatorSeparator: () => ({
					display: "none",
				}),
				container: (baseStyles) => ({
					...baseStyles,
					width: "100%",
					height: 36,
					fontFamily: "AIAIAIv4 normal",
				}),
				singleValue: (baseStyles, state) => ({
					...baseStyles,
					fontWeight: 500,
					fontSize: 13,
				}),
				input: (baseStyles) => ({
					...baseStyles,
				}),
				placeholder: (baseStyles) => ({
					...baseStyles,
					color: "var(--dark)",
					fontSize: 11,
				}),

				control: (baseStyles, state) => ({
					...baseStyles,
					border: `1px solid ${state.isDisabled
							? "#999999"
							: state.isFocused
								? "var(--dark-gray)"
								: error && wasTouched
									? "var(--error)"
									: "var(--gray)"
						} !important`,
					borderRadius: 6,
					backgroundColor: state.isDisabled
						? "#eeeeee" : "var(--white)",
					boxShadow: "none",
					...labelEl(state.hasValue),
				}),
				valueContainer: (baseStyles, state) => ({
					...baseStyles,
					color: "var(--gray)",
					fontSize: 13,
					fontFamily: state.hasValue ? "AIAIAIv4 medium" : "AIAIAIv4 normal",
					transform: `translateY(${state.hasValue ? "5px" : 0})`,
				}),
				menuPortal: (baseStyle) => ({ ...baseStyle }),
				menu: (baseStyles) => ({
					...baseStyles,
					background: "white",
					minWidth: 100,
					borderRadius: 8,
					outline: 0,
					border: "1px solid var(--dark-gray)",
					fontSize: 13,
				}),
				option: (baseStyles, state) => ({
					...baseStyles,
					background:
						state.isFocused || state.isSelected ? "#eee" : "transparent",
					color:
						state.isSelected || state.isFocused ? "black" : baseStyles.color,
				}),
			}}
			{...props}
		/>
	);
};

/* isDisabled
isFocused
isSelected
hasValue
 */

/* clearIndicator
container
control
dropdownIndicator
group
groupHeading
indicatorsContainer
indicatorSeparator
input
loadingIndicator
loadingMessage
menu
menuList
menuPortal
multiValue
multiValueLabel
multiValueRemove
noOptionsMessage
option
placeholder
singleValue
valueContainer */

export default CustomCreatableSelectInput;
