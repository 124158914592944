import { Form, Formik } from "formik";
import { ClassicInputGroup, FieldType, InputGroup, Option } from "../../../types/modulesInterfaces";
import GenericInputGroup from "../../input/inputGroup/GenericInputGroup";
import * as Yup from "yup";
import TranslatableText from "../../translations/TranslatableText";
import ModuleButton from "../../button/ModuleButton";
import { useState } from "react";
import { useGetClimateTypesQuery, useGetMoistureTypesQuery } from "../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { motion } from "framer-motion";
import { translate } from "../../../utils/helperFunctions";

interface ClimateIdentifiers {
  mat: number | null, //mean annual temperature
  map: number | null,//mean annual precipitation
  pet: number | null, //potential evapotranspiration 
  elevation: number | null //elevation above the sea
}

const initValues: ClimateIdentifiers = {
  mat: null,
  map: null,
  pet: null,
  elevation: null
}


const meanAnnualTemperature: ClassicInputGroup = {
  type: FieldType.CLASSIC,
  inputName: "mat",
  inputType: "number",
  label: "map.mat",
  unit: "[C]"
};

const meanAnnualPrecipitation: ClassicInputGroup = {
  type: FieldType.CLASSIC,
  inputName: "map",
  inputType: "number",
  label: "map.map",
  unit: "[mm]"
};

const potentialEvapotranspiration: ClassicInputGroup = {
  type: FieldType.CLASSIC,
  inputName: "pet",
  inputType: "number",
  label: "map.pet",
  unit: "[mm]"
};

const elevation: ClassicInputGroup = {
  type: FieldType.CLASSIC,
  inputName: "elevation",
  inputType: "number",
  label: "map.elevation",
};

const inputGroups: InputGroup[] = [
  meanAnnualTemperature,
  meanAnnualPrecipitation,
  potentialEvapotranspiration,
  elevation
]

const validationSchema = Yup.object().shape({
  mat: Yup.number().nullable().required('validations.mat_required'),
  map: Yup.number().nullable().required('validations.map_required').min(0, 'validations.field_cant_be_negative'),
  pet: Yup.number().nullable().required('validations.pet_required').min(0, 'validations.field_cant_be_negative'),
  elevation: Yup.number().nullable().required('validations.elevation_required').min(0, 'validations.field_cant_be_negative')
  });

const calculateClimate = ({ mat, elevation }: ClimateIdentifiers) => {
  if (mat === null || elevation === null) return undefined;

  if (mat > 18) return elevation < 1000 ? "Tropical" : "Tropical Montane";
  if (mat > 10) return "Warm Temperate"; // 10 < mat <= 18
  if (mat > 0) return "Cool Temperate"; // 0 < mat <= 10
  return "Boreal"; // mat <= 0
  // no polar
}

const calculateMoisture = ({ mat, map, pet, elevation }: ClimateIdentifiers) => {
  if (mat === null || elevation === null || map === null || pet === null) return undefined;

  if (mat <= 18) return map > pet ? "Moist" : "Dry"; // map <= pet
  if (elevation >= 1000) return "Dry"; // mat > 18
  return map >= 2000 ? "Wet" : map >= 1000 ? "Moist" : "Dry"; // mat > 18 && elevation < 1000
}


const ClimateIdentifier = () => {
  // const [climate, setClimate] = useState<Option | null>();
  // const [moisture, setMoisture] = useState<Option | null>();
  const [climate, setClimate] = useState<string>();
  const [moisture, setMoisture] = useState<string>();
  const { data: climateTypes } = useGetClimateTypesQuery();
  const { data: moistureTypes } = useGetMoistureTypesQuery();

  const handleSubmit = (values: ClimateIdentifiers) => {
    console.log(values)
    // console.log("climates", climateTypes)
    // console.log("moistures", moistureTypes)

    setClimate(calculateClimate(values))
    setMoisture(calculateMoisture(values))
  }

  return (
    <div>
      <Formik initialValues={initValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form className="pt-2">
          <h3>
            <TranslatableText translationKey="map.climate_identifier" className="ff-medium" />
          </h3>

          {inputGroups.map((input) => (
            <div className="py-1" key={input.label}>
              <GenericInputGroup
                inputGroup={{ ...input }}
              />
            </div>
          ))}

          <div className="w-100 d-flex justify-content-end">
            <ModuleButton labelKey="map.calculate" />
          </div>
        </Form>
      </Formik>

      {moisture || climate ? //TODO ADD ANIMATION
        <motion.div>
          <div className="pt-2">
            <TranslatableText translationKey="main.climate" />: {climate}
          </div>
          <div className="pt-2">
            <TranslatableText translationKey="main.moisture" />: {moisture}
          </div>
        </motion.div>
        : null}
    </div>

  );
}

export default ClimateIdentifier;