import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import { useAppDispatch, useDebounce } from "../../../app/hooks";
import { setNewCurrentModuleNotes } from "../../../app/features/builder/builderSlice";
import Button from "../../button/Button";
import Spinner from "../../spinner/Spinner";
import { BiSave } from "react-icons/bi";
import TranslatableText from "../../translations/TranslatableText";

const TOOLBAR_OPTIONS = [
	["bold", "italic"],
	["link", "blockquote", "code-block"],
	[{ list: "ordered" }, { list: "bullet" }],
];

const NoteTextEditor = ({ notes,isLoading,onSave,errorMessage }: { notes: string | null, isLoading: boolean, onSave: (newNote: string) => void,errorMessage: string }) => {
	const [note, setNote] = useState<string | null>(notes);
	const debouncedNote = useDebounce(note, 500);
	const dispatch = useAppDispatch();

	const handleNoteChange = (content: string) =>
		setNote(content);
	
	// useEffect(() => {
	// 	dispatch(setNewCurrentModuleNotes(debouncedNote));
	// }, [debouncedNote, dispatch])

	return (
		<>
			<ReactQuill
				className="note-text-editor-container"
				modules={{ toolbar: TOOLBAR_OPTIONS }}
				placeholder={"Write a note..."}
				onChange={handleNoteChange}
				bounds={'.App'}
				value={note ?? undefined}
				theme="snow"
			/>
			<div className="w-100 d-flex justify-content-between pt-2">
				<p className="text-error fs-13 ps-1">
					{errorMessage}
				</p>
				<Button
					classes="standard-action-button"
					onClick={() => {
						if (debouncedNote) onSave(debouncedNote)
					}}
				>
					{isLoading ? (
						<Spinner size={36} padding={8} isBlack />
					) : (
						<BiSave size={36} className="px-1" />
					)}
					<TranslatableText className="fs-12 ps-1 ff-normal-cond" translationKey="module.save_note"/>
				</Button>
			</div>
		</>
	);
};

export default NoteTextEditor;
