import React from 'react'
import { useTranslation } from 'react-i18next';
import TranslatableText from '../translations/TranslatableText';
import { TranslationKey } from '../../types/modulesInterfaces';

interface BaseLabelProps {
    label: string
    className?: string
}

const BaseLabel: React.FC<BaseLabelProps> = ({
    label,
    className
}) => {
    const { i18n } = useTranslation();
    const translationExists = i18n.exists(label);

  return (
    <div className={className}>
        {
            translationExists ? (
                <TranslatableText translationKey={label as TranslationKey} />
            ) : (
                <span>{label}</span>
            )
        }
    </div>
  )
}

export default BaseLabel