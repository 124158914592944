import React, { useContext } from 'react'
import { ForestParentChanges } from './ForestChangeBlock'
import ForestContext, { useForestContext } from '../../../../../contexts/ForestContext'

const useForestTypesValues = ({ change }: { change: ForestParentChanges }) => {
    const forestContext = useContext(ForestContext);

    const formatValue = (value: string | null, types: Array<{ id: number, name: string }> | undefined) => {
        if (!value || !types) return value;
        return types.find(type => type.id.toString() === value)?.name || value;
    };

    const { new: newValue, old: oldValue, field } = change;
    const isForestConditionType = field === "forest_condition_type";
    const isLandUseType = field.includes("land_use_type");

    let formattedNew = newValue;
    let formattedOld = oldValue;

    if (isForestConditionType && forestContext?.forestTypes) {
        formattedNew = formatValue(newValue, forestContext.forestTypes);
        formattedOld = formatValue(oldValue, forestContext.forestTypes);
    }

    if (isLandUseType && forestContext?.lucTypes) {
        formattedNew = formatValue(newValue, forestContext.lucTypes);
        formattedOld = formatValue(oldValue, forestContext.lucTypes);
    }

    return { formattedNew, formattedOld };
};

export default useForestTypesValues