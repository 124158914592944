import React, { createContext, useContext } from 'react';

const AppContext = createContext<string | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isProd = process.env.REACT_ENV === 'PROD';
  const bucketUrl = isProd ? "https://storage.googleapis.com/fao-exact-fao-exact-frontend-static" : "https://storage.googleapis.com/fao-exact-review-fao-exact-frontend-static" ;

  return (
    <AppContext.Provider value={bucketUrl} >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};