import { FieldArray, Form, useFormikContext } from "formik";
import type { Submodule } from "../../../../types/modulesInterfaces";
import type { FloodedMinorSeasonData, ModuleFull } from "./floodedRiceTypes";
import { motion } from "framer-motion";
import FormSubmodule from "../../../../components/formSubmodule/FormSubmodule";
import { TierTwoBlock } from "../GenericTierTwo";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import { useGetFloodedRiceMinorSeasonDefaultsQuery } from "../../../../app/features/api/modules/floodedRiceApiSlice";
import { useEffect } from "react";
import { useCustomTranslation, usePermissions, useWarningMessageT2 } from "../../../../app/hooks";
import DefaultsErrorNote from "../../../../components/messageNote/DefaultsErrorNote";
import useErrorMessage from "../../../../utils/useErrorMessage";
import { handleDisableSww } from "../moduleUtils";
import { preventFormSubmit } from "../inputs/utils";
import LoadDefaultsButton from "../LoadDefaultsButton";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};

interface FRTierTwoProps {
	tabsOpen: boolean;
	parentSchema: Submodule[];
	seasonSchema: Submodule;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	warningMessage?: string | null

}

const FloodedRiceTierTwo = ({
	tabsOpen,
	parentSchema,
	seasonSchema,
	setInitValues,
	warningMessage
}: FRTierTwoProps) => {
	const { values } = useFormikContext<ModuleFull>();
	const { warningMessage: childwarningMessage, handleDefaultsError } = useWarningMessageT2();
	const { isReadOnly } = usePermissions();
	const translatedTitle = useCustomTranslation("project.flooded_rice");
	if (tabsOpen) return null;
	return (
		<motion.div initial={false} variants={variants}>
			<Form onKeyDown={preventFormSubmit} className="py-2">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-13 ff-normal-ext module-header w-fit">
						{translatedTitle} - Tier 2
					</h2>
					{
						warningMessage && <DefaultsErrorNote noteMessage={warningMessage} />
					}
				</div>

				<LoadDefaultsButton />

				{parentSchema.map((subModule) => (
					<FormSubmodule submoduleName={subModule.name} key={subModule.name} className="pt-2">
						<TierTwoBlock>
							{subModule.inputGroups.map((input, index) => {
								const curatedInput = handleDisableSww({ input, isReadOnly, index });
								return (
									<GenericInputGroup
										key={`${input.inputName}${index}`}
										inputGroup={{ ...curatedInput, disabled: isReadOnly }}
									/>
								)
							})}
						</TierTwoBlock>
					</FormSubmodule>
				))}
				{
					childwarningMessage && <DefaultsErrorNote noteMessage={childwarningMessage} />
				}
				<FieldArray name="seasons">
					{() => (
						<>
							{values.seasons.length > 0
								? values.seasons.map((season, index) => (
									<MinorSeasonT2
										season={season}
										seasonSchema={seasonSchema}
										setInitValues={setInitValues}
										index={index}
										key={season.id}
										onDefaultsError={handleDefaultsError}
										disabled={isReadOnly}
									/>
								))
								: null}
						</>
					)}
				</FieldArray>
			</Form>
		</motion.div>
	);
};

interface MinorSeasonProps {
	season: FloodedMinorSeasonData;
	seasonSchema: Submodule;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	index: number;
	onDefaultsError: (message: string) => void
	disabled?: boolean
}

const MinorSeasonT2 = ({
	season,
	seasonSchema,
	index,
	setInitValues,
	onDefaultsError,
	disabled
}: MinorSeasonProps) => {
	const { data: defaults, isError, error } = useGetFloodedRiceMinorSeasonDefaultsQuery(
		season?.id ?? 0,
		{ skip: !season?.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });


	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				seasons: prev.seasons.map((s) =>
					s.id === season.id ? { ...s, ...defaults } : s,
				),
			}));
		if (isError && defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);

	}, [defaults, season, setInitValues, defaultsErrorMsg, isError, onDefaultsError]);

	return (
		<FormSubmodule submoduleName={`Season id: ${season.id}`} key={season.id} className="pt-2">
			<TierTwoBlock>
				{seasonSchema.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index });
					return (
						<GenericInputGroup
							key={`${input.label}-${i}`}
							inputGroup={{ ...curatedInput, index }}
						/>
					)
				})}
			</TierTwoBlock>
		</FormSubmodule>
	);
};

export default FloodedRiceTierTwo;
