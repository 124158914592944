import { apiSlice } from "../apiSlice";
import type { TagTypes } from "../apiSlice";
import type { EmissionsByGas } from "../../../../pages/project/results/resultTypes";
import type { ChangesResponseType } from "../../../../components/actions/changes/changesTypes";
import { Note } from "../../../../types/modulesInterfaces";

interface ModuleResPayload {
	uniqueId: number;
	path: string;
}

export interface NotesCreatePayload {
	content: string | null;
	module_type_id: number;
	module_id: number;
}

export interface NotesUpdatePayload {
	id: number;
	content: string | null;
}
export interface NotesDeletePayload {
	id: number;
}
export interface GetDefaultProps { uniqueId: number, route: string, cacheTag: TagTypes }

export const moduleResApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getModuleResultsByGas: builder.query<EmissionsByGas, ModuleResPayload>({
			query: ({ path, uniqueId }) => `${path}/${uniqueId}/results/?aggregate=gas`,
			providesTags: (_res, _error, { uniqueId, path }) => [
				{ type: "ModuleResults", id: path !== "land-use-changes" ? `${path}-${uniqueId}` : "land-use-changes" },
			],
		}),
		deleteModuleNotes: builder.mutation<Note, { id: number }>({
			query: ({ id }) => {
				return {
					url: `notes/${id}/`,
					method: "DELETE",
				}
			},

		}),
		createModuleNotes: builder.mutation<Note, NotesCreatePayload>({
			query: (body) => {
				return {
					url: "notes/",
					method: "POST",
					body,
				};
			}
		}),
		updateModuleNotes: builder.mutation<Note, NotesUpdatePayload>({
			query: ({ id, content }) => {
				return {
					url: `notes/${id}/`,
					method: "PATCH",
					body: { content }
				};
			},
		}),
		getModuleHistory: builder.query<ChangesResponseType[], ModuleResPayload>({
			query: ({ path, uniqueId }) => `${path}/${uniqueId}/history/`,
			transformResponse: (response: ChangesResponseType[]) => response.map((change) => ({ ...change, date: new Date(change.date).toISOString() })),
			providesTags: (_res, _error, { uniqueId, path }) => [
				{ type: "ModuleHistory", id: `${path}-${uniqueId}` },
			],
		}),
		// biome-ignore lint/suspicious/noExplicitAny: 
		getSubmoduleDefaults: builder.query<any, GetDefaultProps>({
			query: ({ uniqueId, route }) => `${route}/${uniqueId}/defaults/`,
			providesTags: (_res, _error, { uniqueId, cacheTag }) => [{ type: cacheTag, id: uniqueId }],
		})
	})
});

export const {
	useGetModuleResultsByGasQuery,
	useUpdateModuleNotesMutation,
	useGetModuleHistoryQuery,
	useGetSubmoduleDefaultsQuery,
	useCreateModuleNotesMutation,
	useDeleteModuleNotesMutation
} = moduleResApiSlice;
