import { useState, FC, useContext, useEffect, FocusEvent } from "react";
import DatePicker from "react-datepicker";
import { FieldProps, getIn, useFormikContext, FormikProps } from "formik";
import { FormFieldContext } from "./formRow/FormRow";

import "react-datepicker/dist/react-datepicker.css";
import OutsideClickSub from "../outsideClickSub/OutsideClickSub";
import { useDebounce } from "../../app/hooks";

interface CustomProps {
	label: string;
	disabled?: boolean;
	autosubmit?: boolean;
}
const CustomCalendarInput: FC<FieldProps & CustomProps> = ({
	field,
	form: { touched, errors },
	disabled,
	label,
	autosubmit,
}) => {
	const error = getIn(errors, field.name);
	const wasTouched = getIn(touched, field.name);
	const [focused, setFocused] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<Date>(
		field.value ? new Date(field.value) : new Date(),
	);
	const debouncedDate = useDebounce<Date>(startDate, 500);

	const { state, setState } = useContext(FormFieldContext);
	const { setFieldValue, submitForm } = useFormikContext();
	const formikProps = useFormikContext();
	useEffect(() => {
		const newState = focused
			? "focused"
			: !!(wasTouched && error)
				? "error"
				: field.value
					? "idleValue"
					: "idle";
		if (state !== newState) {
			setState(newState);
		}
	}, [error, wasTouched, focused, field.value, setState, state]);

	useEffect(() => {
		if (autosubmit && wasTouched && submitForm) submitForm();
	}, [debouncedDate]);

	const handleFocus = () => setFocused(true);

	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		field.onBlur(e);
		setFocused(false);
	};
	const handleChange = (val: Date) => {
		setFieldValue(field.name, val);
		setStartDate(val);
	};

	const idleLable: boolean = state !== "focused" && !startDate;

	return (
		<OutsideClickSub
			className={`form-group ${
				state === "focused" ? "focused" : state === "error" ? "error" : ""
			} ${disabled ? "disabled" : ""}`}
			callback={() => setFocused(false)}
		>
			<>
				{/* <div style={{ transform: idleLable ? "none" : "translateY(5px)" }}> */}
				<DatePicker
					className={`b-none ${
						idleLable ? "trans-none" : "trans-y-7"
					} ff-medium fs-13 w-100 h-100`}
					{...field}
					dateFormat="dd/MM/yyyy"
					selected={startDate}
					disabled={disabled}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={handleChange}
				/>
				{/* </div> */}
				<label
					className={`form-group-label unclickable ${!idleLable && "small"} ${
						state === "error" && "error"
					}`}
				>
					<span>{label}</span>
				</label>
			</>
		</OutsideClickSub>
	);
};

export default CustomCalendarInput;
