import React, { useContext } from 'react'
import Button from '../button/Button'
import { IoArrowBackSharp } from 'react-icons/io5'
import { BiMenu } from 'react-icons/bi'
import { SideBarContext } from './provider'

const MenuToggle = () => {
    const { isSideBarOpen, toggleSidebar } = useContext(SideBarContext)
    return (
        <div className="pt-2" style={{
            display: 'flex',
            justifyContent: !isSideBarOpen ? 'center' : 'flex-end',
            paddingRight: isSideBarOpen ? '10px' : '0px',
        }}>
            <Button
                whileHoverProps={{ scale: 1.1 }}
                whileTapProps={{ scale: 0.9 }}
                onClick={toggleSidebar}
                style={{
                    alignItems: "center",
                    zIndex: 1100
                }}
            >
                {isSideBarOpen ? <IoArrowBackSharp size={20} color="#fff" /> : <BiMenu size={20} color="#fff" />}
            </Button>
        </div>)
}

export default MenuToggle