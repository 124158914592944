import { ReactNode, useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentBuilder } from "../../app/features/builder/builderSlice";
import { useLocation } from "react-router-dom";

const BuilderMain = ({
	children,
	className,
}: {
	children?: ReactNode;
	className?: string;
}) => {
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);
	const { pathname } = useLocation();
	const isInProject = useMemo(
		() => pathname.split("/")[1] === "project",
		[pathname],
	);

	return (
		<div
			className={`builder-main${
				!tabsOpen && !isInProject ? " tabs-closed" : ""
			} ${className ?? ""}`}
		>
			{children}
		</div>
	);
};

export default BuilderMain;
