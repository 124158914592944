import { useContext } from "react";
import { useGetModuleHistoryQuery } from "../../../../app/features/api/modules/genericOperationsApiSlice";
import { useCustomTranslation } from "../../../../app/hooks";
import { useSearch } from "../../../../app/hooks";
import { ChangesContext, UserChanges } from "../ChangesAction";

interface ChangeBlockProps {
    itemId: number;
    path: string;
    title: string;
  }
  
  const ChangesChildAction: React.FC<ChangeBlockProps> = ({ itemId, path, title }) => {
    const { searchString } = useContext(ChangesContext)
    const { data: moduleHistory, isLoading } = useGetModuleHistoryQuery(
      {
        path,
        uniqueId: itemId,
      },
      { skip: !itemId }
    );
    const translatedLoading = useCustomTranslation('main.loading');
    const translatedHistory = useCustomTranslation("main.history");
    const translatedChangesOn = useCustomTranslation("main.changes_on");
    const { searchResults } = useSearch({ list: moduleHistory ?? [], searchString })

    return (
      <section className="w-100 px-2 pb-2 bg-gray d-flex f-column">
        {isLoading ? (
          <div className="ps-1 ff-bold fs-14">{translatedLoading} {title} {itemId} {translatedHistory?.toLocaleLowerCase()}...</div>
        ) : searchResults?.length === 0 ? (
          null
        ) : (
          <>
            <div className="ps-1 ff-bold fs-14">{translatedChangesOn} {title} {itemId}</div>
            {searchResults?.map((userChanges) => (
              <UserChanges
                key={`${userChanges.user}${userChanges.date}`}
                changes={userChanges}
              />
            ))}
          </>
        )}
      </section>
    );
  };

export default ChangesChildAction
