import type {
	Submodule,
	InputGroup,
	StartWithWithoutGeneric,
	DropdownInputGroup,
	StartWithWithoutTierTwo,
	ModuleSchema,
	T2Schema
} from "../../../../types/modulesInterfaces";
import type {
	LivestockManagementPayload,
	LivestockManagementT2Payload,
} from "./livestockManagementTypes";
import { FieldType } from "../../../../types/modulesInterfaces";
import * as Yup from "yup";
import { validatePercentage, validatePositiveNumber } from "../moduleUtils";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";

export const includedPropList = [
	"id",
	"status",
	"note",
	"livestock_category_type",
	"heads_number_start",
	"heads_number_w",
	"heads_number_wo",
	"livestock_production_type_start",
	"livestock_production_type_w",
	"livestock_production_type_wo",
	"production_start",
	"production_w",
	"production_wo",
	"prp_percentage_t2_start",
	"prp_percentage_t2_w",
	"prp_percentage_t2_wo",
	"complementary_manure_management_type_start",
	"complementary_manure_management_type_w",
	"complementary_manure_management_type_wo",
	"activity", //!!
];

export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"enteric_fermentation_t2_start",
	"enteric_fermentation_t2_w",
	"enteric_fermentation_t2_wo",
	"prp_ch4_t2_start",
	"prp_ch4_t2_w",
	"prp_ch4_t2_wo",
	"prp_n2o_t2_start",
	"prp_n2o_t2_w",
	"prp_n2o_t2_wo",
	"emission_factor_ch4_t2_start",
	"emission_factor_n2o_t2_start",
	"emission_factor_ch4_t2_w",
	"emission_factor_n2o_t2_w",
	"emission_factor_ch4_t2_wo",
	"emission_factor_n2o_t2_wo",
];

const livestockCategories = () => getOptions("livestockCategory");
const livestockMngmTypes = () => getOptions("livestockMngmTypes");
const livestockProds = () => getOptions("livestockProd");

export const validationSchema = Yup.object({
	module: Yup.object().shape({
		prp_percentage_t2_start: validatePercentage.when("complementary_manure_management_type_start", {
			is: (complementary_manure_management_type_start: number | null) =>
				complementary_manure_management_type_start && complementary_manure_management_type_start > 0,
			then: validatePercentage.required('validations.percentage_heads_required_if_complementary_manure'),
		}),
		prp_percentage_t2_w: validatePercentage.when("complementary_manure_management_type_w", {
			is: (complementary_manure_management_type_w: number | null) =>
				complementary_manure_management_type_w && complementary_manure_management_type_w > 0,
			then: validatePercentage.required('validations.percentage_heads_required_if_complementary_manure'),
		}),
		prp_percentage_t2_wo: validatePercentage.when("complementary_manure_management_type_wo", {
			is: (complementary_manure_management_type_wo: number | null) =>
				complementary_manure_management_type_wo && complementary_manure_management_type_wo > 0,
			then: validatePercentage.required('validations.percentage_heads_required_if_complementary_manure'),
		}),
		livestock_category_type: validatePositiveNumber.required(
			'validations.livestock_category_required',
		),
		heads_number_start: validatePositiveNumber.required('validations.heads_number_required'),
		heads_number_w: validatePositiveNumber.required('validations.heads_number_required'),
		heads_number_wo: validatePositiveNumber.required('validations.heads_number_required'),
		livestock_production_type_start: validatePositiveNumber.required(
			'validations.livestock_productivity_required',
		),
		livestock_production_type_w: validatePositiveNumber.required(
			'validations.livestock_productivity_required',
		),
		livestock_production_type_wo: validatePositiveNumber.required(
			'validations.livestock_productivity_required',
		),
	}),
});

export const initialValues: LivestockManagementPayload = {
	id: null,
	status: null,
	note: null,
	livestock_category_type: null,
	heads_number_start: null,
	heads_number_w: null,
	heads_number_wo: null,
	livestock_production_type_start: null,
	livestock_production_type_w: null,
	livestock_production_type_wo: null,
	production_start: null,
	production_w: null,
	production_wo: null,
	prp_percentage_t2_start: null,
	prp_percentage_t2_w: null,
	prp_percentage_t2_wo: null,
	complementary_manure_management_type_start: null,
	complementary_manure_management_type_w: null,
	complementary_manure_management_type_wo: null,
};

const getInitInputGroups: () => InputGroup[] = () => {
	const livestockCategory: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.livestock_category_required",
		inputName: "module.livestock_category_type",
		dropdownOptions: livestockCategories,
	};

	const numberOfHeads: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.number_of_heads_required",
		inputName: "module.heads_number",
	};

	return [livestockCategory, numberOfHeads];
};

const getMandatorySubmodules: () => Submodule[] = () => {
	const livestockProductivity: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.livestock_productivity_required",
		inputName: "module.livestock_production_type",
		startProps: { dropdownOptions: livestockProds },
		withProps: { dropdownOptions: livestockProds },
		withoutProps: { dropdownOptions: livestockProds },
	};
	const production: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.production",
		inputName: "module.production",
		unit: "[unit of product]",
	};

	const headsOnPasture: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.heads_on_pasture",
		inputName: "module.prp_percentage_t2",
		unit: "[%]",
	};
	const complementaryManure: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.specific_manure_management",
		inputName: "module.complementary_manure_management_type",
		startProps: { dropdownOptions: livestockMngmTypes },
		withProps: { dropdownOptions: livestockMngmTypes },
		withoutProps: { dropdownOptions: livestockMngmTypes },
	};

	return [
		{
			name: "module.production_management",
			inputGroups: [livestockProductivity, production],
		},
		{
			name: "module.manure_management_optional",
			inputName: "module.manureManagement",
			inputGroups: [complementaryManure, headsOnPasture],
		},
	];
};


/* T2 */
export const t2InitialValues: LivestockManagementT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	enteric_fermentation_t2_start: null,
	enteric_fermentation_t2_w: null,
	enteric_fermentation_t2_wo: null,
	prp_percentage_t2_start: null,
	prp_percentage_t2_w: null,
	prp_percentage_t2_wo: null,
	prp_ch4_t2_start: null,
	prp_ch4_t2_w: null,
	prp_ch4_t2_wo: null,
	prp_n2o_t2_start: null,
	prp_n2o_t2_w: null,
	prp_n2o_t2_wo: null,
	emission_factor_ch4_t2_start: null,
	emission_factor_n2o_t2_start: null,
	emission_factor_ch4_t2_w: null,
	emission_factor_n2o_t2_w: null,
	emission_factor_ch4_t2_wo: null,
	emission_factor_n2o_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.enteric_fermentation_t2",
			label: "module.enteric_fermentation",
			unit: "[kg CH4/head/yr]",
			links: ["Livestock Category", "Livestock Productivity"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.prp_ch4_t2",
			label: "module.ch4_from_pasture_manure_management",
			unit: "[kg CH4/head/yr]",
			links: ["Livestock Category", "Livestock Productivity"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.prp_n2o_t2",
			label: "module.n2o_from_pasture_manure_management",
			unit: "[kg N2O/head/yr]",
			links: ["Livestock Category", "Livestock Productivity"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.emission_factor_ch4_t2",
			label: "module.ch4_complementary_manure_management",
			unit: "[kg CH4/head/yr]",
			links: ["Complementary Manure Management", "Livestock Category", "Livestock Productivity"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.emission_factor_n2o_t2",
			label: "module.n2o_complementary_manure_management",
			unit: "[kg N2O/head/yr]",
			links: ["Complementary Manure Management", "Livestock Category", "Livestock Productivity"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	return [
		{
			name: "",
			inputGroups: [...rows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: getMandatorySubmodules(),
	};
	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
