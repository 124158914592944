import { useContext } from "react";
import { motion } from "framer-motion";
import MenuToggle from "./MenuToggle";
import { SideBarContext, SideBarProvider } from "./provider";
import MenuContent from "./MenuContent";
import OutsideClickSub from "../outsideClickSub/OutsideClickSub";
const Sidebar = () => {
	return (
		<SideBarProvider>
			<SideBarContent />
		</SideBarProvider>
	)
}

export default Sidebar;

const SideBarContent = () => {
	const { isSideBarOpen, toggleSidebar } = useContext(SideBarContext)
	return (
		<motion.aside
			className="side"
			animate={{ width: isSideBarOpen ? "250px" : undefined }}
			transition={{ type: "spring", stiffness: 300, damping: 30 }}
		>
			<OutsideClickSub
				callback={isSideBarOpen ? toggleSidebar : () => { }}
			>
				<>
					<MenuToggle />
					<MenuContent />
				</>
			</OutsideClickSub>
		</motion.aside>
	)
}
