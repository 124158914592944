import type { User } from "../../../types/interfaces";
import { apiSlice } from "../api/apiSlice";
import type {
	LoginPayload,
	LoginResponse,
	PasswordResetPayload,
	RegisterPayload,
	RegisterResponse,
	UpdatePasswordPayload
} from "./authTypes";

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<LoginResponse, LoginPayload>({
			query: (credentials) => ({
				url: "/accounts/login/",
				method: "POST",
				body: { ...credentials },
			}),
		}),
		register: builder.mutation<RegisterResponse, RegisterPayload>({
			query: (credentials) => ({
				url: "/accounts/register/",
				method: "POST",
				body: { ...credentials },
			}),
		}),
		updateUser: builder.mutation<User, Partial<User>>({
			query: (user) => ({
				url: `/users/${user.id}/`,
				method: "PATCH",
				body: user,
			}),
		}),
		resetPassword: builder.mutation<void, PasswordResetPayload>({
			query: ({ email }) => ({
				url: "accounts/password-reset/",
				method: "POST",
				body: { email }
			})
		}),
		deleteUser: builder.mutation<void, number>({
			query: (id) => ({
				url: `/users/${id}/`,
				method: "DELETE",
			}),
		}),
		updatePassword: builder.mutation<void, UpdatePasswordPayload>({
			query: ({ userId, old_password, new_password }) => ({
				url: `users/${userId}/reset-password/`,
				method: "POST",
				body: {
					password_new: new_password,
					password_old: old_password
				}
			})
		})
	}),
});

export const { useLoginMutation, useRegisterMutation, useUpdateUserMutation, useResetPasswordMutation, useDeleteUserMutation, useUpdatePasswordMutation } =
	authApiSlice;
