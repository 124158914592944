import type { User } from "../../types/interfaces";
import { motion } from "framer-motion";
import { Formik } from "formik";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { rightModalVariants } from "./utils";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentUser } from "../../app/features/auth/authSlice";
import { useUpdateUserMutation } from "../../app/features/auth/authApiSlice";
import { setUser } from "../../app/features/auth/authSlice";

import Backdrop from "../modal/Backdrop";
import UserModalForm from "./UserModalForm";
import useErrorMessage from "../../utils/useErrorMessage";
import KebabMenu, { Line } from "../kebabMenu/KebabMenu";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useDeleteUser } from "./useDeleteUser";
import { BiTrash, BiKey } from "react-icons/bi";
import TranslatableText from "../translations/TranslatableText";
import UpdatePasswordModal from "./UpdatePasswordModal";
import * as Yup from "yup"

interface UserForm {
	first_name: string;
	last_name: string;
	email: string;
	organization?: string,
	country: number | null;
	/*language: number,*/
}

const initialValues: UserForm = {
	first_name: "",
	last_name: "",
	email: "",
	organization: "",
	country: null,
};
const ModalContext = createContext<{ showDeleteModal: () => void, showUpdatePasswordModal: () => void } | null>(null);

const validationSchema = Yup.object().shape({
	first_name: Yup.string()
		.required('first_name_required'),
	last_name: Yup.string()
		.required('last_name_required'),
	email: Yup.string()
		.email("Invalid email format")
		.required('email_required'),
	organization: Yup.string()
		.optional(),
	country: Yup.number()
		.required('country_required')
		.nullable(),
});


const UserModal = ({ handleClose }: { handleClose: () => void }) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectCurrentUser);
	const [initValues, setInitValues] = useState<UserForm>(initialValues);
	const [isUpdatePasswordModalVisible, showUpdatePasswordModal] = useState<boolean>(false);
	const [updateUser, { isError, error }] = useUpdateUserMutation();
	const { errorMsg } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (user) setInitValues(user);
	}, [user]);

	const handleSubmit = async (values: UserForm) => {
		if (!user?.id) return;
		const payload: Partial<User> = {
			id: user?.id,
			first_name: values.first_name,
			last_name: values.last_name,
			email: values.email,
			country: values.country ?? undefined,
			organization: values.organization
		};

		try {
			const user = await updateUser(payload).unwrap();
			dispatch(setUser(user));
		} catch (error) {
			console.error(error);
		}
	};
	const {
		isDeleteDialogOpen,
		handleCancel,
		handleDelete,
		showDeleteModal,
	} = useDeleteUser();

	return (
		<ModalContext.Provider value={{ showDeleteModal, showUpdatePasswordModal: () => showUpdatePasswordModal(true) }}>
			<Backdrop onClick={handleClose}>
				<motion.div
					onClick={(e) => e.stopPropagation()}
					variants={rightModalVariants}
					className="right-side-modal"
					initial="hidden"
					animate="visible"
					exit="exit"
				>
					<Formik
						initialValues={initValues}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
						enableReinitialize
					>
						<>
							<Header user={user} />
							<UserModalForm />

							<p className="text-error fs-13 pt-2">{errorMsg}</p>
						</>
					</Formik>
				</motion.div>
			</Backdrop>
			<ConfirmationModal
				open={isDeleteDialogOpen}
				onCancel={handleCancel}
				onConfirm={handleDelete}
				message={<UserDeleteConfirmMessage />}
				headerText="user.user_delete_header"
				size="md"
			/>
			<UpdatePasswordModal closeDialog={() => showUpdatePasswordModal(false)} isUpdatePasswordDialogOpen={isUpdatePasswordModalVisible} />
		</ModalContext.Provider>
	);
};


const Header = ({ user }: { user: User | null }) => {
	const context = useContext(ModalContext);

	const lines: Line[] = useMemo(
		(): Line[] => [
			{
				id: 0,
				label: "user.change_password",
				icon: BiKey,
				clickHandler: () => context?.showUpdatePasswordModal()
			},
			{
				id: 1,
				label: "user.delete_user",
				icon: BiTrash,
				clickHandler: () => context?.showDeleteModal(),
				activeColor: "var(--error)"
			},
		],
		[],
	);

	return (
		<header className="w-100 d-flex justify-content-between">
			<div className="fs-18 d-flex f-column">
				<div className="d-flex">
					<TranslatableText translationKey="main.hello" />,
				</div>
				<span>
					{user?.first_name} {user?.last_name}
				</span>
			</div>
			<div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
				<KebabMenu lines={lines} activeColor="var(--primary-deep)" position="left" />
			</div>
		</header>
	);
};

export default UserModal;


const UserDeleteConfirmMessage = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
			<TranslatableText translationKey="user.user_delete_confirmation" />
			<TranslatableText translationKey="user.user_delete_confirmation_2" />
		</div>
	)
}