import type {
	ModuleProps,
	T2Schema,
	TranslationKey,
} from "../../../../types/modulesInterfaces";
import {
	Settlements,
	type BuildingResponse,
	type ModuleFull,
	type OtherCreatePayload,
	type OtherResponse,
	type RoadCreatePayload,
	type RoadResponse,
} from "./settlementsTypes";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";
import GenericModule from "../GenericModule";
import { memo, useContext, useEffect, useMemo } from "react";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useCreateBuildingsMutation,
	useCreateOthersMutation,
	useCreateRoadsMutation,
	useDeleteBuildingsMutation,
	useDeleteOthersMutation,
	useDeleteRoadsMutation,
	useGetBuildingsQuery,
	useGetOthersQuery,
	useGetRoadsQuery,
} from "../../../../app/features/api/modules/settlementsApislice";
import { FEModules } from "../../../../utils/moduleList";
import { useGetLandUseChangesQuery } from "../../../../app/features/api/modules/landUseChangeApiSlice";
import { getListIntersection } from "../inputs/utils";
import { useFormikContext } from "formik";
import { useSettlementContext } from "../../../../contexts/SettlementProvider";
import { isEqual } from 'lodash';

interface SettlementTierOneProps {
	initValues: ModuleFull;
	t2Schema: T2Schema;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}
const SettlementTierOne = ({
	moduleSchema,
	initValues,
	title,
	t2Schema,
	isLoading,
	beModuleType,
	blockingError,
	footerError,
	isError,
	setInitValues,
	note
}: ModuleProps & SettlementTierOneProps) => {
	const moduleProps = {
		title,
		moduleSchema,
		footerError,
		blockingError,
		isError,
		isLoading,
	};
	const moduleContext = useSettlementContext();
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { isReadOnly } = usePermissions()
	const [deleteRoad] = useDeleteRoadsMutation();
	const [deleteBuildings] = useDeleteBuildingsMutation();
	const [deleteOthers] = useDeleteOthersMutation();
	const [addRoad, { isLoading: isLoadingAddRoad }] = useCreateRoadsMutation();
	const [addBuidling, { isLoading: isLoadingAddBuilding }] = useCreateBuildingsMutation();
	const [addOther, { isLoading: isLoadingAddOther }] = useCreateOthersMutation();
	const parentId = moduleList.find(
		(m) => m.id === FEModules.Settlements,
	)?.uniqueId;

	const handleRemoveRoad = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		remove(index);

		if (id)
			deleteRoad({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
	};
	const handleRemoveBuilding = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		remove(index);

		if (id)
			deleteBuildings({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
	};
	const handleRemoveOther = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		remove(index);

		if (id)
			deleteOthers({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
	};
	const handleAddRoad = async () => {
		const payload: RoadCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			await addRoad(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};
	const handleAddBuilding = async () => {
		const payload: RoadCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			await addBuidling(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};
	const handleAddOther = async () => {
		const payload: OtherCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			await addOther(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};
	const genericDeleteHandler = (name: string) => {
		switch (name) {
			case Settlements.Roads:
				return handleRemoveRoad;
			case Settlements.Buildings:
				return handleRemoveBuilding;
			case Settlements.Others:
				return handleRemoveOther;
			default:
				return () => { };
		}
	};

	const genericAddHandler = (name: string) => {
		switch (name) {
			case Settlements.Roads:
				return handleAddRoad;
			case Settlements.Buildings:
				return handleAddBuilding;
			case Settlements.Others:
				return handleAddOther;
			default:
				return () => { };
		}
	};
	const genericValuesHandler = (name: string) => {
		switch (name) {
			case Settlements.Roads:
				return initValues.roads;
			case Settlements.Buildings:
				return initValues.buildings;
			case Settlements.Others:
				return initValues.others;
			default:
				return null;
		}
	};
	const genericFieldArrayName = (name: string) => {
		switch (name) {
			case Settlements.Roads:
				return "roads";
			case Settlements.Buildings:
				return "buildings";
			case Settlements.Others:
				return "others";
			default:
				return "";
		}
	};

	const translationKeySubmodule = (name: Settlements): TranslationKey => {
		switch (name) {
			case Settlements.Roads:
				return "module.add_new_road";
			case Settlements.Buildings:
				return "module.add_new_building";
			case Settlements.Others:
				return "module.add_new_other";
		}
	}

	/* ADD SUBMODULES LOGIC */
	const { values } = useFormikContext<ModuleFull>();
	const { data: roadsData, isLoading: isLoadingRoads } = useGetRoadsQuery(
		{ activityId: activeActivityId ?? 0 },
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { data: buildingsData, isLoading: isLoadingBuildings } = useGetBuildingsQuery(
		{ activityId: activeActivityId ?? 0 },
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { data: othersData, isLoading: isLoadingOthers } = useGetOthersQuery(
		{ activityId: activeActivityId ?? 0 },
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { data: lucData, isLoading: isLoadingLuc } = useGetLandUseChangesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const lists = useMemo(() => {
		const isRoadsUpdated = !isEqual(values.roads, roadsData);
		const isBuildingsUpdated = !isEqual(values.buildings, buildingsData)
		const isOthersUpdated = !isEqual(values.others, othersData);
		const isLucUpdated = !isEqual(lucData, values.roads.map((road) => road.land_use_change)) || !isEqual(lucData, values.buildings.map((building) => building.land_use_change)) || !isEqual(lucData, values.others.map((other) => other.land_use_change));
		const roads: RoadResponse[] | undefined = roadsData?.map((road) => ({
			...road,
			land_use_change: lucData?.module,
		})) as RoadResponse[];
		const buildings: BuildingResponse[] | undefined = buildingsData?.map((building) => ({
			...building,
			land_use_change: lucData?.module,
		})) as BuildingResponse[];
		const others: OtherResponse[] | undefined = othersData?.map((other) => ({
			...other,
			land_use_change: lucData?.module,
		})) as OtherResponse[];
		return {
			roads: isRoadsUpdated || isLucUpdated
				? getListIntersection({
					currentChangesList: values.roads.map((road) => ({ ...road, land_use_change: lucData?.module })) as RoadResponse[],
					incomingList: roads ?? [],
				})
				: values.roads,
			buildings: isBuildingsUpdated || isLucUpdated
				? getListIntersection({
					currentChangesList: values.buildings.map((building) => ({ ...building, land_use_change: lucData?.module })) as BuildingResponse[],
					incomingList: buildings ?? [],
				})
				: values.buildings,
			others: isOthersUpdated || isLucUpdated
				? getListIntersection({
					currentChangesList: values.others.map((other) => ({ ...other, land_use_change: lucData?.module })) as OtherResponse[],
					incomingList: others ?? [],
				})
				: values.others,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roadsData, buildingsData, othersData, lucData]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (lists.roads || lists.buildings || lists.others) {
			setInitValues(() => ({
				...values,
				roads: lists.roads,
				buildings: lists.buildings,
				others: lists.others,
			}));
			if (moduleContext) {
				if (lists.buildings) moduleContext.setBuildingsIds(lists.buildings.map((building) => building.id));
				if (lists.roads) moduleContext.setRoadsIds(lists.roads.map((road) => road.id));
				if (lists.others) moduleContext.setOthersIds(lists.others.map((other) => other.id));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lists, setInitValues]);

	return (
		<GenericModule
			{...moduleProps}
			t2Schema={t2Schema}
			beModuleType={beModuleType}
			disableOptionals
			note={note}
		>
			{
				// not scalable implementation
				({ belongsToLuc, isSWW }) => (
					<>
						{moduleSchema.optionalSubmodules?.map((subModule, index) => {
							return (
								<GenericOptionalSubModule<
									RoadResponse | BuildingResponse | OtherResponse
								>
									key={`${subModule.name}-${index}`}
									optionalSchema={subModule}
									values={genericValuesHandler(subModule.name)}
									createHandler={genericAddHandler(subModule.name)}
									removeHandler={genericDeleteHandler(subModule.name)}
									fieldArrayName={genericFieldArrayName(subModule.name)}
									addLabel={translationKeySubmodule(subModule.name as Settlements)}
									belongsToLuc={belongsToLuc}
									isSWW={isSWW}
									isReadOnly={isReadOnly}
									isOptionalLoading={isLoadingLuc || subModule.name === Settlements.Roads ? isLoadingRoads : subModule.name === Settlements.Buildings ? isLoadingBuildings : isLoadingOthers}
									isCreateLoading={subModule.name === Settlements.Roads ? isLoadingAddRoad : subModule.name === Settlements.Buildings ? isLoadingAddBuilding : isLoadingAddOther}
								/>
							);
						})}
					</>
				)
			}
		</GenericModule>
	);
};

export default memo(SettlementTierOne);
