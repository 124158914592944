import type { ErrorType, ModuleStatus, ModuleType } from "./interfaces";
import type { BEModules } from "../utils/moduleList";
import type { TagTypes } from "../app/features/api/apiSlice";
import translationJson from "../../public/locales/en/translation.json";
import { ChangeEvent } from "react";

interface CachedResultModule {
	updatedAt: string
	last_cached_at: string
	cached_results_total: number | null, //not sure
	cached_results_by_activity: number | null, //not sure
	cached_results_by_gas: number | null, //not sure
	cached_results_by_activity_by_gas: number | null, //not sure
	last_modified: string
}

interface ModuleShared {
	activity: number | null,
	start_year: number | null,
	
	soc_t2_start: number | null;
	soc_t2_w: number | null;
	soc_t2_wo: number | null;
	
	note: Note | null,
	status: ModuleStatus | null;
}

interface LandModule {
	land_use_change?: number | null; // Riferimento a un altro modello
	organic_soil?: number | null; // Riferimento a un altro modello

	area?: number | null;

	land_use_type_start?: number | null; // Riferimento a un altro modello
	land_use_type_w?: number | null; // Riferimento a un altro modello
	land_use_type_wo?: number | null; // Riferimento a un altro modello
	land_use_type_thread?: number | null; // Riferimento a un altro modello

	flu_t2_start?: number | null;
	flu_t2_w?: number | null;
	flu_t2_wo?: number | null;

	fi_t2_start?: number | null;
	fi_t2_w?: number | null;
	fi_t2_wo?: number | null;

	fmg_t2_start?: number | null;
	fmg_t2_w?: number | null;
	fmg_t2_wo?: number | null;
}

interface Comment {
	active: boolean;
	onClick: () => void;
}

interface Help {
	type: "info" | "video";
	onClick: () => void;
}

interface Note {
	id: number
	content: string
	module_type: ModuleType
	module_id: number
}

interface Submodule {
	name: TranslationKey | "";
	inputGroups: InputGroup[];
	help?: Help;
	comment?: Comment;
	note?: Note;
}
export type CommentInputGroup = Pick<InputGroup, 'inputName' | 'label'>
export interface CommentGroup {
	name: string;
	inputGroups: CommentInputGroup[];
}

interface OptionalSubmodule<T> {
	name: TranslationKey | "";
	inputGroups: InputGroup[];
	inputName: string;
	repeatable?: boolean;
	max?: number;
	initialValues?: T;
	help?: Help;
	comment?: Comment;
	note?: Note;
	linkedTierTwoInputs?: string[];
	route?: string, //remember: the module full fields must coincide with the route name
	cacheTag?: TagTypes
}

interface ModuleSchema {
	initInputGroups: InputGroup[];
	mandatorySubmodules: Submodule[];
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	optionalSubmodules?: OptionalSubmodule<any>[];
}

interface T2Schema {
	submodules: Submodule[];
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	optionalSubmodules?: OptionalSubmodule<any>[];
}

interface BelongsToLuc {
	is_start?: boolean;
	is_w?: boolean;
	is_wo?: boolean;
}

interface ModuleProps {
	title: TranslationKey;
	moduleSchema: ModuleSchema;
	t2Schema: T2Schema;
	note?: string | null;
	closingNotes?: string;
	children?: (params: { belongsToLuc?: BelongsToLuc, isSWW?: (inputType: FieldType) => boolean }) => JSX.Element;
	beModuleType: BEModules,
	isLoading?: boolean,
	footerError?: string,
	blockingError?: string
	disableOptionals?: boolean
	isError?: boolean
}

//input
export interface StartWithWithout {
	start: string | number | boolean;
	with: string | number | boolean;
	without: string | number | boolean;
}

enum FieldType {
	CLASSIC = "classic",
	SWW = "sww",
	SWW_SELECT = "sww_select",
	SWW_BOOL = "sww_bool",
	SWW_T2 = "sww_t2",
	HECTARES = "hectares",
	BOOLEAN = "boolean",
	RADIO = "radio",
	SELECT = "select",
	SELECT_LUC = "select_luc",
	CREATEABLE_SELECT = "creatable_select",
	SELECT_T2 = "select_t2",
	CLASSIC_T2 = "classic_t2",
	EMISSIONS_T2 = "emissions_t2",
	DYN_EMISSIONS_T2 = "dyn_emissions_t2",
	DATEPICKER = "datepicker",
	CHECKBOXES = "checkboxes",
	BOOLEAN_T2 = "boolean_t2",
}

export interface FetchOptionsKit {
	data: Option[];
	isLoading: boolean;
	error: ErrorType;
}
export type DropdownOptions = Option[] | (() => Promise<FetchOptionsKit>) | ((props?: any)=>Promise<FetchOptionsKit>);

//input groups
type InputName = string | ((index: number) => string);
type SWWPropsType = {
	inputType?: "drop" | "free" | "boolean" | "number" | "percentage";
	disabled?: boolean;
	dropdownOptions?: DropdownOptions;
};
type SWWT2PropsType = {
	inputType: "number";
	disabled?: boolean;
	defaultValue?: number;
};

type TranslationKeys<T, P extends string = ''> = T extends object
	? {
		[K in keyof T]: T[K] extends object
		? `${P}${K & string}.${TranslationKeys<T[K]>}`
		: `${P}${K & string}`;
	}[keyof T]
	: never;

export type TranslationKey = TranslationKeys<typeof translationJson> | "" | 'α';
interface InputGroup {
	type: FieldType;
	label: TranslationKey;
	inputName: InputName;
	help?: Help;
	comment?: Comment;
	index?: number;
	disabled?: boolean;
	unit?: string;
	autosubmit?: boolean;
	placeholder?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface StartWithWithoutGeneric extends InputGroup {
	type: FieldType.SWW | FieldType.SWW_BOOL | FieldType.SWW_SELECT;
	startProps?: SWWPropsType;
	withProps?: SWWPropsType;
	withoutProps?: SWWPropsType;
	showCommentIcon?: boolean;
	thread?: string;
}

interface StartWithWithoutTierTwo extends InputGroup {
	type: FieldType.SWW_T2;
	unit: string;
	links: string[];
	startProps: SWWT2PropsType;
	withProps: SWWT2PropsType;
	withoutProps: SWWT2PropsType;
}

interface Option {
	id: number;
	name: string;
	name_en?: string;
	name_fr?: string;
	name_es?: string;
}

interface ClimateOption extends Option {
	moistures: number[]
}

interface FuelOption extends Option {
	macro_fuel_type: Option,
	fuel_use_type: number
}
interface SalinityOption extends Option {
	value: string;
}

interface HectaresInputGroup extends InputGroup {
	type: FieldType.HECTARES;
	inputType?: "text" | "password" | "number" | "percentage";
}
interface DropdownInputGroup extends InputGroup {
	type: FieldType.SELECT;
	dropdownOptions: DropdownOptions;
	translateOptions?: boolean;
}
type LucDropdownOptions= ((module_types: number)=>Promise<FetchOptionsKit>)
interface LucDropdownInputGroup extends InputGroup {
	type: FieldType.SELECT_LUC;
	dropdownOptions: LucDropdownOptions
	translateOptions?: boolean;
	module_types?: number	
}
interface CreateDropdownInputGroup extends InputGroup {
	type: FieldType.CREATEABLE_SELECT;
	dropdownOptions: DropdownOptions;
	onCreate?: (value: string) => Promise<Option | undefined>;
}
interface DropdownInputGroupTierTwo extends InputGroup {
	type: FieldType.SELECT_T2;
	dropdownOptions: DropdownOptions;
	unit: string;
	links: string[];
	defaultValue: number | string;
}
interface ClassicInputGroup extends InputGroup {
	type: FieldType.CLASSIC;
	inputType: "number" | "text" | "password" | "percentage";
	textend?: boolean;
}
interface ClassicInputGroupTierTwo extends InputGroup {
	type: FieldType.CLASSIC_T2;
	inputType: "number" | "text" | "password" | "percentage";
	textend?: boolean;
	unit: string;
	links: string[];
	defaultValue: number | string;
}
interface BooleanInputGroup extends InputGroup {
	type: FieldType.BOOLEAN;
}
interface BooleanInputGroupTierTwo extends InputGroup {
	type: FieldType.BOOLEAN_T2;
	links: string[];
}
interface RadioInputGroup extends InputGroup {
	type: FieldType.RADIO;
	options: { label: TranslationKey; value: string | number }[];
}
interface CheckboxesInputGroup extends InputGroup {
	type: FieldType.CHECKBOXES;
	options: { name: TranslationKey; id: string | number }[]; //this cannot be an Option[] because there's a bug with numbers as checkboxes values
}
interface EmissionsTierTwo extends InputGroup {
	type: FieldType.EMISSIONS_T2;
	links: string[];
	co2Props: {
		defaultValue?: number;
		disabled?: boolean;
		unit: string;
	};
	n2oProps: {
		defaultValue?: number;
		disabled?: boolean;
		unit: string;
	};
	co2eProps: {
		defaultValue?: number;
		disabled?: boolean;
		unit: string;
	};
}
interface DynamicEmissionsTierTwo extends InputGroup {
	type: FieldType.DYN_EMISSIONS_T2;
	links: string[];
	propList: {
		defaultValue?: number;
		disabled?: boolean;
		unit: string;
		column: string;
		propInputName: string;
	}[];
}
interface DatepickerInputGroup extends InputGroup {
	type: FieldType.DATEPICKER;
}
interface ResultSliderItem {
	name: string;
	value: number;
	unit: TranslationKey
}

export type {
	Option,
	ClimateOption,
	Note,
	Comment,
	Help,
	ModuleProps,
	ModuleSchema,
	T2Schema,
	Submodule,
	OptionalSubmodule,
	InputGroup,
	HectaresInputGroup,
	StartWithWithoutGeneric,
	StartWithWithoutTierTwo,
	DropdownInputGroup,
	CreateDropdownInputGroup,
	DropdownInputGroupTierTwo,
	ClassicInputGroup,
	ClassicInputGroupTierTwo,
	BooleanInputGroup,
	RadioInputGroup,
	CheckboxesInputGroup,
	EmissionsTierTwo,
	DynamicEmissionsTierTwo,
	DatepickerInputGroup,
	BelongsToLuc,
	FuelOption,
	BooleanInputGroupTierTwo,
	SalinityOption,
	ResultSliderItem,
	LucDropdownInputGroup,
	LucDropdownOptions,
	ModuleShared,
	CachedResultModule,
	LandModule
};

export { FieldType };
