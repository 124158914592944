import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import type { RootState, AppDispatch } from "./store";
import {
  ClimateOption,
  FieldType,
  type CommentGroup,
  type DropdownInputGroup,
  type InputGroup,
  type ModuleSchema,
  type Note,
  type Option,
  type Submodule,
} from "../types/modulesInterfaces";
import {
  selectCurrentModuleNotes,
  setCurrentModuleNotes,
  setCurrentModuleThreads,
  setNewCurrentModuleNotes,
} from "./features/builder/builderSlice";
import { checkErrorsTouched } from "../pages/activityBuilder/modules/inputs/utils";
import type { Errors, Touched } from "../pages/activityBuilder/modules/inputs/utils";
import { selectCurrentProject } from "./features/project/projectSlice";
import { Project, Role, User } from "../types/interfaces";
import { selectCurrentUser } from "./features/auth/authSlice";
import { ChangesResponseType } from "../components/actions/changes/changesTypes";
import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";
import { getThreadIds } from "../pages/activityBuilder/modules/moduleUtils";
import { useGetClimateTypesQuery, useGetMoistureTypesQuery } from "./features/dropdownOptions/dropdownOptionsApiSlice";
import { useFormikContext } from "formik";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch; //to dispatch reducers
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; //to get states

interface WindowDimentions {
  width: number;
  height: number;
}

function getWindowDimentions(): WindowDimentions {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const useWindowDimetions = (): WindowDimentions => {
  const [windowDimentions, setWindowDimetions] = useState(getWindowDimentions);
  useEffect(() => {
    const handleResize = () => setWindowDimetions(getWindowDimentions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimentions;
};

export const useInterval = (callback: () => void, delay: number) => {
  const intervalRef = useRef<null | number>(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current ?? 0);
    }
  }, [delay]);
  return intervalRef;
};

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const useSyncNotesWithStore = ({
  notes
}: {
  notes: Note | null;
}
) => {
  const newNotes = useAppSelector(selectCurrentModuleNotes);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setCurrentModuleNotes(notes));
    return () => {
      dispatch(setCurrentModuleNotes(null));
      dispatch(setNewCurrentModuleNotes(null));
    };
  }, [notes]);

  return { notes: newNotes ?? null };
};

//TODO: replace any in VariableSubmodule and think better solution
interface VariableSubmodule {
  data: any,
  schema: Submodule | null
}
/**
 * useSyncModuleWithStore
 * Performs side effects without returning values
 * Dispatches notes and comments necessary data to the store
 *
 * @param T1Data - Module Full data without tiertwo
 * @param moduleSchema - This will be used to create entities to render comments. 
 * @param isT1DataLoading - We don't perform side effects till we have api data. This should be true when the respective hook for the values in T1Data is loading
 * @param notes - We just dispatch notes to the store
 * @param variableSubmodules - A variable Submodule means the user can add as per his needs. We need to create a comment group for each submodule created. It takes the schema and the api data for the variable submodule.
 * @returns {void} 
 */
export const useThreadsProcessor = <T>() => {
  const dispatch = useAppDispatch();

  const processModuleThreads = useCallback((moduleData?: Partial<T> | null) => {
    if (!moduleData) return;
    const threadIds = getThreadIds<T>(moduleData);
    dispatch(setCurrentModuleThreads(threadIds))
  }, [dispatch]);

  const resetThreads = useCallback(() => {
    dispatch(setCurrentModuleThreads([]))
  }, [dispatch])

  useEffect(() => {
    return () => resetThreads()
  }, [dispatch, resetThreads]);

  return {
    processModuleThreads,
  }
};


export const useScrollToElement = (offset = -450) => {
  const pulseAnimate = (element: HTMLElement) => {
    element.style.transition = 'transform 1s';
    element.style.transform = 'scale(1.5)';

    setTimeout(() => {
      element.style.transition = 'transform 0.5s';
      element.style.transform = 'scale(1)';
    }, 1000)
  }

  const scrollToElementById = useCallback((id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      if (element.tagName !== 'ARTICLE') pulseAnimate(element)
    }
  }, [offset]);
  return {
    scrollToElementById
  }
}

export const useHasFormErrors = ({ errors, touched }: { errors: Errors, touched: Touched }) => {
  const hasFormError = useMemo(() => checkErrorsTouched(errors, touched), [errors, touched])

  return hasFormError
}
export interface UserGeoLocation {
  loaded: boolean;
  coordinates: {
    lat: number;
    lng: number;
  };
}
export const useGeoLocation = () => {
  const [location, setLocation] = useState<UserGeoLocation>({
    loaded: false,
    coordinates: { lat: 0, lng: 0 },
  });
  const [error, setError] = useState<string | null>(null);

  const onSuccess = (location: any) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = (error: any) => {
    setError(error.message);
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return { location, locationError: error };
};

export interface Coord {
  lat: number;
  lng: number;
}


export const useCenter = (polygonCoords: Coord[] | null): Coord => {
  const [center, setCenter] = useState<Coord>({ lat: 41.879180, lng: 12.495220 });

  useEffect(() => {
    if (!polygonCoords) return;

    let latSum = 0;
    let lngSum = 0;

    polygonCoords.forEach(coord => {
      latSum += coord.lat;
      lngSum += coord.lng;
    });

    const latCenter = latSum / polygonCoords.length;
    const lngCenter = lngSum / polygonCoords.length;

    setCenter({ lat: latCenter, lng: lngCenter });
  }, []);

  return center

};

export const useWarningMessageT2 = () => {
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const handleDefaultsError = useCallback((message: string) => {
    setWarningMessage(message);
  }, []);
  return { warningMessage, handleDefaultsError, setWarningMessage }
}


export const usePermissions = () => {
  const { project } = useAppSelector(selectCurrentProject);
  const user: User | null = useAppSelector(selectCurrentUser);

  const getUserRole = (passedProject?: Project | null): Role | undefined => passedProject ? passedProject?.role?.[0] : project?.role?.[0];

  const isAdmin = (): boolean => getUserRole() === Role.ADMIN;
  const isAnalyst = (): boolean => getUserRole() === Role.ANALYST;
  const isReceiver = (): boolean => getUserRole() === Role.RECEIVER;

  const canMutateComment = (commentOwnerId: number): boolean => {
    if (isAnalyst()) {
      return commentOwnerId === user?.id;
    }
    return isAdmin();
  };

  const adminOrAnalyst = isAdmin() || isAnalyst();
  const isReadOnly = isReceiver()

  return {
    canMutateComment,
    getUserRole,
    canMutateProject: adminOrAnalyst || !project,
    canMutateNote: adminOrAnalyst,
    canShare: adminOrAnalyst,
    isReadOnly,
  };
};


//TODO: move to 1 hook

export const useCustomTranslation = (key: string | undefined): string | null => {
  const { t, i18n } = useTranslation();
  if (!key || typeof key !== 'string') return null
  const t2 = i18n.exists(key) ? t(key) : key
  return t2
}

export const useCustomTranslationHandler = (): (key: string | undefined) => string | null => {
  const { t, i18n } = useTranslation();

  return (key: string | undefined) => {
    if (!key) return null
    const t2 = i18n.exists(key) ? t(key) : key
    return t2
  }
}

export const useGetAppLanguage = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  function languageOptionKey(): keyof Omit<Option, "id"> {
    switch (language) {
      case "en":
        return "name_en"
      case "fr":
        return "name_fr"
      case "es":
        return "name_es"
      default:
        return "name"
    }
  }
  return { language, apiLanguageField: languageOptionKey() }
}


interface SearchProps {
  list: ChangesResponseType[],
  searchString: string
}

const SearchOptions = {
  keys: ["reason", "user", "changes.field", "changes.old", "changes.new"],
  includeScore: true,
  threshold: 0.3
}

export const useSearch = ({ list, searchString }: SearchProps) => {
  const [searchResults, setSearchResults] = useState<ChangesResponseType[]>([])
  useEffect(() => {
    if (list?.length && searchString.length) {
      const fuse = new Fuse(list, SearchOptions);
      const results = fuse.search(searchString);
      setSearchResults(results.map((result) => result.item));
    } else if (list?.length) {
      setSearchResults(list)
    }
  }, [list, searchString])

  return { searchResults }
}

export function useClimateAndMoistureFormHandlers() {
  const { setFieldValue } = useFormikContext();
  const { data: climateTypes } = useGetClimateTypesQuery();
  const { data: moistureTypes } = useGetMoistureTypesQuery();

  const handleClimateInputChange = (e: React.ChangeEvent<HTMLInputElement>, moisture: number | null | undefined, moistureFieldName: string) => {
    const climateChosen = e.target.value;
    const filteredMoistures = getMoisturesByClimate(climateTypes, +climateChosen, moistureTypes);
    if (!filteredMoistures.find(opt => opt.id === moisture) && moisture) {
      setFieldValue(moistureFieldName, null);
    }
  };

  const getMoistureOptions = (climate: number | null | undefined) => {
    return getMoisturesByClimate(climateTypes, climate, moistureTypes);
  }

  function getMoisturesByClimate(
    climateTypes: ClimateOption[] | undefined,
    climateChosen: number | null | undefined,
    moistureTypes: Option[] | undefined
  ): Option[] {
    if (!climateChosen) return moistureTypes ?? [];

    const climateType = climateTypes?.find(c => c.id === climateChosen);
    return climateType
      ? moistureTypes?.filter(m => climateType.moistures.includes(m.id)) ?? []
      : moistureTypes ?? [];
  }

  return {
    climateTypes,
    moistureTypes,
    handleClimateInputChange,
    getMoistureOptions,
    setFieldValue,
  };
}