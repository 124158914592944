import { getOptions } from "../../../app/features/dropdownOptions/fetchOptions";
import type {
	ClassicInputGroup,
	DropdownInputGroup,
} from "../../../types/modulesInterfaces";
import { FieldType } from "../../../types/modulesInterfaces";
import * as Yup from "yup";
import { validatePositiveNumber } from "../../activityBuilder/modules/moduleUtils";

export interface ProjectForm {
	name: string;
	code: string | null;
	cost: number | null;
	funding_agency: string | null;
	executing_agency: string | null;
	country: number | null;
	climate: number | null;
	moisture: number | null;
	soil_type: number | null;
	implementation_years: number | null;
	start_year_of_activities: number | null;
	last_year_of_accounting: number | null;
	// capitalization_years: number | null;
	total_duration_of_accounting: number | null;
	gw_potential: {
		source: number;
		co2?: number;
		ch4?: number;
		n2o?: number;
	};
	soc_ref?: number | null;
	soc_ref_t2: number | null;
	status: number | null;
}

export const validationSchema = Yup.object({
	name: Yup.string().required('validations.project_title_required').max(100, 'project_title_max'),
	code: Yup.string().nullable(),
	cost: validatePositiveNumber.nullable(),
	funding_agency: Yup.string().nullable(),
	executing_agency: Yup.string().nullable(),
	country: validatePositiveNumber.required('validations.country_required'),
	climate: validatePositiveNumber.required('validations.climate_required'),
	moisture: validatePositiveNumber.required('validations.moisture_required'),
	soil_type: validatePositiveNumber.required('validations.soil_type_required'),
	implementation_years: validatePositiveNumber.required('validations.implementation_phase_required'),
	start_year_of_activities: validatePositiveNumber.required('validations.start_year_of_activities_required'),
	last_year_of_accounting: validatePositiveNumber
		.required('validations.last_year_of_accounting_required')
		.test(
			'last_year_of_accounting',
			'last_year_of_accounting_max_years',
			function (value) {
				const { start_year_of_activities } = this.parent;
				if (!value) {
					return true;
				}
				return value <= start_year_of_activities + 150;
			},
		)
		.test(
			'last_year_of_accounting',
			'last_year_of_accounting_after_implementation',
			function (value) {
				const { start_year_of_activities, implementation_years } = this.parent;
				if (!value) {
					return true;
				}
				return value >= start_year_of_activities + implementation_years;
			},
		),
	total_duration_of_accounting: validatePositiveNumber,
	gw_potential: Yup.object({
		source: validatePositiveNumber.required('validations.gw_potential_source_required'),
		co2: validatePositiveNumber,
		ch4: validatePositiveNumber,
		n2o: validatePositiveNumber,
	}),
	soc_ref: validatePositiveNumber,
	soc_ref_t2: validatePositiveNumber,
	status: validatePositiveNumber,
});
export const initialValues: ProjectForm = {
	name: "",
	code: null,
	cost: null,
	funding_agency: null,
	executing_agency: null,
	country: null,
	climate: null,
	moisture: null,
	soil_type: null,
	implementation_years: null,
	// capitalization_years: null,
	start_year_of_activities: null,
	last_year_of_accounting: null,
	total_duration_of_accounting: null,
	gw_potential: {
		source: 1,
		co2: undefined,
		ch4: undefined,
		n2o: undefined,
	},
	soc_ref: undefined,
	soc_ref_t2: null,
	status: null,
};

//1.1 DESCRIPTION
export const projectTitle: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "name",
	inputType: "text",
	label: "project.project_title",
};
/* export const date: DatepickerInputGroup = {
		type: FieldType.DATEPICKER,
		inputName: "date",
		label: "Date",
} */
//TODO: show date only if we're showing an already created project
export const projectCode: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "code",
	inputType: "text",
	label: "project.project_code",
};
export const projectCost: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "cost",
	inputType: "number",
	label: "project.project_cost",
	textend: true,
};
// Agencies will become select after a while, or creatable select if validation is added
export const fundingAgency: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputType: "text",
	inputName: "funding_agency",
	label: "project.funding_agency",
};
export const executingAgency: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputType: "text",
	inputName: "executing_agency",
	label: "project.executing_agency",
};
export const status: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "status",
	label: "project.status",
	dropdownOptions: () => getOptions("projectStatus"),
};

export const country: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "country",
	label: "project.country",
	dropdownOptions: () => getOptions("country"),
};
export const soilType: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "soil_type",
	label: "project.soil_type",
	dropdownOptions: () => getOptions("soil"),
};
export const startYearOfActivities: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "start_year_of_activities",
	inputType: "number",
	label: "project.start_year_of_activities",
	textend: true,
};
export const lastYearOfAccounting: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "last_year_of_accounting",
	inputType: "number",
	label: "project.last_year_of_accounting",
	textend: true,
};
export const implementationPhase: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "implementation_years",
	inputType: "number",
	label: "project.implementation_years",
	textend: true,
};
export const capitalizationPhase: ClassicInputGroup = {
	/* to be removed */
	type: FieldType.CLASSIC,
	inputName: "capitalization_years",
	inputType: "number",
	label: "project.capitalization_years",
	textend: true,
	disabled: true,
};
export const totalDurationOfAccounting: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "total_duration_of_accounting",
	inputType: "number",
	label: "project.total_duration_of_accounting",
	textend: true,
	disabled: true,
};

//GLOBAL WARMING POTENTIAL
export const source: DropdownInputGroup = {
	type: FieldType.SELECT,
	inputName: "gw_potential.source",
	label: "project.gw_potential.source",
	dropdownOptions: () => getOptions("GWPSource"),
};
export const co2: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "gw_potential.co2",
	inputType: "text",
	label: "project.gw_potential.co2",
	textend: true,
	disabled: true,
};
export const ch4: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "gw_potential.ch4",
	inputType: "text",
	label: "project.gw_potential.ch4",
	textend: true,
	disabled: true,
};
export const n2o: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "gw_potential.n2o",
	inputType: "text",
	label: "project.gw_potential.n2o",
	textend: true,
	disabled: true,
};

//REFERENCE SOIL ORGANIC..
/* socref: "",
		socreft2: "", */
export const socref: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "soc_ref",
	inputType: "text",
	label: "main.default",
	textend: true,
	disabled: true,
};
export const socreft2: ClassicInputGroup = {
	type: FieldType.CLASSIC,
	inputName: "soc_ref_t2",
	inputType: "number",
	label: "module.tier_two",
	textend: true,
};
