import { IconType, IconBaseProps } from "react-icons";

import Note from "../assets/icons/note.svg";
import NoteActive from "../assets/icons/note_active.svg";
import NoteGray from "../assets/icons/note_gray.svg";
import Comment from "../assets/icons/comment.svg";
import CommentActive from "../assets/icons/comment_active.svg";
import HelpVideo from "../assets/icons/help_video.svg";
import HelpInfo from "../assets/icons/help_info.svg";
import ReplyArrow from "../assets/icons/reply_arrow.svg";
import GroupArrow from "../assets/icons/group_arrow.svg";
import LinkedT2 from "../assets/icons/linked.svg";
import LinkedT2White from "../assets/icons/linked_white.svg";
import UnlinkedT2 from "../assets/icons/unlinked.svg";
import CubeLight from "../assets/icons/bxs_cube_white.svg";
import Cube from "../assets/icons/bxs_cube.svg";
import Cubes from "../assets/icons/triple_cubes.svg";
import Separator from "../assets/icons/slider_divider.svg";
import Bolt from "../assets/icons/bolt.svg";
import BoltFilled from "../assets/icons/bolt_filled.svg";

export const NoteIcon: IconType = ({ fill, ...props }: IconBaseProps) => (
	<img src={fill ? NoteActive : Note} style={props.style} alt="note icon" />
);

interface ExtendedIconBaseProps extends IconBaseProps {
	onClick?: () => void;
  }
  
  export const CommentIcon:  React.FC<ExtendedIconBaseProps> = ({ fill, onClick, ...props }: ExtendedIconBaseProps) => (
	<img
	  src={fill ? CommentActive : Comment}
	  style={props.style}
	  alt="comment icon"
	  onClick={onClick}
	/>
  );

export const HelpIcon: IconType = ({ fill, ...props }: IconBaseProps) => (
	<img src={fill ? HelpVideo : HelpInfo} style={props.style} alt="help icon" />
);

export const NoteGrayIcon: IconType = () => (
	<img src={NoteGray} alt="note icon" />
);

export const ReplyArrowIcon: IconType = () => (
	<img src={ReplyArrow} alt="reply icon" className="p-1" />
);

export const GroupArrowIcon: IconType = () => (
	<img src={GroupArrow} alt="split arrow" className="" />
);

export const TripleCubes: IconType = () => (
	<img src={Cubes} alt="modules cubes" className="" />
);
export const SliderSeparator: IconType = () => (
	<img src={Separator} alt="separator" className="" />
);

export const TierTwoIcon: IconType = ({ fill, ...props }: IconBaseProps) => (
	<img
		src={fill === "white" ? LinkedT2White : fill ? LinkedT2 : UnlinkedT2}
		alt="split arrow"
		className=""
	/>
);

export const MyBolt: IconType = (props: IconBaseProps) => {
	const isWhite: boolean = ["#fff", "white", "var(--white)"].includes(
		props.color || "",
	);
	return <img src={isWhite ? BoltFilled : Bolt} alt="bolt icon" />;
};

export const ModulesCube: IconType = ({ fill, ...props }: IconBaseProps) => {
	return (
		<img
			src={fill === "white" ? CubeLight : Cube}
			alt="split arrow"
			className=""
			style={{ height: "16px" }}
		/>
	);
};
