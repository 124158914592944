import React from 'react'

interface InputsContextType {
    inputsEntriesIds: number[]
    setInputsEntriesIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const InputsContext = React.createContext<InputsContextType | null>(null)

export const useInputsContext = () => {
    const context = React.useContext(InputsContext);
    if (context == null) {
      throw new Error('useInputsContext must be used within a InputsProvider');
    }
    return context;
}

export const InputsProvider = ({ children }: { children: React.ReactNode }) => {
    const [inputsEntriesIds, setInputsEntriesIds] = React.useState<number[]>([])
  return (
    <InputsContext.Provider value={{
        inputsEntriesIds,
        setInputsEntriesIds
    }}>
      {children}
    </InputsContext.Provider>
  )
}

export default InputsProvider