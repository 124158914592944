import { MdArrowForward } from "react-icons/md";
import {
	clearProgressiveModules,
	selectCurrentModules,
} from "../../../app/features/setupAssistant/setupAssistantSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import ModuleCard from "./ModuleCard";
import TranslatableText from "../../translations/TranslatableText";

const ModulesAction = () => {
	const dispatch = useAppDispatch();
	const modules = useAppSelector(selectCurrentModules); //these are the progressive modules
	const location = useLocation();
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);

	useEffect(() => {
		if (!location.pathname.endsWith("new-activity") && modules.length)
			dispatch(clearProgressiveModules());
	}, [location.pathname]);

	return (
		<motion.section
			className="w-100 h-100 py-2 px-3 bg-gray"
			initial={{ width: 0 }}
			animate={{ width: tabsOpen ? "inherit" : 0 }}
			exit={{ width: 0 }}
		>
			<div className="pos-relative mb-2 pb-1">
				<h2 className="fs-14 ff-medium-ext module-header w-fit">
					<TranslatableText translationKey="activity.wizard_modules_needed" />
				</h2>
			</div>
			{modules.map((mod) => (
				<div
					className="w-100 d-flex p-1 align-items-center justice-content-start"
					key={mod}
				>
					<div className="px-1">
						<MdArrowForward size={15} color="var(--dark-gray)" />
					</div>
					<ModuleCard module={mod} showDescription />
				</div>
			))}
		</motion.section>
	);
};

export default ModulesAction;
