import { useDispatch } from "react-redux";
import { useAppSelector, useScrollToElement } from "../../../app/hooks";
import { selectActiveThread, setActiveThread } from "../../../app/features/builder/builderSlice";
import CommentBlock from "./CommentsBlock";
import useComments from "./useComments";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import AlertModal from "../../modal/AlertModal";

interface CommentContentProps {
	threadId: number;
	groupLabel?: string;
	isActive: boolean;
	handleThreadCommentsLengthChange: (threadId: number, length: number) => void
}

const CommentContent = ({
	threadId,
	isActive,
	handleThreadCommentsLengthChange
}: CommentContentProps) => {
	const activeThread = useAppSelector(selectActiveThread)
	const dispatch = useDispatch()

	const {
		thread,
		handleCreateReply,
		handleDeleteComment,
		isLoading,
		isCreateCommentError,
		isDeleteCommentError,
	} = useComments({ threadId });

	useEffect(() => {
		if (thread)
			handleThreadCommentsLengthChange(thread.id, thread.comments.length)
	}, [thread])

	const { scrollToElementById } = useScrollToElement()

	if (!thread?.hasComments()) {
		return null
	}

	const handleThreadClick = () => {
		if (activeThread !== threadId) {
			dispatch(setActiveThread(threadId))
			scrollToElementById(threadId.toString())
		}
	}

	return (
		isLoading ?
			<div className="px-2">
				<Skeleton width={"100%"} height={80} baseColor="var(--gray)" />
			</div>
			:
			<button
				type="button"
				className="comments-action-container w-100 clickable"
				onClick={handleThreadClick}
			>
				<div className={`px-1 w-100 ${isActive ? "bg-light-gray" : "bg-gray"}`}>
					{thread?.comments?.map((comment) => {
						if (!comment.parent) {
							return (
								<CommentBlock
									comment={comment}
									key={comment.id}
									deleteHandler={handleDeleteComment}
									createHandler={handleCreateReply}
									id={`side-${threadId}`}
								/>
							);
						}
					})}
				</div>
				{
					isCreateCommentError || isDeleteCommentError ?
					<CommentErrorModal /> : null
				}
			</button>
	);
};

export default CommentContent;

//TODO: to include un modal refactor
const CommentErrorModal = () => {
	const [isOpen, setIsOpen] = useState(true)
  return (
	<AlertModal
	modalOpen={isOpen}
	handleCancel={() => setIsOpen(false)}
	>
		<p>An error occured</p>
	</AlertModal>
  )
}