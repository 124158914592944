import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { MdArrowBack } from 'react-icons/md';
import TranslatableText from '../translations/TranslatableText';

const BackToLoginButton = () => {
    const navigate = useNavigate();
	const handleClick = () => {
		navigate("/auth/login");
	};
  return (
    <Button type="button" classes="btn-login ff-medium" onClick={handleClick} style={{ maxWidth: 150}}>
        <TranslatableText className="bg-quat-de px-1 label" translationKey={"main.back_to_logn"} />
        <div className="bg-quat-br icon">
            <MdArrowBack size={20} color="var(--white)" />
        </div>
    </Button>
  )
}

export default BackToLoginButton