import { useContext } from "react"
import ForestContext from "../../../../../contexts/ForestContext"
import ChangesChildAction from "../ChangesChildAction"

const DisturbancesChanges = () => {
  const forestContext = useContext(ForestContext)
  return (
    <>
      {
        forestContext?.disturbancesIds && (
          forestContext.disturbancesIds.map((disturbanceId) => <DisturbanceChangeBlock key={disturbanceId} disturbanceId={disturbanceId} />)
        )
      }
    </>
  )
}

const DisturbanceChangeBlock = ({ disturbanceId }: { disturbanceId: number }) => {
  return (
    <ChangesChildAction itemId={disturbanceId} path="forest-disturbances" title="Disturbance" />
  )
}

export default DisturbancesChanges