import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import { useGetModuleDefinitionsQuery } from "../../../app/features/definitions/definitionsApiSlice";
import { useAppSelector } from "../../../app/hooks";
import { BEModules, FEModules } from "../../../utils/moduleList";
import { FEModulesObj, moduleDescriptions } from "../../../utils/moduleList";
import { DefinitionEntry } from "../../../app/features/definitions/definitionsTypes";
import { motion } from "framer-motion";
import useErrorMessage from "../../../utils/useErrorMessage";
import TranslatableText from "../../translations/TranslatableText";
import Definition from "./Definition";

const DefinitionsAction = () => {
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);

	const location = useLocation();
	const isInNewActivity = useMemo(() => location.pathname.endsWith('new-activity'), [location])

	const [definitions, setDefinitions] = useState<DefinitionEntry[]>([])
	const [currentBeModule, setCurrentBeModule] = useState<BEModules | null>(
		null,
	);

	const { data, isLoading, isError, error } = useGetModuleDefinitionsQuery(
		currentBeModule,
		{
			skip: !currentBeModule || isInNewActivity,
			refetchOnMountOrArgChange: true,
		},
	);

	const { errorMsg } = useErrorMessage({ isError, error });

	useEffect(() => {
		const currentModulePath = location.pathname.split("/").pop();
		const currentModule = moduleList.find(
			(step) => step.path === currentModulePath,
		);

		if (currentModule) {
			const beModule = FEModulesObj[currentModule.id].beModuleId;
			setCurrentBeModule(beModule ?? null);
		}
	}, [location, moduleList]);

	//if we're creating a new activity we want to show the modules definitions, on builder we fetch them from BE 
	useEffect(() => {
		if (isInNewActivity) {
			const modules: FEModules[] = Object.keys(FEModulesObj) as unknown as FEModules[];
			const definitionEntries: DefinitionEntry[] = modules.map((mod) => ({
				name: FEModulesObj[mod]?.name,
				definition: moduleDescriptions[mod]
			})).filter(def => def.name !== "project.activity_complete")
			setDefinitions(definitionEntries);
		}
		else if (data && data.length > 0) setDefinitions(data?.map((def) => ({ name: `definitions.${def.name}`, definition: def.definition })))
	}, [isInNewActivity, data])

	return (
		<motion.section className="bg-gray w-100 h-100 d-flex f-column p-2"
			initial={{ width: 0 }}
			animate={{ width: tabsOpen ? "inherit" : 0 }}
			exit={{ width: 0 }}>
			<h3>

				{errorMsg
					? errorMsg
					: isLoading && !isInNewActivity
						? <><TranslatableText translationKey={"main.definitions"} /> <TranslatableText translationKey={"main.loading"} />...</>
						: <TranslatableText translationKey={"main.definitions"} />
				}

			</h3>
			<div className="py-2">
				{data || isInNewActivity ? (
					<ul className="ps-2 ff-normal-cond">
						{definitions.map(({ definition, name }, i) => {
							return <Definition translatableKey={name} value={definition} key={name} />
						}
						)}
					</ul>
				) : !isLoading ? (
					<p>
						<TranslatableText translationKey={"definitions.not_available"} />
					</p>
				) : null}
			</div>
		</motion.section>
	);
};

export default DefinitionsAction;


