import {
	convertIncomingPercentage,
	convertOutgoingPercentage,
	filterObjectProps,
	convertEmptyStringsToNull
} from "../../../../pages/activityBuilder/modules/inputs/utils";
import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/largeFisheries/largeFisheries";
import type {
	LargeFisheriesPayload,
	LargeFisheriesResponse,
	ModuleFull,
	LargeFisheriesT2,
	UpdateLargeFisheriesPayload,
	LargeFisheriesDefaults,
} from "../../../../pages/activityBuilder/modules/largeFisheries/largeFisheriesTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getLargeFisheries: builder.query<ModuleFull | null, number>({
			query: (activityId) => `large-fisheries/?activity=${activityId}`,
			transformResponse: (response: LargeFisheriesResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						response[0],
						includedPropList,
					) as LargeFisheriesPayload;
					const t2 = filterObjectProps(
						response[0],
						includedT2PropList,
					) as LargeFisheriesT2;

					convertIncomingPercentage(module, [
						"ice_preserved_catch_pc",
						"refrigerant_pc",
					]);

					return {
						module: module,
						tiertwo: t2,
					} as ModuleFull;
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "LargeFisheries", id: activityId },
			],
		}),
		updateLargeFisheries: builder.mutation<
			LargeFisheriesResponse,
			UpdateLargeFisheriesPayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module };
				const lucId = data.module.land_use_change;
				convertOutgoingPercentage(module, [
					"ice_preserved_catch_pc",
					"refrigerant_pc",
				]);

				const payload = {
					...module,
					...data.tiertwo,
				} as LargeFisheriesResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, j: activityId, land_use_change: lucId };

				convertEmptyStringsToNull(body)
				return { url: `large-fisheries/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, { activityId }) => [
				{ type: "LargeFisheries", id: activityId },
				{ type: "ModulesStatuses", id: activityId },
				{ type: "ModuleResults", id: results?.id ? `large-fisheries-${results?.id}` : undefined },
				{ type: "ModuleHistory", id: results?.id ? `large-fisheries-${results?.id}` : undefined },
				{ type: 'LargeFisheriesDefaults', id: results?.id ? `large-fisheries-${results?.id}` : undefined },
			],
		}),
		getLargeFisheriesDefaults: builder.query<LargeFisheriesDefaults, number>({
			query: (uniqueId) => `large-fisheries/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: 'LargeFisheriesDefaults', id: `large-fisheries-${uniqueId}` }],
		})
	}),
});

export const { useGetLargeFisheriesQuery, useUpdateLargeFisheriesMutation, useGetLargeFisheriesDefaultsQuery } =
	moduleApiSlice;
